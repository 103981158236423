import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DbReservation } from '../../models/DbReservation';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';


@Component({
  standalone: true,
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss'],
  imports: [MatIconModule, MatTooltipModule, TranslocoModule, MatDialogModule, MatFormFieldModule, MatDatepickerModule, MatSelectModule, MatCheckboxModule]
})
export class PaymentLinkComponent {
  reservation: DbReservation;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (typeof data.reservation != 'undefined') {
      this.reservation = data.reservation;
    }
  }
}
