<div class="flex flex-col max-w-240 max-h-screen">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'komunikat' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <div class="h-120">
        <premium-extend (onExtendSubscriptionButtonClick)="close()" [reason]="reason"></premium-extend>
    </div>
    
    <!--Footer-->
    <div class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-stroked-button mat-dialog-close class="me-2">
                    {{'zamknij' | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>