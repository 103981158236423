import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  standalone:true,
  selector: '[integerOnly][integerOnlyMinValue]'
})
export class IntegerOnlyDirective {

    @Input('integerOnlyMinValue') minValue: number = 0
    @Input('integerOnlyMaxValue') maxValue: number = 0


  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.el.nativeElement.value;
    const newValue = initialValue.replace(/[^0-9]/g, '');
    if (newValue !== '0') {
      this.control.control.setValue(Number(newValue));
    } else {
      this.control.control.setValue(this.minValue);
    }

    if(this.maxValue != null && this.maxValue > 0 && newValue > this.maxValue){
      this.control.control.setValue(this.maxValue);
    }
  }
}