import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, from, map, of, tap } from "rxjs";
import { DbMessage } from "../models/DbMessage";
import { AppDB } from "../models/db";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { DbService } from "./db.service";
import { DbReservation } from "../models/DbReservation";
import { ReservationsService } from "./reservations.service";
import { Utils } from "../others/utils";

@Injectable({
    providedIn: 'root'
})
export class MessagesService {

    private _subscriptions: Subscription[] = [];

    private _groups = new BehaviorSubject<ReservationMessagesObject>({})
    public currentGroups: Observable<ReservationMessagesObject> = this._groups.asObservable()

    private _messages = new BehaviorSubject<DbMessageObject>({})
    public currentMessages: Observable<DbMessageObject> = this._messages.asObservable()

    private _db: AppDB

    private _counter = new BehaviorSubject<DbMessageCounter>({
        valid: 0,
        deleted: 0
    })
    // Clean subscriptions
    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

    constructor(
        private _dbService: DbService,
        private _dataSynchronizerService: DataSynchronizerService,
        private _reservationsService: ReservationsService
    ) {

        this._subscriptions.push(
            this._dbService.getDatabase().subscribe({
                next: (data) => {
                    this._db = data
                    this._messages.next({})
                }
            })
        )

        this._subscriptions.push(this.currentMessages.subscribe({
            next: (data) => {
                this._countAll(data)
            }
        }))


        // Add reservation on synchronize
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {
                    if (data.table != 'messages') return
                    // Update
                    this._processMessages(data.value)
                    this._dataSynchronizerService.received()
                    this._messages.next(this._messages.value)

                }
            }))
    }

    getMessages(): DbMessageObject {
        return this._messages.value
    }
    public getCounter(): Observable<DbMessageCounter> {
        return this._counter.asObservable()
    }
    public getSenders() {
        const map = Object.values(this._messages.value).map(x => x.sender)
        let senders = [...new Set(map)];
        senders = senders.filter(x=> x != null && x?.length > 0)
        return senders
    }
    public browseMessages({ allResults = [], filters = [], searchValue, sender  }): PaginatedDbMessages {
        let t1 = new Date().getTime();


        let searchingResults = allResults.length > 0 ? allResults : Object.values(this._messages.value)
            .sort((a: DbMessage, b: DbMessage) => b.messageId - a.messageId)

        if(!Utils.isNullOrEmpty(searchValue)) {
            filters.push(
                (message:DbMessage) => 
                message?.templateName?.toLowerCase()?.includes(searchValue) ||
                message?.topic?.toLowerCase()?.includes(searchValue) ||
                message?.contents?.toLowerCase()?.includes(searchValue) ||
                message?.recipient?.some(rec => rec?.toLowerCase()?.includes(searchValue) ||
                message?.attachment?.some(att => att?.name?.toLowerCase()?.includes(searchValue))
            ))
        } 

        if(!Utils.isNullOrEmpty(sender)) {
            filters.push(
                (message:DbMessage) => 
                message?.sender == sender
            )
        }     

        if (filters.length > 0) {
            filters.forEach(filter => {
                searchingResults = searchingResults.filter(filter)
            })
        }

        const paginationResult: PaginatedDbMessages = {
            time: new Date().getTime() - t1,

            resultsCount: searchingResults.length,
            allResults: searchingResults
        }

        return paginationResult

    }

    getById(messageId: number): DbMessage {
        if (messageId == null) return null
        const reservation = this._messages.value[messageId]
        if (reservation) return reservation
        else console.warn(`Message with id: ${messageId} not found.`)

    }

    getAll(): Observable<boolean> {

        this._messages.next({});
        if(!this._db) return of(true)
        let startTime = 0

        const loader = from(this._db.transaction("r", this._db.messages, () => {
            startTime = performance.now();
            return this._db.messages.toArray();
        }))
            .pipe(
                map((messages: DbMessage[]) => {
                    this._processMessages(messages);

                    this._messages.next(this._messages.value);
                    return true;
                }),
                tap(() => console.log(`[Messages Service]: Built in ${performance.now() - startTime}ms.`))
            );

        return loader
    }

    private _processMessages(messages: DbMessage[]): DbMessage[] {

        const updatedMessages: DbMessage[] = []
        messages.forEach(message => {

            if(typeof this._groups.value[message.reservationId] == 'undefined') this._groups.value[message.reservationId] = {
                isUnreaded: false,
                latestMessage: null,
                messages: {},
                reservation: null
            }

            message = new DbMessage(message)

            this._messages.value[message.messageId] = message;
            message.reservation = this._reservationsService.getById(message.reservationId)
            // this._groups.value[message.reservationId].messages[message.messageId] = message;
            // const messages = Object.values(this._groups.value[message.reservationId].messages)
            // const maxMessageId = messages.reduce((max, message) => {
            //     return message.messageId > max ? message.messageId : max;
            //   }, messages[0].messageId)

            // if(messages.length == 0 || maxMessageId <= message.messageId) {
            //     this._groups.value[message.reservationId].latestMessage = message
            // }
            // this._groups.value[message.reservationId]
            updatedMessages.push(message)
        })
        return updatedMessages
    }

    private _countAll(data) {
        let valid = 0
        let deleted = 0
        Object.values(data).forEach((message: DbMessage) => {
            if (message.status == 1) valid++
            else deleted++
        })

        this._counter.next({ valid, deleted })
    }
}

export interface DbMessageObject {
    [key: number]: DbMessage;
}

export interface ReservationMessagesObject {
    [key: number]: ReservationMessages;
}

export interface PaginatedDbMessages {
    allResults: DbMessage[]
    resultsCount: number
    time: number,
}
export interface DbMessageCounter {
    valid: number,
    deleted: number
}

export interface ReservationMessages {
    reservation: DbReservation
    isUnreaded: boolean
    latestMessage: DbMessage
    messages: MessageObject
}
export interface MessageObject {
    [key: number]: DbMessage;
}