import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable, Provider, inject } from "@angular/core"
import { after } from "lodash"
import { Observable, catchError, tap, pipe } from "rxjs"
import { Utils } from "../others/utils";
import { ChannelManagerComponent } from "../errors/channel-manager/channel-manager.component";
import { MatDialog } from "@angular/material/dialog";
import { OfflineModeInterceptor } from "./offline-mode.interceptor";

@Injectable()
export class ChannelManagerInterceptor implements HttpInterceptor {

    private dialogRef: MatDialog = inject(MatDialog)

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(!request) return next.handle(null)
        
        return next.handle(request)
        .pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.status >= 200 && event.status < 300) {
                    // Process successful responses (status code between 200 and 300)
                    this._chechChannelManagers(event.body)
                }
            })
        );
    }

    private _chechChannelManagers(body) {

        let retry = null
        const channelManagerErrors = []
        const channelManagerSuccesses = []

        if (Utils.isDefined(body, "data.channelManager.channels")) {
            body.data.channelManager.channels.forEach((channelManager:OTAChannelManagerResponse) => {
                if (channelManager.status == 'error') {
                    channelManagerErrors.push(channelManager)
                }
                if (channelManager.status == 'success') {
                    channelManagerSuccesses.push(channelManager)
                }
            });
        }

        if (Utils.isDefined(body, "data.channelManager.retry")) {
            retry = body.data.channelManager.retry
        }

        if(channelManagerErrors.length > 0) this._showChannerManagerErrorsDialog(channelManagerSuccesses, channelManagerErrors, retry)
        

    }

    private _showChannerManagerErrorsDialog(successes:OTAChannelManagerResponse[], errors:OTAChannelManagerResponse[], retry) {

        let disableClose = false
        if(errors.length > 0) disableClose = true
        this.dialogRef.open(ChannelManagerComponent, {
            maxWidth: '100%',
            disableClose: disableClose,
            data: { successes: successes, errors: errors, retry: retry }
        })
    }
}



export interface OTAChannelManagerResponse {
    channelName: string
    httpCode: number
    status: string
    result: string
    message: string
}
export const ChannelManagerInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ChannelManagerInterceptor,
    multi: true
}
