<div class="flex flex-col w-full min-h-[calc(100%-64px)] max-h-[calc(100%-64px)]">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            
            <!-- create -->
            <ng-container *ngIf="mode == 'create'">
                <p class="text-lg font-medium">
                    <span *ngIf="addReservationForm.controls.type.value != 2">
                        {{'dodaj_rezerwacje' | transloco}}
                    </span>
                    <span *ngIf="addReservationForm.controls.type.value == 2">
                        {{'dodaj_blokade' | transloco}}
                    </span>
                </p>
                <div class="ml-auto">
                    <button *ngIf="addReservationForm.controls.type.value != 2" mat-icon-button
                        [matTooltip]="('ustawienia' | transloco)" (click)="showOnFormSettings()">
                        <mat-icon [svgIcon]="'heroicons_solid:cog-8-tooth'"></mat-icon>
                    </button>
                    <!-- close -->
                    <button mat-icon-button [matTooltip]="'zamknij' | transloco" (click)="close()">
                        <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                    </button>
                </div>
            </ng-container>

            <!-- update -->
            <ng-container *ngIf="mode == 'update'">

                <p class="text-lg font-medium">
                    <span *ngIf="addReservationForm.controls.type.value != 2">
                        {{res?.groupId > 0 ? ('edytuj_rezerwacje_grupowa' | transloco) : ('edytuj_rezerwacje' |
                        transloco)}} <br />
                        <span class="text-sm text-secondary">{{reservation?.groupId > 0 ? ('grupa' | transloco) +' '+
                            reservation?.groupId : '#' + reservation?.reservationNo}}</span>
                    </span>
                    <span *ngIf="addReservationForm.controls.type.value == 2">
                        {{'edycja_blokady' | transloco}}
                    </span>
                </p>
                <div class="ml-auto">

                    <div class="inline-flex" *ngIf="!isSmallScreen">
                        
                        <button *ngIf="addReservationForm.controls.type.value != 2" mat-icon-button
                        (click)="showSendConfirmation()"
                        [disabled]="reservation?.status == 0 || !permissions.has('sendConfirmation')"
                        [matTooltip]="!permissions.has('sendConfirmation') ? translate.translate('brak_uprawnien') : translate.translate('wyslij_potwierdzenie')">
                        <mat-icon [matBadge]="reservation?.sentMessages"
                            [matBadgeHidden]="reservation?.sentMessages == 0" matBadgeColor="warn" matBadgeSize="small"
                            [svgIcon]="'heroicons_solid:paper-airplane'">
                        </mat-icon>
                        </button>

                        <button mat-icon-button *ngIf="reservation?.bookingDetails != null"
                            [matTooltip]="('pokaz_dane_z_booking_com' | transloco)"
                            (click)="showReservationBookingDataDialog()">
                            <img [src]="'assets/images/logo/booking.com.svg'">
                        </button>

                        <button mat-icon-button
                            [disabled]="reservation?.status == 0  || !permissions.has('sendConfirmation')"
                            [matTooltip]="!permissions.has('sendConfirmation') ? translate.translate('brak_uprawnien') : translate.translate('kopiuj')"
                            (click)="showCopyReservation()">
                            <mat-icon [svgIcon]="'heroicons_solid:document-duplicate'"></mat-icon>
                        </button>

                    </div>
                    
                    <button mat-icon-button [disabled]="reservation?.status == 0" [matTooltip]="('wiecej' | transloco)"
                        [matMenuTriggerFor]="wiecej">
                        <mat-icon [svgIcon]="'heroicons_solid:ellipsis-vertical'"></mat-icon>
                    </button>

                    <mat-menu #wiecej="matMenu">
                        <button *ngIf="addReservationForm.controls.type.value != 2" mat-menu-item
                            (click)="showOnFormSettings()"
                            [disabled]="reservation?.status == 0 || !permissions.has('settings')"
                            [matTooltip]="!permissions.has('settings') ? translate.translate('brak_uprawnien') : ''">
                            <mat-icon [svgIcon]="'heroicons_solid:cog-8-tooth'"></mat-icon>
                            <span>{{'ustawienia' | transloco}}</span>
                        </button>

                        <button [disabled]="reservation?.status == 0" mat-menu-item (click)="navigateToReservation()">
                            <mat-icon [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                            <span>{{'pokaz_na_kalendarzu' | transloco}}</span>
                        </button>

                        <button *ngIf="reservation.type != 2" [disabled]="reservation?.status == 0 || !permissions.has('invoicing')"
                            [matTooltip]="!permissions.has('invoicing') ? translate.translate('brak_uprawnien') : ''"
                            mat-menu-item [routerLink]="'/app/invoices/new' | localize"
                            [queryParams]="{ reservationNo: reservation?.reservationNo }">
                            <mat-icon [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                            <span>{{'wystaw_fakture' | transloco}}</span>
                        </button>

                        <button *ngIf="addReservationForm.controls.type.value != 2"
                            [routerLink]="('/app/reservations/confirmation/'+ reservation?.reservationId) | localize"
                            [disabled]="reservation?.status == 0  || !permissions.has('sendConfirmation')"
                            [matTooltip]="!permissions.has('sendConfirmation') ? translate.translate('brak_uprawnien') : ''"
                            mat-menu-item>
                            <mat-icon [svgIcon]="'heroicons_solid:printer'"></mat-icon>
                            <span>{{'potwierdzenie' | transloco}}</span>
                        </button>

                        <button mat-menu-item (click)="showHistoryDialog()" [disabled]="!permissions.has('history')"
                            [matTooltip]="!permissions.has('history') ? translate.translate('brak_uprawnien') : ''">
                            <mat-icon [svgIcon]="'heroicons_solid:clock'"></mat-icon>
                            <span>{{'historia' | transloco}}</span>
                        </button>

                        <ng-container *ngIf="isSmallScreen">
                            <button *ngIf="addReservationForm.controls.type.value != 2" mat-menu-item
                                (click)="showSendConfirmation()"
                                [disabled]="reservation?.status == 0 || !permissions.has('sendConfirmation')"
                                >
                                <mat-icon [matBadge]="reservation?.sentMessages"
                                    [matBadgeHidden]="reservation?.sentMessages == 0" matBadgeColor="warn" matBadgeSize="small"
                                    [svgIcon]="'heroicons_solid:paper-airplane'">
                                </mat-icon>
                                <span>{{'wyslij_potwierdzenie' | transloco}}</span>
                            </button>

                            <button mat-menu-item *ngIf="reservation?.bookingDetails != null"
                                (click)="showReservationBookingDataDialog()">
                                <img [src]="'assets/images/logo/booking.com.svg'">
                                <span>{{'pokaz_dane_z_booking_com' | transloco}}</span>
                            </button>

                            <button mat-menu-item
                                [disabled]="reservation?.status == 0  || !permissions.has('addReservation')"
                                (click)="showCopyReservation()">
                                <mat-icon [svgIcon]="'heroicons_solid:document-duplicate'"></mat-icon>
                                <span>{{'kopiuj' | transloco}}</span>
                            </button>
                        </ng-container>
                        <!-- <button mat-menu-item (click)="showUrlPaymentDialog()">
                            <mat-icon [svgIcon]="'heroicons_solid:credit-card'"></mat-icon>
                            <span>{{'link_do_platnosci' | transloco}}</span>
                        </button> -->

                    </mat-menu>

                    <button mat-icon-button [matTooltip]="'Close'" (click)="close()">
                        <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                    </button>

                </div>
            </ng-container>
        </div>
    </div>
    <div class="relative flex flex-col items-center" *ngIf="reservationFormView == 'TAB'">
        <div class="w-full max-w-3xl">
            <form [formGroup]="addReservationForm">
                <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex" 
                    (selectedIndexChange)="changeTab($event)" class="vertically-tabs p-0-content"
                    mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight dynamicWidth>
                    <mat-tab>
                        <ng-template matTabLabel>
                            {{addReservationForm.controls.type.value == 2 ? translate.translate('blokada') :
                            translate.translate('rezerwacja')}}
                            <mat-icon @shake *ngIf="errors?.rezerwacja"
                                [svgIcon]="'heroicons_outline:exclamation-triangle'" color="warn"></mat-icon>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="addReservationForm.controls.type.value != 2" color="warn">
                        <ng-template matTabLabel>
                            {{'klient' | transloco}}
                            <mat-icon *ngIf="errors?.klient" color="warn" @shake
                                [svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="addReservationForm.controls.type.value != 2" label="{{'szczegoly' | transloco}}">
                    </mat-tab>
                    <mat-tab *ngIf="addReservationForm.controls.type.value != 2" label="{{'finanse' | transloco}}">
                    </mat-tab>
                </mat-tab-group>
            </form>
        </div>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden mb-2">
        <div class="flex flex-col flex-auto overflow-y-auto p-2">

            <ng-container>
                <!-- body -->
                <div class="px-6 py-4">

                    <ng-container *ngIf="reservationFormView == 'TAB'">
                        <ng-container *ngIf="tabIndex === 0">
                            <ng-container *ngTemplateOutlet="reservationTemplate"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="tabIndex === 1">
                            <ng-container *ngTemplateOutlet="clientsTemplate"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="tabIndex === 2">
                            <ng-container *ngTemplateOutlet="detailsTemplate"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="tabIndex === 3">
                            <ng-container *ngTemplateOutlet="financialsTemplate"></ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="reservationFormView == 'NORMAL'">
                        <div class="text-xl font-semibold mb-4">
                            {{addReservationForm.controls.type.value == 2 ? translate.translate('blokada') :
                            translate.translate('rezerwacja')}}
                            <mat-icon @shake *ngIf="errors?.rezerwacja"
                                [svgIcon]="'heroicons_outline:exclamation-triangle'" color="warn"></mat-icon>
                        </div>
                        <ng-container *ngTemplateOutlet="reservationTemplate"></ng-container>

                        <ng-container *ngIf="addReservationForm.controls.type.value != 2">
                            <div class="text-xl font-semibold mt-8 mb-4">
                                {{'klient' | transloco}} <mat-icon *ngIf="errors?.klient" color="warn" @shake
                                    [svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
                            </div>
                            <ng-container *ngTemplateOutlet="clientsTemplate"></ng-container>
                        </ng-container>

                        <ng-container *ngIf="addReservationForm.controls.type.value != 2">
                            <div class="text-xl font-semibold mt-8 mb-4">
                                {{'szczegoly' | transloco}}
                            </div>
                            <ng-container *ngTemplateOutlet="detailsTemplate"></ng-container>
                        </ng-container>

                        <ng-container *ngIf="addReservationForm.controls.type.value != 2">
                            <div class="text-xl font-semibold mt-8 mb-4">
                                {{'finanse' | transloco}}
                            </div>
                            <ng-container *ngTemplateOutlet="financialsTemplate"></ng-container>
                        </ng-container>

                    </ng-container>

                </div>

            </ng-container>

            <fuse-alert *ngIf="priceChangesDetector == true" [appearance]="'outline'" class="absolute inset-x-0 bottom-20 mx-6 my-2"
                [dismissible]="false" [dismissed]="false" [type]="'info'" @shake>
                <span fuseAlertTitle>{{'wykryto_zmiany' | transloco}}</span>
                {{'chcesz_ponownie_przeliczyc_ceny' | transloco}}
                <span class="float-end">
                    <button mat-button (click)="priceChangesDetector = false">{{'anuluj' | transloco}}</button>
                    <button mat-button color="primary" (click)="showCalculatePricesDialog()">{{'tak' | transloco}}</button>
                </span>
            </fuse-alert>

        </div>
    </div>

    <!-- footer -->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t z-10 bg-gray-50 dark:bg-gray-900">

        <div class="flex">

            <button color="warn" [disabled]="processing || !permissions.has('deleteReservation')" class="mc-button me-2"
                mat-button (click)="cancelReservation()" *ngIf="mode == 'update' && res?.status != 0">
                {{"usun" | transloco}}
            </button>

            <button *ngIf="mode == 'update' && addReservationForm.controls.type.value == 2" mat-button
                (click)="switchToReservation()" class="mc-button  me-auto">
                {{'zamien_na_rezerwacje' | transloco}}
            </button>

            <div class="ml-auto flex">

                <button mat-button (click)="close()" class="mc-button ml-auto">{{'anuluj' | transloco}}</button>

                <button [disabled]="processing || !permissions.has('addReservation')" *ngIf="mode == 'create'"
                    mat-raised-button color="primary" class="ml-2" (click)="createOrUpdateReservation()">
                    <span *ngIf="addReservationForm.controls.type.value != 2">
                        {{'dodaj_rezerwacje' | transloco}}
                    </span>
                    <span *ngIf="addReservationForm.controls.type.value == 2">
                        {{'dodaj_blokade' | transloco}}
                    </span>
                </button>
                <button [disabled]="processing || !permissions.has('editReservation')"
                    *ngIf="mode == 'update' && res?.status != 0" mat-raised-button color="primary" class="ml-2"
                    (click)="createOrUpdateReservation()">
                    {{'zapisz_zmiany' | transloco}}
                </button>
                <button [disabled]="processing || !permissions.has('restoreReservation')"
                    *ngIf="mode == 'update' && res?.status == 0" mat-raised-button color="primary" class="ml-2"
                    (click)="showRestoreReservationConfirmation()">
                    {{"przywroc" | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>
<!-- reservation -->
<ng-template #reservationTemplate>
    <!-- type -->
    <ng-container *ngIf="mode != 'update'">
        <mat-radio-group class="invisible absolute w-0 h-0 pointer-events-none" #typeRadioGroup="matRadioGroup"
            [formControl]="addReservationForm.controls.type">
            <ng-container *ngFor="let type of types;">
                <mat-radio-button [value]="type.id"></mat-radio-button>
            </ng-container>
        </mat-radio-group>
        <div class="grid sm:grid-cols-3 gap-6 w-full pb-6 flex-auto">
            <ng-container *ngFor="let type of types;">
                <div (click)="addReservationForm.controls.type.setValue(type.id)"
                    class="relative sm:col-span-1 shadow cursor-pointer bg-card flex flex-col items-center justify-center rounded-2xl"
                    [ngClass]="{'ring ring-inset ring-primary': addReservationForm.controls.type.value === type.id}">
                    <div class="py-8 px-1">
                        <ng-container *ngIf="addReservationForm.controls.type.value === type.id">
                            <mat-icon class="absolute top-0 right-0 mt-3 mr-3 icon-size-7 text-primary"
                                [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                        </ng-container>
                        <div class="text-center">
                            <mat-icon class="icon-size-7 text-primary" [svgIcon]="type.icon"></mat-icon>
                            <div class="mt-1 whitespace-normal text-secondary">{{type.translation | transloco}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <div class="grid sm:grid-cols-4 gap-6 w-full">
        <div class="sm:col-span-3">
            <mat-form-field class="w-full availability-picker" [subscriptSizing]="'dynamic'" (click)="picker.open()"
                required>
                <mat-label>{{'przyjazd' | transloco}} - {{'wyjazd' | transloco}}
                </mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [formControl]="addReservationForm.controls.arrival" readonly>
                    <input matEndDate [formControl]="addReservationForm.controls.departure" readonly>
                </mat-date-range-input>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                <mat-date-range-picker [dateClass]="dateClass" #picker></mat-date-range-picker>
                <mat-error
                    *ngIf="addReservationForm?.controls?.arrival.hasError('required') || addReservationForm.controls?.departure.hasError('required')">
                    {{'to_pole_jest_wymagane' | transloco}}
                </mat-error>
            </mat-form-field>
        </div>
        <!-- days -->
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'liczba_dni' | transloco}}</mat-label>
                <button [disabled]="res?.status == 0" mat-icon-button matPrefix (click)="decrement('days', 1);">
                    <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                </button>
                <input [formControl]="addReservationForm.controls.days" integerOnly [integerOnlyMinValue]="1" matInput
                    type="text" class="text-center" value="1" />
                <button [disabled]="res?.status == 0" mat-icon-button matSuffix (click)="increment('days', 1);">
                    <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                </button>
            </mat-form-field>
        </div>
        <!-- room -->
        <div class="sm:col-span-4">
            <ng-container
                *ngIf="addReservationForm.controls.type.value == 0 || (addReservationForm.controls.type.value == 2 && mode == 'update')">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'pomieszczenie' | transloco}}
                    </mat-label>
                    <mat-icon matPrefix [svgIcon]="'heroicons_solid:home'"></mat-icon>
                    <mat-select matInput [formControl]="addReservationForm.controls.roomId">
                        <mat-option *ngFor="let room of availableRooms" [value]="room?.roomId">
                            {{room?.name}}
                            <!-- <small>ID: {{room?.roomId}}</small> -->
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="addReservationForm.controls.roomId.hasError('required')">
                        {{'to_pole_jest_wymagane' | transloco}}
                    </mat-error>
                </mat-form-field>
                <div *ngIf="res?.groupId > 0"
                    class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
                    (click)="showCopyReservation()">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    <span class="ml-2 font-medium text-secondary group-hover:underline">{{'dodaj_do_grupy' |
                        transloco}}</span>
                </div>
            </ng-container>

            <div class="mb-4"
                *ngIf="addReservationForm.controls.type.value == 1  || (addReservationForm.controls.type.value == 2 && mode == 'create')">
                <mat-label class="font-medium">{{'pomieszczenia' |
                    transloco}} ({{selectedRooms?.length}}) *</mat-label>
                <div class="rooms-container my-3">
                    <div class="flex flex-wrap items-center -m-1.5">
                        <ng-container *ngFor="let room of selectedRooms; let i = index;">
                            <div class="flex items-center justify-center pl-4 px-2 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 cursor-pointer"
                                matRipple (click)="removeRoom(i)">
                                <span class="text-md font-medium whitespace-nowrap">
                                    {{ (room.name.length > 35) ? (room.name | slice:0:35)+'...':(room.name) }}
                                </span>
                                <mat-icon class="icon-size-5 ml-2" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                            </div>
                        </ng-container>
                        <mat-error *ngIf="selectedRooms.length == 0">
                            {{'to_pole_jest_wymagane' | transloco}}
                        </mat-error>
                    </div>
                </div>
                <div class="group inline-flex items-center -ml-4 px-4 rounded cursor-pointer"
                    (click)="openSelectRoomDialogComponent()">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    <span class="ml-2 font-medium text-secondary group-hover:underline">{{'wybierz' | transloco}}</span>
                </div>
            </div>
        </div>
        <ng-template #cancelBtn>
            <button mat-button>{{"anuluj" | transloco}}</button>
        </ng-template>
        <ng-template #confirmBtn>
            <button mat-flat-button color="primary" class="ml-2">{{"zmien" | transloco}}</button>
        </ng-template>
        <ng-container *ngIf="addReservationForm.controls.type.value != 2">
            <div class="sm:col-span-2" *ngIf="formFields.includes('hours')">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'godzina_przyjazdu' | transloco}}</mat-label>
                    <mat-icon matPrefix [svgIcon]="'heroicons_solid:clock'"></mat-icon>
                    <input matInput [ngxMatTimepicker]="checkin_picker" [format]="timeFormat"
                        [formControl]="addReservationForm.controls.checkIn" readonly />
                </mat-form-field>
                <ngx-mat-timepicker color="primary" #checkin_picker [cancelBtnTmpl]="cancelBtn"
                    [confirmBtnTmpl]="confirmBtn">
                </ngx-mat-timepicker>
            </div>
            <div class="sm:col-span-2" *ngIf="formFields.includes('hours')">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'godzina_wyjazdu' | transloco}}</mat-label>
                    <mat-icon matPrefix [svgIcon]="'heroicons_solid:clock'"></mat-icon>
                    <input matInput [ngxMatTimepicker]="checkout_picker" [format]="timeFormat"
                        [formControl]="addReservationForm.controls.checkOut" readonly />
                </mat-form-field>
                <ngx-mat-timepicker color="primary" #checkout_picker [cancelBtnTmpl]="cancelBtn"
                    [confirmBtnTmpl]="confirmBtn"></ngx-mat-timepicker>
            </div>
            <ng-container>
                <div class="sm:col-span-2" *ngIf="formFields.includes('adults')">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{'dorosli' | transloco}}</mat-label>
                        <button mat-icon-button matPrefix [disabled]="reservation?.status == 0"
                            (click)="decrement('adults', 1);">
                            <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                        </button>
                        <input matInput type="text" class="text-center"
                            [formControl]="addReservationForm.controls.adults" integerOnly [integerOnlyMinValue]="1" />
                        <button mat-icon-button matSuffix [disabled]="reservation?.status == 0"
                            (click)="increment('adults', 1);">
                            <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="sm:col-span-2" *ngIf="formFields.includes('children')">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{'dzieci' | transloco}}</mat-label>
                        <button [disabled]="reservation?.status == 0" mat-icon-button matPrefix
                            (click)="decrement('children', 0);">
                            <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                        </button>
                        <input matInput type="text" integerOnly [integerOnlyMinValue]="0" class="text-center"
                            [formControl]="addReservationForm.controls.children" />
                        <button [disabled]="reservation?.status == 0" mat-icon-button matSuffix
                            (click)="increment('children', 0);">
                            <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container
                *ngIf="addReservationForm.controls.children.value > 0 && addReservationForm.controls.type.value != 1">
                <div class="sm:col-span-1" *ngFor="let kids of kidsCount; let i = index;">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{'dziecko' | transloco}} {{i+1}} ({{'wiek' | transloco}})</mat-label>
                        <mat-select matInput [formControl]="addReservationForm.controls['kidAge'+(i+1)]" value="0">
                            <mat-option *ngFor="let age of ages" [value]="age.value">
                                {{age.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="addReservationForm.controls.type.value == 2">
            <div class="sm:col-span-4">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'dodatkowe_informacje' | transloco}}</mat-label>
                    <mat-icon matPrefix [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                    <textarea matInput [formControl]="addReservationForm.controls.additionalInfo" rows="5"></textarea>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
</ng-template>

<!-- clients -->
<ng-template #clientsTemplate>
    <div class="grid sm:grid-cols-4 gap-6 w-full">
        <div class="sm:col-span-4">
            <div class="relative flex">
                <div class="flex-col mt-2">
                    <span *ngIf="addReservationForm.controls.clientId.value == null"
                        class=" h-6 min-h-6 px-2 py-1 rounded-full bg-hover">
                        <span class="font-medium text-sm text-secondary">{{'nowy_klient' | transloco}}</span>
                    </span>
                    <span *ngIf="addReservationForm.controls.clientId.value != null"
                        class="h-6 min-h-6 px-2 py-1 rounded-full bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50">
                        <span class="font-medium text-sm">ID: {{addReservationForm.controls.clientId.value}}</span>
                    </span>
                </div>
                <div class="flex items-center pl-2"
                    *ngIf="addReservationForm.controls.clientId.value != null && res?.status == 1">
                    <button class="w-8 h-8 min-h-8" mat-icon-button (click)="clearClient()">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
                <mat-label>{{'imie' | transloco}}</mat-label>
                <input matInput type="text" [formControl]="addReservationForm.controls.forename" />
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'nazwisko' | transloco}}</mat-label>
                <input matInput type="text" [formControl]="addReservationForm.controls.name" [matAutocomplete]="auto" />
                <mat-autocomplete [displayWith]="displayClientNameFn" (optionSelected)="onOptionSelected($event)"
                    #auto="matAutocomplete">
                    <mat-option *ngFor="let client of searchClients" [value]="client" (click)="saveClientData(client)">
                        <!-- search result -->
                        <client-row [data]="client"></client-row>
                    </mat-option>

                </mat-autocomplete>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <mat-error *ngIf="addReservationForm.controls['name'].hasError('required')">
                    {{'to_pole_jest_wymagane' | transloco}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
                <mat-label>{{'telefon' | transloco}}</mat-label>
                <input matInput type="text" [formControl]="addReservationForm.controls.phone" />
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
                <mat-label>{{'email' | transloco}}</mat-label>
                <input matInput type="text" [formControl]="addReservationForm.controls.email" />
            </mat-form-field>
        </div>
        <div class="sm:col-span-4" *ngIf="formFields.includes('moreClientData')">
            <mat-slide-toggle color="primary" (change)="show.more_client_data = !show.more_client_data">
                {{'wiecej_danych_klienta' | transloco}}</mat-slide-toggle>
        </div>

        <!-- More Client Data -->
        <div *ngIf="formFields.includes('moreClientData')" class="sm:col-span-4"
            [style.display]="show.more_client_data == true ? 'block' : 'none'">
            <div class="grid sm:grid-cols-4 gap-6 w-full">

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:identification'"></mat-icon>
                        <mat-label>{{'nr_dowodu' | transloco}}</mat-label>
                        <input matInput type="text" [formControl]="addReservationForm.controls.idCard" />
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:finger-print'"></mat-icon>
                        <mat-label>{{'pesel' | transloco}}</mat-label>
                        <input matInput type="text" [formControl]="addReservationForm.controls.personalId" />
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:building-office'"></mat-icon>
                        <mat-label>{{'nazwa_firmy' | transloco}}</mat-label>
                        <input matInput type="text" [formControl]="addReservationForm.controls.companyName" />
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:hashtag'"></mat-icon>
                        <mat-label>{{'nip' | transloco}}</mat-label>
                        <input matInput type="text" [formControl]="addReservationForm.controls.taxId" />
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-search-select [label]="translate.translate('narodowosc')" [displayProperty]="'name'"
                        [value]="addReservationForm.controls.countryId.value" (valueChanges)="setCountry($event)"
                        [options]="countries" [matPrefixIcon]="'heroicons_solid:map-pin'"></mat-search-select>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:truck'"></mat-icon>
                        <mat-label>{{'nr_rejestracyjny_pojazdu' | transloco}}</mat-label>
                        <input matInput type="text"
                            [formControl]="addReservationForm.controls.vehicleRegistrationNumber" />
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:map-pin'"></mat-icon>
                        <mat-label>{{'adres' | transloco}}</mat-label>
                        <textarea matInput type="text" [formControl]="addReservationForm.controls.address"
                            cdkAutosizeMinRows="2"></textarea>
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:chat-bubble-bottom-center-text'"></mat-icon>
                        <mat-label>{{'uwagi' | transloco}}</mat-label>
                        <textarea matInput type="text" [formControl]="addReservationForm.controls.comments"
                            cdkAutosizeMinRows="2"></textarea>
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-search-select [label]="translate.translate('jezyk')" [displayProperty]="'name'"
                        [valueProperty]="'shortName'" [value]="addReservationForm.controls.lang.value"
                        (valueChanges)="setLanguage($event)" [options]="languages"
                        [matPrefixIcon]="'heroicons_solid:language'">
                    </mat-search-select>
                </div>

                <div class="sm:col-span-4">
                    <mat-label class="font-medium">{{'status_klienta' | transloco}}</mat-label>
                    <br />
                    <mat-radio-group color="primary" [formControl]="addReservationForm.controls.clientType">
                        <mat-radio-button [value]="clientType.id"
                            *ngFor="let clientType of clientTypes">{{clientType.translation |
                            transloco}}</mat-radio-button>
                    </mat-radio-group>
                </div>

            </div>
        </div>
    </div>
</ng-template>

<!-- details -->
<ng-template #detailsTemplate>
    <div class="grid sm:grid-cols-4 gap-6 w-full">
        <div class="sm:col-span-2" *ngIf="formFields.includes('registration')">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:arrow-right-on-rectangle'"></mat-icon>
                <mat-label>{{'meldunek' | transloco}}</mat-label>
                <mat-select matInput [formControl]="addReservationForm.controls.registration">
                    <mat-option [value]="type.value" *ngFor="let type of registrationTypes">
                        {{type.translation | transloco}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2" *ngIf="formFields.includes('doorCode')">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'kod_do_drzwi' | transloco}}</mat-label>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:lock-open'"></mat-icon>
                <input matInput type="text" [formControl]="addReservationForm.controls.doorCode" />
            </mat-form-field>
        </div>
        <div class="sm:col-span-4" *ngIf="formFields.includes('source')">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:tag'"></mat-icon>
                <mat-label>{{'zrodlo_rezerwacji' | transloco}}</mat-label>
                <mat-select matInput [formControl]="addReservationForm.controls.sourceId">
                    <mat-option [value]="source?.sourceId" *ngFor="let source of sources">
                        {{source.isCustom ? source?.name : (source?.name | transloco)}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
                (click)="showSourceManage()">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                <span class="ml-2 font-medium text-secondary group-hover:underline">{{'dodaj' | transloco}}</span>
            </div>
        </div>
        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                <mat-label>{{'dodatkowe_informacje' | transloco}}</mat-label>
                <textarea matInput [formControl]="addReservationForm.controls.additionalInfo" cdkAutosizeMinRows="6"
                    rows="6"></textarea>
            </mat-form-field>
        </div>
    </div>
</ng-template>

<!-- financials -->
<ng-template #financialsTemplate>
    <div class="grid sm:grid-cols-3 gap-6 w-full">
        <div class="sm:col-span-3">
            <p class="text-lg font-medium">{{'cena_za_pokoj' | transloco}}</p>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'za_dobe' | transloco}}</mat-label>
                <input matInput type="text" [formControl]="addReservationForm.controls.pricePerRoomPerNight"
                    replaceCommaByDot />
                <span matTextSuffix>{{addReservationForm.controls?.currency?.value}}</span>
                <mat-error *ngIf="addReservationForm.controls['pricePerRoomPerNight'].hasError('invalidPrice')">
                    {{'format' | transloco}} 100 / 100.00
                </mat-error>
            </mat-form-field>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'liczba_dni_pobytu' | transloco}}</mat-label>
                <input matInput type="text" [formControl]="addReservationForm.controls.days" readonly />
                <mat-icon matSuffix [svgIcon]="'heroicons_solid:moon'"></mat-icon>
            </mat-form-field>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'za_pobyt' | transloco}}</mat-label>
                <input matInput type="text" [formControl]="addReservationForm.controls.pricePerRoom"
                    replaceCommaByDot />
                <span matTextSuffix>{{addReservationForm.controls?.currency?.value}}</span>
                <mat-error *ngIf="addReservationForm.controls['pricePerRoom'].hasError('invalidPrice')">
                    {{'format' | transloco}} 100 / 100.00
                </mat-error>
            </mat-form-field>
        </div>
        <ng-container *ngIf="formFields.includes('meals')">
            <div class="sm:col-span-3">
                <div class="my-2 border-t"></div>
            </div>
            <div class="sm:col-span-3">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'wyzywienie' | transloco}}</mat-label>
                    <mat-select matInput [formControl]="addReservationForm.controls.meal">
                        <mat-option [value]="alimentation.id" *ngFor="let alimentation of alimentations">
                            {{alimentation.translation | transloco}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngIf="addReservationForm.controls.meal.value != 0">

            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'dorosli' | transloco}}</mat-label>
                    <button [disabled]="reservation?.status == 0" mat-icon-button matPrefix
                        (click)="decrement('adultsPortion', 0);">
                        <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                    </button>
                    <input matInput type="text" class="text-center"
                        [formControl]="addReservationForm.controls.adultsPortion" />
                    <button [disabled]="reservation?.status == 0" mat-icon-button matSuffix
                        (click)="increment('adultsPortion', 0);">
                        <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'dzieci' | transloco}}</mat-label>
                    <button [disabled]="reservation?.status == 0" mat-icon-button matPrefix
                        (click)="decrement('childrenPortion', 0);">
                        <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                    </button>
                    <input matInput type="text" class="text-center"
                        [formControl]="addReservationForm.controls.childrenPortion" />
                    <button [disabled]="reservation?.status == 0" mat-icon-button matSuffix
                        (click)="increment('childrenPortion', 0);">
                        <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'suma' | transloco}}</mat-label>
                    <input matInput type="text" [formControl]="addReservationForm.controls.pricePerMeal"
                        replaceCommaByDot />
                    <span matTextSuffix>{{addReservationForm.controls?.currency?.value}}</span>
                </mat-form-field>
            </div>

        </ng-container>






        <!-- <ng-container *ngIf="addReservationForm.controls.traktowanie.value == 1">
      <div class="col-12 mb-3">
        <p><b>Ceny</b></p>
        <button mat-button class="mc-button" color="primary" (click)="showConfigureGroupPricesDialog()"
          [disabled]="reservation?.status == 0">
          <mat-icon svgIcon="plus"></mat-icon>
          <span>Konfiguruj ceny</span>
        </button>
      </div>
    </ng-container> -->


        <!-- additional services -->
        <ng-container *ngIf="formFields.includes('additionalServices')">

            <div class="sm:col-span-3">
                <div class="my-2 border-t"></div>
            </div>
            <div class="sm:col-span-3">
                <p class="text-lg font-medium">{{'uslugi_dodatkowe' | transloco}}</p>
            </div>

            <ng-container *ngFor="let additionalService of reservationAdditionalServices; let i=index">
                <div class="sm:col-span-3">
                    <div class="relative flex">
                        <mat-form-field class="flex-auto" [subscriptSizing]="'dynamic'">
                            <mat-label>{{additionalService.displayName}}</mat-label>
                            <mat-select [disabled]="mode == 'update' && reservation?.status == 0" matInput
                                [value]="additionalService.status"
                                (selectionChange)="updateAdditionalServiceStatus($event, additionalService)">
                                <mat-option *ngFor="let paymentStatus of additionalServicePaymentStatuses"
                                    [value]="paymentStatus.id">
                                    {{paymentStatus.translation | transloco}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto w-full max-w-24 sm:max-w-40 ml-2 sm:ml-4"
                            [subscriptSizing]="'dynamic'">
                            <mat-label>{{'cena' | transloco}}</mat-label>
                            <input [disabled]="mode == 'update' && reservation?.status == 0"
                                (change)="updateAdditionalServicePrice($event, additionalService)"
                                [value]="additionalService.price" matInput type="text" replaceCommaByDotNoControl />
                            <span matTextSuffix>{{addReservationForm.controls?.currency?.value}}</span>
                        </mat-form-field>
                        <!-- Remove -->
                        <div class="flex items-center w-10 pl-2 mt-6">
                            <button [disabled]="mode == 'update' && reservation?.status == 0" class="w-8 h-8 min-h-8"
                                mat-icon-button (click)="removeAdditionalService(i);">
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

            </ng-container>

            <div class="sm:col-span-3">
                <button class="group inline-flex items-center rounded cursor-pointer"
                    (click)="openAdditionalServiceDialog()">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    <span class="ml-2 font-medium text-secondary group-hover:underline">{{'dodaj' | transloco}}</span>
                </button>
            </div>
        </ng-container>

        <div class="sm:col-span-3">
            <div class="my-4 border-t"></div>
        </div>
    </div>
    <div class="grid sm:grid-cols-4 gap-6 w-full">
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'status_platnosci' | transloco}}</mat-label>
                <span matPrefix>
                    <span *ngIf="selectedPaymentStatus?.style != null" class="mr-3 inline-block w-2 h-2 rounded-full"
                        [style]="selectedPaymentStatus?.style"></span>
                    <span *ngIf="selectedPaymentStatus?.style == null" class="mr-3 inline-block w-2 h-2 rounded-full"
                        [class]="selectedPaymentStatus?.class || 'bg-red-400'"></span>
                </span>
                <mat-select matInput [formControl]="addReservationForm.controls.paymentStatus">
                    <mat-option *ngFor="let paymentStatus of reservationPaymentStatuses" [value]="paymentStatus.id">

                        <ng-container *ngIf="selectedPaymentStatus?.style == null">
                            <span class="inline-block w-2 h-2 rounded-full mr-2"
                                [class]="paymentStatus?.class || 'bg-red-400'"></span> {{paymentStatus.text |
                            transloco}}
                        </ng-container>

                        <ng-container *ngIf="selectedPaymentStatus?.style != null">
                            <span class="inline-block w-2 h-2 rounded-full mr-2" [style]="paymentStatus.style"></span>
                            {{paymentStatus.text | transloco}}
                        </ng-container>

                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <div class="grid sm:grid-cols-2 gap-0 w-full">
                <div class="sm:col-span-1">
                    <mat-slide-toggle color="primary" class="pt-9" [formControl]="addReservationForm.controls.ownColor">
                        {{'wlasny_kolor' | transloco}}
                    </mat-slide-toggle>
                </div>
                <div class="sm:col-span-1">
                    <mat-form-field class="w-full mt-6" [subscriptSizing]="'dynamic'" ngx-colors-trigger="colorPicker"
                        *ngIf="addReservationForm.controls.ownColor.value == true" [hideTextInput]="false"
                        [palette]="colorPalette" 
                        [colorPickerControls]="colorPickerControls" [acceptLabel]="translate.translate('wybierz')"
                        [cancelLabel]="translate.translate('anuluj')" (change)="updateColor($event)">
                        <input readonly matInput [formControl]="addReservationForm.controls.color">
                        <ngx-colors #colorPicker [formControl]="addReservationForm.controls.colorPckr" ngx-colors-trigger
                            matSuffix>
                        </ngx-colors>
                            <mat-error>{{'nieprawidlowy_kolor' | transloco}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'zadatek' | transloco}}</mat-label>
                <input matInput type="text" [formControl]="addReservationForm.controls.prepayment" replaceCommaByDot />
                <span matTextSuffix>{{addReservationForm.controls?.currency?.value}}</span>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" (click)="prepaymentDeadline.open()">
                <mat-label>{{'czas_oczekiwania_na_zaliczke' | transloco}}</mat-label>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                <input matInput readonly [matDatepicker]="prepaymentDeadline"
                    [formControl]="addReservationForm.controls.prepaymentDeadline">
                <!-- <mat-datepicker-toggle matSuffix [for]="prepaymentDeadline"></mat-datepicker-toggle> -->
                <mat-datepicker #prepaymentDeadline></mat-datepicker>
                <button mat-icon-button (click)="$event.stopPropagation(); resetPrepaymentDeadline()"
                    *ngIf="addReservationForm.controls.prepaymentDeadline.value != null">
                    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'rodzaj_platnosci' | transloco}}</mat-label>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:credit-card'"></mat-icon>
                <mat-select matInput [formControl]="addReservationForm.controls.paymentType">
                    <mat-option [value]="paymentType.id" *ngFor="let paymentType of paymentTypes">
                        {{paymentType.text | transloco}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'waluta' | transloco}}</mat-label>
                <mat-select matInput [formControl]="addReservationForm.controls.currency">
                    <ng-container *ngFor="let currency of currencies; let i = index;">
                        <mat-option disabled *ngIf="i > 0 && this.currency.priority != currencies[i-1].priority">
                            ______
                        </mat-option>
                        <mat-option [value]="currency.name">
                            {{currency.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="sm:col-span-4">
            <!-- Rabat -->
            <ng-container *ngIf="formFields.includes('discount')">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" *ngIf="show.discount">
                    <mat-label>{{'rabat' | transloco}}</mat-label>
                    <input matInput type="text" [formControl]="addReservationForm.controls.discount"
                        replaceCommaByDot />
                    <mat-select class="ml-1.5" matSuffix [formControl]="addReservationForm.controls.discountType">
                        <mat-option *ngFor="let type of discountTypes" [value]="type.id">
                            {{ type.id == 1 ? addReservationForm.controls.currency.value : type.text}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
                    (click)="toggleDiscount()">
                    <ng-container *ngIf="show.discount">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
                        <span class="ml-2 font-medium text-secondary group-hover:underline">{{'usun_rabat' |
                            transloco}}</span>
                    </ng-container>
                    <ng-container *ngIf="!show.discount">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                        <span class="ml-2 font-medium text-secondary group-hover:underline">{{'dodaj_rabat' |
                            transloco}}</span>
                    </ng-container>
                </div>
            </ng-container>


            <div class="sm:col-span-4">
                <div class="my-4 border-t"></div>
            </div>

            <div class="sm:col-span-4">
                <p class="text-lg font-medium mt-10">{{'podsumowanie' | transloco}}</p>
            </div>

            <div class="sm:col-span-4">
                <div class="flex items-center justify-between">
                    <div class="flex-auto">
                        <div class="text-md text-secondary">
                            {{'cena_za_pokoj' | transloco}}
                        </div>
                    </div>
                    <span class="font-medium">
                        {{pricingSummary.pricePerRoom}} {{addReservationForm.controls?.currency?.value}}
                    </span>
                </div>
                <div class="flex items-center justify-between">
                    <div class="flex-auto">
                        <div class="text-md text-secondary">
                            {{'wyzywienie' | transloco}}
                        </div>
                    </div>
                    <span class="font-medium">
                        {{pricingSummary.foodPrice}} {{addReservationForm.controls?.currency?.value}}
                    </span>
                </div>
                <div class="flex items-center justify-between">
                    <div class="flex-auto">
                        <div class="text-md text-secondary">
                            {{'uslugi_dodatkowe' | transloco}}
                        </div>
                    </div>
                    <span class="font-medium">
                        {{pricingSummary.additionalServices}} {{addReservationForm.controls?.currency?.value}}
                    </span>
                </div>
                <div class="flex items-center justify-between" *ngIf="show.discount">
                    <div class="flex-auto">
                        <div class="text-md text-secondary">
                            {{'rabat' | transloco}}
                        </div>
                    </div>
                    <span class="font-medium">
                        - {{pricingSummary.discountInFiat}} {{addReservationForm.controls?.currency?.value}}
                    </span>
                </div>

                <div class="my-4 border-t"></div>

                <div class="text-end">
                    <span class="text-md text-secondary font-bold">
                        {{'suma' | transloco}}:
                    </span>
                    <span class="font-bold">
                        {{pricingSummary.summary}} {{addReservationForm.controls?.currency?.value}}
                    </span>
                </div>

                <div class="text-end" *ngIf="pricingSummary.paid > 0">
                    <span class="text-md text-secondary font-bold">
                        {{'zaplacono' | transloco}}:
                    </span>
                    <span class="font-bold">
                        {{pricingSummary.paid}} {{addReservationForm.controls?.currency?.value}}
                    </span>
                </div>

                <div class="text-end" *ngIf="pricingSummary.toPay > 0">
                    <span class="text-md text-secondary font-bold">
                        {{'do_zaplaty' | transloco}}:
                    </span>
                    <span class="font-bold">
                        {{pricingSummary.toPay}} {{addReservationForm.controls?.currency?.value}}
                    </span>
                </div>

            </div>

            <div class="sm:col-span-4">
                <div class="my-4 border-t"></div>
            </div>

            <ng-container *ngIf="mode == 'update' && permissions.has('invoicing')">
                <div class="sm:col-span-4">
                    <p class="text-lg font-medium mt-10">{{'faktury' | transloco}}</p>
                </div>
                <div class="sm:col-span-4" *ngFor="let invoice of invoices">
                    <div class="flex items-center justify-between">
                        <div class="flex-auto">
                            <div class="text-md font-medium mt-3">
                                <div class="font-semibold text-secondary">{{invoice?.companyNameBuyer}}</div>
                                <invoice-type-to-name [invoice]="invoice"></invoice-type-to-name>
                                {{invoice?.fullInvoiceName}} - {{invoice?.grossValue}} {{invoice?.currency}}
                            </div>
                        </div>
                        <div>
                            <button [routerLink]="'/app/invoices/'+invoice.invoiceId+'/edit' | localize" mat-icon-button
                                [matTooltip]="'edytuj' | transloco"
                                matTooltipPosition="above">
                                <mat-icon [svgIcon]="'heroicons_outline:pencil-square'"></mat-icon>
                            </button>

                            <button mat-icon-button [matTooltip]="'pobierz' | transloco"
                                (click)="downloadInvoice(invoice)" matTooltipPosition="above">
                                <mat-icon [svgIcon]="'heroicons_outline:arrow-down-tray'"></mat-icon>
                            </button>

                            <button mat-icon-button [matTooltip]="'drukuj' | transloco" (click)="printInvoice(invoice)"
                                matTooltipPosition="above">
                                <mat-icon [svgIcon]="'heroicons_outline:printer'"></mat-icon>
                            </button>

                            <button mat-icon-button [matTooltip]="'wyslij' | transloco" (click)="sendInvoice(invoice)"
                                matTooltipPosition="above">
                                <mat-icon [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
                            </button>

                            <button mat-icon-button [matTooltip]="'usun_fakture' | transloco"
                                (click)="deleteInvoice(invoice)" matTooltipPosition="above">
                                <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="sm:col-span-4">

                    <div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
                        [routerLink]="'/app/invoices/new' | localize"
                        [queryParams]="{ reservationNo: reservation?.reservationNo }">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                        <span class="ml-2 font-medium text-secondary group-hover:underline">{{'wystaw_fakture' |
                            transloco}}</span>
                    </div>
                </div>

                <div class="mb-10"></div>
            </ng-container>


        </div>
    </div>

</ng-template>