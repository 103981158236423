<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            <p *ngIf="table == 'arrivals'" class="text-lg font-medium"> {{'przyjazdy' | transloco}} </p>
            <p *ngIf="table == 'departures'" class="text-lg font-medium"> {{'wyjazdy' | transloco}} </p>
            <p *ngIf="table == 'inHotel'" class="text-lg font-medium"> {{'w_obiekcie' | transloco}} </p>
            <p *ngIf="table == 'prepayments'" class="text-lg font-medium"> {{'zadatki' | transloco}} </p>

            <div class="ml-auto">
                <button mat-icon-button [matTooltip]="'zamknij' | transloco" (click)="close()">
                    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="flex flex-col sm:flex-row items-start justify-between">
        

        <div class="mt-3 sm:mt-0 sm:ml-2">
            <mat-button-toggle-group [(ngModel)]="reservationRegistrationType"
                #reservationRegistrationTypes="matButtonToggleGroup"
                (change)="onReservationRegistrationTypesChange($event)">
                <mat-button-toggle value="all">{{'wszystkie' | transloco}} </mat-button-toggle>
                <mat-button-toggle *ngIf="table == 'arrivals'" value="no-data">{{'niezameldowani' | transloco}}</mat-button-toggle>
                <mat-button-toggle *ngIf="table == 'departures'" value="no-data">{{'niewymeldowani' | transloco}}</mat-button-toggle>
                <mat-button-toggle *ngIf="table == 'arrivals'" value="checked-in">{{'zameldowani' | transloco}}</mat-button-toggle>
                <mat-button-toggle *ngIf="table == 'departures'" value="checked-out">{{'wymeldowani' | transloco}}</mat-button-toggle>
                <mat-button-toggle *ngIf="table == 'prepayments'" value="no-prepayment">{{'nie_wplacono_zaliczki' | transloco}}</mat-button-toggle>
                <mat-button-toggle *ngIf="table == 'prepayments'" value="awaiting-for-prepayment">{{'oczekiwanie_na_zaliczke' | transloco}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

    </div>
    <div class="flex flex-col mt-2 divide-y overflow-y-auto" *ngIf="filteredReservations.length > 0; else noReservations">
        <ng-container *ngFor="let reservation of filteredReservations">
            <div class="py-4 px-0.5 md:px-4">
                <app-reservation-row [reservation]="reservation" [showMoreButton]="true"></app-reservation-row>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #noReservations>
    <div class="mt-2">
        <div class="py-4 px-0.5 md:px-4 text-secondary">
            {{'brak_rezerwacji' | transloco}}
        </div>
    </div>
</ng-template>