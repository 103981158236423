<div class="flex flex-auto flex-col">
    <div class="flex min-w-0 flex-auto flex-col items-center sm:justify-center">
        <div class="w-full px-4 py-8 sm:w-auto sm:rounded-2xl">
            <fuse-card class="flex flex-col items-center max-w-100 w-full p-8 filter-info">
               
                <ng-container *ngIf="status === 'awaiting'">
                    <div class="flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-primary-600" [@zoomIn]="true">
                        <mat-icon  class="icon-size-10 text-primary-500 dark:text-primary-300 icn-spinner" [svgIcon]="'heroicons_outline:arrow-path'"></mat-icon>
                    </div>
                    <div class="text-2xl font-semibold leading-tight text-center mt-6">{{'weryfikacja' | transloco}}</div>
                    <div class="text-center text-secondary mt-3">{{'trwa_oczekiwanie_na_odpowiedz' | transloco}}</div>
                </ng-container>

                <ng-container *ngIf="status === 'success'">
                    <div class="flex items-center justify-center p-5 rounded-full bg-green-100 dark:bg-green-600" [@zoomIn]="true">
                        <mat-icon
                            class="icon-size-10 text-green-500 dark:text-green-300"
                            [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                    </div>
                    <div class="text-2xl font-semibold leading-tight text-center mt-6">{{'sukces' | transloco}}</div>
                    <div class="text-center text-secondary mt-3">{{'konto_pocztowe_zostalo_dodane' | transloco}} {{'zostaniesz_przekierowany_za' | transloco}} {{ countdown }}s.</div>
                </ng-container>

                <ng-container *ngIf="status === 'error'">
                    <div class="flex items-center justify-center p-5 rounded-full bg-red-100 dark:bg-red-600" [@shake]="true">
                        <mat-icon
                            class="icon-size-10 text-red-500 dark:text-red-300"
                            [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
                    </div>
                    <div class="text-2xl font-semibold leading-tight text-center mt-6">{{'blad' | transloco}}</div>
                    <div class="text-center text-secondary mt-3">
                        {{'niestety_nie_udalo_sie_dodac_konta_pocztowego' | transloco}}
                        <br>
                        {{'zostaniesz_przekierowany_za' | transloco}} {{ countdown }}s.
                    </div>
                </ng-container>
            </fuse-card>
        </div>
    </div>
</div>