import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { api_v1 } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RoomsApiService {

    private constructor(
        private _http: HttpClient
    ) {
    }

    public createMany(data) {
        return this._http.post(api_v1 + `/rooms/multiple`, data);
    }

    public setTasks(data) {
        return this._http.patch(api_v1 + `/rooms/service-info`, data);
    }
    
    public setCleaning(data) {
        return this._http.patch(api_v1 + `/rooms/service-status`, data);
    }
    
}