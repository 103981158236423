import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef } from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { PushNotificationData } from '../models/push-notification-data';
import { ToastComponent } from '../components/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastStack: any | ComponentRef<ToastComponent>[] = [];

  audio
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  )
  {
    this.audio = new Audio('assets/sounds/definite-555.mp3');
  }

  showToast(n:PushNotificationData) {

    const container = document.getElementById('notifications-container')

    if(!container) {
      console.warn("Notifications container not found. Can't display notification.");
      return;
    }

    let portalHost = new DomPortalOutlet(container, this.componentFactoryResolver, this.appRef, this.injector);
    const portal: any = new ComponentPortal(ToastComponent);
    
    let componentRef: any = null;
    // Check if there's an existing portal attached
    componentRef = portalHost.attach(portal);

    if(n.showDate === undefined) n.showDate = true
    if(n.timeToClose === undefined) n.timeToClose = 3000
    if(n.added === undefined) n.added = new Date()
    componentRef.instance.data = n;
   
    componentRef.instance.portalHost = portalHost;
    componentRef.instance.portal = portal;

    this.toastStack.unshift(portal);
    const maxToasts = 50; // Adjust as needed
    if (this.toastStack.length > maxToasts) {
      const removedPortal: any = this.toastStack.pop();
      removedPortal.detach();
    }

    this.playSound()
    if (n.timeToClose > 0) this._scheduleDetachment(portalHost, portal, n.timeToClose);
    return componentRef
  }

  private _scheduleDetachment(host:DomPortalOutlet, portal: ComponentPortal<ToastComponent>, timeToClose: number): void {
    setTimeout(() => {
      host.detach();
      const index = this.toastStack.indexOf(portal);
      if (index !== -1) {
        this.toastStack.splice(index, 1);
      }
    }, timeToClose);
  }

  private playSound() {
    if(!this.audio) {
      console.log("[ToastService]: Can't load audio notification sound.")
      return
    }
    this.audio.play();
  }
}