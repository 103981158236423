<div #preview class="break-before-avoid w-240 rounded-2xl shadow p-16 bg-card print:w-auto print:rounded-none print:shadow-none print:bg-transparent dark:bg-white dark:text-gray-950"
    style="font-family: 'Inter var', normal;">
    <div class="flex items-start mb-20">
        <div class="lace-items-start gap-y-12">
           
            <div class="grid auto-cols-max grid-flow-col gap-x-8">
               
                <div *ngIf="logoPath == null" class="place-self-center w-24 text-center text-2xl text-secondary">
                    <span>{{'sprzedawca' | transloco}}</span>
                </div>

                <div *ngIf="logoPath != null" class="place-self-center w-24">
                    <img [style.width.px]="staticWidthPx" [style.height.px]="calculatedHeight" (load)="onImageLoad($event)" width="100%" height="auto" crossorigin="anonymous" [src]="logoPath + '?cacheblock=true&d=' + dynamic"/>
                </div>

                <div class="pl-8 border-l border-zinc-100 text-md w-80">
                    <div>{{invoice?.companyNameSeller}}</div>
                    <div>{{'nip' | transloco}}: {{invoice?.taxIdSeller}}</div>
                    <div>{{invoice?.addressSeller}}</div>
                    <div>{{'konto_bankowe' | transloco}}:<br /> {{invoice?.bankNoSeller}}
                    </div>
                </div>
            </div>
            <div class="grid auto-cols-max grid-flow-col gap-x-8 mt-6">
                <div class="place-self-center w-24 text-center text-2xl text-secondary">
                    <span>{{'nabywca' | transloco}}</span>
                </div>
                <div class="pl-8 border-l border-zinc-100 text-md w-80">
                    <div>{{invoice?.companyNameBuyer}}</div>
                    <div>{{'nip' | transloco}}: {{invoice?.taxIdBuyer}}</div>
                    <div>{{invoice?.addressBuyer}}</div>
                </div>
            </div>

            <div class="grid auto-cols-max grid-flow-col gap-x-8 mt-6" *ngIf="invoice?.hasReceiver">
                <div class="place-self-center w-24 text-center text-2xl text-secondary">
                    <span>{{'odbiorca' | transloco}}</span>
                </div>

                <div class="pl-8 border-l border-zinc-100 text-md w-80">
                    <div>{{invoice?.companyNameReceiver}}</div>
                    <div>{{'nip' | transloco}}: {{taxIdReceiver}}</div>
                    <div>{{addressReceiver}}</div>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-x-4 gap-y-2 ml-auto">

            <div class="col-span-2 text-center">
                <div class="justify-self-center text-4xl font-medium text-secondary uppercase">
                    <span><invoice-type-to-name [invoice]="invoice"></invoice-type-to-name></span>
                </div>
                <div class="text-4xl">
                    <span>{{invoice?.fullInvoiceName}}</span>
                </div>
            </div>

            <div class="justify-self-start font-medium text-secondary pl-4 uppercase">
                <span>{{'data_wystawienia' | transloco}}</span>
            </div>
            <div class="font-medium">
                <span>
                    <date-formatter [date]="invoice?.issueDate"></date-formatter>
                </span>
                
            </div>

            <div class="justify-self-start font-medium text-secondary pl-4 uppercase">
                <span>{{'data_sprzedazy' | transloco}}</span>
            </div>
            <div class="font-medium">
                <span><date-formatter [date]="invoice?.saleDate"></date-formatter></span>
            </div>
            <div class="justify-self-start font-medium text-secondary pl-4  uppercase">
                <span>{{'miejsce_wystawienia' | transloco}}</span>
            </div>
            <div class="font-medium">
                <span>{{invoice?.town}}</span>
            </div>
        </div>
    </div>


    <div class="text-xl text-secondary mt-4" *ngIf="invoice?.prepayments?.length > 0">
        {{'przed_rozliczeniem_faktur_zaliczkowych' | transloco}}
    </div>

    <ng-container *ngIf="invoice?.correctedInvoice != null">

        <div class="text-xl text-center text-secondary mt-4">
            {{'dotyczy' | transloco}} <invoice-type-to-name
                [invoice]="invoice?.correctedInvoice"></invoice-type-to-name>
            {{invoice?.correctedInvoice?.fullInvoiceName}} {{'z_dnia' | transloco}}: <date-formatter
                [date]="invoice?.correctedInvoice?.issueDate"></date-formatter>
        </div>
        <div class="text-xl text-secondary mt-4">
            {{'przed_korekta' | transloco}}
        </div>
        <invoice-item-listing [invoice]="invoice?.correctedInvoice"></invoice-item-listing>

        <div class="text-xl text-secondary mt-4">
            {{'po_korekcie' | transloco}}
        </div>
    </ng-container>

    <div class="grid gap-x-1" [ngClass]="{
        'grid-cols-12' : invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7, 
        'grid-cols-9' : invoice.invoiceType == 0 || invoice.invoiceType == 5 || invoice.invoiceType == 7 
    }">
        <!-- Columns -->
        <div class="uppercase col-span-4 font-medium text-sm text-secondary">
            <span>{{'nazwa_towaru_lub_uslugi' | transloco}}</span>
        </div>
        <div class="uppercase col-span-1 font-medium text-sm text-center text-secondary">
            <span>{{'symbol' | transloco}}</span>
        </div>
        <div class="uppercase col-span-1 font-medium text-sm text-center text-secondary">
            <span>{{'jednostka' | transloco}}</span>
        </div>
        <div class="uppercase col-span-1 font-medium text-sm text-center text-secondary">
            <span>{{'ilosc' | transloco}}</span>
        </div>
        <div class="uppercase col-span-1 font-medium text-sm text-center text-secondary">
            <span *ngIf="invoice.invoiceType == 0 || invoice.invoiceType == 5 || invoice.invoiceType == 7">{{'cena' | transloco}}</span>
            <span *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7">{{'cena_jedn_brutto' | transloco}}</span>
        </div>
        <div *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7" class="uppercase col-span-1 font-medium text-sm text-center text-secondary">
            <span>{{'wartosc_netto' | transloco}}</span>
        </div>

        <div *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7" class="uppercase col-span-1 font-medium text-sm text-center text-secondary">
            <span>{{'vat' | transloco}} %</span>
        </div>

        <div *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7" class="uppercase col-span-1 font-medium text-sm text-center text-secondary">
            <span>{{'kwota_vat' | transloco}}</span>
        </div>

        <div class="uppercase col-span-1 font-medium text-sm text-center text-secondary">
            <span *ngIf="invoice.invoiceType == 0 || invoice.invoiceType == 5 || invoice.invoiceType == 7">{{'wartosc' | transloco}}</span>
            <span *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7">{{'wartosc_brutto' | transloco}}</span>
        </div>

        <!-- Divider -->
        <div class="col-span-12 my-4 border-b"></div>

        <!-- Item -->
        <ng-container *ngFor="let item of invoice?.products; let i = index;">
            <div class="col-span-4 text-md font-medium">
                {{item.name}}
            </div>
            
            <div class="col-span-1 self-center text-center">
                {{item.symbol}}
            </div>

            <div class="col-span-1 self-center text-center">

                {{item.unit}}

            </div>
            <div class="col-span-1 self-center text-center">

                {{item.quantity}}

            </div>

            <div class="col-span-1 self-center text-center">

                {{item.grossUnitPrice}}

            </div>

            <div class="col-span-1 self-center text-center" *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7">

                {{item.nettValue}}

            </div>

            <div class="col-span-1 self-center text-center" *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7">

                {{item.rate | taxRate}}

            </div>

            <div class="col-span-1 self-center text-center" *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7">

                {{item.taxAmount}}

            </div>

            <div class="col-span-1 self-center text-center">

                {{item.grossValue}}

            </div>

            <!-- Divider -->
            <div class="col-span-12 my-4 border-b"></div>

        </ng-container>

        <ng-container *ngIf="invoice?.products.length > 0">

            <!-- Item summary -->
            <ng-container *ngIf="invoice.invoiceType != 0 && invoice.invoiceType != 5 && invoice.invoiceType != 7">
                <ng-container *ngFor="let tax of invoice?.taxes; let i = index;">
                    <div class="col-span-7">
    
                    </div>
    
                    <div class="col-span-1 self-center text-center">
                        <b *ngIf="i == 0">{{'w_tym' | transloco}}</b>
                    </div>
    
                    <div class="col-span-1 self-center text-center">
                        {{tax.nettValue.toFixed(2)}}
                    </div>
    
                    <div class="col-span-1 self-center text-center">
                        {{tax.rate | taxRate}}
                    </div>
    
                    <div class="col-span-1 self-center text-center">
                        {{tax.taxAmount.toFixed(2)}}
                    </div>
    
                    <div class="col-span-1 self-center text-center">
                        {{tax.grossValue.toFixed(2)}}
                    </div>
    
    
                </ng-container>
    
                <div class="col-span-7">
    
                </div>
    
                <div class="col-span-1 self-center text-center">
                    <b>{{'razem' | transloco}}</b>
                </div>
    
                <div class="col-span-1 self-center text-center">
                    {{invoice?.taxesSummaryNettValue.toFixed(2)}}
                </div>
    
                <div class="col-span-1 self-center text-center">
    
                </div>
    
                <div class="col-span-1 self-center text-center">
                    {{invoice?.taxesSummaryTaxAmount.toFixed(2)}}
                </div>
    
                <div class="col-span-1 self-center text-center">
                    {{invoice?.taxesSummaryGrossValue.toFixed(2)}}
                </div>
            </ng-container>
           
        </ng-container>

        <ng-container>

            <ng-container *ngIf="invoice?.prepayments?.length > 0">
                <div class="col-span-12 text-xl text-secondary mt-4">
                    {{'rozliczenie_faktur_zaliczkowych' | transloco}}
                </div>

                <div class="uppercase col-span-6 font-medium text-md text-secondary">{{'dokument' |
                    transloco}}
                </div>
                <div class="uppercase col-span-2 font-medium text-md text-center text-secondary">
                    {{'wartosc_netto' |
                    transloco}}</div>
                <div class="uppercase col-span-2 font-medium text-md text-center text-secondary">
                    {{'kwota_vat' |
                    transloco}}</div>
                <div class="uppercase col-span-2 font-medium text-md text-center text-secondary">
                    {{'wartosc_brutto'
                    | transloco}}</div>

                <!-- Divider -->
                <div class="col-span-12 my-4 border-b"></div>


                <ng-container *ngFor="let prepayment of invoice?.prepayments; let i = index">

                    <div class="col-span-6 self-center">
                        <invoice-type-to-name [invoice]="prepayment"></invoice-type-to-name>
                        {{prepayment.fullInvoiceName}}
                    </div>
                    <div class="col-span-2 self-center text-center">
                        {{prepayment.nettValue.toFixed(2)}}
                    </div>
                    <div class="col-span-2 self-center text-center">
                        {{prepayment.taxAmount.toFixed(2)}}
                    </div>
                    <div class="col-span-2 self-center text-center">
                        {{prepayment.grossValue.toFixed(2)}}
                    </div>
                </ng-container>

                <ng-container>
                    <div class="col-span-4 self-center">
                    </div>
                    <div class="col-span-2 self-center text-center">
                        <b>{{'razem' | transloco}}</b>
                    </div>
                    <div class="col-span-2 self-center text-center">
                        {{invoice?.prepaymentsNettValue.toFixed(2)}}
                    </div>
                    <div class="col-span-2 self-center text-center">
                        {{invoice?.prepaymentTaxAmount.toFixed(2)}}
                    </div>
                    <div class="col-span-2 self-center text-center">
                        {{invoice?.prepaymentGrossValue.toFixed(2)}}
                    </div>
                </ng-container>

                <div class="col-span-12 text-xl text-secondary mt-4">
                    {{'po_rozliczeniu_faktur_zaliczkowych' | transloco}}
                </div>


            </ng-container>

            <ng-container *ngIf="(invoice?.invoiceType == 1 && invoice?.prepayments?.length > 0) || invoice?.invoiceType == 6 || invoice?.invoiceType == 7">
                
                <div *ngIf="invoice?.invoiceType == 6 || invoice?.invoiceType == 7" class="col-span-12 text-xl text-secondary mt-4">
                    {{'rozliczenie_vat' | transloco}}
                </div>

                <div class="uppercase col-span-4 font-medium text-md text-secondary"></div>

                <div class="uppercase col-span-2 font-medium text-md text-center text-secondary">
                    {{'vat' | transloco}} %
                </div>

                <div class="uppercase col-span-2 font-medium text-md text-center text-secondary">
                    {{'wartosc_netto' | transloco}}
                </div>

                <div class="uppercase col-span-2 font-medium text-md text-center text-secondary">
                    {{'kwota_vat' | transloco}}
                </div>

                <div class="uppercase col-span-2 font-medium text-md text-center text-secondary">
                    {{'wartosc_brutto' | transloco}}
                </div>

                <!-- Divider -->
                <div class="col-span-12 my-4 border-b"></div>

                <ng-container *ngFor="let settlement of invoice?.taxSettlement; let i = index">

                    <div class="col-span-4 self-center">

                    </div>

                    <div class="col-span-2 self-center text-center">
                        {{settlement.rate | taxRate}}
                    </div>

                    <div class="col-span-2 self-center text-center">
                        {{settlement.nettValue.toFixed(2)}}
                    </div>

                    <div class="col-span-2 self-center text-center">
                        {{settlement.taxAmount.toFixed(2)}}
                    </div>

                    <div class="col-span-2 self-center text-center">
                        {{settlement.grossValue.toFixed(2)}}
                    </div>

                </ng-container>

                <div class="col-span-4 self-center"></div>

                <div class="col-span-2 self-center text-center">
                    <b>{{'razem' | transloco}}</b>
                </div>

                <div class="col-span-2 self-center text-center">
                    {{invoice?.nettValue.toFixed(2)}}
                </div>

                <div class="col-span-2 self-center text-center">
                    {{invoice?.taxAmount.toFixed(2)}}
                </div>

                <div class="col-span-2 self-center text-center">
                    {{invoice?.grossValue.toFixed(2)}}
                </div>

            </ng-container>

        </ng-container>

        <!-- Spacer -->
        <div class="col-span-12 mt-6"></div>

        <!-- Total -->
        <div class="col-span-12 text-2xl font-medium uppercase text-right">
            <span class="text-secondary" *ngIf="invoice?.paymentStatus == 0">{{'do_zaplaty' | transloco}} </span>
            <span class="text-secondary" *ngIf="invoice?.paymentStatus == 1">{{'zaplacono' | transloco}} </span>
            <span class="text-2xl font-medium"><span>{{invoice?.grossValue?.toFixed(2)}} {{invoice?.currency}}</span></span>
        </div>

    </div>

    <div class="grid grid-cols-2 gap-x-4 gap-y-1 w-2/4 mt-2">

        <div class="font-medium text-secondary  uppercase">
            {{'forma_platnosci' | transloco}}
        </div>
        <div class="font-medium">
            <payment-type-to-text [paymentType]="invoice?.paymentType"></payment-type-to-text>
        </div>
        <div class="font-medium text-secondary  uppercase">{{'termin_platnosci' | transloco}}
        </div>
        <div class="font-medium">

            <date-formatter [date]="invoice?.paymentDate"></date-formatter>
        </div>
    </div>

    <div class="mt-4">
        <div class="text-sm text-secondary">
            <span>{{'uwagi' | transloco}}: {{invoice?.additionalInfo}}</span>
           
        </div>
    </div>


    <div class="grid grid-cols-7 gap-x-4 gap-y-1">

        <div class="col-span-7 mt-4 mb-6 border-b"></div>

        <div class="col-span-3">
            <div class="my-3 mt-12 border-b text-center h-10">
                {{invoice?.personIssue}}
            </div>
            <div class="text-center font-medium">
                <span>{{'osoba_upowazniona_do_wystawienia' | transloco}}</span>
            </div>
        </div>

        <div class="col-span-1"></div>

        <div class="col-span-3">

            <div class="my-3 mt-12 border-b text-center h-10">
                {{invoice?.personCollect}}
            </div>
            <div class="text-center font-medium">
                <span>{{'osoba_upowazniona_do_odbioru' | transloco}}</span>
            </div>
        </div>
        
    </div>

</div>