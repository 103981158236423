import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChannelManagerImportNotificationDetailsComponent } from './notification-details/channel-manager-import-notification-details/channel-manager-import-notification-details.component'
import { IcalNotificationDetailsComponent } from './notification-details/ical-notification-details/ical-notification-details.component';

const notificationDetails = [
  ChannelManagerImportNotificationDetailsComponent,
  IcalNotificationDetailsComponent
]

@Component({
  selector: 'app-notification-details-dialog',
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatDialogModule, notificationDetails, MatButtonModule, MatIconModule],
  templateUrl: './notification-details-dialog.component.html',
  styleUrl: './notification-details-dialog.component.scss'
})
export class NotificationDetailsDialogComponent {

  notification:any
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      if(!data) return
      if(data.notification) this.notification = data.notification
  }
}
