<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            <!-- create -->
            <p class="text-lg font-medium" *ngIf="mode == 'create'">
                {{'dodaj_usluge_dodatkowa' | transloco}}
            </p>
            <p class="text-lg font-medium" *ngIf="mode == 'update'">
                {{'edytuj_usluge_dodatkowa' | transloco}}
            </p>
            <div class="ml-auto">
                <!-- close -->
                <button mat-icon-button (click)="close()">
                    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="px-6 py-4">
                <div class="grid sm:grid-cols-4 gap-6 w-full">

                    <div class="sm:col-span-4">
                        <mat-checkbox color="primary" [formControl]="form.controls.showOtherLanguages" class="-ml-2">{{'pokaz_inne_jezyki' | transloco}}</mat-checkbox>
                        
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                        *ngIf="form.controls.showOtherLanguages.value === true">
                        <mat-label>{{'jezyk' | transloco}}</mat-label>
                        <mat-select [formControl]="form.controls.language">
                            <mat-option value="default">
                                {{'domyslny' | transloco}} <small class="text-secondary ms-2">
                                    ({{'wymagane' | transloco}})
                                </small>
                            </mat-option>
                            <mat-option [value]="lang.shortName" *ngFor="let lang of languages">
                                <div class="flex items-center">
                                    <img [src]="lang.img" alt="" class="me-2">
                                    {{lang?.name}}
                                    <small *ngIf="name?.[lang.shortName]?.length == 0 || name?.[lang.shortName] == null" class="text-secondary ms-2">
                                        ({{'brak' | transloco}})
                                    </small>
                                </div>
                            </mat-option>
                        </mat-select>
                      
                    </mat-form-field>
                    </div>
                    <div class="sm:col-span-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                            <mat-label>{{'nazwa' | transloco}}</mat-label>
                            <input [formControl]="form.controls.nameTemp" matInput type="text" />
                            <mat-error *ngIf="name?.default?.length == 0 || name?.default == null">
                                {{'uzupelnij_domyslna_nazwe' | transloco}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                            <mat-label>{{'opis' | transloco}}</mat-label>
                            <textarea [formControl]="form.controls.descriptionTemp" matInput rows="3"></textarea>
                            
                        </mat-form-field>
                    </div>

                    <div class="sm:col-span-4">
                        <hr class="my-6">
                    </div>

                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix [svgIcon]="'heroicons_solid:clock'"></mat-icon>
                            <mat-label>{{'okres' | transloco}}</mat-label>
                            <mat-select [formControl]="form.controls.period" matInput>
                                <mat-option [value]="0">{{'za_dobe' | transloco}}</mat-option>
                                <mat-option [value]="1">{{'za_pobyt' | transloco}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                            <mat-label>{{'platnosc' | transloco}}</mat-label>
                            <mat-select matInput [formControl]="form.controls.type">
                                <mat-option [value]="0">{{'za_usluge' | transloco}}</mat-option>
                                <mat-option [value]="1">{{'za_osobe' | transloco}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>{{form.controls.type.value == 1 ? ('cena_dorosli' | transloco) : ('cena' | transloco)}}</mat-label>
                            <input replaceCommaByDot matInput [formControl]="form.controls.priceAdult" type="text" />
                            <span matSuffix>{{currency}}</span>
                        </mat-form-field>
                    </div>

                    <div class="sm:col-span-2" *ngIf="form.controls.type.value == 1">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>{{'cena_dzieci' | transloco}}</mat-label>
                            <input replaceCommaByDot matInput [formControl]="form.controls.priceChildren" type="text" />
                            <span matSuffix>{{currency}}</span>
                        </mat-form-field>
                    </div>

                    <div class="sm:col-span-2">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>{{'stawka_vat' | transloco}}</mat-label>
                            <input matInput [formControl]="form.controls.taxRate" type="text" />
                        </mat-form-field>
                    </div>
                    

                </div>
            </div>
            <!-- footer -->
            <div
                class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
                <div class="flex items-center">
                    <button [disabled]="isProcessing" (click)="confirmDelete()"
                        color="warn" class="me-2" mat-button
                        *ngIf="mode == 'update'">
                        {{"usun" | transloco}}
                    </button>

                    <button mat-button (click)="close()" class="mc-button ml-auto">{{'anuluj' | transloco}}</button>

                    <button mat-raised-button color="primary" [disabled]="form.invalid || isProcessing" (click)="createOrUpdate()">
                        {{mode == 'create' ? ( 'dodaj' | transloco ) : ('zapisz_zmiany' | transloco)}}
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>