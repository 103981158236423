import { Component, Inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Observable, timer, map } from 'rxjs';
import { AlertData } from '../../models/alert-data';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FuseAlertComponent } from '@fuse/components/alert';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-select-dates-dialog',
  templateUrl: './select-dates-dialog.component.html',
  styleUrls: ['./select-dates-dialog.component.scss'],
  imports: [MatDialogModule, CommonModule, MatDatepickerModule, TranslocoModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule, MatButtonModule, FuseAlertComponent]
})
export class SelectDatesDialogComponent {

  selectDatesForm = this.formBuilder.group({
    from: ['', Validators.required],
    to: ['', [Validators.required, this.validateDateRange]]
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<SelectDatesDialogComponent>,
    private formBuilder: FormBuilder,
    public translate: TranslocoService
  ) {

  }
  validateDateRange(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: boolean } | null> => {
      const startDate = new Date(this.selectDatesForm.controls.from.value);
      const endDate = new Date(this.selectDatesForm.controls.to.value);

      if (startDate && endDate) {
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (diffDays > 31) {
          return timer(500).pipe(
            map(() => {
              return { invalidRange: true };
            })
          );
        }
      }

      return timer(0).pipe(
        map(() => {
          return null;
        })
      );
    };
  }

  invalidRange = false;
  invalidRangeAlertData: AlertData = {
    canDismiss: true,
    message: this.translate.translate('zakres_pomiedzy_datami_wynosi_od_7_do_31_dni'),
    title: this.translate.translate('informacja_wf'),
    status: 'info'
  }
  changeDateRange() {
    this.invalidRange = false;
    const startDate = new Date(this.selectDatesForm.controls.from.value);
    const endDate = new Date(this.selectDatesForm.controls.to.value);

    if (startDate && endDate) {
      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (diffDays > 31 || diffDays < 6) {
        this.selectDatesForm.controls.to.setErrors({ 'invalidRange': true }, { emitEvent: true })
        this.selectDatesForm.controls.to.setValue('')
        this.selectDatesForm.controls.to.updateValueAndValidity();
        this.invalidRange = true;
      }
    }
  }
  close(state) {
    this.dialog.close({ action: state, selected: this.selectDatesForm.value });
  }
}
