
<div class="flex flex-auto">

    <ng-container *ngIf="source">
        <app-source-avatar class="mr-4" [sourceId]="notification?.body?.sourceId"></app-source-avatar>
    </ng-container> 

    <div class="flex flex-col flex-auto">
        {{room?.name}}

        <div class="font-semibold line-clamp-2 pr-2">
            {{'import_rezerwacji_ical' | transloco}} {{source?.name}}
        </div>

        <div *ngIf="notification?.body?.data?.new?.length > 0" class="line-clamp-1">
            {{'nowe' | transloco}}: {{notification?.body?.data?.new?.length}}
        </div>

        <div *ngIf="notification?.body?.data?.edited?.length > 0" class="line-clamp-1">
            {{'edytowane' | transloco}}: {{notification?.body?.data?.edited?.length}}
        </div>

        <div *ngIf="notification?.body?.data?.cancelled?.length > 0" class="line-clamp-1">
            {{'usuniete' | transloco}}: {{notification?.body?.data?.cancelled?.length}}
        </div>

        <div *ngIf="notification?.body?.data?.errors?.length > 0" class="line-clamp-1 text-red-600 flex items-center">
            <mat-icon class="icon-size-4 mr-0 text-red-600" [svgIcon]="'heroicons_solid:exclamation-triangle'"></mat-icon>
            <span>{{'bledy' | transloco}}: {{notification?.body?.data?.errors?.length}}</span>
        </div>
    
        <div class="mt-2 text-sm leading-none text-secondary">
            <date-formatter [date]="notification.addDate" [showTime]="true"></date-formatter>
        </div>

    </div>
</div>
