import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-calculate-prices',
  standalone: true,
  imports: [CommonModule, FormsModule, MatCheckboxModule, MatDialogModule, ReactiveFormsModule, TranslocoModule, MatIconModule, MatButtonModule],
  templateUrl: './calculate-prices-dialog.component.html',
  styleUrl: './calculate-prices-dialog.component.scss'
})
export class CalculatePricesDialogComponent {

  fields = {
    pricePerRoom: { id: 'pricePerRoom', checkboxControl: new FormControl(true), valueControl: new FormControl(0), translation: 'cena_za_pokoj'},
    pricePerMeal: { id: 'pricePerMeal', checkboxControl: new FormControl(true), valueControl: new FormControl(0), translation: 'cena_za_wyzywienie'}
  }

  currency = ''
  pricePerRoomPerNight = 0
  days = 0

  additionalServicesPriceCalculation = {
    additionalServices: []
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialog:MatDialogRef<CalculatePricesDialogComponent>
  ) {

    if (typeof data.currency != 'undefined') {
      this.currency = data.currency
    }

    // Price per meal
    if (typeof data.pricePerMeal != 'undefined') {
      this.fields.pricePerMeal.valueControl.setValue(parseFloat((data.pricePerMeal).toFixed(2)))
    }

    // Price per room
    if (typeof data.pricePerRoomPerNight != 'undefined' && typeof data.days != 'undefined') {
      this.pricePerRoomPerNight = data.pricePerRoomPerNight
      this.days = data.days
      this.fields.pricePerRoom.valueControl.setValue(parseFloat((parseFloat(this.pricePerRoomPerNight.toString()) * this.days).toFixed(2)))
    }

    // Additional services price calculation
    if(typeof data.additionalServicesPriceCalculation != 'undefined') {
      this.additionalServicesPriceCalculation = data.additionalServicesPriceCalculation

      this.additionalServicesPriceCalculation.additionalServices.forEach(service=> {
        service.enabled = true
      })
    }

  }

  getCheckedFieldsValues(fields) {
    const result:any = {}

    for (const fieldName in fields) {
        const field = fields[fieldName]
        if (field.checkboxControl.value === true) {
            result[fieldName] = field.valueControl.value
        }
    }

    result.additionalServices = this.additionalServicesPriceCalculation.additionalServices.filter(x=>x.enabled == true)
    console.log('result: ', result)
    return result
  }

  close() {
    this._dialog.close()
  }
  
  save() {
    this._dialog.close(
      this.getCheckedFieldsValues(this.fields)
    )
  }

}
