import { Injectable, inject } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { LocalizeRouterService } from "@penleychan/ngx-transloco-router";
import { Utils } from "app/core/shared/others/utils";
import { SettingsService } from "app/core/shared/services/settings.service";
import { map, tap } from "rxjs";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: 'root'
})
export class OnlyNotConfiguredAccountGuard implements CanActivate {


  constructor(
    private settingsService: SettingsService,
    private localize: LocalizeRouterService,
    private router: Router,
    private _authService:AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this._authService.isLoggedIn()) {
      return false;
    }

    if (Utils.isDefined(this.settingsService.userSettings.value, "userData.roomIndex")) {
      const roomIndex = this.settingsService.userSettings.value.userData.roomIndex;

      if(roomIndex > 0) {
        console.log('[OnlyNotConfiguredAccountGuard]: Redirecting...')
        const translatedPath = this.localize.translateRoute('/pl/app');
        this.router.navigate([translatedPath]);
        return true;
      }
    }

  }
}