export class PricingChannel {
    id: string | number
    name: string
    restrictions: any[]
    fetch: boolean

    constructor({id, name, restrictions, fetch}: {id: string, name: string, restrictions: any[], fetch:boolean}) {
        this.id = id,
        this.name = name,
        this.restrictions = restrictions
        this.fetch = fetch
    }
}