import { Utils } from "../others/utils"

export class UserPermissions {
        employeeId: number
        editDate: string
        addRoom: boolean = true
        editRoom: boolean = true
        deleteRoom: boolean = true
        filterRoom: boolean = true
        addReservation: boolean = true
        showReservation: boolean = true
        editReservation: boolean = true
        deleteReservation: boolean = true
        editDeletePastReservations: boolean = true
        registration: boolean = true
        changeReservationPaymentStatus: boolean = true
        searchReservation: boolean = true
        dashboard: boolean = true
        arrivalsDeparturesReport: boolean = true
        printCalendar: boolean = true
        widget: boolean = true
        customerDatabase: boolean = true
        statistics: boolean = true
        exportReservations: boolean = true
        importReservations: boolean = true
        searchAvailableRooms: boolean = true
        pricing: boolean = true
        reservations: boolean = true
        sendConfirmation: boolean = true
        messagesTemplates: boolean = true
        smtpConfiguration: boolean = true
        invoicing: boolean = true
        editObjectData: boolean = true
        restoreReservation: boolean = true
        changeEmail: boolean = true
        changePassword: boolean = true
        editPersonalData: boolean = true
        meals: boolean = true
        additionalServices: boolean = true
        onlineBookingEngine: boolean = true
        rooms: number[] = []
        lastEditRooms: string
        googleCalendar: boolean = true
        channelManager: boolean = true
        reservationSource: boolean = true
        notifications: boolean = true
        settings: boolean = true
        buySubscription: boolean = true
        showNewChanges: boolean = true
        iCalendar: boolean = true
        addTask: boolean = true
        changeRoomStatus: boolean = true
        history: boolean = true
        tasks: boolean = true
        employees: boolean = true
        restApi: boolean = true
      
        has(permission) {
                return this[permission] == true ? true : false
        }

        constructor(object) {
                if(Object.keys(object).length > 0) {
                        Object.keys(this).forEach((key: string) => {
                                if(typeof object[key] === 'undefined') this[key] = false
                                else this[key] = object[key]
                        })   
                }
                   
        }
}
