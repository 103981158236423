import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'mat-search-select',
  templateUrl: './mat-search-select.component.html',
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule, TranslocoModule, MatSelectModule, MatIconModule,]
})
export class MatSearchSelectComponent implements OnChanges, OnDestroy {

  subscriptions: Subscription[] = []
  @Output('valueChanges') valueChanges = new EventEmitter<any>();
  
  @Input('control') control = new UntypedFormControl('')
  @Input('controlValueProperty') controlValueProperty = null
  @Input('appearance') appearance = 'fill'
  @Input('label') label = ''
  @Input('displayProperty') displayProperty = ''
  @Input('value') value = ''
  @Input('matPrefixIcon') matPrefixIcon = ''
  @Input('options') options = []
  @Input('valueProperty') valueProperty = 'id'
  @Input('displayImagePrefix') displayImagePrefix = false;


  private _copyOptions = []
  constructor()
  {
    this.subscriptions.push(this.control.valueChanges.subscribe({
      next: (data)=> {
        this.valueChanges.emit(data);
      }
    }))
  }

  ngOnChanges(changes:SimpleChanges) {

    if(typeof changes.options != 'undefined') {
      this.filteredOptions = changes.options.currentValue;
      this._copyOptions = cloneDeep(changes.options.currentValue)
    }

    if(typeof changes.value == 'undefined') return

    // Handle clearing input
    if(changes.value.currentValue == null || changes.value.currentValue == '') {
      this.control.setValue(null)
      this.valueChanges.emit(null);
      return
    }
    
    if(changes.value.currentValue.length <= 0) return
    let selected = null
    
    if(this.controlValueProperty == null) {
      selected = this.filteredOptions.find(x=>x[this.valueProperty] == changes.value.currentValue);
    } else {
      selected = this.filteredOptions.find(x=>x[this.controlValueProperty] == changes.value.currentValue);
    }

    if(selected) { 
      this.control.setValue(selected)
      this.valueChanges.emit(selected);
    }
  }

  selectOption(option) { 
    if(this.controlValueProperty != null) {
      this.control.setValue(option[this.controlValueProperty])
    } else {
      this.control.setValue(option)
    }
    this.valueChanges.emit(option);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  filteredOptions: any = this.options;
  filterOptions(text): void {
    const value = text.toLowerCase();

    if(this.controlValueProperty != null) {
      this.filteredOptions = this.options.filter(
        option => 
        option[this.displayProperty].toLowerCase().includes(value)
        || option[this.controlValueProperty] == this.value
      )
    }
    else {
      this.filteredOptions = this.options.filter(
        option => 
        option[this.displayProperty].toLowerCase().includes(value)
        || option[this.valueProperty] == this.value
      )
    }
  }

  restoreOptions() {
    this.filteredOptions = this._copyOptions
    // if(this.controlValueProperty == null) {
    //   selected = this.filteredOptions.find(x=>x[this.valueProperty] == changes.value.currentValue);
    // } else {
    //   selected = this.filteredOptions.find(x=>x[this.controlValueProperty] == changes.value.currentValue);
    // }
    // if(selected) { 
    //   this.control.setValue(selected)
    //   this.valueChanges.emit(selected);
    // }
  }

  @ViewChild('selector') selector: MatSelect;
  @ViewChild('searchText') searchText: ElementRef;
  checkFilters() {
    setTimeout(() => {
      if(this.searchText)  {
        this.searchText.nativeElement.click()
        this.searchText.nativeElement.focus()
      } 
    },1)
  
    if(this.filteredOptions.length == 0) {
      this.filteredOptions = cloneDeep(this._copyOptions)

      setTimeout(()=> {
        if(this.selector) this.selector.open()
      },1)
    }
  }

  resetSearchText() {
    this.searchText.nativeElement.value = ''
    this.filterOptions('')
  }
}
