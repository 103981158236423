import { DbReservation } from "./DbReservation";
import { DbRoom } from "./DbRoom";

export class Collision {

    constructor()
    {
        this.hasCollision = false;
        this.collisions = [];
    }

    hasCollision: boolean = false
    collisions: RoomCollision[] = []

    addCollision(
        room:DbRoom,
        reservation:DbReservation,
        collidingReservation:DbReservation
    ):boolean
    {
        let r:RoomCollision = typeof this.collisions.filter(x=>x.room.roomId == room.roomId)[0] != 'undefined' ? this.collisions.filter(x=>x.room.roomId == room.roomId)[0] : <RoomCollision>{ room: room, reservations: []}
        let hasAlready = r.reservations.filter(
            x =>
            x.collidingReservation.reservationId == reservation.reservationId ||
            x.collidingReservation.reservationId == collidingReservation.reservationId ||
            x.reservation.reservationId == reservation.reservationId ||
            x.reservation.reservationId == collidingReservation.reservationId
        );
        if(hasAlready.length == 0)
        {
            r.reservations.push(
                { reservation: reservation, collidingReservation: collidingReservation}
            );
            
            if(typeof this.collisions.filter(x=>x.room.name == r.room.name)[0] == 'undefined')
            {
                this.collisions.push(r);
            }
            
            this.hasCollision = true;
            return true;   
        }
        return false;
    }
}

export class RoomCollision
{
     
    room: DbRoom;
    reservations: ReservationCollision[] = [];
}

export interface ReservationCollision {
    reservation : DbReservation,
    collidingReservation : DbReservation
}