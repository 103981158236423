import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Subscription, finalize, take } from 'rxjs';
import { DbMessage } from '../../models/DbMessage';
import { DbReservation } from '../../models/DbReservation';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { QuillEditorComponent } from 'ngx-quill';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DateFormatterComponent } from '../../components/date-formatter/date-formatter.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { cloneDeep } from 'lodash';
import { ApiService } from '../../services/api.service';
import { SnackbarService } from '../../services/snackbar.service';
import { FileAttachmentComponent } from '../../components/file-attachment/file-attachment.component';
import { TemplatesService } from '../../services/templates.service';
import { DbTemplate } from '../../models/DbTemplate';
import { Utils } from '../../others/utils';
import { MatSearchSelectComponent } from '../../components/mat-search-select/mat-search-select.component';
import { PhoneNumberDirective } from '../../directives/phone-number.directive';
import { ReservationPricingSummary } from '../../models/ReservationPricingSummary';
import { AdditionalServicesService } from '../../services/additional-services.service';
import { SettingsService } from '../../services/settings.service';
import { UserSettings } from '../../models/UserSettings';
import { MessagesService } from '../../services/messages.service';
import { SynchronizationService } from '../../services/synchronization.service';
import jsPDF from 'jspdf';
import { SMTPAccountsApiService } from '../../services/api/smtp-accounts.api.service';
import { SMTPAccount } from '../../models/smtp-account';
import { ReservationsService } from '../../services/reservations.service';

@Component({
  standalone: true,
  selector: 'app-reservation-confirmation-dialog',
  templateUrl: './reservation-confirmation-dialog.component.html',
  styleUrls: ['./reservation-confirmation-dialog.component.scss'],
  imports: [CommonModule, PhoneNumberDirective, MatDialogModule, MatTabsModule, MatIconModule, MatButtonModule, MatSearchSelectComponent, TranslocoModule, MatFormFieldModule, MatTooltipModule, MatSelectModule, MatCheckboxModule, MatExpansionModule, MatInputModule, QuillEditorComponent, MatRadioModule, FormsModule, DateFormatterComponent, FuseAlertComponent, ReactiveFormsModule, MatChipsModule, FileAttachmentComponent]
})
export class ReservationConfirmationDialogComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  senders:SMTPAccount[] = []
  reservation: DbReservation;
  messages: DbMessage[] = [];

  messageType = 0;
  recipients = []

  separatorKeysCodes: number[] = [ENTER, COMMA];
  receiverInputControl = new FormControl('')

  announcer = inject(LiveAnnouncer);
  processing = false

  form = new FormGroup({
    reservationId: new FormControl(),
    smtpId: new FormControl<number>(null),
    messageType: new FormControl('EMAIL'),
    recipients: new FormControl([]),
    recipientSms: new FormControl(''),
    templateId: new FormControl(''),
    templateName: new FormControl(''),
    subject: new FormControl(''),
    message: new FormControl(''),
    smsMessage: new FormControl(''),
    sendCopyToSender: new FormControl(false)
  })

  filesLimit = 8
  filesList = []
  files = []
  @ViewChild('imageInput', { static: false }) imageInput: ElementRef;

  // Errors must be a flag
  // because we are doing operations that could be really hard
  // using normat control error, like adding on focusout
  receiverAlreadyAdded = false
  invalidEmailFormat = false

  @ViewChild('messageEditor') messageEditor: QuillEditorComponent = null
  templates:DbTemplate[] = []

  sampleDiv = null
  userSettings: UserSettings = null

  senderAccount = null


  // Quill config
  quillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean']  // remove formatting button
    ]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialog:MatDialogRef<ReservationConfirmationDialogComponent>,
    public translate: TranslocoService,
    private _apiService: ApiService,
    private _snackbarService: SnackbarService,
    private _cdr:ChangeDetectorRef,
    private _templatesService:TemplatesService,
    private _additionalServicesService:AdditionalServicesService,
    private _settingsService:SettingsService,
    private _messagesService:MessagesService,
    private _synchronizationService:SynchronizationService,
    private _smtpAccountsApiService: SMTPAccountsApiService,
    private _reservationsService: ReservationsService

  ) 
  {

    if(Utils.isDefined(data,"reservation"))
    {
      this.reservation = data.reservation;
      this.form.controls.reservationId.setValue(this.reservation.reservationId)
    }

    if(Utils.isDefined(data, "invoice.reservationId"))
    {
      this.form.controls.reservationId.setValue(data.invoice.reservationId)
      this.reservation = this._reservationsService.getById(data.invoice.reservationId)
    }

    if(Utils.isDefined(this.reservation,"client.email") && !Utils.isNullOrEmpty(this.reservation.client.email)) {
      this._addReceiverByClientEmail(this.reservation.client.email)
    }

    if(Utils.isDefined(this.reservation,"client.phone") && !Utils.isNullOrEmpty(this.reservation.client.phone)) {
      this.form.controls.recipientSms.setValue(this.reservation.client.phone)
    }


    if(Utils.isDefined(data,"file")) {

      const pdf:jsPDF = data.file.pdf
      const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });

      // Create File object
      let fileName = `${data.file.name}.pdf`; // Provide a filename for the file
      fileName = fileName.replaceAll('/','_')
      const file = new File([blob], fileName, { type: 'application/pdf' });
      // Add file to the list
      // const base64 = await 
      this.files.push(file);

      this.filesList.push({
        name: file.name,
        type: file.type,
        data: '',
        size: this._getSize(file.size),
        extension: this._getExtension(file.name),
      });
    }

    this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
      next: (userSettings:UserSettings) => {
        this.userSettings = userSettings
      }
    }))

    this._subscriptions.push(this._templatesService.currentTemplates.subscribe({
      next: (data) => {
        this.templates = Object.values(data).filter(x=>x.status == 1)
      }
    }))

    this._subscriptions.push(this.form.controls.message.valueChanges.subscribe({
      next: (value) => {
        // Update sms message
        this.sampleDiv = document.createElement('div');
        value = value.replaceAll('</p>', '\n</p>')
        this.sampleDiv.innerHTML = value;
        let formattedValue = this.sampleDiv.textContent || this.sampleDiv.innerText || '';
        this.form.controls.smsMessage.setValue(formattedValue)
      }
    }))

    this._subscriptions.push(this.form.controls.templateId.valueChanges.subscribe({
      next: (value) => {
        const template = this.templates.find(x=>x.templateId == value)

        if(template) {

          // Replace subject tags
          let subject = template.subject

          if(this.reservation) {
            subject = Utils.replaceRoomTags(this.translate, subject, this.reservation.room)
            subject = Utils.replaceReservationTags(this.translate, subject, this.reservation)
            subject = Utils.replaceClientTags(this.translate, subject, this.reservation.client)
          }
    
          subject = Utils.replaceCompanyTags(this.translate, subject, this.userSettings)
          this.form.controls.subject.setValue(subject)

          let message:string = template.message

          if(this.form.controls.messageType.value == 'EMAIL') {
            message = message.replaceAll(/\n/g, '<br/>')
          }
          else if(this.form.controls.messageType.value == 'SMS') {
            message = message.replace('<br>',"\n")
            message = message.replace('<br/>',"\n")
          }

          // Replace message tags
          if(this.reservation) {
            message = Utils.replaceRoomTags(this.translate, message, this.reservation.room)
            message = Utils.replaceReservationTags(this.translate, message, this.reservation)
            message = Utils.replaceClientTags(this.translate, message, this.reservation.client)
          }
        
          message = Utils.replaceCompanyTags(this.translate, message, this.userSettings)

          //
          if(this.reservation) {
            const pricingSummary = new ReservationPricingSummary(this.reservation.currency, this._additionalServicesService)
            pricingSummary.fromReservation(this.reservation)
            message = Utils.replacePricingTags(this.translate, message, pricingSummary)
          }
         

          this.form.controls.message.setValue(message)

          this.form.controls.templateName.setValue(template.templateName)
        }
      }
    }))

    this._subscriptions.push(this.receiverInputControl.valueChanges.subscribe({
      next: (value) => {
        this.receiverAlreadyAdded = false
        this.invalidEmailFormat = false
      }
    }))

    this._subscriptions.push(this.form.controls.messageType.valueChanges.subscribe({
      next: (messageType) => {
        if(messageType == 'SMS') {

          // this.form.controls.message.setValue(this.form.controls?.message?.value?.replaceAll(/\n/g, "%0D%0A"))
          // this.form.controls.message.setValue(this.form.controls?.message?.value?.replaceAll('<br/>', "%0D%0A"))
          // this.form.controls.message.setValue(this.form.controls?.message?.value?.replaceAll('<br>', "%0D%0A"))

          this.form.controls.message.setValue(this.form.controls?.message?.value?.replaceAll('<br/>', "\n"))
          this.form.controls.message.setValue(this.form.controls?.message?.value?.replaceAll('<br>', "\n"))
        }
      }
    }))
  }
  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result as string;
            resolve(base64data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob); // Reads Blob as a Data URL (Base64)
    });
}
  ngOnInit(): void {

    // Is verified account
    this._subscriptions.push(this.form.controls.smtpId.valueChanges.subscribe({
      next: (value) => {

        this.senderAccount = this.senders.find(x => x.smtpId == value)
        this._cdr.markForCheck()

      }
    }))

    this._subscriptions.push(this._smtpAccountsApiService.currentSMTPAccounts.subscribe({

      next: (accounts) => {
        this.senders = accounts
        if (this.senders.length > 0) {
          this.senderAccount = this.senders[0]
          if (this.senderAccount)  this.form.controls.smtpId.setValue(this.senderAccount.smtpId) 
        }
      }

    }))
    this._getSMTPAccounts()
    this._browseMessages()

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  setTemplate(ev) {
    if(!ev) return
    this.form.controls.templateId.setValue(ev.templateId)
    this.form.controls.templateName.setValue(ev.templateName)
  }

  sendSMS() {
    let aElement = document.createElement('a')
    const receiver = this.form.controls.recipientSms.value
    let body = this.form.controls.smsMessage.value
    body = body.replaceAll('\n','%0A')
    body = body.replaceAll('<br/>','%0A')
    body = body.replaceAll('<br>','%0A')
    const href = `sms:${receiver}?subject=tytul&body=${body}`

    aElement.href = href
    aElement.target = '_blank'
    aElement.click()
    aElement = null


  }

  /** If smtp account is unavailable it opens outlook, but outlook opening doesnt support such img tags */
  removeImgTags(html) {
    return html.replace(/<img[^>]*>/g, '');
  }

  openOutlook() 
  {
    let aElement = document.createElement('a')
    let receivers = this.form.controls.recipients.value.join(',')
    let subject = this.form.controls.subject.value

    let body = this.removeImgTags(this.form.controls.message.value)

    body = body.replaceAll('<br/>','%0A')
    body = body.replaceAll('<br>','%0A')
    aElement.href = `mailto:${receivers}?subject=${subject}&body=${body}`
    aElement.click()
    aElement = null

  }

  handleFocusOutAndChange() {
    const value = this.receiverInputControl.value
    if(value == null || typeof value == 'undefined') return

    if(value.length > 0) {
      this._addReceiverByClientEmail(value)
    }
  }

 
  async handleFileInput(event) {
    const files = event.target.files;


    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        this.files.push(file)
        this.filesList.push({
          name: file.name,
          type: file.type,
          data: '',
          size: this._getSize(file.size),
          extension: this._getExtension(file.name),
        })
      }
    }

    if (this.files.length > this.filesLimit) {
      this._snackbarService.showSnackBar(this.translate.translate('MAX_FILES_INCLUDED'), 'warning', 8000);
      this.files = []
      this.filesList = []
      this.imageInput.nativeElement.value = ''
      return
    }

  }

  private _browseMessages() {
    this.messages = Object.values(this._messagesService.getMessages()).filter((message:DbMessage)=>message?.reservationId == this.reservation?.reservationId).sort((a:DbMessage,b:DbMessage)=>  b.messageId - a.messageId)
  }


  private _getSMTPAccounts() {
    this._smtpAccountsApiService.getSMTPAccounts()
  }

  public removeAttachment(i:number) {
    Utils.removeElementByIndex(this.files,i)
    Utils.removeElementByIndex(this.filesList,i)
    this.imageInput.nativeElement.value = ''
  }

  private _getExtension(fileName: string): string {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return ''; // No extension found
    }
    return fileName.substr(lastDotIndex + 1).toLowerCase();
  }

  private _getSize(fileSizeInBytes: number): string {
    if (fileSizeInBytes === 0) {
      return '0 B';
    }
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(fileSizeInBytes) / Math.log(k));
    return parseFloat((fileSizeInBytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  selectedTabId
  onTabChanged(ev) {
    console.log(ev)
    // this.selectedTabId = tabId;
  }

  async sendConfirmation() {

    if(this.senderAccount == null || this.senderAccount.isVerified == false) {
      this.openOutlook()
      return
    }

    const formData = new FormData();
    const requestData = cloneDeep(this.form.value);

    // Append form data
    for (const key of Object.keys(requestData)) {
      if (key == 'recipients') {
        requestData.recipients.forEach(email => {
          formData.append('recipients[]', email);
        });
      }
      // Do not send null reservationId
      else  if (key == 'reservationId') {
        if(!Utils.isNullOrEmpty(requestData[key])){
          formData.append(key, requestData[key]);
        }
      }
      else {
        formData.append(key, requestData[key]);
      }
    }

    let i=1;
    for (const file of this.files) {
      // const base64 = await this.blobToBase64(file)
      formData.append(`file${i}`, file, file.name);
      i++
    }

    this.processing = true
    this._apiService.sendReservationConfirmation(formData)
      .pipe(take(1))
      .pipe(finalize(()=> {
        this.processing = false
      }))
      .subscribe({
        next: (response:any) => {

          if(response.status != 'success') {
            this._snackbarService.showSnackBar(this.translate.translate(response.message), 'error', 6000)
            return
          }

          this._snackbarService.showSnackBar(this.translate.translate('wyslano_wiadomosc'),'success',6000)
          this._synchronizationService.newSynchronize('Send confirmation message')
          this.close()
          
        },
        error: (error) => {
          this._snackbarService.showSnackBar(this.translate.translate('nie_udalo_sie_wyslac_wiadomosci'),'error',6000)
        }
      })
  }

  public binaryStreamToBlob(binaryStr: string, mimeType = 'application/octet-stream'): Blob {
    // Convert binary string to a byte array
    const byteNumbers = binaryStr.match(/.{1,8}/g)!.map(byte => parseInt(byte, 2));
    const byteArray = new Uint8Array(byteNumbers);
    
    // Create a Blob from the byte array
    return new Blob([byteArray], { type: mimeType });
  }

  public fileToByteArray(file):any {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result;
            const byteArray = new Uint8Array(arrayBuffer as ArrayBuffer);
            resolve(byteArray);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
  }

  public close() {
    this._dialog.close()
  }

  private _addReceiverByClientEmail(email:string) {
      if(email == null) return
      let value = (email || '').trim();
  
      value = value.toLowerCase()
      // Add our fruit
      if (value) {
        const copy = cloneDeep(this.form.controls.recipients.value)
        if(copy.includes(value)) {
          this.receiverAlreadyAdded = true
          return
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const valid = emailRegex.test(value);
        if(!valid) {
          this.invalidEmailFormat = true
          return
        }
        copy.push(value);
        this.form.controls.recipients.setValue(copy)
      }
  
      // Validate email address format
      // Clear the input value
      this.receiverInputControl.setValue(null);
  }
  
  addReceiver(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    this._addReceiverByClientEmail(value)
  }

  removeReceiver(receiver) {
    const index = this.form.controls.recipients.value.indexOf(receiver);

    if (index >= 0) {

      const copy = cloneDeep(this.form.controls.recipients.value)
      copy.splice(index, 1);
      this.form.controls.recipients.setValue(copy)

      this.announcer.announce(`Removed ${receiver}`);
    }
  }
}
