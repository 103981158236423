import { ChangeDetectorRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { Navigation } from 'app/core/navigation/navigation.types';
import { BehaviorSubject, take, Observable, Subscription} from 'rxjs';
import { PermissionsService } from '../shared/services/permissions.service';
import { UserPermissions } from '../shared/models/UserPermissions';
import { SettingsService } from '../shared/services/settings.service';
import { UserSettings } from '../shared/models/UserSettings';
import { Utils } from '../shared/others/utils';
import { DayStatisticsService } from '../shared/services/day-statistics.service';
import { DateTime } from 'luxon';
import { DataSynchronizerService } from '../shared/services/data-synchronizer.service';
import { ReloginService } from '../shared/services/relogin.service';
import { AuthService } from '../auth/auth.service';
import { SnackbarService } from '../shared/services/snackbar.service';
import { IAPIResponse } from 'app/core/shared/services/app-init.service';
import { debounce } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class NavigationService implements OnInit, OnDestroy
{
    private readonly _subscriptions:Subscription[] = []

    private _navigation = new BehaviorSubject<any>([])
    permissions = new UserPermissions({})

    premiumType = 'STANDARD'
    premiumDays = 0

    constructor(
        private _localize:LocalizeRouterService,
        private _permissionsService:PermissionsService,
        private _settingsService:SettingsService,
        private _dayStatisticsService:DayStatisticsService,
        private _reloginService:ReloginService,
        private _dataSynchronizerService:DataSynchronizerService,
        private _authService: AuthService,
        private _snackbarService: SnackbarService,
        private _translate: TranslocoService
    )
    {

        this._subscriptions.push(
            this._permissionsService.currentPermissions.subscribe({
              next: (data) => {
                this.permissions = data
              }
            })
        )

        this._subscriptions.push(
            this._translate.langChanges$.subscribe({
                next: (data) => {
                    this._buildNavigation()
                }
            })
        )

        this._subscriptions.push(
            this._translate.events$.subscribe({
                next: (event) => {
                    if(event.type === 'translationLoadSuccess') {
                        this._buildNavigation()
                    }
                }
            })
        )

        // Refresh navigation menu when reservations are updated
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
              next: (data) => {
                if(data.table != 'reservations' && data.table != 'rooms') return
                this._buildNavigation()
              }
            })
        )

        this._subscriptions.push(
            this._settingsService.currentUserSettings.subscribe({
                next: (userSettings:UserSettings) => {
                    this.premiumType = Utils.getSubscriptionName(userSettings.userData.premiumType)

                    const premiumDays = Utils.getNestedValue(userSettings, "userData.subscriptionState.expiredInDays")
                    this.premiumDays = premiumDays
                    this._buildNavigation()
                }
            })
        )

        // Relogin event
        this._subscriptions.push(
            this._reloginService.currentEvent.subscribe({
                next: (event) => {

                    if(event != 'data_load_end') return
                    this._buildNavigation()

                }
            })
        )
   

        this._buildNavigation()

    }
    ngOnInit(): void {

        console.log("initialize of navigation service")
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable()
    }

    private _buildNavigation()
    {
        const statistics = this._dayStatisticsService.getDayStatistics(DateTime.utc())

        const dashboard:any = {
            title: this._translate.translate('pulpit'),
            type : 'basic',
            hidden: () => !this.permissions.has('dashboard'),
            icon : 'heroicons_outline:presentation-chart-line',
            link : this._localize.translateRoute('/app/dashboard').toString(),
        }

        const dashboardAlerts = statistics.arrivalDeparturesAlerts + statistics.cleaningAlerts

        if(dashboardAlerts > 0) {
            dashboard.badge = {
                title  : dashboardAlerts,
                classes: 'px-2 bg-pink-600 text-white rounded-full',
            }
        }
       
        const menu = [
            {
                id      : 'application',
                title   : this._translate.translate('aplikacja'),
                subtitle: this._translate.translate('funkcje_aplikacji'),
                type    : 'group',
                children: [
                    {
                        id: 'calendar',
                        title: this._translate.translate('kalendarz'),
                        type : 'basic',
                        icon : 'heroicons_outline:calendar',
                        link : this._localize.translateRoute('/app/calendar').toString(),
                    },
                    dashboard,
                ],
            },   
            {
                id: 'reservations',
                title: this._translate.translate('rezerwacje'),
                type : 'collapsable',
                hidden: () => !this.permissions.has('reservations'),
                icon : 'heroicons_outline:queue-list',
                children : [
                    {
                        title: this._translate.translate('lista_rezerwacji'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('reservations'),
                        link : this._localize.translateRoute('/app/reservations').toString(),
                    },
                    {
                        title: this._translate.translate('historia'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('history'),
                        link : this._localize.translateRoute('/app/history').toString(),
                        badge: this._getBadge(),
                    }
                ]
            },
            {
                id: 'management',
                title: this._translate.translate('zarzadzanie'),
                type : 'collapsable',
                hidden: () => 
                !this.permissions.has('editRoom') && 
                !this.permissions.has('employees') && 
                !this.permissions.has('history') &&
                !this.permissions.has('customerDatabase'),
                icon : 'heroicons_outline:user-group',
                children : [
                    {
                        title: this._translate.translate('pokoje'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('editRoom'),
                        link : this._localize.translateRoute('/app/rooms').toString(),
                    },
                    {
                        title: this._translate.translate('pracownicy'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('employees'),
                        link : this._localize.translateRoute('/app/employees').toString(),
                    },
                    {
                        title: this._translate.translate('baza_klientow'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('customerDatabase'),
                        link : this._localize.translateRoute('/app/clients').toString(),
                    }
                ]
            },
            {
                id: 'communication',
                title: this._translate.translate('komunikacja'),
                type : 'collapsable',
                hidden: () => 
                !this.permissions.has('smtpConfigurtation') && 
                !this.permissions.has('messagesTemplates') && 
                !this.permissions.has('sendConfirmation'),
                icon : 'heroicons_outline:chat-bubble-bottom-center-text',
                children : [
                    {
                        title: this._translate.translate('konto_pocztowe'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('smtpConfiguration'),
                        link : this._localize.translateRoute('/app/smtp-config').toString(),
                    },
                    {
                        title: this._translate.translate('szablony_wiadomosci'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('messagesTemplates'),
                        link : this._localize.translateRoute('/app/templates').toString(),
                    },
                    {
                        title: this._translate.translate('wiadomosci'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('sendConfirmation'),
                        link : this._localize.translateRoute('/app/messages').toString(),
                    }
                ]
            },
            {
                id: 'pricing',
                title: this._translate.translate('cennik'),
                type : 'collapsable',
                hidden: () => 
                !this.permissions.has('pricing') && 
                !this.permissions.has('additionalServices') && 
                !this.permissions.has('meals'),
                icon : 'heroicons_outline:banknotes',
                children : [
                    {
                        title: this._translate.translate('ustaw_ceny'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('pricing'),
                        link : this._localize.translateRoute('/app/pricing/manage-prices').toString(),
                    },
                    {
                        title: this._translate.translate('uslugi_dodatkowe'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('additionalServices'),
                        link : this._localize.translateRoute('/app/additional-services').toString(),
                    },
                    {
                        title: this._translate.translate('wyzywienie'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('meals'),
                        link : this._localize.translateRoute('/app/meals').toString(),
                    }
                ]
            },
            {
                id: 'statistics-and-reports',
                title: this._translate.translate('raporty_i_statystyki'),
                type : 'collapsable',
                hidden: () => 
                !this.permissions.has('statistics') && 
                !this.permissions.has('arrivalsDeparturesReport') && 
                !this.permissions.has('meals'),
                icon : 'heroicons_outline:chart-pie',
                children : [
                    {
                        title: this._translate.translate('statystyki'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('statistics'),
                        link : this._localize.translateRoute('/app/statistics').toString(),
                    },
                    {
                        title: this._translate.translate('przyjazdy_wyjazdy'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('arrivalsDeparturesReport'),
                        link : this._localize.translateRoute('/app/reports/arrivals-departures').toString(),
                    },
                    {
                        title: this._translate.translate('wyzywienie'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('meals'),
                        link : this._localize.translateRoute('/app/reports/meals').toString(),
                    }
                ]
            },
            {
                id: 'invoices',
                title: this._translate.translate('faktury'),
                type : 'collapsable',
                hidden: () => !this.permissions.has('invoicing'),
                icon : 'heroicons_outline:document-text',
                children : [
                    {
                        title: this._translate.translate('nowa_faktura'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('invoicing'),
                        link : this._localize.translateRoute('/app/invoices/new').toString(),

                    },
                    {
                        title: this._translate.translate('lista_faktur'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('invoicing'),
                        link : this._localize.translateRoute('/app/invoices/list').toString(),

                    },
                    {
                        title: this._translate.translate('ustawienia'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('invoicing'),
                        link : this._localize.translateRoute('/app/settings/invoices').toString(),

                    },
                ]
            },
            {
                id: 'online-booking-engine',
                title: this._translate.translate('system_rezerwacji_online'),
                type : 'collapsable',
                hidden: () => 
                !this.permissions.has('onlineBookingEngine'),
                icon : 'heroicons_outline:squares-plus',
                children : [
                    {
                        title: this._translate.translate('konfiguracja'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('onlineBookingEngine'),
                        link : this._localize.translateRoute('/app/booking-engine/config').toString(),

                    },
                    {
                        title: this._translate.translate('rezerwacje'),
                        type : 'basic',
                        hidden: () => !this.permissions.has('onlineBookingEngine'),
                        link : this._localize.translateRoute('/app/booking-engine/reservations').toString(),

                    }
                ]
            },
            {
                id: 'integrations',
                title: this._translate.translate('integracje'),
                subtitle: 'Booking.com, Airbnb...',
                type : 'basic',
                hidden: () => 
                !this.permissions.has('booking') && 
                !this.permissions.has('iCalendar'),
                icon: 'heroicons_outline:link',
                link : this._localize.translateRoute('/app/channel-manager/channels').toString(),
                badge: this._getBadge(),
            },
            {
                id      : 'subscription',
                title   : this._translate.translate('subskrypcja'),
                subtitle: this._translate.translate('kup_zarzadzaj_lub_rozszerz_plan'),
                type    : 'group',
                hidden: () => !this.permissions.has('buySubscription'),
                children: [
                    {
                        title: this._translate.translate('subskrypcja'),
                        subtitle: `${this._translate.translate('pozostalo_dni')}: ${this.premiumDays}`,
                        type : 'basic',
                        icon : 'heroicons_outline:shopping-cart',
                        link : this._localize.translateRoute('/app/subscription').toString(),
                        badge: {
                            title  : this.premiumType,
                            classes: 'px-2 bg-primary rounded-full',
                        },
                    }
                ],
            },
            {
                id      : 'help',
                title   : this._translate.translate('centrum_pomocy'),
                subtitle: this._translate.translate('wszystko_co_musisz_wiedziec_na_temat_aplikacji'),
                type    : 'group',
                children: [
                    {
                        title: this._translate.translate('pomoc'),
                        type : 'basic',
                        externalLink: true,
                        icon : 'heroicons_outline:book-open',
                        link : 'https://docs.mobile-calendar.com',
                        target: '_blank'
                    }
                ],
            },
            {
                id      : 'old-version',
                title   : this._translate.translate('stara_wersja'),
                subtitle: this._translate.translate('stara_wersja_aplikacji_niedlugo_wygasnie'),
                type    : 'group',
                children: [
                    {
                        title: this._translate.translate('powrot_do_starej_wersji'),
                        type : 'basic',
                        icon : 'heroicons_outline:arrow-uturn-left',
                        function : (item) => this._oldVersion(),
                        badge: {
                            title  : '2.2.7',
                            classes: 'px-2 bg-pink-600 text-white rounded-full',
                        }
                    }
                ],
            },
        ]

        setTimeout(()=> {
            this._navigation.next({
                default: menu
            })
        },0)
       

    }

    private _oldVersion() {
        this._authService.getOldVersion()
        .pipe(take(1))
        .subscribe({
            next: (response: IAPIResponse) => {
                
                if(response.status != 'success') {
                    this._snackbarService.showSnackBar(this._translate.translate('poprzednia_wersja_jest_aktualnie_niedostepna'), 'error')
                    return
                }

                // Open the URL in same tab
                // Some devies block opening new tabs
                window.location.href = response.data.url;
            }
        })
    }

    private _getBadge() {
        if(this.premiumType == 'STANDARD') {
            return   {
                title  : 'PRO',
                classes: 'px-2 bg-primary rounded-full',
            }
        }
        return null
    }
}
