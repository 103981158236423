import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { DbInvoice } from '../../models/DbInvoice';
import { TranslocoModule } from '@ngneat/transloco';
import { InvoiceTypeToNameComponent } from '../invoice-type-to-name/invoice-type-to-name.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { DateFormatterComponent } from '../date-formatter/date-formatter.component';
import { PaymentTypeToTextComponent } from '../payment-type-to-text/payment-type-to-text.component';
import { TaxRatePipe } from '../../pipes/tax-rate.pipe';
import { UserSettings } from '../../models/UserSettings';
import { SettingsService } from '../../services/settings.service';
import { Subscription } from 'rxjs';
import { InvoiceItemListingComponent } from 'app/modules/admin/invoices/invoice-details/invoice-item-listing/invoice-item-listing.component';
import { DateTime } from 'luxon';
import { Utils } from '../../others/utils';

@Component({
  selector: 'invoice-preview',
  standalone: true,
  imports: [CommonModule, TranslocoModule, JsonPipe, InvoiceTypeToNameComponent, MatFormFieldModule, MatDatepickerModule, MatButtonModule, TaxRatePipe, MatInputModule, MatIconModule, MatAutocompleteModule, MatSelectModule, DateFormatterComponent, PaymentTypeToTextComponent, InvoiceItemListingComponent],
  templateUrl: './invoice-preview.component.html',
  styleUrl: './invoice-preview.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class InvoicePreviewComponent implements OnChanges, OnDestroy {

  private readonly _subscriptions: Subscription[] = []
  isEditable: boolean = false
  userSettings: UserSettings
  logoPath = ""

  dimensions: { width: number, height: number } | null = null;
  calculatedHeight: number | null = null;

  readonly staticWidthPx = 96
  dynamic = new Date().getTime();
  @Input() invoice: DbInvoice
  constructor(
    private _settingsService: SettingsService
  ) {

    this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
      next: (userSettings: UserSettings) => {
        this.userSettings = userSettings
        this._getInvoiceLogoPath()

        
      }
    }))

  }

  private _checkInvoiceSellerFromOlderVersion() {
    if(this.invoice != null) {

      // Only if the invoice has not companyNameSeller provided

      if(Utils.isNullOrEmpty(this.invoice.companyNameSeller)) {
        this.invoice.companyNameSeller = this.userSettings.userData.companyName

        if(Utils.isNullOrEmpty(this.invoice.addressSeller)) {
          this.invoice.addressSeller = `${this.userSettings.userData.street} ${this.userSettings.userData.postalCode} ${this.userSettings.userData.city}`
        }
  
        if(Utils.isNullOrEmpty(this.invoice.taxIdSeller)) {
          this.invoice.taxIdSeller = this.userSettings.userData.taxId
        }
  
        if(Utils.isNullOrEmpty(this.invoice.bankNoSeller)) {
          this.invoice.bankNoSeller = this.userSettings.userData.bankAccountNo
        }
      }
      
    }
  }

  onImageLoad(event: Event): void {
    const image = event.target as HTMLImageElement;
    this.dimensions = {
      width: image.naturalWidth,
      height: image.naturalHeight
    };
    this.calculatedHeight = (this.staticWidthPx / image.naturalWidth) * image.naturalHeight;
  }

  ngOnChanges(changes: SimpleChanges): void {

    this._checkInvoiceSellerFromOlderVersion()
    this.dynamic = new Date().getTime()

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  private _getInvoiceLogoPath() {
    this.logoPath = this.userSettings.hotelData.fullLogo
  }

}
