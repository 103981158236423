<div class="relative flex flex-col w-full sm:w-180 max-h-full sm:max-h-screen overflow-hidden">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{"zmien_ceny_masowo" | transloco}}</div>
        <button mat-icon-button (click)="close()" [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto mb-18 p-6 overflow-y-scroll" >
            <div class="grid sm:grid-cols-6 gap-6 w-full">
                <div class="col-span-12">
                    <span
                        class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full uppercase bg-gray-200 dark:bg-gray-600 dark:text-gray-50 text-gray-800">
                        {{channel?.name}}
                    </span>
                </div>
                <div class="col-span-12">
                    <div class="flex items-center">

                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" (click)="picker.open()" required>
                            <mat-label>{{'od' | transloco}} - {{'do' | transloco}}
                            </mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate [formControl]="startControl" readonly>
                                <input matEndDate [formControl]="endControl" readonly>
                            </mat-date-range-input>
                            <mat-icon matPrefix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                            <mat-error
                                *ngIf="(startControl.touched && startControl.invalid) || (endControl.touched && endControl.invalid)">{{'wybierz_termin'
                                | transloco}}</mat-error>
                        </mat-form-field>


                    </div>

                </div>

                <div class="col-span-12">
                    <p class="font-medium">{{'dni_tygodnia_w_ktorych_chcesz_wprowadzic_zmiany' | transloco}}</p>
                    <ng-container *ngFor="let weekDay of weekDaysControls">
                        <mat-checkbox color="primary" class="-ml-2"
                            [formControl]="weekDay.control">{{weekDay.translation |
                            transloco}}</mat-checkbox>
                    </ng-container>
                    <mat-error *ngIf="!areDaysSelected()">{{'wybierz_minimum_jeden_dzien_tygodnia' | transloco}}</mat-error>
                </div>


                <div class="col-span-12">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>
                            {{'plan_cenowy' | transloco}}
                        </mat-label>
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:currency-dollar'"></mat-icon>
                        <mat-select matInput [formControl]="rateControl">
                            <mat-option [value]="rate.rateId" *ngFor="let rate of rates">
                                <span class="font-medium">{{rate.name}} </span>
                                <div class="truncate text-ellipsis text-secondary w-full" *ngIf="channel?.id != 'mobile-calendar' && rate?.roomNames?.length > 0">
                                    ({{rate?.roomNames}})
                                </div>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="rateControl.touched && rateControl.invalid">{{'wybierz_plan_cenowy' |
                            transloco}}</mat-error>
                    </mat-form-field>
                </div>


                <div class="col-span-12">
                    <mat-slide-toggle color="primary" (change)="setPrices = !setPrices">{{'ustaw_ceny' |
                        transloco}}</mat-slide-toggle>
                </div>
                <ng-container *ngIf="setPrices">

                    <div class="col-span-12">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>
                                {{'cena' | transloco}}
                            </mat-label>
                            <span matSuffix>{{defaultCurrency}}</span>
                            <input matInput replaceCommaByDot [formControl]="priceControl" type="text" />
                        </mat-form-field>
                    </div>

                </ng-container>


                <div class="col-span-12">
                    <mat-slide-toggle color="primary" (change)="setRestrictions = !setRestrictions">{{'ustaw_restrykcje'
                        |
                        transloco}}</mat-slide-toggle>
                </div>

                <ng-container *ngIf="setRestrictions">

                    <ng-container *ngFor="let restrictionControl of restrictionControls; let index = index;">
                        <div class="col-span-5">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>
                                    {{'restrykcja' | transloco}}
                                </mat-label>
                                <mat-select matInput [formControl]="restrictionControl.control">
                                    <mat-option [disabled]="!isRestrictionAvailable(restriction.id)"
                                        [value]="restriction.id" *ngFor="let restriction of availableRestrictions">
                                        {{restriction.translation | transloco}}
                                        <div class="text-sm text-secondary" *ngIf="restriction.id == 'price1'">({{'cena_za_pokoj_do_pojedynczego_wykorzystania_za_noc' | transloco}})</div>
                                    </mat-option>
                                </mat-select>
                                <mat-hint
                                    *ngIf="restrictionControl.control.value == 'minAdvanceRes' || restrictionControl.control.value == 'maxAdvanceRes'">
                                    {{'prawidlowy_format_to_1d_lub_12h' | transloco}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-span-5">
                            <ng-container [ngSwitch]="getRestrictionType(restrictionControl.control.value)">
                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                    <mat-label>
                                        {{'wartosc' | transloco}}
                                    </mat-label>
                                    <ng-container *ngSwitchCase="'text'" >
                                        <!-- Available options 1H, 12D -->
                                        <input matInput *ngIf="restrictionControl.control.value == 'minAdvanceRes' || restrictionControl.control.value == 'maxAdvanceRes'" type="text" advanceRes [formControl]="restrictionControl.valueControl" />
                                        <!-- Normal integer input -->
                                        <input matInput *ngIf="restrictionControl.control.value != 'minAdvanceRes' && restrictionControl.control.value != 'maxAdvanceRes'" type="text" integerOnly [integerOnlyMinValue]="0" [formControl]="restrictionControl.valueControl" />
                                    </ng-container>

                                    <mat-select matInput [formControl]="restrictionControl.valueControl" *ngSwitchCase="'checkbox'">
                                        <mat-option [value]="true">
                                            {{ 'tak' | transloco}}
                                        </mat-option>
                                        <mat-option [value]="false">
                                            {{ 'nie' | transloco}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </ng-container>

                        </div>
                        <div class="col-span-2 items-center">
                            <mat-icon class="mt-8 cursor-pointer" (click)="deleteRestriction(index)"
                                [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                        </div>
                        <div class="col-span-12"
                            *ngIf="restrictionControl.control.touched && restrictionControl.control.invalid">
                            <p class="text-red-600">{{'wybierz_restrykcje' | transloco}}</p>
                        </div>
                    </ng-container>


                    <div class="col-span-12">
                        <div (click)="addRestriction()" class="group inline-flex items-center rounded cursor-pointer mt-2">
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                            <span class="ml-2 font-medium text-secondary group-hover:underline">
                                {{'dodaj_wiecej' | transloco}}
                            </span>
                        </div>
                    </div>

                </ng-container>

                <!-- <div class="col-span-12">
                    <p *ngIf="!setRestrictions && !setPrices" class="text-red-600">{{'wybierz_dane_do_aktualizacji'
                        | transloco}}</p>
                </div> -->

            </div>


            <div class="mt-6">
                <p class="font-medium">{{'pokoje' | transloco}} ({{selectedIds.size}})</p>
                <mat-error *ngIf="selectedIds.size == 0">{{'wybierz_minimum_jeden_pokoj' | transloco}}
                </mat-error>
            </div>



            <mat-form-field class="fuse-mat-dense my-6 fuse-mat-rounded w-full min-w-50" [subscriptSizing]="'dynamic'">
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input [formControl]="searchControl" matInput type="text"
                    [placeholder]="translate.translate('filtruj')">
                <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clear()">
                    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </mat-form-field>

            <!-- 
            <mat-form-field class="w-full">
                <mat-label>{{'szukaj' | transloco}}</mat-label>
                <input matInput [formControl]="searchControl" />
            </mat-form-field> -->

            <button *ngIf="selectedIds.size != channel.rooms.length" (click)="selectAll()" mat-flat-button
                class="-ml-3">{{"zaznacz_wszystkie" | transloco}}</button>
            <!--*ngIf="rooms.length - 1 != selected.length - 1" (click)="selectAll()" -->
            <button *ngIf="selectedIds.size == channel.rooms.length" (click)="deSelectAll()" mat-flat-button
                class="-ml-3">{{"odznacz_wszystkie" | transloco}}</button>
            <!--*ngIf="rooms.length - 1 != selected.length - 1" (click)="selectAll()" -->
            <!-- <button *ngIf="rooms.length - 1 == selected.length - 1" (click)="deselectAll()" mat-flat-button>{{"odznacz_wszystkie" | transloco}}</button> -->

            <div class="my-4 border-t"></div>

            <div class="h-full">
                <div class="py-2" *ngFor="let room of filteredObjects" matRipple>
                    <div (click)="toggle(room?.id)" class="flex items-center pl-1 pr-4 cursor-pointer hover:bg-hover h-full" >
                        <mat-checkbox [checked]="selectedIds.has(room.id)"
                            class="flex items-center  pointer-events-none" [color]="'primary'"
                            [disableRipple]="true">
                        </mat-checkbox>
                        <div class="ml-1">
                            <div class="flex items-center">
                                <div>
                                    ID: <b>{{room?.name}}</b>
                                    <span *ngIf="room?.roomNames?.length > 0"> ({{room?.roomNames?.length}})</span>
                                </div>
                                <div *ngIf="channel.id == 'mobile-calendar'">
                                    <mat-icon class="icon-size-5 ml-1.5" [svgIcon]="'heroicons_solid:user'"></mat-icon>
                                    x{{room?.persons}}
                                </div>
                            </div>
                            <div class="block text-md" *ngIf="channel.id != 'mobile-calendar'">
                                <span *ngFor="let r of room?.roomNames; let last = last">
                                    {{r}}<span *ngIf="!last">,</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Footer-->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-button (click)="close()" class="mc-button ml-auto">{{'anuluj' | transloco}}</button>
                <button mat-raised-button (click)="update()" [disabled]="!isFormValid() || isProcessing" color="primary"
                    class="ml-2">
                    {{"zmien_ustawienia_masowo" | transloco}}
                </button>
            </div>
        </div>
    </div>