export class DbTemplate {

    constructor(obj:any) {
        Object.keys(obj).forEach(key => {
            this[key] = obj[key]
        })
    }
    
    templateId: any
    userId: any
    status: any
    templateName: any
    subject: any
    message: any
    lang: string
    lastEdit: any
}