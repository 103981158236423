import { IStyles } from "./interfaces/istyles";


export class LightStyles implements IStyles {

    public static DefaultHeaderBackgroundColor = '#ffffff'
    public static DefaultBookingReservationColor = '#e53935'
    public static BackgroundLinesColor = "#dbdbdb"
    public static TodayLineColor = '#f53513'
    public static CanDragColor = "rgba(100,250,100,0.8)"
    public static CanNotDragColor = "rgba(113, 113, 113, 0.2)"
    public static WeekendHeaderBackgroundColor = '#eeeeee'
    public static WeekendCalendarBackgroundColor = 'rgba(150,150,150,0.1)'
    public static InActiveRoomBackgroundColor = 'rgba(113, 113, 113, 0.2)'
    public static PastBackgroundColor = 'rgba(75,75,75,0.05)'

    public static CalendarHeaderHistoricalTextColor = '#dbdbdb'
    public static CalendarHeaderFutureTextColor = '#525f7f'
    public static CalendarHeaderBorderColor = '#dbdbdb'

    public static DefaultRoomRectColor = '#ffffff'
    public static CreatedReservationBackground = '#f53513'
    public static DefaultReservationRectColor = '#f53513'
    public static HighlightColor = 'rgba(150,150,150,0.1)'
    public static HighlightRectColor = 'rgba(150,150,150,0.9)'
    public static SingleRoomCalendarPrevNextMonthDayBackgroundColor = 'rgba(150,150,150,0.1)'
    public static SingleRoomCalendarCurrentMonthDayBackgroundColor = 'rgba(250,250,250,0.3)'

    public static TextColor = 'black'
    public static InversedTextColor = 'white'

    public static LockGradientFirstColor = 'rgb(250,250,250)'
    public static LockGradientSecondColor = 'rgb(230,230,230)'

    public static VariantHighlight = '#f4f4f4'
    
    public static Reservations = {
        typ1d: '#e53935',
        typ1w: '#fb8c00',
        typ1: '#43a047',
        typ1i: '#00acc1',
        typ1waiting: '#8e24aa',
    };
}
