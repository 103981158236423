import { Injectable, OnDestroy } from "@angular/core";
import { AppDB } from "../models/db";
import { BehaviorSubject, Observable, Subscription, from, map, of, tap } from "rxjs";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { DbClient } from "../models/DbClient";

@Injectable({
  providedIn: 'root'
})
export class ClientsService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _clients = new BehaviorSubject<DbClientObject>({})
  public currentClients: Observable<DbClientObject> = this._clients.asObservable()

  private _db: AppDB

  private _counter = new BehaviorSubject<DbClientCounter>({
    valid: 0,
    deleted: 0
  })
  // Clean subscriptions
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService
  ) {

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
          this._clients.next({})
        }
      })
    )

    this._subscriptions.push(this.currentClients.subscribe({
      next: (data) => {
        this._countAll(data)
      }
    }))
    

    // Add reservation on synchronize
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
        next: (data) => {
          if (data.table != 'clients') return
          // Update
          const updatedClients = this._processClients(data.value)
          this._dataSynchronizerService.received()

          // Push changes
          this._clients.next(this._clients.value)
          this._dataSynchronizerService.next("reservationClients", updatedClients, null)

        }
      }))
  }

  getClients(): DbClientObject {
    return this._clients.value
  }

  public getCounter(): Observable<DbClientCounter> {
    return this._counter.asObservable()
  }

  public findCompanies(searchText:string) {
    const search = searchText.toLowerCase()

    return Object.values(this._clients.value).filter((client: DbClient) => {
      if(client.status == 0) return false
      else if (client.companyName?.length > 0 && client.companyName.toLowerCase().includes(search)) return true
      else if (client.name?.length > 0 && client.nameToLowerCase.includes(search)) return true
      else if (client.forename?.length > 0 && client.forenameToLowerCase.includes(search)) return true
      return false
    })

  }

  public browseClients({ allResults = [], filters = [] }): PaginatedDbClients {
    let t1 = new Date().getTime();

   
    let searchingResults = allResults.length > 0 ? allResults : Object.values(this._clients.value)
      .sort((a: DbClient, b: DbClient) => b.clientId - a.clientId)

    if (filters.length > 0) {
      filters.forEach(filter => {
        searchingResults = searchingResults.filter(filter)
      })
    }
  
    const paginationResult: PaginatedDbClients = {
      time: new Date().getTime() - t1,
      resultsCount: searchingResults.length,
      allResults: searchingResults
    }

    return paginationResult

  }

  getById(clientId: number): DbClient {
    if (clientId == null) return null
    const reservation = this._clients.value[clientId]
    if (reservation) return reservation
    else console.warn(`Client with id: ${clientId} not found.`)

  }

  getAll(): Observable<boolean> {

    this._clients.next({});
    if(!this._db) return of(true)
    let startTime = 0

    const loader = from(this._db.transaction("r", this._db.clients, () => {
      startTime = performance.now();
      return this._db.clients.toArray();
    }))
      .pipe(
        map((clients: DbClient[]) => {
          this._processClients(clients);

          this._clients.next(this._clients.value);
          return true;
        }),
        tap(() => console.log(`[Clients Service]: Built in ${performance.now() - startTime}ms.`))
      );

    return loader
  }

  private _processClients(clients: DbClient[]): DbClient[] {

    const updatedClients: DbClient[] = []
    clients.forEach(client => {

      client = new DbClient(client)

      if (typeof client.liczba_rezerwacji == 'undefined') client.liczba_rezerwacji = 0;
      if (typeof client.reservations == 'undefined') client.reservations = [];
      if (typeof client.canceledReservations == 'undefined') client.canceledReservations = [];

      this._clients.value[client.clientId] = client;

      // Updating calendar informations
      // Update reservation client data
      // this._dataSynchronizerService.next('reservations',[])
      // Object.entries(this.data.value.rooms).forEach((room: any) => {
      //   if (typeof room[1].reservations != 'undefined') {
      //     room[1].reservations.forEach((reservation) => {
      //       if (reservation.clientId == client.clientId) {

      //         reservation.client = client;
      //         // Rebuild calendar reservation
      //         const calendarReservation = this.calendarReservationService.create(this.data.value.employees, reservation)
      //         reservation.calendarReservation = calendarReservation;
      //       }
      //     })
      //   }
      // });

      updatedClients.push(client)
    })
    return updatedClients
  }
  
  private _countAll(data) {
    let valid = 0
    let deleted = 0
    Object.values(data).forEach((client: DbClient) => {
      if (client.status == 1) valid++
      else deleted++
    })

    this._counter.next({ valid, deleted })
  }
}

export interface DbClientObject {
  [key: number]: DbClient;
}

export interface PaginatedDbClients {
  allResults: DbClient[]
  resultsCount: number
  time: number,
}
export interface DbClientCounter {
  valid: number,
  deleted: number
}