import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, from, of } from "rxjs";
import { AppDB } from "../models/db";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { DbService } from "./db.service";
import { DbNotification } from "../models/DbNotification";
import { DbAdditionalService } from "../models/DbAdditionalService";
import { Utils } from "../others/utils";
import { PaginationUtils } from "../others/pagination.utils";

@Injectable({
    providedIn: 'root'
})
export class NotificationsService implements OnDestroy {

    private _subscriptions: Subscription[] = [];
    private _db: AppDB

    private _notifications = new BehaviorSubject<DbNotification[]>([])
    public currentNotifications: Observable<DbNotification[]> = this._notifications.asObservable()

    constructor(
        private _dbService: DbService,
        private _dataSynchronizerService: DataSynchronizerService
    ) {

        this._subscriptions.push(
            this._dbService.getDatabase().subscribe({
                next: (data) => {
                    this._db = data
                }
            })
        )

        // Add currencies on synchronize
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {
                    if (!data) return
                    if (data.table != 'notifications') return

                    // data.value = data.value.map(service => new DbAdditionalService(service))
                    // Add or update
                    data.value.forEach((notification: DbNotification) => {
                        const index = this._notifications.value.findIndex(x => x.notificationId === notification.notificationId)
                        if (index >= 0) this._notifications.value[index] = notification
                        else this._notifications.value.push(notification)
                    });
                    // Show not deleted only
                    this._notifications.next(this._notifications.value.filter(x => x.status === 1))
                    this._dataSynchronizerService.received()
                }
            }))
    }

    getNotifications() {

        const notifications = this._notifications.value.filter(x => x.status == 1).sort((a, b) => a.notificationId - b.notificationId)
        const resultsCount = notifications.length

        return {
            resultsCount,
            results: notifications
        }
    }
    browsePaginatedNotifications({
        currentPage,
        itemsPerPage
    }): PaginatedNotifications {

        const notifications = this._notifications.value.filter(x => x.status == 1).sort((a, b) => a.notificationId - b.notificationId)
        const allPages = Math.ceil(notifications.length / itemsPerPage)
        const resultsCount = notifications.length
        const results = PaginationUtils.getPageRecords({
            currentPage: currentPage,
            allResults: notifications,
            defaultPageSize: itemsPerPage
        })

        return {
            currentPage,
            itemsPerPage,
            allPages,
            resultsCount,
            results
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

    getAll(): Observable<DbNotification[]> {
        this._notifications.next([])
        if(!this._db) return of([])
        return from(
            this._db.transaction("r", this._db.notifications, () => {
                return this._db.notifications.where({ status: 1 }).toArray();
            })
                .then((result: DbNotification[]) => {
                    // Additional service name can be JSON
                    // So it needs to be maped using constructor
                    //   result = result.map(service => new DbAdditionalService(service))
                    this._notifications.next(result)
                    return result;
                })
        );
    }

    getById(notificationId): DbNotification {
        const notification = this._notifications.value.find(x => x.notificationId == notificationId)
        if (notification) return notification
        else console.warn(`[Notifications Service]: Notificaiton with id: ${notificationId} not found.`)
    }
}

export class PaginatedNotifications {
    currentPage: number
    itemsPerPage: number
    allPages: number
    resultsCount: number
    results: DbNotification[]
}