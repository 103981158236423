<div *ngIf="panelService.stackOfPortals.length > 1">
    <button mat-flat-button class="mc-button" (click)="panelService.back()">
        <mat-icon svgIcon="heroicons_mini:arrow-long-left"></mat-icon>
        {{'wstecz' | transloco}}
    </button>
</div>
<ng-template [cdkPortalOutlet]="panelService.portal$ | async">
    <ng-content></ng-content>
</ng-template>
<div *ngIf="(panelService.hasPortal | async) == false" class="d-flex align-items-center justify-content-center h-100">
    <!-- <img src="/assets/img/logo_svg.svg" height="180px" width="180px" alt="Mobile-Calendar"/> -->
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
