export class ReservationUtils {
    public static getPrepaymentValue(summary, prepaymentType, prepaymentValue) {


        if(Number.isNaN(summary)) return 0
        if(Number.isNaN(prepaymentValue)) return 0
        if(prepaymentType == 0) {

            return parseFloat((summary * (prepaymentValue / 100)).toFixed(2))
        }

        return parseFloat(prepaymentValue)
    }
}