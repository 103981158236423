import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DATA_INJECTION_TOKEN } from '../../others/data-injection-token';
import { PanelService } from '../../services/panel.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoDirective, TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { DialogService } from '../../services/dialog.service';
import { RoomsService } from '../../services/rooms.service';
import { ApplicationData } from '../../data/application-data';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { finalize, Subscription, take } from 'rxjs';
import { RoomsApiService } from '../../services/api/rooms.api.service';
import { IAPIResponse } from '../../services/app-init.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SynchronizationService } from '../../services/synchronization.service';

@Component({
  selector: 'app-add-or-update-task-or-cleaning',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRadioModule, MatTooltipModule, MatFormFieldModule, MatInputModule, MatSelectModule, TranslocoPipe, ReactiveFormsModule],
  templateUrl: './add-or-update-task-or-cleaning.component.html',
  styleUrl: './add-or-update-task-or-cleaning.component.scss'
})
export class AddOrUpdateTaskOrCleaningComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = []
  isProcessing = false
  form = new FormGroup({
    roomId: new FormControl([], [Validators.required, Validators.minLength(1)]),
    type: new FormControl('CLEANING', Validators.required),
    service: new FormControl(0),
    serviceInfo: new FormControl(''),
  })

  types = [
    { id: 'CLEANING', icon: 'mat_outline:cleaning_services', translation: 'sprzatanie'},
    { id: 'TASK', icon: 'mat_outline:task_alt', translation: 'zadanie'}
  ]

  selectedRooms = []
  selectedRoomStatus = null
  roomStatuses = ApplicationData.CleaningStatuses

  constructor(
    @Inject(DATA_INJECTION_TOKEN) public data: any,
    private _panelService: PanelService,
    private _dialogService: DialogService,
    public translate: TranslocoService,
    private _roomsService: RoomsService,
    private _roomsApiService: RoomsApiService,
    private _snackBar: SnackbarService,
    private _synchronizationService: SynchronizationService
  ) {

    if(data.mode) {
      this.form.controls.type.setValue(data.mode)
    }

  }

  public create() {
    if(this.form.invalid) return;

    this.isProcessing = true;

    if(this.form.controls.type.value == 'CLEANING') {
      this._setCleaning()
      return
    }

    this._setTasks()
    return
  }

  private _setCleaning() {

    const formData = this.form.getRawValue()

    const requestData = {
      roomId: formData.roomId,
      service: formData.service
    }

    this._roomsApiService.setCleaning(requestData)
    .pipe(take(1))
    .pipe(finalize(() => this.isProcessing = false))
    .subscribe(this._processResponse)
  }

  private _setTasks() {
    const formData = this.form.getRawValue()

    const requestData = {
      roomId: formData.roomId,
      serviceInfo: formData.serviceInfo
    }

    this._roomsApiService.setTasks(requestData)
    .pipe(take(1))
    .pipe(finalize(() => this.isProcessing = false))
    .subscribe(this._processResponse)
  }

  private _processResponse = {
    next: (response: IAPIResponse) => {
      
      if(response.status != 'success') {
        this._snackBar.showSnackBar(this.translate.translate('nie_udalo_sie_zapisac_zmian'), 'error')
        return
      }

      this._panelService.close();
      this._snackBar.showSnackBar(this.translate.translate('zapisano_zmiany'), 'success')
      this._synchronizationService.newSynchronize('cCanging rooms statuses.')
      return
    }
  }

  public close() {
    this._panelService.close();
  }

  public openSelectRoomDialogComponent() {
    this._dialogService.openSelectRoomDialogComponent({
      selectedIds: this.form.controls.roomId.value,
      title: this.translate.translate('zaznacz_pomieszczenia'),
      confirmText: this.translate.translate('wybierz')
    }).subscribe(
      (data) => {
        if (data.status == true) {
          this.form.controls.roomId.setValue(data.results)
          this.selectedRooms = data.results.map((roomId) => { return this._roomsService.getById(roomId) })
        }
      }
    )
  }

  public removeRoom(index) {
    if (!Array.isArray(this.form.controls.roomId.value)) return;
    const rooms = this.form.controls.roomId.value || [];
    rooms.splice(index, 1);
    this.form.controls.roomId.setValue(rooms);

    this.selectedRooms = this.form.controls.roomId.value.map((roomId) => { return this._roomsService.getById(roomId) })
  }

  ngOnInit(): void {

    // Select default room status by form
    this.selectedRoomStatus = this.roomStatuses.find(x => x.id == this.form.controls.service.value)

    this._subscriptions.push(
      this.form.controls.service.valueChanges.subscribe({
        next: (value) => {
          this.selectedRoomStatus = this.roomStatuses.find(x => x.id == value)
        }
      })
    )
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

}
