import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SettingsService } from '../../services/settings.service';
import { Utils } from '../../others/utils';
import { CommonModule } from '@angular/common';
import { DateTime } from 'luxon';

@Component({
  standalone: true,
  selector: 'date-formatter',
  templateUrl: './date-formatter.component.html',
  styleUrls: ['./date-formatter.component.scss'],
  imports: [CommonModule, TranslocoModule]
})
export class DateFormatterComponent implements OnChanges, OnDestroy {

  subscriptions: Subscription[] = [];
  @Input('date') date : DateTime | moment.Moment;
  @Input('isMoment') isMoment = false;
  @Input('showDayName') showDayName = false;
  @Input('showTime') showTime = false;
  @Input('dateFormat') dateFormat = "YYYY-MM-DD";
  @Input('useCustomDateFormat') useCustomDateFormat = false
  timeFormat = "HH:mm:ss";
  dayNameFormat = "dddd"
  format;
  output;

  currentLang = this.translate.getActiveLang();

  constructor(
    private settingService: SettingsService,
    private translate: TranslocoService,
  ) {

    this.subscriptions.push(this.translate.langChanges$.subscribe(
      {
        next: (data) => {
          this.currentLang = data;
          if(this.date == null) {
            this.output = this.translate.translate('brak_danych')
            return
          }
          this.output = moment.utc(this.date).locale(this.currentLang).local().format(this.format)
          
        }
      }
    ))

    this.subscriptions.push(this.settingService.getUserSettings().subscribe(
      {
        next: (data) => {
          if (data != null) {

            if (this.useCustomDateFormat == false && Utils.isDefined(data, "settings.generalDateFormat")) {
              this.dateFormat = data.settings.generalDateFormat;
            }

            if (Utils.isDefined(data, "settings.generalTimeFormat")) {
              this.timeFormat = data.settings.generalTimeFormat;
            }

            this.buildFormat()
            if(this.date == null) {
              this.output = this.translate.translate('brak_danych')
              return
            }
            this.output = moment.utc(this.date).locale(this.currentLang).local().format(this.format)
          }
        }
      }
    ))
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildFormat()
    if(this.date == null) {
      this.output = this.translate.translate('brak_danych')
      return
    }
    this.output = moment.utc(this.date).locale(this.currentLang).local().format(this.format)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  buildFormat() {
    this.format = this.dateFormat;

    if (this.showTime) { this.format = this.format + ' ' + this.timeFormat }
    if (this.showDayName) { this.format = this.format + ', ' + this.dayNameFormat }
  }
}
