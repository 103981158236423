import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelService } from '../../services/panel.service';
import { MatIconModule } from '@angular/material/icon';
import { PortalModule } from '@angular/cdk/portal';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'portal-outlet-loader',
  standalone: true,
  imports: [CommonModule, MatIconModule, PortalModule, TranslocoModule],
  templateUrl: './portal-outlet-loader.component.html',
  styleUrl: './portal-outlet-loader.component.scss'
})
export class PortalOutletLoaderComponent {
constructor(public panelService:PanelService){}
}
