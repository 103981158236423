import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ConfirmDialogData } from '../models/confirm-dialog-data';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { ApiService } from './api.service';
import { Validators } from '@angular/forms';
import { Subscription } from 'dexie';
import { HandlingService } from './handling.service';
import { SnackbarService } from './snackbar.service';
import { DataChangeTrackingService } from './data-change-tracking.service';
import { AddNoteDialogComponent } from '../dialogs/add-note-dialog/add-note-dialog.component';
import { ConfigureGroupPricesComponent } from '../dialogs/configure-group-prices/configure-group-prices.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { LoadingDialogComponent } from '../dialogs/loading-dialog/loading-dialog.component';
import { SelectDatesDialogComponent } from '../dialogs/select-dates-dialog/select-dates-dialog.component';
import { SelectRoomDialogComponent } from '../dialogs/select-room-dialog/select-room-dialog.component';
import { DbAdditionalService } from '../models/DbAdditionalService';
import { DbReservation } from '../models/DbReservation';
import { Utils } from '../others/utils';
import { CreateOrUpdateSourceDialogComponent } from '../dialogs/create-or-update-source-dialog/create-or-update-source-dialog.component';
import { SelectAdditionalServicesDialogComponent } from '../dialogs/select-additional-services-dialog/select-additional-services-dialog.component';
import { SynchronizationService } from './synchronization.service';
import { DbClient } from '../models/DbClient';
import { SelectInvoiceDialogComponent } from '../dialogs/select-invoice-dialog/select-invoice-dialog.component';
import { CollisionService } from './collision.service';
import { ImageGalleryDialogComponent } from '../dialogs/image-gallery-dialog/image-gallery-dialog.component';
import { DbEmployee } from '../models/DbEmployee';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { Router } from '@angular/router';
import { SourcesApiService } from './api/sources.api.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService implements OnDestroy {

  subscriptions: Subscription[] = [];
  constructor(
    private dialog: MatDialog,
    private _translate: TranslocoService,
    private _apiService: ApiService,
    private handlingService: HandlingService,
    private _dataChangeTrackingService: DataChangeTrackingService,
    private _snackbarService: SnackbarService,
    private _synchronizationService:SynchronizationService,
    private _sourcesApiService:SourcesApiService,
    private _localize:LocalizeRouterService,
    private _router:Router

  ) {

  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  loadingDialog: MatDialogRef<LoadingDialogComponent> | null = null;
  showLoaderDialog(title = null, content = null) {
    // if(typeof this.loadingDialog != "undefined") return;
    if (Utils.isDefined(this.loadingDialog, "id")) if (this.loadingDialog.getState() === MatDialogState.OPEN) return

    this.loadingDialog = this.dialog.open(LoadingDialogComponent, {
      data: {
        title: title,
        content: content
      },
      maxWidth: '100%',
      disableClose: true
    })

  }

  hideLoaderDialog() {
    if (!this.loadingDialog != null && typeof this.loadingDialog != 'undefined') this.loadingDialog.close();
  }

  confirmDeleteInvoice(invoice) {
    this.confirmDialog({
      type: 'warning',
      title: this._translate.translate('potwierdzenie_usuniecia'),
      message: this._translate.translate('czy_na_pewno_chcesz_usunac_ten_dokument'),
      cancelText: this._translate.translate('anuluj'),
      confirmText: this._translate.translate('tak')
    })
      .subscribe(
        {
          next: (data) => {
            if (data != null && data == true) {

              // delete invoice
              this._apiService.deleteInvoice(invoice.invoiceId)
              .pipe(take(1))
              .subscribe({
                next: (response) => {
                  if(response.status != 'success') {
                    this._snackbarService.showSnackBar(this._translate.translate('nie_udalo_sie_usunac_faktury'),'error')
                    return
                  }

                  this._synchronizationService.newSynchronize('Invoice deletion')
                  const path = this._localize.translateRoute('/app/invoices/list')
                  this._router.navigate([path])
                }
              })

            }
          }
        }
      )
  }
  
  confirmDeleteSource(sourceId, sourceName) {
    let toReturn = new BehaviorSubject<boolean>(null);

    this.subscriptions.push(this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this._translate.translate('usuwanie_zrodla_rezerwacji'),
        message: this._translate.translate('potwierdzasz_usuniecie_zrodla') + sourceName,
        showIcon: true,
        type: 'danger',
        confirmText: this._translate.translate('usun'),
        cancelText: this._translate.translate('anuluj'),
      },
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            if (!Utils.isDefined(data, "action")) { toReturn.next(false); return; }

            if (data.action == true) {
              if (!Utils.isNullOrEmpty(sourceId)) {
                this.subscriptions.push(
                  this._sourcesApiService.delete(sourceId)
                  .pipe(take(1))
                  .subscribe({
                    next: (data) => {
                      toReturn.next(true)
                    },
                    error: (error) => {
                      toReturn.next(false)
                    }
                  }
                  ))
              }
            }
            else if (data.action == false) {
              toReturn.next(false)
            }
          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }

  showImageGalleryDialog({images, currentImageIndex}) {
    this.dialog.open(ImageGalleryDialogComponent, {
      data: {
        images: images,
        currentImageIndex: currentImageIndex
      },
      width: '90%',
      maxWidth: '100%'
    })
    
  }

  addOrUpdateSource(source) {
    let toReturn = new BehaviorSubject<{ status: boolean, action: any }>({ status: null, action: null });

    this.subscriptions.push(this.dialog.open(CreateOrUpdateSourceDialogComponent, {
      data: {
        source: source
      },
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            if (!Utils.isDefined(data, "action")) { toReturn.next({ status: false, action: '' }); return; }

            if (data.action == true) {
              if (Utils.isDefined(data, "form.controls.sourceName.value")) {
                let sourceId = data.form.value.sourceId;
                let sourceName = data.form.value.sourceName;
                let color = data.form.value.color;
                this.subscriptions.push(this._sourcesApiService.update(sourceId, { color: color, sourceName: sourceName }).subscribe(
                  {
                    next: (data) => {
                      if (Utils.isNullOrEmpty(sourceId)) {
                        toReturn.next({ status: true, action: 'create' })
                      }
                      else {
                        toReturn.next({ status: true, action: 'update' })
                      }
                    },
                    error: (err) => {
                      toReturn.next({ status: false, action: '' })
                    }
                  }
                ))
              }
            }
            else if (data.action == false) {
              toReturn.next({ status: false, action: '' })
            }
          },
          error: (err) => {
            toReturn.next({ status: false, action: '' })
          }
        }
      ))
    return toReturn.asObservable();
  }

  confirmDialog(data: ConfirmDialogData): Observable<boolean> {
    let toReturn = new BehaviorSubject<boolean>(null);

    this.subscriptions.push(this.dialog.open(ConfirmDialogComponent, {
      data,
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            if (!Utils.isDefined(data, "action")) { toReturn.next(false); return; }

            if (data.action == true) {
              toReturn.next(true)
            }
            else if (data.action == false) {
              toReturn.next(false)
            }
          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }

  showSelectAdditionalServicesDialog(selected): Observable<any> {
    let toReturn = new BehaviorSubject<any>(null);

    this.subscriptions.push(this.dialog.open(SelectAdditionalServicesDialogComponent, {
      data: { selected: selected },
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe({
        next: (data) => {
          toReturn.next(data)
        },
        error: (err) => {
          toReturn.next({ status: false, results: null })
        }
      }
      ))

    return toReturn.asObservable();
  }

  confirmDialogWithInputs(data: ConfirmDialogData): Observable<{ status: boolean, results: any }> {
    let toReturn = new BehaviorSubject<{ status: boolean, results: {} }>({ status: null, results: {} });

    this.dialog.open(ConfirmDialogComponent, {
      data,
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        {
          next: (data) => {
            console.log(data)
            if (!Utils.isDefined(data, "action")) { toReturn.next({ status: false, results: {} }); return; }

            if (data.action == true) {

              toReturn.next({ status: true, results: data.form.value })
            }
            else if (data.action == false) {
              toReturn.next({ status: false, results: {} })
            }

          },
          error: (err) => {
            toReturn.next({ status: false, results: {} })
          }
        }
      )

    return toReturn.asObservable();
  }

  openSelectRoomDialogComponent(data: any): Observable<{ status: boolean, results: [] }> {
    let toReturn = new BehaviorSubject<{ status: boolean, results: [] }>({ status: null, results: [] });

    this.subscriptions.push(this.dialog.open(SelectRoomDialogComponent, {
      data,
      width: '512px',
      panelClass: 'mobile-calendar-responsive-dialog'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            if (!Utils.isDefined(data, "action")) { toReturn.next({ status: false, results: [] }); return; }

            if (data.action == true) {
              toReturn.next({ status: true, results: data.selected })
            }
            else if (data.action == false) {
              toReturn.next({ status: false, results: [] })
            }
          },
          error: (err) => {
            toReturn.next({ status: false, results: [] })
          }
        }
      ))

    return toReturn.asObservable();
  }

  openSelectInvoiceDialogComponent(data: any): Observable<{ status: boolean, results: any[] }> {
    let toReturn = new BehaviorSubject<{ status: boolean, results: [] }>({ status: null, results: [] });

    this.subscriptions.push(this.dialog.open(SelectInvoiceDialogComponent, {
      data,
      width: '512px',
      panelClass: 'mobile-calendar-responsive-dialog'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            if (!Utils.isDefined(data, "action")) { toReturn.next({ status: false, results: [] }); return; }

            if (data.action == true) {
              toReturn.next({ status: true, results: data.selected })
            }
            else if (data.action == false) {
              toReturn.next({ status: false, results: [] })
            }
          },
          error: (err) => {
            toReturn.next({ status: false, results: [] })
          }
        }
      ))

    return toReturn.asObservable();
  }


  openSelectDatesDialogComponent(): Observable<{ status: boolean, results: any }> {
    let toReturn = new BehaviorSubject<{ status: boolean, results: any }>({ status: null, results: null });

    this.subscriptions.push(this.dialog.open(SelectDatesDialogComponent, {
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            if (!Utils.isDefined(data, "action")) { toReturn.next({ status: false, results: null }); return; }

            if (data.action == true) {
              toReturn.next({ status: true, results: data.selected })
            }
            else if (data.action == false) {
              toReturn.next({ status: false, results: null })
            }
          },
          error: (err) => {
            toReturn.next({ status: false, results: null })
          }
        }
      ))
    return toReturn.asObservable();
  }
  openConfigureGroupPricesDialog(): Observable<{ status: boolean, results: any }> {
    let toReturn = new BehaviorSubject<{ status: boolean, results: any }>({ status: null, results: null });

    this.subscriptions.push(this.dialog.open(ConfigureGroupPricesComponent, {
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            // if(!Utils.isDefined(data, "action")) { toReturn.next({status:false, results:null}); return;}

            // if(data.action == true)
            // {
            //   toReturn.next({status:true, results:data.selected})
            // }
            // else if(data.action == false)
            // {
            //   toReturn.next({status:false, results:null})
            // }
          },
          error: (err) => {
            // toReturn.next({status:false, results:null})
          }
        }
      ))
    return toReturn.asObservable();
  }

  confirmDeletingReservation(reservation: DbReservation): Observable<boolean> {

    let toReturn = new BehaviorSubject<boolean>(null);

    let inputs = [];
    if (reservation.groupId != 0) {
      inputs = [
        { name: 'deleteGroup', type: 'checkbox', value: false, description: this._translate.translate('usun_cala_grupe') }
      ]
    }

    this.subscriptions.push(this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: 'danger',
        title: this._translate.translate('uwaga'),
        inputs: inputs,
        message: this._translate.translate('czy_napewno_chcesz_usunac_rezerwacje'),
        confirmText: this._translate.translate('tak'),
        cancelText: this._translate.translate('anuluj')

      },
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {
            if (!Utils.isDefined(data, "action")) { return; }

            if (data.action == true) {
              let deleteGroup = false;
              if (Utils.isDefined(data, "form.controls.deleteGroup.value")) {
                deleteGroup = data.form.controls.deleteGroup.value
              }
              this.subscriptions.push(this._apiService.deleteReservation(reservation.reservationId, deleteGroup)
                .pipe(take(1))
                .subscribe({
                  next: (data) => {
                    if (data != null) {
                      this.handlingService.handleResponse(data);
                      this._dataChangeTrackingService.addInternalChange([reservation.reservationId])

                      if (data.status == 'success') {
                        toReturn.next(true)
                      }
                      else if (data.status == 'error') {
                        toReturn.next(false)
                      }
                    }
                  }
                }
                ))
            }
          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }

  confirmDeleteClient(client: DbClient): Observable<boolean> {

    if (!Utils.isDefined(client, "clientId")) return

    let toReturn = new BehaviorSubject<boolean>(null);

    this.subscriptions.push(this.dialog.open(ConfirmDialogComponent, {
      data: {
        confirmText: this._translate.translate('usun'),
        message: this._translate.translate('czy_na_pewno_chcesz_usunac_klienta_i_jego_wszystkie_rezerwacje'),
        title: this._translate.translate('uwaga'),
        type: 'danger',
        cancelText: this._translate.translate('anuluj'),
        showIcon: true
      },
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {
            if (!Utils.isDefined(data, "action")) { return; }

            if (data.action == true) {

              this.subscriptions.push(this._apiService.deleteClient(client.clientId)
                .pipe(take(1))
                .subscribe({
                  next: (response) => {
                    if (response != null) {
                      if (response.status == 'success') {
                        this._snackbarService.showSnackBar(this._translate.translate('klient_zostal_usuniety'), 'success');
                        this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
                        this._synchronizationService.synchronizeWithPendingCheck("Removed room")
                        toReturn.next(true)
                      }
                      else if (response.status == 'error') {
                        toReturn.next(false)
                      }
                    }
                  }
                }
                ))
            }
          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }
  confirmDeleteEmployee(employee: DbEmployee): Observable<boolean> {

    if (!Utils.isDefined(employee, "employeeId")) return

    let toReturn = new BehaviorSubject<boolean>(null);

    this.subscriptions.push(this.dialog.open(ConfirmDialogComponent, {
      data: {
        confirmText: this._translate.translate('usun'),
        message: `${this._translate.translate('czy_na_pewno_chcesz_tego_pracownika')} ${employee.forename} ${employee.name} (${employee.email})`,
        title: this._translate.translate('uwaga'),
        type: 'danger',
        cancelText: this._translate.translate('anuluj'),
        showIcon: true
      },
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {
            if (!Utils.isDefined(data, "action")) { return; }

            if (data.action == true) {

              this.subscriptions.push(this._apiService.deleteEmployee(employee.employeeId)
                .pipe(take(1))
                .subscribe({
                  next: (response) => {
                    if (response != null) {
                      if (response.status == 'success') {
                        this._snackbarService.showSnackBar(this._translate.translate('pracownik_zostal_usuniety'), 'success');
                        this._synchronizationService.synchronizeWithPendingCheck("Removed room")
                        toReturn.next(true)
                      }
                      else if (response.status == 'error') {
                        toReturn.next(false)
                      }
                    }
                  }
                }
                ))
            }
          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }

  showAddNoteDialog(note) {
    this.dialog.open(AddNoteDialogComponent, {
      data: {
        note: note
      },
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe()
  }
  showRestoreReservationConfirmation(reservation: DbReservation): Observable<boolean> {

    let toReturn = new BehaviorSubject<boolean>(null);

    let inputs = [];
    if (reservation.groupId != 0) {
      inputs = [
        { name: 'restoreGroup', type: 'checkbox', value: false, description: this._translate.translate('przywrocic_cala_grupe') }
      ]
    }

    this.subscriptions.push(this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: 'danger',
        title: this._translate.translate('uwaga'),
        inputs: inputs,
        message: this._translate.translate('czy_na_pewno_chcesz_przywrocic_rezerwacje'),
        confirmText: this._translate.translate('przywroc_rezerwacje'),
        cancelText: this._translate.translate('anuluj')

      },
      width: '512px',
      maxWidth: '100%'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {
            if (!Utils.isDefined(data, "action")) { return; }
            if (data.action != true) return

            let restoreGroup = false;
            if (Utils.isDefined(data, "form.controls.restoreGroup.value")) {
              restoreGroup = data.form.controls.restoreGroup.value
            }

            this._apiService.restoreRerservation({
              reservationId: reservation.reservationId,
              group: restoreGroup
            })
              .pipe(take(1))
              .subscribe({
                next: (data) => {
                  if (data != null) {
                    if (data.status == 'success') {
                      this._dataChangeTrackingService.addInternalChange([reservation.reservationId])
                      toReturn.next(true)
                    }
                    else if (data.status == 'error') {
                      toReturn.next(false)
                    }
                  }
                }
              })

          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }

  clearSubscriptions() {
    // Print subs to delete
    // console.log(this.subscriptions.length);
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }
}

