
<div class="flex flex-auto">
    <div class="mr-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-700 ng-star-inserted">
        <mat-icon [svgIcon]="'heroicons_solid:shopping-cart'"></mat-icon>
    </div>
    <div class="flex flex-col flex-auto">
        <div class="font-semibold line-clamp-2 pr-2">
            {{'subskrypcja' | transloco}} <i>{{notification?.body?.subscriptionName}}</i>
        </div>
        <div>
            <span [innerHTML]="'twoje_konto_zostalo_aktywowane' | transloco : {subscriptionPeriod: notification?.body?.period + (' dni' | transloco)}"></span>
        </div>
        <div class="mt-2 text-sm leading-none text-secondary">
            <date-formatter [date]="notification.addDate" [showTime]="true"></date-formatter>
        </div>
    </div>
</div>