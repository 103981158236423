import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ApplicationData } from '../../data/application-data';
import { Utils } from '../../others/utils';

@Component({
  selector: 'invoice-type-to-name',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './invoice-type-to-name.component.html',
  styleUrl: './invoice-type-to-name.component.scss'
})
export class InvoiceTypeToNameComponent implements OnChanges {
  
  @Input('invoice') invoice;
  translation = ''

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof changes.invoice.currentValue == 'undefined') return

    if(Utils.isNullOrEmpty(this.invoice)) return
    if(this.invoice.prepayments == 0) {
      const type = ApplicationData.InvoiceTypes.find(x=>x.id == this.invoice.invoiceType)
      if(type) this.translation = type.name
      return
    }
   
    this.translation = "faktura_koncowa"
  }
}
