import { DbAdditionalService } from "../models/DbAdditionalService";
import { DbClient } from "../models/DbClient";
import { Utils } from "../others/utils";

export const clientFilter = ({client, value}: {client:  DbClient, value:string}) => {
    let forename = '';
    let name = '';
    let imieNazwisko = '';
    let nazwiskoImie = '';

    if (client == null || typeof client == 'undefined') return false;
    if (typeof client['forename'] != 'undefined' && client['forename'] != null) { forename = client.forenameToLowerCase }
    if (typeof client['name'] != 'undefined' && client['name'] != null) { name = client.nameToLowerCase }

    if (
      forename.length > 0 && name.length > 0
    ) {
      imieNazwisko = `${forename} ${name}`
      nazwiskoImie = `${name} ${forename}`
    }

    if (forename.includes(value)) { return true }
    else if (name.includes(value)) { return true }
    else if (imieNazwisko.includes(value)) { return true }
    else if (nazwiskoImie.includes(value)) { return true }
    else if (Utils.isDefined(client, "email") && client.email.includes(value)) return true
    else if (Utils.isDefined(client, "phone") && client.phone.includes(value)) return true
    else if (Utils.isDefined(client,"vehicleRegistrationNumber") && client.vehicleRegistrationNumber?.toLowerCase().includes(value)) return true

    return false;
}

