import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Collision } from '../models/Collision';
import { RoomsService } from './rooms.service';
import { DbReservation } from '../models/DbReservation';

@Injectable({
  providedIn: 'root'
})
export class CollisionService {

  collision = new BehaviorSubject<Collision>(new Collision());
  currentCollision = this.collision.asObservable();

  constructor(
    private _roomsService:RoomsService
    ) {

  }


  detectCollisions() {

    console.log("[CollisionService]: Detecting possible collisions")
    const t1 = new Date().getTime()
    let collision = new Collision();
    
    const rooms = this._roomsService.getRooms()

    Object.values(rooms).forEach(room => {
      if(typeof room == 'undefined') return
      const filtered = room.reservations
  
      filtered.forEach((res: DbReservation) => {

        if(res.hasPermission == false) return
        let arrival = new Date(res.arrival);
        let departure = new Date(res.departure);
  
        let toCheck = room.reservations
          .filter(
            r =>
              r.reservationId != res.reservationId &&
              new Date(r.departure) > arrival &&
              new Date(r.arrival) < departure
          );
  
        toCheck.forEach(collidingReservation => {
          collision.addCollision(room, res, collidingReservation);
        });
  
      })
    })
    
    const t2 = new Date().getTime();
    console.log(`[CollisionService]: Detection finished in: ${t2 - t1}ms`)

    this.collision.next(collision);
  }
  
  remove(reservationId) {
    const collision = this.collision.value;
    let counter = 0;
    collision.collisions.forEach(
      (roomColision) => {
        roomColision.reservations.forEach(
          (reservationCollision, index) => {
            if (reservationCollision.collidingReservation.reservationId === reservationId ||
              reservationCollision.reservation.reservationId === reservationId) {
              roomColision.reservations.splice(index, 1);
            }
            else { counter = counter + 1 }
          }
        )
      }
    )

    if (counter == 0) {
      this.collision.next(new Collision())
    }
  }

  // Clean collisions (for example, chaning account, app init)
  clean() {
    this.collision.next(new Collision())
  }

}
