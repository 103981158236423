import { Injectable, OnDestroy } from "@angular/core";
import { DbReservation } from "../models/DbReservation";
import { Utils } from "../others/utils";
import { DbRoom } from "../models/DbRoom";
import { DbRoomsObject, RoomsService } from "./rooms.service";
import { Subscription } from "rxjs";
import { PaymentStatuses } from "../models/payment-statuses.enum";
import { UserSettings } from "../models/UserSettings";
import { SettingsService } from "./settings.service";
import { DateTime } from "luxon";

@Injectable({
    providedIn: 'root'
})
export class DayStatisticsService implements OnDestroy {

    private _subscriptions:Subscription[] = [];
    private _userSettings: UserSettings
    data: DayStatistics = new DayStatistics()
    private _selectedReservationType = 'reservations';
    private _rooms:DbRoomsObject = {}
    constructor(
        private _roomsService:RoomsService,
        private _settingsService:SettingsService
        ) {
        this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
            next: (userSettings:UserSettings) => {
                this._userSettings = userSettings
            }
        }))
        this._subscriptions.push(this._roomsService.currentRooms.subscribe({
            next: (data)=> this._rooms = data
        }))
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }

    getDayStatistics(date: DateTime):DayStatistics {

        // Reset
        this.data = new DayStatistics();

        // this.data.isToday = Utils.isToday(date);
        // const today = Utils.clearUTC(new Date().getTime()).slice(0, 10);
        // const checkingDate = Utils.clearUTC(date.getTime()).slice(0, 10);
        this.data.isToday = date.toISODate() == DateTime.now().toISODate() ? true : false;
        const today = DateTime.utc().toISODate()
        const checkingDate = date.toISODate()

        this._clearReservationLists();
        this._initializeCounters();
        this._initializeStats();
    
        this._processRooms(today, checkingDate);
    
        this.data.roomTasks.sort((a, b) => b.serviceInfo.length - a.serviceInfo.length);
        
        this._countStats()
        return this.data
    }
    
    private _clearReservationLists() {
        this.data.arrivalsToday = [];
        this.data.departuresToday = [];
        this.data.arrivals = [];
        this.data.doneArrivals = [];
        this.data.departures = [];
        this.data.doneDepartures = [];
        this.data.inHotel = [];
        this.data.inHotelAdults = 0;
        this.data.inHotelKids = 0;
        this.data.noPrepayment = [];
        this.data.arrivalDeparturesAlerts = 0
        this.data.cleaningAlerts = 0
    }
    
    private _initializeCounters() {
        this.data.counters = {
            noPayment: 0,
            waitingForPrepayment: 0,
            prepaymentDeadlinePassed: 0,
            paidPrePayment: 0,
            paidAll: 0
        };
    }
    
    private _initializeStats() {
        this.data.personOccupancyMax = 0;
        this.data.roomOccupancyMax = 0;
    }
    
    private _processRooms(today: string, checkingDate: string) {
        let processedGroups = [];
        Object.entries(this._rooms).filter((x: [string, DbRoom]) => x[1].status == 1).forEach((room: [string, DbRoom]) => {
            this._processIndividualRoom(room, today, checkingDate, processedGroups);
        });
    }
    
    private _processIndividualRoom(room: [string, DbRoom], today: string, checkingDate: string, processedGroups: number[]) {
        if (room[1].status != 0) {
            this._updateStatsAndTasks(room);
        }
    
        if (typeof room[1][this._selectedReservationType] == 'undefined') return;
    
        this._checkForBlockades(room);
    
        this._processReservations(room[1][this._selectedReservationType], today, checkingDate, processedGroups);

        if(room[1].serviceInfo.length > 0) this.data.cleaningAlerts += 1
        else if(room[1].service > 0) this.data.cleaningAlerts += 1
    }
    
    private _updateStatsAndTasks(room: [string, DbRoom]) {
        if (Utils.isNullOrEmpty(room[1].persons)) room[1].persons = 0;
        this.data.personOccupancyMax += room[1].persons * 1;
        this.data.roomOccupancyMax++;
        if (room[1].service == 1 || room[1].service == 2) {
            this.data.roomTasks.push(room[1]);
        }
    }
    
    private _checkForBlockades(room: [string, DbRoom]) {
        room[1][this._selectedReservationType].forEach((res) => {
            if (res.clientId == null || res.clientId == 'null') return;
        });
    }
    
    private _processReservations(reservations: DbReservation[], today: string, checkingDate: string, processedGroups: number[]) {
        
        // If doesnt wants to have locks as reservations
        if(this._userSettings.settings.reservationLockAsReservationTurnOn == 0)
        {
            reservations = reservations.filter((reservation:DbReservation) => reservation.clientId > 0)
        }
        
        const isToday = DateTime.now().toISODate() == today

        reservations.forEach((res) => {
            this._checkArrivalsAndDepartures(res, today, checkingDate);
            this._checkInHotel(res, isToday, today, checkingDate, processedGroups);
            this._checkPrepaymentStatus(res);
            
        });

        this.data.arrivals.sort((a,b)=> a.registration - b.registration)
        this.data.departures.sort((a,b)=> a.registration - b.registration)

    }
    
    private _checkArrivalsAndDepartures(res: DbReservation, today: string, checkingDate: string) {
       
        if (res.arrival == today) {
            this.data.arrivalsToday.push(res);
            if (res.registration == 1) {
                this.data.doneArrivals.push(res);
            }
        }

        if (res.departure == today) {
            this.data.departuresToday.push(res);
            if (res.registration == 2) {
                this.data.doneDepartures.push(res);
            }
        }

        // Arrays
        if (res.arrival == checkingDate) {
            this.data.arrivals.push(res);
        }

        if (res.departure == checkingDate) {
            this.data.departures.push(res);
        }

        // Counters
        // Counters are more advanced informations
        if (res.arrival == today) {
            if(res.registration != 1) this.data.arrivalDeparturesAlerts += 1
        }

        if (res.departure == today) {
            if(res.registration != 2) this.data.arrivalDeparturesAlerts += 1
        }
    }

    
    private _checkInHotel(res: any, isToday:boolean, today: string, checkingDate: string, processedGroups: number[]) {
        if ((res.arrival < checkingDate && res.departure > checkingDate && res.status == 1)) {
            this._addToInHotel(res, processedGroups);
        }
    }
    
    private _addToInHotel(res: any, processedGroups: number[]) {
        this.data.inHotel.push(res);
        if (res.groupId > 0 && !processedGroups.includes(res.groupId) || !(res.groupId > 0)) {
            if (parseInt(res.adults) != Number.NaN) {
                this.data.inHotelAdults += parseInt(res.adults);
            }
            if (parseInt(res.children) != Number.NaN) {
                this.data.inHotelKids += parseInt(res.children);
            }
            processedGroups.push(res.groupId);
        }
    }
    
    private _checkPrepaymentStatus(res: any) {
        const paymentStatus = Utils.getPaymentStatus(new Date(), res.paymentStatus, res.prepaymentDeadline);
        switch (paymentStatus) {
            case PaymentStatuses.brak_wplaty: this.data.counters.noPayment++; break;
            case PaymentStatuses.oczekiwanie_na_zaliczke: this.data.counters.waitingForPrepayment++; break;
            case PaymentStatuses.uplynal_termin_wplaty_zaliczki: this.data.counters.prepaymentDeadlinePassed++; break;
            case PaymentStatuses.wplacony_zadatek: this.data.counters.paidPrePayment++; break;
            case PaymentStatuses.wplacona_calosc: this.data.counters.paidAll++; break;
            default: break;
        }

        if(DateTime.fromISO(res.departure) < DateTime.utc()) return

        if(paymentStatus == PaymentStatuses.uplynal_termin_wplaty_zaliczki) {
            this.data.noPrepayment.push(res)
            this.data.arrivalDeparturesAlerts += 1
        }
         
        if(paymentStatus == PaymentStatuses.oczekiwanie_na_zaliczke) {
            this.data.waitingForPrepayment.push(res)
        } 

    }

    private _countStats() {
        this.data.personOccupancyNow = this.data.inHotelAdults + this.data.inHotelKids;

        if (this.data.personOccupancyMax > 0) {
            this.data.personOccupancyInPercent = (this.data.personOccupancyNow / this.data.personOccupancyMax) * 100;
        }

        this.data.roomOccupancyNow = this.data.inHotel.length;
        if (this.data.roomOccupancyMax > 0) {
            this.data.roomOccupancyInPercent = (this.data.roomOccupancyNow / this.data.roomOccupancyMax) * 100;
        }
    }
}


export interface Counters {
    noPayment: number;
    waitingForPrepayment: number;
    prepaymentDeadlinePassed: number;
    paidPrePayment: number;
    paidAll: number;
}

export interface Stats {
    personOccupancyMax: number;
    roomOccupancyMax: number;
    personOccupancyNow: number;
    personOccupancyInPercent: number;
    roomOccupancyNow: number;
    roomOccupancyInPercent: number;
}

export class DayStatistics {
    isToday: boolean = null
    roomTasks = []
    personOccupancyNow: number = 0
    personOccupancyMax: number = 0

    inHotelAdults: number = 0
    inHotelKids: number = 0
    roomOccupancyNow: number = 0
    roomOccupancyMax: number = 0

    personOccupancyInPercent = 0
    roomOccupancyInPercent: number = 0

    arrivalsToday: DbReservation[] = [];
    departuresToday: DbReservation[] = [];
    arrivals: DbReservation[] = [];
    doneArrivals: DbReservation[] = [];
    departures: DbReservation[] = [];
    doneDepartures: DbReservation[] = [];
    inHotel: DbReservation[] = [];

    noPrepayment: DbReservation[] = [];
    waitingForPrepayment: DbReservation[] = [];

    counters: Counters = {
        noPayment: 0,
        waitingForPrepayment: 0,
        prepaymentDeadlinePassed: 0,
        paidPrePayment: 0,
        paidAll: 0
    };

    arrivalDeparturesAlerts = 0
    cleaningAlerts = 0
    constructor() {
        this.roomTasks = []
    }
}