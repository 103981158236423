import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { Subscription, take } from 'rxjs';
import { Utils } from '../../others/utils';
import { ApiService } from '../../services/api.service';
import { SettingsService } from '../../services/settings.service';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgForOf } from '@angular/common';
import { ApplicationData } from '../../data/application-data';
import { MatRippleModule } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { UserSettings } from '../../models/UserSettings';

@Component({
  standalone: true,
  selector: 'app-show-on-form',
  templateUrl: './show-on-form.component.html',
  styleUrls: ['./show-on-form.component.scss'],
  imports: [TranslocoModule, MatDialogModule, MatListModule, MatButtonModule, MatIconModule, MatCheckboxModule, MatFormFieldModule, NgForOf, MatRippleModule]
})
export class ShowOnFormComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = []
  fields = ApplicationData.ReservationFields
  fieldsControls = {}
  userFields = []

  constructor(
    private _settingsService: SettingsService,
    private _apiService: ApiService
  ) 
  {

  }

  ngOnInit(): void {
     // Create labels controls
     this.fields.forEach(label => {
      this.fieldsControls[label.id] = new FormControl()
    })
    
    // Subscribe to changes in settings
    this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
      next: (data: UserSettings) => {
        // Labels
        this.userFields = data.settings.reservationField
        this.fields.forEach(label => {
          this.fieldsControls[label.id].setValue(this.userFields.includes(label.id), { emitEvent:false })
        })
      }
    }))

    // Handle changes in fast view configuration
    this.fields.forEach(label=> {
      this.fieldsControls[label.id].valueChanges.subscribe({
        next: (value) => {
          const selected = this.getCheckedKeys(this.fieldsControls)
          this._apiService.updateUserSettings("reservationField", selected).pipe(take(1)).subscribe({
            next: (data) => {
              this._settingsService.setSetting('reservationField',selected)
            }
          })
        }
      })
    })
  }

  getCheckedKeys(controls): string[] {
    const checkedKeys: string[] = [];
    Object.keys(controls).forEach(key => {
      const control = controls[key];
      if (control.value) { // Assuming value is boolean indicating whether the control is checked
        checkedKeys.push(key);
      }
    });
    return checkedKeys;
  }

  ngOnDestroy(): void {
    this.userFields = []
    this.fieldsControls = {}
    this._subscriptions.forEach(sub=>sub.unsubscribe())

  }

  toggleField(key) {
    const value:boolean = this.fieldsControls[key].value
    const index = this.userFields.findIndex(x=>x == key)
    if(index == -1) this.userFields.push(key)
    else this.userFields.splice(index,1)
    this.fieldsControls[key].setValue(!value)
  }
}
