import { Component, ComponentRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentPortal } from '@angular/cdk/portal';
import { PanelService } from 'app/core/shared/services/panel.service';
import { CollisionService } from 'app/core/shared/services/collision.service';
import { Subscription } from 'rxjs';
import { Collision } from 'app/core/shared/models/Collision';
import { ToastComponent } from 'app/core/shared/components/toast/toast.component';
import { ReservationsService } from 'app/core/shared/services/reservations.service';
import { ToastService } from 'app/core/shared/services/toast.service';
import { RoomsService } from 'app/core/shared/services/rooms.service';
import { debounce } from 'lodash';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CollisionsDialogComponent } from 'app/core/shared/components/collisions/collisions.component';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'collisions',
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatButtonModule, MatTooltipModule, MatIconModule],
  templateUrl: './collisions.component.html',
  styleUrl: './collisions.component.scss',
  animations: fuseAnimations
})
export class CollisionsComponent implements OnInit{

  collision = new Collision()
  private _subscriptions:Subscription[] = []
  mode: string;

  state: boolean = true;
  clicked: boolean = false;

  onEnd(event){
    this.state = false;
    if(this.clicked == false){
      setTimeout(() => {
        this.state = true;
      }, 1000);
    }
    
  }

  constructor(
    private _panelService: PanelService,
    private _collisionService:CollisionService,
    private _toastService: ToastService,
    private _reservationsService: ReservationsService,
    private _roomsService: RoomsService,
    private _translocoService: TranslocoService
    ) {

    this._showCollisionNotification = debounce(this._showCollisionNotification, 200)

    this._subscriptions.push(this._collisionService.currentCollision.subscribe(
      {
        next: (data) => {
          this.collision = data;
          if (this.collision.hasCollision) {
            this._showCollisionNotification(this.collision)
          }
        }
      }
    ))

  }
  
  ngOnInit(): void {
    
    //  // Fake collision testing purposes only
    //  const collision = new Collision()
    //  const rId1 = 350161
    //  const rId2 = 350124
 
    //  const reservation = this._reservationsService.getById(rId1)
    //  const reservation2 = this._reservationsService.getById(rId2)
    //  const room = this._roomsService.getById(reservation?.roomId)
    //  if (reservation && reservation2 && room) {
    //    collision.addCollision(room, reservation, reservation2);
    //    this._collisionService.collision.next(collision);
    //  } 
     
  }
  

  shown = false
  private _showCollisionNotification(collision) {
   

    if (this.shown) return
    //if (this.mode != 'calendar') return


    this.shown = true
    const ref: ComponentRef<ToastComponent> = this._toastService.showToast({
      icon: "exclamation-triangle",
      status: "danger",
      title: this._translocoService.translate('kolizje_rezerwacji'),
      message: this._translocoService.translate('wykryto_kolizje_rezerwacji'),
      added: new Date(),
      buttons: [
        { text: 'Sprawdź', action: 'showCollisions' }
      ],
      timeToClose: 0,
    })

    ref.instance.on('showCollisions').subscribe({
      next: (data) => {
        if (data == true) {
          this.showDetectedCollisions()
          ref.instance.close()
        }
      }
    })

  }

  public showDetectedCollisions() {
    this.clicked = true;
    if (this._panelService.panel.opened) { this._panelService.clearPanelPortal(); }
    this._panelService.open(new ComponentPortal<CollisionsDialogComponent>(
      CollisionsDialogComponent,
      null,
      null
    ))
  }

}
