import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DbRoom } from "../models/DbRoom";
import { AppDB } from "../models/db";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { DbReservation } from "../models/DbReservation";
import { Utils } from "../others/utils";
import { ApplicationData } from "../data/application-data";
import { LightStyles } from "../data/light-styles";
import { DarkStyles } from "../data/dark-styles";

@Injectable({
    providedIn: 'root'
})
export class RoomsService implements OnDestroy {

    private _subscriptions: Subscription[] = [];
    private _rooms = new BehaviorSubject<DbRoomsObject>({})
    public currentRooms: Observable<DbRoomsObject> = this._rooms.asObservable()

    private _db: AppDB
    private _counter = new BehaviorSubject<DbRoomCounter>({
        valid: 0,
        deleted: 0
    })

    // Room types and sizes based on all rooms in database
    private _allRoomTypes = ApplicationData.RoomTypes
    private _availableRoomSizes = new Set()
    private _availableRoomTypes = new Set()
    private _roomSizes = new BehaviorSubject<any>([])
    private _roomTypes = new BehaviorSubject<any>([])

    public currentRoomSizes = this._roomSizes.asObservable()
    public currentRoomTypes = this._roomTypes.asObservable()

    constructor(
        private _dbService: DbService,
        private _dataSynchronizerService: DataSynchronizerService
    ) {
        this._subscriptions.push(
            this._dbService.getDatabase().subscribe({
                next: (data) => {
                    this._db = data
                    this._rooms.next({})

                }
            })
        )

        this._subscriptions.push(this.currentRooms.subscribe({
            next: (data) => {
                this._countAll(data)
            }
        }))

        // Subscribe to updates on tables
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {
                    if (!data) return

                    if (data.table == 'rooms') {
                        // Add or update
                        // Update subject
                        this._processRooms(data.value)
                        this._dataSynchronizerService.received()

                        // Notify observers
                        this._rooms.next(this._rooms.value)
                    }

                    // Refresh reservations in rooms
                    // ReservationChanges array passed
                    else if (data.table == 'roomsReservations') {
                        this._processRoomReservations(data.value)
                        this._dataSynchronizerService.received()
                        // Notify observers
                        this._rooms.next(this._rooms.value)
                    }

                }
            }))
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

    getRooms(): DbRoomsObject {
        return this._rooms.value
    }

    getAll(): Observable<boolean> {

        this._rooms.next({})
        if(!this._db) return of(true)
        
        let startTime = 0
        const loader = from(this._db.transaction("r", this._db.rooms, () => {
            startTime = performance.now();
            return this._db.rooms.toArray();
        }))
            .pipe(
                tap(() => console.log(`[Rooms Service]: Built in ${performance.now() - startTime}ms.`)),
                map((rooms: DbRoom[]) => {
                    this._processRooms(rooms)
                    this._rooms.next(this._rooms.value)
                    return true;
                })
            );

        return loader
        //   return loader.pipe(
        //     tap(() => console.log(`[Rooms Service]: Observable completed in ${new Date().getTime() - t}ms.`)),
        //   );   

    }

    getById(roomId): DbRoom {
        roomId = `"${roomId}"`
        const room = this._rooms.value[roomId]
        if (room) return room
        else console.warn(`Room with id: ${roomId} not found.`)
    }


    public getCounter(): Observable<DbRoomCounter> {
        return this._counter.asObservable()
    }
    
    lastSort = null
    browseRooms({ defaultPageSize, sort = { active: 'order', direction: 'asc' }, filters = [], allResults = [] }): PaginatedDbRooms {
        let t1 = new Date().getTime();

        let sortFn = (a: DbRoom, b: DbRoom) => a.order - b.order

        if (sort.active == 'order') {
            if (sort.direction == 'desc') sortFn = (a: DbRoom, b: DbRoom) => b.order - a.order
            if (sort.direction == 'asc') sortFn = (a: DbRoom, b: DbRoom) => a.order - b.order
        }

        // Take from temporary or from already filtered
        let searchingResults = allResults.length > 0 ? allResults : Object.values(this._rooms.value)

        // if (this.lastSort != sort)
        searchingResults = searchingResults.sort(sortFn)
        this.lastSort = sort

        if (filters.length > 0) {
            filters.forEach(filter => {
                searchingResults = searchingResults.filter(filter)
            })
        }

        const paginationResult: PaginatedDbRooms = {
            time: new Date().getTime() - t1,
            allPages: Math.ceil(searchingResults.length / defaultPageSize),
            resultsCount: searchingResults.length,
            allResults: searchingResults
        }

        return paginationResult

    }

  

    private _processRooms(rooms: DbRoom[]) {

       
        const availableRoomSizes = this._availableRoomSizes
        const availableRoomTypes = this._availableRoomTypes

        rooms.forEach((room: DbRoom) => {
            const id = `"${room.roomId}"`

            if (!Utils.isNullOrEmpty(room.persons)) {
                availableRoomSizes.add(room.persons)
            }

            if (!Utils.isNullOrEmpty(room.roomType)) {
                availableRoomTypes.add(room.roomType)
            }

            // Update room text color dynamically
            if(!Utils.isNullOrEmpty(room.color)) room.textColor = Utils.setTextColorBasedOnBackground(room.color  ||  DarkStyles.DefaultReservationRectColor)
            else room.textColor = 'black'
            
            // Creating new room
            if (typeof this._rooms.value[id] == 'undefined') {
                room.reservations = []
                room.canceledReservations = []
                this._rooms.value[id] = room;
            }
            // updating existing
            else {
                // Get room reservations before you set new room, to do not loose previous data
                const reservations = this._rooms.value[id].reservations;
                const canceledReservations = this._rooms.value[id].canceledReservations;

                this._rooms.value[id] = room;
                this._rooms.value[id].reservations = reservations;
                this._rooms.value[id].canceledReservations = canceledReservations;
            }
        })

        this._roomSizes.next(Array.from(availableRoomSizes).sort((a: number, b: number) => a - b))
        this._roomTypes.next(this._allRoomTypes.filter(x => availableRoomTypes.has(x.id)))
    }

    private _processRoomReservations(reservationChanges: DbReservationChange[]) {

        const tables = ['reservations', 'canceledReservations']

        reservationChanges.forEach((change: DbReservationChange) => {
            // If reservation was already in database
            // Detect if reservation room is not changed
            // if (reservationChanges.length == 1) { console.log("Zmiana: ", change) }
            if (change.previous != null) {
                if (change.previous.roomId != change.current.roomId) {
                    // Detect in multiple tables
                    tables.forEach((table: string) => {
                        if (typeof this._rooms.value[`"${change.previous.roomId}"`][table] != 'undefined') {
                            this._rooms.value[`"${change.previous.roomId}"`][table].forEach((elRes: any, index) => {
                                if (elRes.reservationId == change.current.reservationId) {
                                    this._rooms.value[`"${change.previous.roomId}"`][table].splice(index, 1)
                                }
                            })
                        }
                    })
                }
            }

            // Update reservation in room
            const reservation = change.current
            const roomId = `"${change.current.roomId}"`


            if (typeof this._rooms.value[roomId] != 'undefined') {
                tables.forEach((table: string) => {
                    const index = this._rooms.value[roomId][table].findIndex(res => res.reservationId === reservation.reservationId);

                    // Not found in table
                    if (index == -1) {
                        // If reservation is disabled and there is not reservation in canceled table add it
                        if (reservation.status == 0 && table == 'canceledReservation') this._rooms.value[roomId][table].push(reservation)
                        // Handle restore
                        if (reservation.status == 1 && table == 'reservations') this._rooms.value[roomId][table].push(reservation)
                    }
                    // Found in table
                    else {
                        // Just update
                        if (reservation.status == 1 && table == 'reservations') this._rooms.value[roomId][table][index] = reservation;
                        // Handle remove
                        if (reservation.status == 0 && table == 'reservations') this._rooms.value[roomId][table].splice(index, 1)
                    }
                })
            }

            // Collision detection
        })
    }
    private _countAll(data) {
        let valid = 0
        let deleted = 0
        Object.values(data).forEach((room: DbRoom) => {
            if (room.status == 1) valid++
            else deleted++
        })

        this._counter.next({ valid, deleted })
    }
}
export interface DbRoomsObject {
    [key: number]: DbRoom
}

export interface PaginatedDbRooms {
    allResults: DbRoom[]
    resultsCount: number
    time: number,
    allPages: number
}

export interface DbReservationChange {
    previous: DbReservation | null
    current: DbReservation
}
export interface DbRoomCounter {
    valid: number,
    deleted: number
}