
    <!-- Upgrade -->
    <div *ngIf="subscriptionState.isTestMode == true && subscriptionState.expiredInDays > 7" class="relative flex flex-0 items-center justify-start w-full p-2 z-49 border-t dark:border-gray-800 bg-card dark:bg-transparent print:hidden">
        <fuse-alert
            class="w-full"
            [appearance]="'outline'"
            [dismissible]="true"
            [type]="'primary'">
            <mat-icon fuseAlertIcon [svgIcon]="'heroicons_outline:information-circle'"></mat-icon>
            <span [innerHTML]="('jestes_w_okresie_testowym_masz_dostep_do_pelnej_funkcjonalnosci_aplikacji' | transloco: {premiumDays: subscriptionState.expiredInDays})"></span>
            <button mat-flat-button color="primary" class="h-6 min-h-6 ml-2 px-2 text-sm" [routerLink]="'/app/subscription/pricing' | localize">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:shopping-cart'"></mat-icon>
                {{'kup_dostep' | transloco}}
            </button>
        </fuse-alert>
    </div>

    <div *ngIf="subscriptionState.expiredInDays > 0 && subscriptionState.expiredInDays <= 7" class="relative flex flex-0 items-center justify-start w-full p-2 z-49 border-t dark:border-gray-800 bg-card dark:bg-transparent print:hidden">
        <fuse-alert
            class="w-full"
            [appearance]="'outline'"
            [dismissible]="false"
            [type]="subscriptionState.expiredInDays <= 3 ? 'warn' : 'warning'">
            <mat-icon fuseAlertIcon [svgIcon]="'heroicons_outline:clock'"></mat-icon>
            <span [innerHTML]="('pozostalo_ci_x_dni_subskrybcji' | transloco: {premiumDays: subscriptionState.expiredInDays})"></span>
            <button mat-flat-button color="primary" class="h-6 min-h-6 ml-2 px-2 text-sm" [routerLink]="'/app/subscription/pricing' | localize">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:shopping-cart'"></mat-icon>
                {{'kup_dostep' | transloco}}
            </button>
        </fuse-alert>
    </div>
  
    <div *ngIf="subscriptionState.expiredInDays == 0" class="relative flex flex-0 items-center justify-start w-full p-2 z-49 border-t dark:border-gray-800 bg-card dark:bg-transparent print:hidden">
        <fuse-alert
            class="w-full"
            [appearance]="'outline'"
            [dismissible]="false"
            [type]="'warn'"> 
            <mat-icon fuseAlertIcon [svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
            <span [innerHTML]="('twoja_subskrypcja_stracila_waznosc_przedluz_subskrypcje' | transloco: {premiumDays: subscriptionState.expiredInDays})"></span>
            
            <button mat-flat-button color="warn" class="h-6 min-h-6 ml-2 px-2 text-sm" [routerLink]="'/app/subscription/pricing' | localize">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:shopping-cart'"></mat-icon>
                {{'kup_dostep' | transloco}}
            </button>
        </fuse-alert>
    </div>