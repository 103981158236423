export class ObjectUtils {

  public static ObjectIsEqual(obj, obj2) {

    if (obj && obj2 == null) return false
    if (obj2 && obj == null) return false

    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (typeof obj2[key] == 'undefined') return false
      if (obj[key] !== obj2[key]) return false
    }

    return true
  }

  static compareObjects(obj1: any, obj2: any, excludedKeys: string[]): { changed: boolean, changes: { [key: string]: { before: any, after: any } } } {
    if (obj1 == null || obj2 == null) return { changed: false, changes: {} };

    const changes: { [key: string]: { before: any, after: any } } = {};
    let changed = false;

    function compareValues(key: string, value1: any, value2: any) {
        if (excludedKeys.includes(key)) return;
        
        if (Array.isArray(value1) && Array.isArray(value2)) {
            if (value1.length !== value2.length || !value1.every((val, index) => val === value2[index])) {
                changes[key] = { before: value1, after: value2 };
                changed = true;
            }
        } else if (typeof value1 === 'object' && typeof value2 === 'object' && value1 !== null && value2 !== null) {
            const nestedChanges = ObjectUtils.compareObjects(value1, value2, excludedKeys);
            if (nestedChanges.changed) {
                changes[key] = { before: value1, after: value2 };
                changed = true;
            }
        } else if (value1 !== value2) {
            changes[key] = { before: value1, after: value2 };
            changed = true;
        }
    }

    for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && !excludedKeys.includes(key)) {
            compareValues(key, obj1[key], obj2[key]);
        }
    }

    for (const key in obj2) {
        if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key) && !excludedKeys.includes(key)) {
            changes[key] = { before: null, after: obj2[key] };
            changed = true;
        }
    }

    return { changed, changes };
}
}