import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, from, map, of, tap } from "rxjs";
import { DbInvoice } from "../models/DbInvoice";
import { DbInvoiceItem } from "../models/DbInvoiceItem";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { AppDB } from "../models/db";
import { DbInvoiceCorrection } from "../models/DbInvoiceCorrection";
import { DbInvoicePrepayment } from "../models/DbInvoicePrepayment";
import { Utils } from "../others/utils";
import { cloneDeep } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class InvoicesService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _db: AppDB

  private _counter = new BehaviorSubject<DbInvoiceCounter>({
    valid: 0,
    deleted: 0
  })

  private _invoices = new BehaviorSubject<DbInvoicesObject>({})
  public invoicesPrepayment: {};

  currentInvoices = this._invoices.asObservable();

  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService
  ) {

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
          this._invoices.next({})
        }
      })
    )

    this._subscriptions.push(this.currentInvoices.subscribe({
      next: (data) => {
        this._countAll(data)
      }
    }))

    this._dataSynchronizerService.currentData.subscribe({
      next: (data) => {
        if (data.table == 'invoices') {
          // this._updateInvoices(data.value)
          this._processInvoices(data.value)
        }
        else if (data.table == 'invoicesItems') {
          this._processInvoiceItems(data.value)
        }
        else if (data.table == 'invoicesCorrection') {
          this._processInvoicesCorrection(data.value)
        }
        else if (data.table == 'invoicesPrepayment') {
          this._processInvoicesPrepayment(data.value)
        }
        this._countAll(data)
      }
    })
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  public getCounter(): Observable<DbInvoiceCounter> {
    return this._counter.asObservable()
  }

  public checkInvoiceNameAvailability(invoiceName, invoiceType) {
    return !Object.values(this.getInvoices()).some(x => x.status == 1 && x.invoiceType == invoiceType && x.fullInvoiceName == invoiceName)
  }

  public getInvoices(): DbInvoicesObject {
    return this._invoices.value
  }

  // Get all data from tables and create object of counted invoices
  // From database i take only not deleted entities
  public getAll(): Observable<boolean> {
    this._invoices.next({})
    if(!this._db) return of(true)
    let startTime = 0
    const loader = from(this._db.transaction(
      "r",
      this._db.invoices,
      this._db.invoicesItems,
      this._db.invoicesCorrection,
      this._db.invoicesPrepayment,
      async () => {
        startTime = performance.now();
        const invoices = await this._db.invoices.toArray()

        return invoices.filter(x => x.status == 1)
        // return this._db.invoices.where(x=>x.status == 1).toArray();
      })
      .then(async (invoices: DbInvoice[]) => {

        this._processInvoices(invoices)
        let query: any = this._db.invoicesItems
        // let query: any = this._db.invoicesItems.where(x=>x.status == 1)
        const products = await query.toArray()

        this._processInvoiceItems(products.filter(x => x.status == 1))
        return invoices

      })
      .then(async (invoices: DbInvoice[]) => {
        let query: any = await this._db.invoicesCorrection
        // let query: any = this._db.invoicesCorrection.where(x=>x.status == 1)
        const corrections = await query.toArray()
        this._processInvoicesCorrection(corrections.filter(x => x.status == 1))
        return invoices;

      })
      .then(async (invoices: DbInvoice[]) => {
        let query: any = this._db.invoicesPrepayment

        const prepayments = await query.toArray()
        // let query: any = this._db.invoicesPrepayment.where(x=>x.status == 1)
        this._processInvoicesPrepayment(prepayments.filter(x => x.status == 1))
        return invoices
      }))
      .pipe(
        tap(() => console.log(`[Invoices Service]: Built in ${performance.now() - startTime}ms.`)),
        map((invoices: DbInvoice[]) => {
          this._invoices.next(this._invoices.value)
          return true;
        })
      );
    return loader
  }



  public getById(invoiceId): DbInvoice {
    const invoice = this._invoices.value[invoiceId]

    if (invoice) return invoice
    else console.warn(`Invoice with id: ${invoiceId} not found.`)

  }


  public browseInvoices({ allResults = [], filters = [] }): PaginatedDbInvoices {
    let t1 = new Date().getTime();

    let searchingResults = allResults.length > 0 ? allResults : Object.values(this._invoices.value)
      .sort((a: DbInvoice, b: DbInvoice) => b.invoiceId - a.invoiceId)

    if (filters.length > 0) {
      filters.forEach(filter => {
        searchingResults = searchingResults.filter(filter)
      })
    }

    const paginationResult: PaginatedDbInvoices = {
      time: new Date().getTime() - t1,
      resultsCount: searchingResults.length,
      allResults: searchingResults
    }

    return paginationResult

  }

  private _processInvoices(invoices: DbInvoice[]) {

    invoices.forEach(invoice => {

      if (invoice == null || typeof invoice == 'undefined') console.warn("Invalid value of invoice.")

      let issueDate = new Date(invoice.issueDate);
      let month: any = issueDate.getMonth() + 1
      if (month < 10) month = "0" + month.toString();
      let fullInvoiceName = `${invoice.extraChar}${invoice.invoiceNo}/${month}/${issueDate.getFullYear()}`
      invoice.fullInvoiceName = fullInvoiceName;

      // Take some data from already existing
      const existsAlready = this._invoices.value[invoice.invoiceId]
      let products = []
      let prepayments = []
      let correctedInvoice = null
      let correctedInvoiceId = null
      if (existsAlready) {
        if (products.length > 0) {
          products = products.filter(x => x.status == 1)
        }
        products = cloneDeep(existsAlready.products)
        prepayments = existsAlready.prepayments
        correctedInvoice = existsAlready.correctedInvoice
        correctedInvoiceId = existsAlready.correctedInvoiceId
      }

      // Create new object
      const newInvoice = new DbInvoice(invoice);
      // Items
      newInvoice.products = products
      // Prepayments
      newInvoice.prepayments = prepayments
      // Corrected invoices
      newInvoice.correctedInvoiceId = correctedInvoiceId
      newInvoice.correctedInvoice = correctedInvoice

      // Recount data
      newInvoice.count()

      // Update memory
      this._invoices.value[invoice.invoiceId] = newInvoice

    })

  }

  private _processInvoiceItems(invoicesItems: DbInvoiceItem[]) {
    invoicesItems.forEach(invoiceItem => {
      const invoice: DbInvoice = this._invoices.value[invoiceItem.invoiceId]

      if (!Utils.isDefined(invoice, "products")) return

      // Remove positions which doesnt contains products with positionId
      invoice.products = invoice.products.filter(x => x.positionId > 0)
      let index = invoice.products.findIndex(x => x.positionId == invoiceItem.positionId)
      // If exists
      if (index > -1) {
        // Remove if deleted
        if (invoice.status == 0) Utils.removeElementByIndex(invoice.products, index)
        // Update
        else invoice.products[index] = new DbInvoiceItem(invoiceItem)
      }
      // If not exists add
      else invoice.products.push(new DbInvoiceItem(invoiceItem))

      invoice.count()

    })
  }

  private _processInvoicesCorrection(invoicesCorrection: DbInvoiceCorrection[]) {
    invoicesCorrection.forEach(correctionRow => {

      const invoice: DbInvoice = this._invoices.value[correctionRow.invoiceId]
      // Korygowana faktura
      const correctedInvoice: DbInvoice = this._invoices.value[correctionRow.correctionInvoiceId]

      if (typeof correctedInvoice !== 'undefined') {
        this._invoices.value[correctedInvoice.invoiceId] = correctedInvoice

        // Add corrected invoice
        if (invoice != null && typeof invoice !== 'undefined' && correctionRow.status == 1) {

          invoice.setCorrectedDoc(correctedInvoice)
          invoice.count()
          this._invoices.value[invoice.invoiceId] = invoice

        } 
        // Remove corrected invoice
        else if (invoice != null && typeof invoice !== 'undefined' && correctionRow.status == 1) {
          if(invoice.correctedInvoiceId == correctedInvoice.invoiceId) {
            invoice.correctedInvoice = null
            invoice.correctedInvoiceId = null
            invoice.count()
            this._invoices.value[invoice.invoiceId] = invoice
          }
        }
      }

    })
  }

  private _processInvoicesPrepayment(invoicesPrepayments: DbInvoicePrepayment[]) {

    invoicesPrepayments.forEach(prepaymentRow => {

      const invoice = this._invoices.value[prepaymentRow.invoiceId]
      const invoice_zaliczka = this._invoices.value[prepaymentRow.prepaymentInvoiceId]


      if (invoice != null && typeof invoice !== 'undefined' && typeof invoice_zaliczka !== 'undefined' && prepaymentRow.status == 1) {
        invoice.addPrepayment(invoice_zaliczka)
        invoice.prepayments = invoice.prepayments.filter(x => x.status == 1)
        invoice.count()
        this._invoices.value[invoice.invoiceId] = invoice
      }
      else if(invoice != null && typeof invoice !== 'undefined' && typeof invoice_zaliczka !== 'undefined' && prepaymentRow.status == 0) {
        invoice.prepayments = invoice.prepayments.filter(x=>x.invoiceId != invoice_zaliczka.invoiceId)
        invoice.count()
        this._invoices.value[invoice.invoiceId] = invoice
      }

    })
  }

  private _countAll(data) {
    let valid = 0
    let deleted = 0

    if (data == null) {
      this._counter.next({ valid, deleted })
      return
    }

    Object.values(data).forEach((invoice: DbInvoice) => {
      if (invoice == null) return
      if (invoice.status == 1) valid++
      else deleted++
    })

    this._counter.next({ valid, deleted })
  }
}
export interface DbInvoicesObject {
  [key: number]: DbInvoice
}

export interface PaginatedDbInvoices {
  allResults: DbInvoice[]
  resultsCount: number
  time: number,
}

export interface DbInvoiceCounter {
  valid: number,
  deleted: number
}