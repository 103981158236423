import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CONST } from '../../data/const';
import { DbLogs } from '../../models/DbLogs';
import { HistoryUtils } from '../../models/HistoryUtils';
import { UserSettings } from '../../models/UserSettings';
import { Utils } from '../../others/utils';
import { PanelService } from '../../services/panel.service';
import { SettingsService } from '../../services/settings.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { DbService } from '../../services/db.service';
import { AppDB } from '../../models/db';
import { DbAdditionalService } from '../../models/DbAdditionalService';
import { AdditionalServicesService } from '../../services/additional-services.service';
import { ClientsService } from '../../services/clients.service';
import { ReservationsService } from '../../services/reservations.service';
import { RoomsService } from '../../services/rooms.service';
import { EmployeesService } from '../../services/employees.service';
import { CommonModule, JsonPipe } from '@angular/common';
import { DateFormatterComponent } from '../date-formatter/date-formatter.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { HistoryKeyToTextComponent } from '../history-key-to-text/history-key-to-text.component';
import { SourcesService } from '../../services/sources.service';
import { debounce } from 'lodash';
import { DataSynchronizerService } from '../../services/data-synchronizer.service';
import { DateTime } from 'luxon';
import { ApplicationData } from '../../data/application-data';
import { LogActionNameComponent } from '../log-action-name/log-action-name.component';
import { UserAvatarImageComponent } from '../user-avatar-image/user-avatar-image.component';


@Component({
  standalone: true,
  selector: 'history-master',
  templateUrl: './history-master.component.html',
  styleUrls: ['./history-master.component.scss'],
  imports: [CommonModule, LogActionNameComponent, JsonPipe, UserAvatarImageComponent, JsonPipe, HistoryKeyToTextComponent, DateFormatterComponent, MatSelectModule, MatInputModule, MatFormFieldModule, MatChipsModule, MatIconModule, TranslocoModule, MatIconModule]
})
export class HistoryMasterComponent implements OnChanges, OnDestroy {

  private _subscriptions: Subscription[] = [];

  additionalServices: DbAdditionalService[] = []
  found = 0;
  currentPage = 1;
  allPages = 1;
  itemsPerPage = 10;

  histories = [];
  grouped = [];
  userSettings: UserSettings;

  @Input('selectedHistoryTypes') selectedHistoryTypes = [];
  @Input('startDate') startDate = null
  @Input('endDate') endDate = null
  @Input('reservationNumber') reservationNumber: any = null
  @Input('searchValue') searchValue: any = null
  @Input('employeeId') employeeId = null
  @Input('clientId') clientId = null
  @Output('onBrowseFinished') onBrowseFinished = new EventEmitter<number>();

  employees = []

  showTime = false;
  dFormat;
  tFormat;
  dateFormat;

  private _db: AppDB
  constructor(
    private readonly _dbService: DbService,
    private readonly _clientsService: ClientsService,
    private readonly _reservationsService: ReservationsService,
    private readonly _roomsService: RoomsService,
    private readonly _employeesService: EmployeesService,
    private readonly _sourcesService: SourcesService,
    public readonly translate: TranslocoService,
    private readonly settingsService: SettingsService,
    private readonly panelService: PanelService,
    private _additionalServicesService: AdditionalServicesService,
    private _dataSynchronizerService: DataSynchronizerService,
    private _cdr: ChangeDetectorRef
  ) {

    this.prepareFirstPage = debounce(this.prepareFirstPage, 10)
    this.loadData = debounce(this.loadData, 10)
    this._subscriptions.push(this._additionalServicesService.currentAdditionalServices.subscribe({
      next: (data) => this.additionalServices = data
    }))

    // Update list results on any changes in those tables
    this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
      next: async (data) => {
        const trackTables = ['logs']
        if (trackTables.includes(data.table)) {
          // Reset all results (full check is required)
          this.loadedData = false;
          await this.loadData()
        }
      }
    }))

    this._subscriptions.push(this.settingsService.getUserSettings().subscribe(
      {
        next: (data) => {
          this.userSettings = data;

          if (data != null) {

            if (Utils.isDefined(data, "settings.generalDateFormat")) {
              this.dFormat = data.settings.generalDateFormat;
            }

            if (Utils.isDefined(data, "settings.generalTimeFormat")) {
              this.tFormat = data.settings.generalTimeFormat;
            }

            this.buildFormat()
          }
        }
      }))

    this._subscriptions.push(this._dbService.getDatabase().subscribe(
      {
        next: async (data) => {
          this._db = data
        }
      }))

    this._subscriptions.push(this._employeesService.currentEmployees.subscribe(
      {
        next: async (data) => {
          if (data) {
            this.employees = data
            this.loadedData = false;
            await this.loadData()
          }
        }
      }))

    this._subscriptions.push(this.translate.langChanges$.subscribe({
      next: (data) => {
        this.prepareFirstPage();
      }
    }))
  }

  onScroll(e: any) {
    if (this.currentPage != this.allPages) {
      this.nextPage()
    }
  }

  buildFormat() {
    this.dateFormat = this.dFormat;
    if (this.showTime) { this.dateFormat = this.dFormat + ' ' + this.tFormat }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (
      typeof changes['selectedHistoryTypes'] != 'undefined' ||
      typeof changes['employeeId'] != 'undefined' ||
      typeof changes['reservationNumber'] != 'undefined' ||
      typeof changes['searchValue'] != 'undefined' ||
      typeof changes['endDate'] != 'undefined'
    ) {
      this.prepareFirstPage();
    }
  }

  ngOnDestroy(): void {
    this.history = [];
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  nextPageLoading = false;
  nextPage() {
    if (this.nextPageLoading == true) return
    this.nextPageLoading = true;
    this.currentPage++;
    this.getHistoryFromDates(this.history);
  }

  loadedData = false;
  async loadData() {
    await this.getHistory()
    await this.getHistoryFromDates(this.history);
    this.loadedData = true;
    this._cdr.markForCheck()
  }

  isLock(reservation) {
    return Utils.isLock(reservation);
  }

  showReservation(reservation) {
    this.panelService.openUpdateReservationPanel(reservation)
  }

  showClient(client) {
    this.panelService.showCreateOrUpdateClient('update', client)
  }

  showRoom(room) {
    this.panelService.showCreateOrUpdateRoom('update', room)
  }

  showLoader = false;
  history = [];
  async getHistory() {
    return await this._db.transaction("r", this._db.logs, async () => {
      return await this._db.logs.toArray();
    })
  }

  async prepareFirstPage() {

    this.currentPage = 1;

    this.history = await this.getHistory()
    this.grouped = [];
    this.histories = [];
    await this.getHistoryFromDates(this.history);
  }

  // Counting timing
  t2 = 0;
  tx1 = 0;
  tx2 = 0;

  async getHistoryFromDates(history) {

    if (history.length == 0) return;

    let t1 = new Date().getTime();
    let reservationId = null;
    await this._db.transaction("r", this._db.reservations, async () => {

      if (this.currentPage == 1) {
        this.showLoader = true;
      }

      if (typeof this.reservationNumber == 'string') {
        this.reservationNumber = this.reservationNumber.replace('#', '')
      }

      if (typeof this.reservationNumber !== undefined && this.reservationNumber > 0) {
        const res = await this._db.reservations.where({ reservationNo: this.reservationNumber }).first();
        if (typeof res != 'undefined') { reservationId = res.reservationId; }
      }

      let filtered = history.filter(x => this.selectedHistoryTypes.includes(x.objectName))
      if (this.startDate != null && this.endDate != null) {
        this.startDate.setHours(0, 0, 0, 0);
        const startDateTime = this.startDate.getTime()
        this.endDate.setHours(0, 0, 0, 0);
        const endDateTime = this.endDate.getTime() + CONST.DAYINMILLIS;

        filtered = history.filter(
          x =>
            new Date(x.addDate).getTime() >= startDateTime &&
            new Date(x.addDate).getTime() <= endDateTime
        )
      }

      
      if (this.employeeId > 0) filtered = filtered.filter(x => x.employeeId == this.employeeId)
      if(reservationId != null) {
        // Blockades
        if ( this.clientId == null || reservationId > 0) filtered = filtered.filter(x => x.objectId ==  reservationId || (Array.isArray(x.objectId) && x?.objectId?.includes(reservationId)))
        // Reservations
        if ( reservationId > 0 && this.clientId > 0) filtered = filtered.filter(x => x.objectId == this.clientId || x.objectId == reservationId || (Array.isArray(x.objectId) && x?.objectId?.includes(reservationId)))
      }


      // Search query filter
      if (!Utils.isNullOrEmpty(this.searchValue)) {
        const searchValue = this.searchValue.toLowerCase()
        filtered = filtered.filter((log: DbLogs) => {

          let match = false
          // Check reservation clientss
          if (log.objectName == 'Reservations') {

            if (Array.isArray(log.objectId)) {
              log.objectId.forEach(rId => {
                let res = this._reservationsService.getById(rId)
                if (typeof res == 'undefined') return
             
                if (searchValue.charAt(0) === '#') {
                  let foundId = searchValue.replaceAll('#', '');
                  if (res.reservationNo == parseInt(foundId)) match = true;
                }
                else if (Utils.isDefined(res, "client.forename") && res.client.forename.toLowerCase().includes(searchValue)) match = true;
                else if (Utils.isDefined(res, "client.name") && res.client.name.toLowerCase().includes(searchValue)) match = true;
                else if (Utils.isDefined(res, "reservationId") && res.reservationId.toString().toLowerCase().includes(searchValue)) match = true;
                else if (Utils.isDefined(res, "reservationNo") && res.reservationNo.toString().includes(searchValue)) match = true;
                else if (Utils.isDefined(res, "client.vehicleRegistrationNumber") && res.client.vehicleRegistrationNumber.toLowerCase().includes(searchValue)) match = true;
                else if (Utils.isDefined(res, "client.idCard") && res.client.idCard.toLowerCase().includes(searchValue)) match = true;
              });
              return match
            }
            else {
              let res = this._reservationsService.getById(log.objectId)
              if (typeof res == 'undefined') return match
           
              if (searchValue.charAt(0) === '#') {
                let foundId = searchValue.replaceAll('#', '');
                if (res.reservationNo == parseInt(foundId)) match = true;
              }
              else if (Utils.isDefined(res, "client.forename") && res.client.forename.toLowerCase().includes(searchValue)) match = true;
              else if (Utils.isDefined(res, "client.name") && res.client.name.toLowerCase().includes(searchValue)) match = true;
              else if (Utils.isDefined(res, "reservationId") && res.reservationId.toString().toLowerCase().includes(searchValue)) match = true;
              else if (Utils.isDefined(res, "reservationNo") && res.reservationNo.toString().includes(searchValue)) match = true;
              else if (Utils.isDefined(res, "client.vehicleRegistrationNumber") && res.client.vehicleRegistrationNumber.toLowerCase().includes(searchValue)) match = true;
              else if (Utils.isDefined(res, "client.idCard") && res.client.idCard.toLowerCase().includes(searchValue)) match = true;
            }
          }

          else if (log.objectName == "Clients") {
            if (Array.isArray(log.objectId)) return match
            let client = this._clientsService.getById(log.objectId)
            if (typeof client == 'undefined') return match
            if (Utils.isDefined(client, "forename") && client.forename.toLowerCase().includes(searchValue)) match = true;
            else if (Utils.isDefined(client, "name") && client.name.toLowerCase().includes(searchValue)) match = true;
          }

          else if (log.objectName == "Rooms") {
            if (Array.isArray(log.objectId)) return match
            let room = this._roomsService.getById(log.objectId);
            if (typeof room == 'undefined') return match
            if (Utils.isDefined(room, "name") && room.name.toLowerCase().includes(searchValue)) match = true;
          }

          return match
        })
      }

      // Count before paginate
      this.found = filtered.length;
      this.onBrowseFinished.emit(this.found);
      this.allPages = Math.ceil(this.found / this.itemsPerPage);

      // Parse string to JSON
      const utils = new HistoryUtils(
        this.settingsService,
        this.translate,
        this._sourcesService.getSources(),
        this._additionalServicesService,
        this._clientsService.getClients(),
        this._roomsService.getRooms(),
        this.dateFormat
      );

      // Paginate
      filtered.sort((a, b) => b.logId - a.logId)
      const offset = this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.itemsPerPage;
      filtered = Utils.getObjectsInRange(filtered, offset, offset + this.itemsPerPage - 1)

      filtered.forEach(history => {

        if (history.objectName == 'Reservations') {
          if (!Array.isArray(history.objectId)) {
            const res = this._reservationsService.getById(history.objectId)
            if (typeof res != 'undefined') { history.object = res; }
          } else {
            history.object = []
            history.objectId.forEach((reservationId: number) => {
              const res = this._reservationsService.getById(reservationId)
              if (typeof res != 'undefined') { history.object.push(res) }
            })
          }
        }

        else if (history.objectName == "Clients") {
          if (!Array.isArray(history.objectId)) {
            const client = this._clientsService.getById(history.objectId)
            if (typeof client != 'undefined') { history.object = client; }
          } else {
            history.object = []
            history.objectId.forEach((clientId: number) => {
              const client = this._clientsService.getById(clientId)
              if (typeof client != 'undefined') { history.object.push(client) }
            })
          }
        }

        else if (history.objectName == "Rooms") {
          if (!Array.isArray(history.objectId)) {
            const room = this._roomsService.getById(history.objectId)
            if (typeof room != 'undefined') { history.object = room; }
          } else {
            history.object = []
            history.objectId.forEach((roomId: number) => {
              const room = this._roomsService.getById(roomId)
              if (typeof room != 'undefined') { history.object.push(room) }
            })
          }
        }

        if (typeof history.createdBy == 'number' && history.createdBy >= 0) {
          if(history.createdBy) {
            const source = this._sourcesService.getSourceById(history.createdBy)
            if (source) history.source = source
          }
         
        }

        if (typeof history.userId == 'number' && history.userId > 0) {

          // Check if its owner
          if (typeof this.userSettings != 'undefined' && this.userSettings.userData.userId == history.userId) {
            history.user = {
              email: this.userSettings.userData.email,
              // avatar: this.userSettings.avatar,
              forename: this.userSettings.userData.forename,
              name: this.userSettings.userData.name
            }
          } else {
            const employee = this._employeesService.getById(history.userId)
            if (employee != null) {
              history.user = employee
            }
          }
        }

        // Dodaj po id pracownika
        if (typeof history.employeeId == 'number' && history.employeeId > 0) {
          const employee = this._employeesService.getById(history.employeeId)
          if (employee != null) {
            history.employee = employee
          }
        }

        if (Utils.isDefined(history, "changes") && !Utils.isNullOrEmpty(history.changes)) {
          history.changes = utils.PrepareChanges(history.changes);
        }

      })

      if (this.currentPage == 1) { this.grouped = []; }

      filtered.forEach(element => {
        this.histories.push(element)
      });

      this.histories.sort((a, b) => b.logId - a.logId)

      filtered.forEach(history => {
        let date = new Date(history.addDate)
        let dateString = date.toISOString().split('T')[0]
        let isToday = dateString === new Date().toISOString().split('T')[0];

        const grouperDate = this.grouped.find(x => x.dateString == dateString);
        if (typeof grouperDate == 'undefined') {
          this.grouped.push(
            {
              date: date,
              dateString: dateString,
              isToday: isToday,
              histories: [
                history
              ]
            }
          )
        }
        else {
          grouperDate.histories.push(history)
        }
      });

      if (this.currentPage == 1) {
        setTimeout(() => {
          this.showLoader = false
        }, 10)
      }

      this.nextPageLoading = false
      this.t2 = new Date().getTime() - t1;

    })
  }

  isSameDay(current: string, compare: string): boolean {
    return DateTime.fromISO(current).hasSame(DateTime.fromISO(compare), 'day');
  }
}
