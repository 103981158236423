import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { PortalOutletLoaderComponent } from 'app/core/shared/components/portal-outlet-loader/portal-outlet-loader.component';
import { RouterOutlet } from '@angular/router';
import { PanelService } from 'app/core/shared/services/panel.service';

@Component({
  selector: 'drawer-router-outlet',
  standalone: true,
  templateUrl: './drawer-router-outlet.component.html',
  styleUrl: './drawer-router-outlet.component.scss',
  imports: [CommonModule, PortalOutletLoaderComponent, MatSidenavModule, RouterOutlet]
})
export class DrawerRouterOutletComponent implements OnInit {

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  drawerMode: 'side' | 'over';
  @ViewChild('drawer') drawer: MatDrawer

  
  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _panelService:PanelService
  ) {}

  // Register portal to make it available for services
  ngAfterViewInit(): void {
    this._panelService.setPanel(this.drawer);
  
  }
  ngOnInit(): void {
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Set the drawerMode if the given breakpoint is active
        if (matchingAliases.includes('lg')) {
          this.drawerMode = 'side';
        }
        else {
          this.drawerMode = 'over';
        }

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

}
