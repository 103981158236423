import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'client-row',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './client-row.component.html',
  styleUrl: './client-row.component.scss'
})
export class ClientRowComponent {
  @Input('data') client = null;
}
