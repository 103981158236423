<fuse-card class="relative flex flex-col flex-auto shadow-lg mb-3 w-full max-w-96 mt-6 mr-12" [@fadeInRight]="state">
    <div class="flex group">
        <!-- Notification without a link -->
        <!-- {{data | json}} -->
        <ng-container>
            <div class="flex flex-auto py-5 pl-6">
                <ng-container *ngIf="!data?.isImage">
                    <!-- bg-gray-100 dark:bg-gray-700-->
                    <div class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full"
                    [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data?.status == 'primary',
                    'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data?.status == 'accent',
                    'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data?.status == 'warning',
                    'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data?.status == 'danger',
                    'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data?.status == 'basic',
                    'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data?.status == 'info',
                    'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data?.status == 'success',
                    'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data?.status == 'warning',
                    'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data?.status == 'error'
                    }">
                        <mat-icon class="text-current icon-size-5" *ngIf="data?.status == 'danger'" [svgIcon]="'heroicons_solid:exclamation-triangle'"></mat-icon>
                        <mat-icon class="text-current icon-size-5" *ngIf="data?.status == 'warning' || data?.status == 'error'" [svgIcon]="'heroicons_solid:exclamation-triangle'"></mat-icon>
                        <mat-icon class="text-current icon-size-5" *ngIf="data?.status == 'info'" [svgIcon]="'heroicons_solid:bell'"></mat-icon>
                        <mat-icon class="text-current icon-size-5" *ngIf="data?.status == 'edit'" [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                        <mat-icon class="text-current icon-size-5" *ngIf="data?.status == 'success'" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                        <!-- <mat-icon class="icon-size-5" [svgIcon]="data?.icon">
                        </mat-icon> -->
                    </div>
                </ng-container>
                <!-- Image -->
                <ng-container *ngIf="data?.isImage">
                    <img class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center" src="assets/images/sources/booking.png"
                         [alt]="'Notification image'"> <!--[src]="data?.image"-->
                </ng-container>
                <!-- Title, description & time -->
                <div class="flex flex-col flex-auto">
                    <ng-container *ngIf="data?.title">
                        <div class="font-semibold line-clamp-1">{{data?.title}}</div>
                    </ng-container>
                    <ng-container *ngIf="data?.message">
                        <div class="line-clamp-3" [innerHTML]="data?.message"></div>
                    </ng-container>
                    <div class="mt-2 text-sm leading-none text-secondary">
                        <date-formatter [date]="data?.added" [showTime]="true"></date-formatter>
                    </div>
                    <div class="mt-2 -mx-3" *ngIf="data?.buttons?.length > 0">
                        <ng-container *ngFor="let btn of data?.buttons" >
                            <button mat-button class="px-3" [color]="'primary'" (click)="invoke(btn.action)">
                                {{btn.text}}
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- Actions -->
        <div class="relative flex flex-col p-2">
            <!-- Remove -->
            <button class="w-8 h-8 min-h-8" mat-icon-button (click)="close()">
                <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
            </button>
        </div>
    </div>
</fuse-card>