import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationData } from '../../data/application-data';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'payment-type-to-text',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './payment-type-to-text.component.html',
  styleUrl: './payment-type-to-text.component.scss'
})
export class PaymentTypeToTextComponent implements OnChanges {
  
  @Input('paymentType') paymentType
  translation = ''

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof changes.paymentType.currentValue == 'undefined') return

    const type = ApplicationData.InvoicePaymentTypes.find(x=>x.id == changes.paymentType.currentValue)
    if(type) this.translation = type.text
  }
}