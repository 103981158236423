<mat-drawer-container class="drawer-container">
    <div class="drawer-sidenav-content">
         <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
      </div>
      <mat-drawer #drawer class="mat-drawer w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" position="end">
        <portal-outlet-loader class="disable-selection"></portal-outlet-loader>
    </mat-drawer>
 </mat-drawer-container>
