import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { LocalizeRouterService } from "@penleychan/ngx-transloco-router";
import { AuthService } from "app/core/auth/auth.service";
import { environment } from "environments/environment";
import { DeviceDetectorService } from "ngx-device-detector";
import { RefreshPageComponent } from "../dialogs/refresh-page/refresh-page.component";
import { Utils } from "../others/utils";
import { InternetConnectionService } from "./internet-connection.service";
import { SynchronizationService } from "./synchronization.service";
import { DialogService } from "./dialog.service";
import { SettingsService } from "./settings.service";
import { ApiService } from "./api.service";
import { PanelService } from "./panel.service";
import { Injectable } from "@angular/core";
import { DbService } from "./db.service";
import { DbUserSettingsService } from "./db-user-settings.service";
import { BehaviorSubject, take } from "rxjs";
import { DataLoaderService } from "./data-loader.service";
import { CollisionService } from "./collision.service";
import { decodeToken } from "../utils/jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class ReloginService {

  private _event = new BehaviorSubject<any>(null)
  public currentEvent = this._event.asObservable();

  device_id = localStorage.getItem('device_id') == null ? Utils.generateDeviceId() : localStorage.device_id;
  device = `${this.deviceInformationService.getDeviceInfo().browser} v${this.deviceInformationService.getDeviceInfo().browser_version}`;
  device_name = `${this.deviceInformationService.getDeviceInfo().os} ${this.deviceInformationService.getDeviceInfo().os_version}`;
  platform = this.deviceInformationService.getDeviceInfo().deviceType;


  constructor(
    private deviceInformationService: DeviceDetectorService,
    public ics: InternetConnectionService,
    public translate: TranslocoService,
    private _authService: AuthService,
    private _localize: LocalizeRouterService,
    private _dbService: DbService,
    private _dbUserSettingsService: DbUserSettingsService,
    private _router: Router,
    private _synchronizationService: SynchronizationService,
    private _dialogRef: MatDialog,
    private _settingsService: SettingsService,
    private _dialogService: DialogService,
    private _apiService: ApiService,
    private _panelService: PanelService,
    private _dataLoaderService: DataLoaderService,
    private _collisionService: CollisionService
  ) 
  {

  }

  relogin(user) {

    if (this.ics.offlineMode.value && !user.isOfflineModeAvailable) {

      this._dialogService.confirmDialog({
        title: this.translate.translate('tryb_offline'),
        message: this.translate.translate('nie_mozna_zalogowac_sie_na_to_konto_w_trybie_offline'),
        type: 'info',
        confirmText: this.translate.translate('rozumiem')
      })
      return
    }

    if (this.ics.offlineMode.value) { 
      this.showOfflineModeLaunchConfirmation(user)
      return
    }

    this._authService.relogin(user.userId, this.device_id, this.device, this.device_name, this.platform)
      .pipe(take(1))
      .subscribe({
          next: (loginResponse) => {

            if (loginResponse.status == 'success') {
              this._collisionService.clean()
              // Get new JWT
              // Set new JWT
              localStorage.setItem(environment.ACCESS_TOKEN, loginResponse.data.accessToken);
              localStorage.setItem(environment.REFRESH_TOKEN, loginResponse.data.refreshToken	);
              this._authService.updateTokens() 
              // Get new settings
              this._apiService.refreshSettings()
                .pipe(take(1))
                .subscribe({
                    next: async (response: any) => {
                      //jeżeli ok to odśwież komponenty

                      console.warn(`[Relogin Service]: Logging as: ${response.data.userData.userId}`);

                      this._settingsService.updateUserSettings(response.data);
                      await this._dbUserSettingsService.saveUserSettings(response.data)

                      await this._dbService.setDatabase(
                        response.data.userData.userId,
                        response.data.userData.employeeId
                      );

                      this._loadUserData(response.data)

                    },
                    error: (error: HttpErrorResponse) => {
                      this.showOfflineModeLaunchConfirmation(user);
                    }
                  });
            }
            
          },
          error: (error: HttpErrorResponse) => {
            this.showOfflineModeLaunchConfirmation(user)
          }
        })
  }

  private async _loadUserData(userSettings) {

    this._dialogService.showLoaderDialog()
    
    const loader = await this._dataLoaderService.getStrategy()
    
    this._dialogService.hideLoaderDialog()

    // Log event to get moment where data is loaded after relogin
    // To for example get sidebar data
    this._event.next('data_load_end')
    this._synchronizationService.newSynchronize("[Relogin Service]: Synchronize from relogin action");

    // Close panel
    this._panelService.close();

    // Check premium time
    const premiumDays = Utils.checkPremiumDays(userSettings.userData.premiumTime)
    if (premiumDays <= 0) {
      console.log(`[SubscriptionGuard]: Access available only for active subscription. No premium days left. Redirecting...`)
      const translatedPath = this._localize.translateRoute('/app/subscription/expired');
      this._router.navigate([translatedPath]);
      return
    }

    // Auto navigate to page from settings
    const settings = this._settingsService.userSettings.value.settings.generalHomepage
    let path = '/app/calendar'
    if (settings == 'dashboard') path = '/app/dashboard'
    const translatedRoute = this._localize.translateRoute(path)
    this._router.navigate([translatedRoute]);

  }

  showOfflineModeLaunchConfirmation(user) {
    const errorDialog = this._dialogRef.open(RefreshPageComponent, {
      width: '300px',
      maxWidth: '100%',
      disableClose: true,
      data: {
        userId: user.userId,
        employeeId: user.employeeId
      }
    });

    errorDialog.afterClosed().subscribe(
      async (data) => {

        console.log(data, typeof data.offlineMode !== undefined, user)
        if (typeof data === 'undefined') return
        if (typeof data.offlineMode !== undefined) {

          await this._dbService.setDatabase(user.userId, user.employeeId);
          // this._synchronizationService.newSynchronize("[Database Service]: Synchronize from Database Service");

          const settings: any = await this._dbUserSettingsService.getUserSettings()
          this._settingsService.updateUserSettings(settings);

          this._loadUserData(this._settingsService.userSettings.value)
        }
      }
    )
  }
}