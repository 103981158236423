import { Utils } from "../others/utils";

export class DbInvoiceItem {
    positionId:       number = null;
    invoiceId:       number = null;
    userId:         string;
    status:           number = 1;
    name:            string;
    grossUnitPrice: number | string;
    quantity:            number;
    unit:        string;
    rate:           string;
    symbol:           string;
    addDate:        Date;
    editDate:        Date;

    grossValue?: number | string = 0
    nettValue?: number | string = 0
    taxAmount?: number | string = 0

    constructor(object:any)
    {
        Object.keys(object).forEach((key:string)=> {
            if(key == 'grossUnitPrice') object[key] = parseFloat(object[key])
            if(key == 'quantity') object[key] = parseFloat(object[key])
            this[key] = object[key]
        })

       this.count()
    }

    count() {

        if(typeof this.grossUnitPrice === 'string') this.grossUnitPrice = parseFloat(this.grossUnitPrice)
        this.grossValue = parseFloat((this.grossUnitPrice  * this.quantity).toFixed(2))

        if(Utils.isNullOrEmpty(this.rate)) this.rate = '0'
        if(Utils.isFieldParseableToFloat(this.rate))
        {
            this.nettValue =  parseFloat((this.grossValue / (1 + (parseFloat(this.rate)) / 100)).toFixed(2));
        }
        else {
            this.nettValue =  parseFloat(this.grossValue.toFixed(2))
        }

       
        this.taxAmount = this.grossValue - this.nettValue

        this.makeReadable()

    }

    makeReadable()
    {
        if(typeof this.grossValue !== 'string') this.grossValue = this.grossValue.toFixed(2)
        if(typeof this.nettValue !== 'string') this.nettValue = this.nettValue.toFixed(2)
        if(typeof this.grossUnitPrice !== 'string') this.grossUnitPrice = this.grossUnitPrice.toFixed(2)
        if(typeof this.taxAmount !== 'string') this.taxAmount = this.taxAmount.toFixed(2)
    }

    updateField(key,value)
    {
        this[key] = value;
        this.count()
    }

    set(key,value)
    {
        if(value === '') return;

        if(key === 'name')  {
            this.name = value;
        }
        if(key === 'symbol')  {
            this.symbol = value;
        }
        if(key === 'unit')  {
            this.unit = value;
        }

        
        if(key != 'rate' && Number.isNaN(parseFloat(value))) return;
        if(key === 'grossUnitPrice')  {
            value = parseFloat(value)
            this.updateField(key,value)
            this.grossUnitPrice = parseFloat((parseFloat(this.grossValue.toString()) / this.quantity).toFixed(2));
        }
        else if(key === 'quantity') {
            value = parseFloat(value)
            this.updateField(key,value)
        }
        else if(key === 'grossValue') this.updateGrossValue(value)
        else if(key === 'nettValue') this.updateNetValue(value)
        else {
            this.updateField(key,value)
        }
    }

    updateNetValue(netValue)
    {
        // if(typeof netValue === 'string') this.grossValue = netValue
        if(typeof this.grossValue === 'string') this.grossValue = parseFloat(this.grossValue)
        this.nettValue = parseFloat(netValue);

        if(Utils.isFieldParseableToFloat(this.rate)) {
            this.grossValue = parseFloat((this.nettValue + (this.nettValue * (parseFloat(this.rate) / 100))).toFixed(2))
            this.grossUnitPrice = parseFloat((this.grossValue / this.quantity).toFixed(2));
            this.taxAmount = parseFloat((this.grossValue - this.nettValue).toFixed(2));
        }
        else {
            this.grossUnitPrice = (parseFloat(this.grossValue.toFixed(2)) / this.quantity);
            this.grossValue = parseFloat(this.nettValue.toFixed(2))
            this.taxAmount = 0.00;
        } 

        this.grossUnitPrice = this.grossUnitPrice.toFixed(2)
        this.grossValue = this.grossValue.toFixed(2)
        this.taxAmount = this.taxAmount.toFixed(2)
    }

    updateGrossValue(grossValue)
    {
        this.grossValue = parseFloat(grossValue);

        if(Utils.isFieldParseableToFloat(this.rate)) {
            this.nettValue =  parseFloat((this.grossValue / (1 + (parseFloat(this.rate) / 100))).toFixed(2))
            this.grossUnitPrice =  parseFloat((this.grossValue / this.quantity).toFixed(2))
            this.taxAmount = parseFloat((this.grossValue - this.nettValue).toFixed(2));
        }
        else {
            this.grossUnitPrice = this.grossValue / this.quantity;
            this.nettValue = parseFloat(this.grossValue.toFixed(2))
            this.taxAmount = 0.00;
        }

        this.grossUnitPrice = this.grossUnitPrice.toFixed(2)
        this.nettValue = this.nettValue.toFixed(2)
        this.taxAmount = this.taxAmount.toFixed(2)
    }
}