
<div class="flex flex-auto">

    <ng-container>
        <app-source-avatar class="mr-4" [sourceId]="notification?.body?.sourceId"></app-source-avatar>
    </ng-container>

    <div class="flex flex-col flex-auto">

        <div class="font-semibold line-clamp-2 pr-2">
            {{'system_rezerwacji_online' | transloco}}
        </div>

        <div>
            <span [innerHTML]="'anulowano_rezerwacje_x_na_x_dni_x_przez_x' | transloco : {
                reservationNo: notification?.body?.data?.reservationNumber,
                arrivalDate: notification?.body?.data?.arrival,
                stayDays: countStay(notification?.body.data),
                clientFullName: notification?.body?.data?.forename + ' ' + notification?.body?.data?.name,
            }"></span>
        </div>

        <!-- {{notification | json}} -->
        <div class="mt-2 text-sm leading-none text-secondary">
            <date-formatter [date]="notification.addDate" [showTime]="true"></date-formatter>
        </div>
        
    </div>
</div>