import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ReservationRowComponent } from 'app/core/shared/components/reservation-row/reservation-row.component';
import { Utils } from 'app/core/shared/others/utils';
import { RoomsService } from 'app/core/shared/services/rooms.service';
import { DbRoom } from 'app/core/shared/models/DbRoom';
import { DbNotification } from 'app/core/shared/models/DbNotification';

@Component({
  selector: 'ical-notification-details',
  standalone: true,
  imports: [CommonModule, TranslocoModule, ReservationRowComponent],
  templateUrl: './ical-notification-details.component.html',
  styleUrl: './ical-notification-details.component.scss'
})
export class IcalNotificationDetailsComponent implements OnChanges {

  @Input() notification:DbNotification = null
  room:DbRoom = null

  constructor(private _roomsService:RoomsService) {

  }
  ngOnChanges(changes:SimpleChanges) {

    if(Utils.isDefined(this.notification,"body.roomId")) {
      this.room = this._roomsService.getById(this.notification.body.roomId)
    }
  }

}
