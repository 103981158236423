import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';
import { BehaviorSubject, take } from 'rxjs';
import { ApiService } from './api.service';
import { HandlingService } from './handling.service';
import { TranslocoService } from '@ngneat/transloco';
import { PanelService } from './panel.service';
import * as RecordRTC from 'recordrtc';

@Injectable({
  providedIn: 'root'
})
export class AudioRecordingService {

  public isRecording = new BehaviorSubject<boolean>(false)
  public isLoading = new BehaviorSubject<boolean>(false)
  private mediaRecorder: any;
  private audioChunks: any[] = [];
  private recordedAudioURL: string | null = null;

  mime = 'audio/wav'
  constructor(
    private toastService: ToastService,
    private apiService: ApiService,
    private handlingService: HandlingService,
    private panelService:PanelService,
  ) {

  }

  startRecording(): void {

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaRecorder = new RecordRTC(stream, {
          type: 'audio',
          mimeType: this.mime,
          numberOfAudioChannels: 1,
          bufferSize: 0,
          recorderType: RecordRTC.StereoAudioRecorder
        });

        this.mediaRecorder.startRecording();

        this.toastService.showToast({
          status: 'info',
          icon: 'microphone',
          title: 'Asystent głosowy',
          showDate: false,
          message: 'Powiedz: "Dodaj rezerwację od... do... na nazwisko... na pokój..."',
          timeToClose: 5000
        })

        this.isRecording.next(true)

      })
      .catch((error) => console.error('Error accessing microphone:', error));
  }

  async stopRecording() {

    this.isRecording.next(false)
    this.isLoading.next(true)
    new Promise(() => {
      this.mediaRecorder.stopRecording(() => {
        this.mediaRecorder.getDataURL((audioDataURL: string) => {
          this.recordedAudioURL = audioDataURL;
          this.apiService.transcribeAudio(this.dataURLtoBlob(audioDataURL)).pipe(take(1)).subscribe({
            next: (response)=> {
              if(response.status == 'success') {

                let roomId = null
                // const roomId = this._roomsService.findRoomByName(response.data.roomName)?.roomId || undefined;
                this.panelService.openCreateReservationPanel({
                  roomIdOrIds:roomId, 
                  startDate: response.data.arrival, 
                  endDate: response.data.departure, 
                  forename: response.data.forename,
                  name: response.data.name,
                  phone: response.data.phone,
                  email: response.data.email,
                  adults: response.data.adults,
                  children: response.data.children
                })
              } 
              else this.handlingService.handleErrorsInSnackbar(response)

              this.isLoading.next(false)
            },
            error: (err) => {
              this.handlingService.handleResponse(err)
              this.isLoading.next(false)
            }
          })
        });
      });
    });

  }

  getRecordedAudioURL(): Promise<string | null> {
    return new Promise((resolve) => {
      resolve(this.recordedAudioURL);
    });
  }

  clearRecording(): void {
    this.recordedAudioURL = null;
    this.audioChunks = [];
    this.mediaRecorder.clearRecordedData()
    this.isRecording.next(false)
  }


  private dataURLtoBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: this.mime});
  }
}