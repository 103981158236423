import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { api_v1, environment } from 'environments/environment';
import { SMTPAccount } from "../../models/smtp-account";
import { BehaviorSubject, take } from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class SMTPAccountsApiService {

    private smtpAccounts = new BehaviorSubject<SMTPAccount[]>([]);
    currentSMTPAccounts = this.smtpAccounts.asObservable();
    constructor(
        private _http: HttpClient,
      ) {
    
      }
    // SMTP
    // SMTP Accounts
    createSMTPAccount(requestData) {
        return this._http.post(api_v1 + 'smtp', requestData)
    }

    updateSMTPAccount(id, requestData) {
        return this._http.put(api_v1 + `smtp/${id}`, requestData)
    }

    getSMTPAccounts() {
        this._http.get(api_v1 + 'smtp')
        .pipe(take(1))
        .subscribe({
            next: (response: any) => this.smtpAccounts.next(response.data)
        })
    }

    deleteSMTPAccount(id: number) {
        return this._http.delete(api_v1 + `smtp/${id}`)
    }

    oAuth(data) {
        return this._http.post(api_v1 + `smtp/oauth`, data)
    }
}