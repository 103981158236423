import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { DbReservation } from '../../models/DbReservation';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HistoryMasterComponent } from '../history-master/history-master.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-reservation-history',
  templateUrl: './reservation-history.component.html',
  styleUrls: ['./reservation-history.component.scss'],
  imports: [CommonModule, TranslocoModule, MatIconModule, MatButtonModule, MatTooltipModule, HistoryMasterComponent, MatDialogModule]
})

export class ReservationHistoryComponent {

  reservation: DbReservation = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (typeof data.reservation != 'undefined') {
      this.reservation = data.reservation;
    }
  }
}


