import { Injectable, OnDestroy } from "@angular/core";
import { DbRate } from "../models/DbRate";
import { DbPricing } from "../models/DbPricing";
import { DbPricingModel } from "../models/DbPricingModel";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, take, tap, pipe } from "rxjs";
import { DbService } from "./db.service";
import { AppDB } from "../models/db";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { ApiService } from "./api.service";
import { DateTime } from "luxon";
import { Utils } from "../others/utils";
import { X } from "@angular/cdk/keycodes";

@Injectable({
    providedIn: 'root'
})
export class PricingService implements OnDestroy {

    private _subscriptions: Subscription[] = [];

    private _db: AppDB

    private _pricing = new BehaviorSubject<PricingObject>({})
    public currentPricing = this._pricing.asObservable()

    private _rates = new BehaviorSubject<RatesObject>({})
    public currentRates = this._rates.asObservable()

    private _pricingModel = new BehaviorSubject<PricingModelObject>({})
    public currentPricingModel = this._pricingModel.asObservable()

    rooms: any = []

    constructor(
        private _dbService: DbService,
        private _dataSynchronizerService: DataSynchronizerService,
        private _apiService: ApiService
    ) {

        this._subscriptions.push(
            this._dbService.getDatabase().subscribe({
                next: (data) => {
                    this._db = data
                }
            }))

        // Subscribe to changes in rates
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {

                    if (!data) return
                    if (data.table != 'rates') return

                    // Update
                    data.value.forEach((rate: DbRate) => {
                        this._rates.value[rate.rateId] = rate
                    });

                    // Filter by status
                    const filtered: RatesObject = Object.entries(this._rates.value)
                        .filter(([_, rate]) => rate.status === 1)
                        .reduce((acc, [key, value]) => {
                            acc[key] = value;
                            return acc;
                        }, {} as RatesObject);

                    // Push changes
                    this._rates.next(filtered)

                    this._dataSynchronizerService.received()
                }
            }))

        // Subscribe to changes in pricing
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {

                    if (!data) return
                    if (data.table != 'prices') return

                    // Update
                    data.value.forEach((pricing: DbPricing) => {
                        // During sync that prices of pricing is string type
                        // That data is not from database, in which is saved as valid object
                        if (typeof pricing.prices == 'string') {
                            pricing.prices = JSON.parse(pricing.prices)
                        }
                        this._pricing.value[pricing.pricingId] = pricing
                    });

                    // Push changes
                    this._pricing.next(this._pricing.value)
                    this._dataSynchronizerService.received()
                }
        }))

        // Subscribe to changes in pricing model
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {
                    if (!data) return
                    if (data.table != 'pricingModel') return

                    // filter by status
                    const filtered: PricingModelObject = Object.entries(
                        this.buildPricingModelObject(data.value, this._pricingModel.value)
                    )
                        .filter(([_, pricing]) => pricing.status === 1)
                        .reduce((acc, [key, value]) => {
                            acc[key] = value;
                            return acc;
                        }, {} as PricingModelObject);

                    this._pricingModel.next(this.buildPricingModelObject(data.value, filtered))

                    this._dataSynchronizerService.received()
                }
            }))


    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

    getPricing() {
        return this._pricing.value
    }

    getRateById(rateId) {
        const rate = Object.values(this._rates.value).find(x => x.rateId == rateId)
        if (rate) return rate
        else {
            console.warn(`Rate ${rateId} not found!`)
            return null
        }
    }

    async getPrices(channel:any) {

        if(!channel) return
        if(!channel.db) return
        const loader = this._db.transaction("r", this._db[channel.db], () => {
            return this._db[channel.db].toArray();
        }).then((result: DbPricing[]) => {
            const object: PricingObject = {};
            result.forEach(pricing => {
                object[pricing.pricingId] = pricing;
            });
            return object;
        });

        const pricing = await loader
        const prices = {}

        channel.rooms.forEach(room => {
            room.rates.forEach(rate => {
              const roomId = room.id
              const rateId = rate.rateId
      
              if (typeof prices[roomId] == 'undefined') {
                prices[roomId] = {}
              }
              if (typeof prices[roomId][rateId] == 'undefined') {
                prices[roomId][rateId] = {}
              }
      
              const pricingData = Object.values(pricing).filter(x => x.rateId == rateId && x.roomId == roomId)
              pricingData.forEach(pricingPerYear => {
      
                const year = pricingPerYear.year
                if (typeof prices[roomId][rateId][year] == 'undefined') {
                  prices[roomId][rateId][year] = {}
                }
      
                prices[roomId][rateId][year] = pricingPerYear
              });
      
            })
        })

        
        return {
            pricing: pricing,
            channel: channel,
            prices: prices
        }
    }

    getAll() {

        if(!this._db) return

        const s1 = new Date().getTime();
        const ratesLoader = this._db.transaction("r", this._db.rates, () => {
            return this._db.rates.where({ status: 1 }).toArray();
        }).then((result: DbRate[]) => {
            const object: RatesObject = {};
            result.forEach(rate => {
                object[rate.rateId] = rate;
            });
            return object;
        });

        const pricingLoader = this._db.transaction("r", this._db.prices, () => {
            return this._db.prices.toArray();
        }).then((result: DbPricing[]) => {
            const object: PricingObject = {};
            result.forEach(pricing => {
                object[pricing.pricingId] = pricing;
            });
            return object;
        });

        const pricingModelLoader = this._db.transaction("r", this._db.pricingModel, () => {
            return this._db.pricingModel.toArray();
            // return this._db.pricingModel.where({ status: 1 }).toArray();
        }).then((result: DbPricingModel[]) => {
            const object = this.buildPricingModelObject(result);
            return object;
        });

        return forkJoin([from(ratesLoader), from(pricingLoader), from(pricingModelLoader)]).pipe(
            map(([rates, pricing, pricingModel]) => {
                // Update your subjects
                this._rates.next(rates);
                this._pricing.next(pricing);
                this._pricingModel.next(pricingModel);

                return { rates, pricing, pricingModel };
            }),
            tap(() => console.log(`[Pricing Service]: Built pricing model in ${new Date().getTime() - s1}ms.`))
        );
    }

    buildPricingModelObject(result: DbPricingModel[], object: PricingModelObject = {}): PricingModelObject {

        for (let r = 0; r <= result.length - 1; r++) {

            const roomId = result[r].roomId
            const rateId = result[r].rateId

            if (typeof object[roomId] == 'undefined') {
                object[roomId] = {}
            }
            if (typeof object[roomId][rateId] == 'undefined') {
                object[roomId][rateId] = {}
            }
            object[roomId][rateId] = result[r]
        }

        return object
    }

    /**
     * Get total price of dates
     * @param roomId Room Id
     * @param from DateTime arrival
     * @param to DateTime departure
     * @returns 
     */
    getPricePerRoom(roomId: number, from:DateTime, to: DateTime, adults: number): {
        isSuccess: boolean,
        total: number,
        days: {date: string, price: number}[],
        adults: number
    } {

        const standardRate = Object.values(this._rates.value).find(x => x.name == 'Standard')
        if(!standardRate) {
            return {
                isSuccess: false,
                total: 0,
                days: [],
                adults
            }
        }
        
        let success = true
        const dates: DateTime[] = []
        let current = from;

        while (current < to) {
            dates.push(current);
            current = current.plus({ days: 1 });
        }

        const roomPricing = Object.values(this.getPricing())
        const prices = roomPricing
        const datePrices:any = []
        
        let rate = null

        const firstFoundPrice =  prices.find(x => x.roomId == roomId && x.rateId == standardRate.rateId)
        if(!firstFoundPrice) {
            return {
                isSuccess: false,
                total: 0,
                days: dates.map(x=> { return { date: x.toISODate(), price: 0}}),
                adults
            }
        }

        if(firstFoundPrice) rate = this._rates.value[firstFoundPrice.rateId]

        if(!rate) {
            return {
                isSuccess: false,
                total: 0,
                days: dates.map(x=> { return { date: x.toISODate(), price: 0}}),
                adults
            }
        }

        const pricingModel = this._pricingModel.value?.[roomId]?.[rate.rateId]

        let total = 0

        for(let i=0; i< dates.length; i++) {
            const date = dates[i]

            const datePricing:any = prices.find(x => x.year == date.year && x.roomId == roomId && x.rateId == rate.rateId)
            const dateISO = date.toISODate()

            if(!Utils.isDefined(datePricing, `prices.${dateISO}`)) {
                success = false
                datePrices.push({date: dateISO, price: 0})
                continue
            }

            const dayPrice = datePricing.prices?.[dateISO]

            if(!Utils.isDefined(dayPrice,"price")) {
                success = false
                datePrices.push({date: dateISO, price: 0})
                continue
            }

            let price = parseFloat(dayPrice.price)
            if(Utils.isDefined(pricingModel, "options.leadingOccupancy")) {
                price = Utils.countPricePerPerson(pricingModel, dayPrice, adults)
            }

            if(price == 0) {
                success = false
                datePrices.push({date: dateISO, price: 0})
                continue
            }

            datePrices.push({date: dateISO, price: price})
            total += parseFloat(price.toString())

        }

        return {
            isSuccess: success,
            total: total,
            days: datePrices,
            adults: adults
        }
    }
    // For reservation form
    // Calculate price for specific room, in specific dates range
    // calculatePricing(roomId, from: Date, to: Date) {

    //     console.log("GET PRICING FOR: ", roomId, from.toDateString(), to.toDateString())
    //     // this.getPricingData()

    //     let sumPrice = 0
    //     let dayPrices = []

    //     const dates = this.createDates(from, to)
    //     for (let r = 0; r < Object.keys(this.rates).length; r++) {

    //         let hasModel = false;
    //         let pricingModel = null
    //         let priceForDay = null;

    //         const room = this.rooms[roomId]
    //         const rate = <any>Object.values(this.rates)[r]

    //         for (let d = 0; d < dates.length; d++) {

    //             const date = dates[d];
    //             if (typeof room.rates[rate.rateId] != 'undefined') {
    //                 const roomPricing = room.rates[rate.rateId];


    //                 if (typeof roomPricing != 'undefined') { priceForDay = roomPricing[date] }
    //                 // Check if has model
    //                 if (typeof this.pricingModel[roomId] != 'undefined') {
    //                     if (typeof this.pricingModel[roomId][rate.rateId] != 'undefined') {
    //                         pricingModel = this.pricingModel[roomId][rate.rateId]
    //                         hasModel = true
    //                     }
    //                 }
    //             }
    //             // With price model
    //             if (hasModel) {
    //                 let price = Utils.countPricePerPerson(pricingModel, priceForDay, pricingModel.options.leading_occupancy)
    //                 // Numer of ppl
    //                 for (let i = 0; i <= pricingModel.options.persons.length - 1; i++) {
    //                     let price = Utils.countPricePerPerson(pricingModel, priceForDay, i)
    //                     console.log("i: ", price)
    //                 }
    //                 console.log(price)
    //             }
    //             // No price model
    //             else {
    //                 // Price
    //                 let price = 0;
    //                 // Has price
    //                 if (priceForDay && typeof priceForDay.price != 'undefined') {
    //                     price = Math.round(priceForDay.price)
    //                 }
    //                 console.log(price)
    //             }
    //         }
    //     }
    // }

    private createDates(from: Date, to: Date) {

        if (from > to) {
            throw new Error("'from' date must be before or equal to 'to' date");
        }

        const dateArray = [];
        let currentDate = new Date(from);

        while (currentDate <= to) {
            const dateString = currentDate.toISOString().split('T')[0];
            dateArray.push(dateString);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    }
}

export interface PricingObject {
    [key: number]: DbPricing;
}
export interface RatesObject {
    [key: number]: DbRate;
}
export interface PricingModelObject {
    [key: number]: DbPricingModel | any;
}
