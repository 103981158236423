<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            <!-- create -->
            <p class="text-lg font-medium">
                {{'nowe_zmiany' | transloco}}({{dataChangeTrackingService?.changes?.length}})
            </p>
            <div class="ml-auto">
                <!-- close -->
                <button mat-icon-button (click)="close()">
                    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
    </div><!-- end p-6 -->
    <div class="px-6">
        <button mat-flat-button (click)="clearList()">{{'wyczysc_liste' | transloco}}</button>
    </div>
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="px-6 py-4">
                <ng-container *ngFor="let reservation of dataChangeTrackingService.changes | orderBy: 'editDate': 'desc'">
                    
                    <span *ngIf="reservation.status == 0" class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50 mt-3 mb-2">
                        <span class="leading-relaxed whitespace-nowrap">
                            {{'anulowana_rezerwacja' | transloco}}
                        </span>
                    </span>

                    <span *ngIf="reservation.status == 1 && reservation.addDate == reservation.editDate" class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50 mt-3 mb-2">
                        <span class="leading-relaxed whitespace-nowrap">
                            {{'nowa_rezerwacja' | transloco}}
                        </span>
                    </span>

                    <span *ngIf="reservation.status == 1 && reservation.addDate != reservation.editDate" class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-yellow-200 text-yellow-800 dark:bg-yellow-600 dark:text-yellow-50 mt-3 mb-2">
                        <span class="leading-relaxed whitespace-nowrap">
                            {{'edytowana_rezerwacja' | transloco}}
                        </span>
                    </span>

                    <app-reservation-row [reservation]="reservation" [showMoreButton]="true"></app-reservation-row>

                </ng-container>
            </div>
        </div>
    </div>
</div>