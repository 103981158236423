import { Injectable } from "@angular/core";
import { ApplicationData } from "../data/application-data";
import { DbReservation } from "../models/DbReservation";
import { DbRoom } from "../models/DbRoom";
import { Utils } from "../others/utils";
import { RoomsService } from "./rooms.service";

@Injectable({
    providedIn: 'root'
})

export class AvailabilityService {

    
    constructor(
        private _roomsService: RoomsService
    ) {

    }

    getAvailability(checkIn: Date | any, checkOut: Date | any, onlySizes: any[], onlyTypes: any[], exludedReservationIds: number[] = []): Availability {
        
        let checkInAt = null;
        let checkOutAt = null;  
        if(checkIn != null && checkOut != null) {
            if(checkIn?.isLuxonDateTime) checkIn = new Date(
                checkIn.c.year,
                checkIn.c.month - 1,
                checkIn.c.day,
                0,0,0,0
            )
    
            if(checkOut?.isLuxonDateTime) checkOut = new Date(
                checkOut.c.year,
                checkOut.c.month - 1,
                checkOut.c.day,
                0,0,0,0
            )
    
            if(!(checkIn instanceof Date)) {
                console.error('[Availability Service]: Checkin is not date.')
                return
            }
            if(!(checkOut instanceof Date)) {
                console.error('[Availability Service]: Checkout is not date.')
                return
            }
            var checkInTimezoneOffset = checkIn.getTimezoneOffset() * 60000;
            checkInAt = new Date(checkIn.getTime() - checkInTimezoneOffset);
    
            var checkOutTimezoneOffset = checkOut.getTimezoneOffset() * 60000;
            checkOutAt = new Date(checkOut.getTime() - checkOutTimezoneOffset);
        } else {
            console.log("[AvailabilityService]: Gettings rooms without availability check.")
        }
        

        const availableRooms: DbRoom[] = [];

        // Check each room availability
        Object.entries(this._roomsService.getRooms())
            .filter((room: [string, DbRoom]) => 
                room[1].status != 0
            )
            .forEach((room: [string, DbRoom]) => {

                if (typeof room[1].reservations == 'undefined') room[1].reservations = [];

                const reservations = room[1].reservations.filter((reservation: DbReservation) => !exludedReservationIds.includes(reservation.reservationId));

                let available = true

                if(checkInAt != null && checkOutAt != null) {
                    available = this.isReservationAvailable(reservations, checkInAt, checkOutAt)
                }

                if (available) {

                    let matchesSize = true;
                    let matchesType = true;

                    if (Array.isArray(onlySizes) && onlySizes.length > 0 && !onlySizes.includes(room[1].persons) && !onlySizes.includes('')) matchesSize = false
                    if (Array.isArray(onlyTypes) && onlyTypes.length > 0 && !onlyTypes.includes(room[1].roomType) && !onlyTypes.includes('')) matchesType = false

                    if (matchesSize && matchesType) {
                        availableRooms.push(room[1]);
                    }

                }
            })
        
        // Sort rooms
        availableRooms.sort((a: any, b: any) => a.order - b.order)
        return {
            checkIn: checkIn,
            checkOut: checkOut,
            availableRooms: availableRooms
        };
    }

    isReservationAvailable(reservations, s: Date, e: Date) {

        if (typeof reservations == 'undefined') { return true; }
        if (reservations.length === 0) { return true; }

        s = Utils.toDate(s);
        e = Utils.toDate(e);

        let start = s.getTime()
        let end = e.getTime()

        for (let i = 0; i < reservations.length; i++) {
            const reservation = reservations[i];

            let rStart = new Date(reservation.arrival).getTime()
            let rEnd = new Date(reservation.departure).getTime()
            if (
                (start >= rStart && start < rEnd) ||
                (end > rStart && end <= rEnd) ||
                (start < rStart && end > rEnd)
            ) {
                return false;
            }
        }
        // Jeśli nie ma kolizji z żadną z istniejących rezerwacji, zwracamy true
        return true;
    }
}

type Availability = {
    checkIn: Date,
    checkOut: Date
    availableRooms: DbRoom[]
}