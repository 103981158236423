<h2 mat-dialog-title>
    {{'link_do_platnosci' | transloco}} #{{reservation?.reservationNo}}
    <button class="mt-2 float-end" mat-dialog-close mat-icon-button matTooltip="{{'zamknij_okno' | transloco}}">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-12 mb-1">
            <mat-label>Link jest ważny do:</mat-label>
        </div>
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Wybierz datę</mat-label>
                <input matInput value="20 LIP 2023, tutaj może brać automatycznie z terminu zaliczki">
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>{{'rodzaj_platnosci' | transloco}}</mat-label>
                <mat-select matInput value="0">
                    <mat-option value="0">
                        {{'przedplata' | transloco}} (500 PLN)
                    </mat-option>
                    <mat-option value="1">
                        {{'calosc_kwoty' | transloco}}
                    </mat-option>
                    <mat-option value="2">
                        Daj wybór ?lang
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>{{'dodatkowe_informacje' | transloco}}</mat-label>
                <textarea matInput></textarea>
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-checkbox color="primary">Automatycznie anuluj rezerwację, jeżeli nie zostanie opłacona do ...</mat-checkbox>
        </div>
    </div>
   

    <div class="mb-3"></div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="bg-gray-50">
    <button mat-stroked-button mat-dialog-close class="mc-button">{{'zamknij_okno' | transloco}}</button>
    <button mat-raised-button color="primary" class="mc-button">{{'generuj_link' | transloco}}</button>
</mat-dialog-actions>