<div class="relative flex flex-col w-full max-w-180 min-h-full md:min-h-auto min-w-180 max-h-full sm:max-h-screen overflow-hidden">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'przelicz_ceny' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" (click)="close()">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto mb-18 p-6 overflow-y-auto">

            <div class="prose prose-sm">
                <div class="flex items-center">
                    <mat-checkbox color="primary" [formControl]="fields.pricePerRoom.checkboxControl"></mat-checkbox>
                    <div>{{'cena_za_pokoj' | transloco}}: </div>
                    <div class="pl-2 font-semibold">{{fields.pricePerRoom.valueControl.value}} {{currency}}</div>  
                </div>
            </div>

            <div class="prose prose-sm">
                <div class="flex items-center">
                    <mat-checkbox color="primary" [formControl]="fields.pricePerMeal.checkboxControl"></mat-checkbox>
                    <div>{{'cena_za_wyzywienie' | transloco}}: </div>
                    <div class="pl-2 font-semibold">{{fields.pricePerMeal.valueControl.value}} {{currency}}</div>  
                </div>
            </div>

            <div class="prose prose-sm" *ngFor="let additionalService of additionalServicesPriceCalculation.additionalServices">
                <div class="flex items-center">
                    <mat-checkbox color="primary" [(ngModel)]="additionalService.enabled"></mat-checkbox>
                    <div>{{additionalService.displayName | transloco}}: </div>
                    <div class="pl-2 font-semibold">{{additionalService.price}} {{currency}}</div>  
                </div>
            </div>
        </div>
    </div>

    <!--Footer-->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-stroked-button (click)="close()">{{'zamknij' | transloco}}</button>
                <button mat-flat-button color="primary" class="ml-2" (click)="save()">{{'zastosuj_zmiany' | transloco}}</button>
            </div>
        </div>
    </div>
    
</div>