<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            <!-- create -->
            <ng-container>
                <p class="text-lg font-medium">
                    Log #6728920
                </p>
                <div class="ml-auto">
                    <!-- close -->
                    <button mat-icon-button [matTooltip]="'Close'" (click)="close()">
                        <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
    </div><!-- end p-6 -->
    <div class="relative flex flex-col items-center">
        <!-- flex-auto -->
        <div class="w-full max-w-3xl">

            <!-- tabs -->
            <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex" (selectedIndexChange)="changeTab($event)"
                class="vertically-tabs p-0-content" mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight
                dynamicWidth>
                <mat-tab>
                    <ng-template matTabLabel>
                        {{'zadanie_request' | transloco}}
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template matTabLabel>
                        {{'odpowiedz' | transloco}}
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

        </div>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <ng-container>
                <!-- body -->
                <div class="px-6 py-4">
                    <ng-container *ngIf="tabIndex === 0">
                        <ng-container *ngTemplateOutlet="request"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="tabIndex === 1">
                        <ng-container *ngTemplateOutlet="response"></ng-container>
                    </ng-container>
                </div>
                <!-- footer -->
                <div class="sm:absolute sm:inset-x-0 sm:bottom-0 sm:border-b-0 z-10">
                    <div class="bg-gray-50 dark:bg-transparent pl-1 py-4 pr-4 sm:pr-8 sm:pl-7 border-b sm:border-t">
                        <div class="flex items-center">
                            <button mat-button (click)="close()" class="mc-button ml-auto">{{'zamknij' |
                                transloco}}</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<ng-template #request>
    <div class="prose">
        
        <span class="inline-flex max-w-26 items-center font-bold text-xs px-2.5 py-0.5 rounded-full uppercase bg-gray-200 dark:bg-gray-600 dark:text-gray-50 text-gray-800">
            Availability
        </span>
        <h3 class="mt-0">Wysłano dostępność do <b>booking.com</b></h3>
        <textarea fuse-highlight [lang]="'json'">{{obj1 | json}}</textarea>
    </div>
</ng-template>
<ng-template #response>
    <div class="prose">
        <!-- <span class="inline-flex max-w-26 items-center font-bold text-xs px-2.5 py-0.5 rounded-full uppercase bg-green-200 dark:bg-green-600 dark:text-green-50 text-green-800">
            HTTP 200
        </span> -->
        <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full uppercase bg-red-200 dark:bg-red-600 dark:text-red-50 text-red-800">
            HTTP 401 Authorization required
        </span>
        <h3 class="mt-0">Wystąpił błąd</h3>
        <mat-form-field class="w-full mt-4" [subscriptSizing]="'dynamic'">
            <mat-label>RUID</mat-label>
            <input matInput type="text" readonly value="2693b6f0-f75c-4f8b-874e-366fb5e553e7"/>
            <button mat-icon-button matSuffix>
                <mat-icon [svgIcon]="'heroicons_solid:document-duplicate'"></mat-icon>
            </button>
        </mat-form-field>
        <textarea fuse-highlight [lang]="'json'">{{obj2 | json}}</textarea>
    </div>
</ng-template>