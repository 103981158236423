import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DbNotification } from 'app/core/shared/models/DbNotification';

@Component({
  selector: 'channel-manager-import-notification-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './channel-manager-import-notification-details.component.html',
  styleUrl: './channel-manager-import-notification-details.component.scss'
})
export class ChannelManagerImportNotificationDetailsComponent {
  @Input() notification:DbNotification = null
}
