import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { UserSettings } from '../models/UserSettings';
import { AppDB } from '../models/db';
import { Utils } from '../others/utils';
import { ApiService } from './api.service';
import { Settings } from '../models/Settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public userSettings = new BehaviorSubject<UserSettings>(<UserSettings> null);
  public currentUserSettings = this.userSettings.asObservable()

  constructor() 
  {
    this.userSettings.next(null);
  }

  /*
  * Zapisuje ustawienia usera po inicjalizacji aplikacji
  */
  updateUserSettings(userSettings:UserSettings): void {

    userSettings.connectedAccounts.forEach((account) => {

      let db = new AppDB(account.userId, null)

      account.isOfflineModeAvailable = false
      db.transaction("r", db.userSettings, async () => {
        const availableUserSettings = await db.userSettings.toArray()
        if(!availableUserSettings) return
        const settings = availableUserSettings.find(x=>x.userData.userId == account.userId)
        if(!settings) return
        account.isOfflineModeAvailable = true
      }).finally(() => {
        db.close()
      })

    })

    this.userSettings.next(UserSettings.from(userSettings));
  }

  getPremiumDays() {
    return Utils.checkPremiumDays(this.userSettings.value.userData.premiumTime)
  }

  updateUserSettingsAfterChange(userSettings:UserSettings)
  {
    this.userSettings.next(UserSettings.from(userSettings))
    // this.databaseService.saveUserSettings(userSettings)
  }

  setFields(path,value)
  {
    Utils.setNestedPropertyValue(
      this.userSettings.value.settings,
      path,
      value
    )
    return this.userSettings.value.settings;
  }

  setSetting(path,value)
  {
    Utils.setNestedPropertyValue(
      this.userSettings.value.settings,
      path,
      value
    )
    this.userSettings.next(this.userSettings.value)
  }

  // Refresh settings
  // If user change settings and error occured refresh setting to show
  // that value is not changed.
  refreshSettings()
  {
    this.userSettings.next(this.userSettings.value)
  }
  /*
  * Pobiera ustawienia w aplikacji
  */
  getUserSettings(): Observable<UserSettings> {
    return this.userSettings;
  }

  getSettings(): Observable<Settings> {
    return this.userSettings.pipe(
    map(userSettings => userSettings.settings)
  );
  }
  /*
  * Aktualizuje ustawienia z zakładki "Ustawienia"
  */
  updateSettings(settings): void {
    this.userSettings.value.settings = settings;
    this.userSettings.next(this.userSettings.value);
  }
  /*
   *  Usuwa obiekt po wylogowaniu 
  */
  destroy(): void {
    this.userSettings.next(null);
  }

 
}


