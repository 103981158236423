import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumExtendComponent } from 'app/modules/admin/subscription/premium-extend/premium-extend.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Utils } from '../../others/utils';

@Component({
  selector: 'app-premium-extend-dialog',
  standalone: true,
  imports: [CommonModule, PremiumExtendComponent, MatIconModule, MatButtonModule, MatDialogModule, TranslocoModule],
  templateUrl: './premium-extend-dialog.component.html',
  styleUrl: './premium-extend-dialog.component.scss'
})
export class PremiumExtendDialogComponent {

  reason: 'PRO_REQUIRED' | 'ROOM_LIMIT_EXCEED' = 'PRO_REQUIRED'

  constructor(
    private _matDialog: MatDialogRef<PremiumExtendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  {

    if(Utils.isDefined(data, "reason")) {
      this.reason = data.reason
    }

  }

  close() {
    this._matDialog.close()
  }
  
}
