import { Injectable, inject } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { LocalizeRouterService } from "@penleychan/ngx-transloco-router";
import { Utils } from "app/core/shared/others/utils";
import { SettingsService } from "app/core/shared/services/settings.service";
import { map, tap } from "rxjs";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: 'root'
})
export class ConfiguredAccountGuard implements CanActivate {


  constructor(
    private settingsService: SettingsService,
    private localize: LocalizeRouterService,
    private router: Router,
    private _authService:AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this._authService.isLoggedIn()) {
      return false;
    }

    // const portal = route.data['portal'];
    if (Utils.isDefined(this.settingsService.userSettings.value, "userData.roomIndex")) {
      const roomIndex = this.settingsService.userSettings.value.userData.roomIndex;

      if(roomIndex == 0) {
        console.log('[ConfiguredAccountGuard]: Redirecting...')
        const translatedPath = this.localize.translateRoute('/register-wizard');
        this.router.navigate([translatedPath]);
        return true;
      }
      // if (userPortal == 1 && portal != 'housekeeper') {
      //   console.log('[PortalGuard]: Redirecting...')
      //   const translatedPath = this.localize.translateRoute('/housekeeper');
      //   this.router.navigate([translatedPath]);
      //   return true;
      // }
      // else if (userPortal == 0 && portal != 'app') {
      //   console.log('[PortalGuard]: Redirecting...')
      //   const translatedPath = this.localize.translateRoute('/app');
      //   this.router.navigate([translatedPath]);
      //   return true;
      // }
    }

  }
}