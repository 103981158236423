import { ApplicationRef, ChangeDetectorRef, ComponentFactoryResolver, Injectable, Injector, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { DialogService } from "./dialog.service";
import { TranslocoService } from "@ngneat/transloco";
import jsPDF from 'jspdf';
import { PDFDocument } from "pdf-lib";
import { InterFont } from "assets/fonts/inter/inter";
import { InvoicesService } from "./invoices.service";
import { DbInvoice } from "../models/DbInvoice";
import { InvoicePreviewComponent } from "../components/invoice-preview/invoice-preview.component";
import { LoaderDialogService } from "./loader-dialog.service";
import { Utils } from "../others/utils";
import { SettingsService } from "./settings.service";
import { UserSettings } from "../models/UserSettings";

@Injectable({
    providedIn: 'root'
})
export class InvoiceGeneratorService implements OnDestroy {


    private _subscriptions: Subscription[] = [];

    userSettings: UserSettings
    font = InterFont.Data;

    constructor(
        private _dialogService: DialogService,
        private _translate: TranslocoService,
        private _invoicesService: InvoicesService,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _injector: Injector,
        private _appRef: ApplicationRef,
        private _loaderDialogService:LoaderDialogService,
        private _settingsService: SettingsService
    ) {

        this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
            next: (userSettings: UserSettings) => {
                this.userSettings = userSettings
            }
        }))
    }
  
    async mergePdfs(pdfs) {
        const mergedPdf = await PDFDocument.create();

        for (const pdfBytes of pdfs) {
            await PDFDocument.create()
            const pdf = await PDFDocument.load(pdfBytes);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => {
                mergedPdf.addPage(page);
            });
        }

        const mergedPdfBytes = await mergedPdf.save();
        return mergedPdfBytes;
    }

    private _checkInvoiceSellerFromOlderVersion(invoice) {
        
        if(invoice == null) return 
    
        // Only if the invoice has not companyNameSeller provided
        if(Utils.isNullOrEmpty(invoice.companyNameSeller)) {
        invoice.companyNameSeller = this.userSettings.userData.companyName

            if(Utils.isNullOrEmpty(invoice.addressSeller)) {
                invoice.addressSeller = `${this.userSettings.userData.street} ${this.userSettings.userData.postalCode} ${this.userSettings.userData.city}`
            }
        
            if(Utils.isNullOrEmpty(invoice.taxIdSeller)) {
                invoice.taxIdSeller = this.userSettings.userData.taxId
            }
        
            if(Utils.isNullOrEmpty(invoice.bankNoSeller)) {
                invoice.bankNoSeller = this.userSettings.userData.bankAccountNo
            }

        }

        return invoice
    }

    async preparePdfByInvoiceId(id: number, componentRef,  cdr:ChangeDetectorRef, resultType: 'arraybuffer' | 'jsPDF'): Promise<ArrayBuffer | jsPDF> {
           
        const invoiceContentElement = document.createElement('div');
        invoiceContentElement.style.width = '960px';

        let invoice: DbInvoice = await this._invoicesService.getById(id);
        if (!invoice) return null;
        
        invoice = this._checkInvoiceSellerFromOlderVersion(invoice)
        // Update instance property with new invoice data
        componentRef.instance.invoice = invoice;
        cdr.detectChanges();
    
        // Clear previous content and append updated component
        while (invoiceContentElement.firstChild) {
            invoiceContentElement.removeChild(invoiceContentElement.firstChild);
        }

        // Delay to ensure the content is fully loaded
        await new Promise(resolve => setTimeout(resolve, 1));
            invoiceContentElement.appendChild(componentRef.location.nativeElement);
    
        // Ensure images are loaded
        await this._waitForImagesToLoad(invoiceContentElement);
    
        const pdf = this._createJsPDF()

        // Convert HTML to PDF
        await new Promise((resolve, reject) => {

            pdf.html(invoiceContentElement, {
                callback: () => {
                    resolve(true);
                },
                image: { quality: 2, type: 'png' },
                autoPaging: 'text',
                html2canvas: {
                    scale: 0.42,
                    logging: false,
                    useCORS: true, // Enable this if your content contains cross-origin images
                },
                x: 10,
                y: 10,
                margin: 0,
            });
        });
    
        if(resultType == 'arraybuffer') return pdf.output('arraybuffer')
        else return pdf
    }

    private async _waitForImagesToLoad(container: HTMLElement): Promise<void> {
        const images = Array.from(container.querySelectorAll('img'));
        await Promise.all(images.map(img => {
            return new Promise<void>((resolve, reject) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = () => resolve();
                    img.onerror = () => reject(new Error('Image failed to load'));
                }
            });
        }));
    }

    async processInvoices(ids: number[], cdr, resultType: 'arraybuffer' | 'jsPDF' ): Promise<ArrayBuffer[] | jsPDF>{
        const results = [];
    
        // Create a single component reference and a single invoiceContentElement
        const componentRef = this._componentFactoryResolver
            .resolveComponentFactory(InvoicePreviewComponent)
            .create(this._injector);
 
    
        this._appRef.attachView(componentRef.hostView);
    
    
        let i = 1
        for (const id of ids) {
            this._loaderDialogService.title.next(`${this._translate.translate('generowanie_faktur')} ${i}/${ids.length}`)
            const result = await this.preparePdfByInvoiceId(id, componentRef, cdr, resultType);
            results.push(result);
    
            // Force garbage collection
            if (typeof globalThis.gc === 'function') {
                globalThis.gc();
            }
            i++
        }
    
        // Cleanup
        this._appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    
        if(resultType == 'arraybuffer') return results
        else return results[0]
    }

    private _createJsPDF(): jsPDF {
        const pdf = new jsPDF('p', 'px', 'A4', true);
        pdf.addFileToVFS('Inter var.ttf', this.font);
        pdf.addFont('Inter var.ttf', 'Inter var', 'normal');
        pdf.setFont('Inter var', 'normal');

        return pdf
    }

    async printMany(cdr, ids: number[]) {

        this._dialogService.showLoaderDialog(
            this._translate.translate('generowanie_faktur'),
            this._translate.translate('przygotowujemy_faktury_info'),
        )

        this.processInvoices(ids,cdr,'arraybuffer').then(async results=> {
            let mergedPdfBytes = await this.mergePdfs(results);
            setTimeout(() => {
                const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
                const blobURL = URL.createObjectURL(blob);
                window.open(blobURL, "_blank");
    
                mergedPdfBytes = null
                this._dialogService.hideLoaderDialog()
    
            }, 1);
        })
        
    }

    async createJsPDFByInvoiceId(cdr, id): Promise<jsPDF> {

        setTimeout(() => {
            this._dialogService.showLoaderDialog(
                this._translate.translate('generowanie_faktury'),
                this._translate.translate('przygotowujemy_fakture'),
            )
        })

        const jspdf = await this.processInvoices([id], cdr, 'jsPDF')

        setTimeout(() => {
            this._dialogService.hideLoaderDialog()
        })

        return jspdf as jsPDF;

    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

}