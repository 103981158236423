import { Injectable, OnDestroy } from "@angular/core";
import { DbAdditionalService } from "../models/DbAdditionalService";
import { BehaviorSubject, Observable, Subscription, from, of } from "rxjs";
import { AppDB } from "../models/db";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { DbService } from "./db.service";
import { cloneDeep } from "lodash-es";
import { Utils } from "../others/utils";

@Injectable({
  providedIn: 'root'
})
export class AdditionalServicesService implements OnDestroy {

  private _subscriptions: Subscription[] = [];

  private _additionalServices = new BehaviorSubject<DbAdditionalService[]>([])
  public currentAdditionalServices: Observable<DbAdditionalService[]> = this._additionalServices.asObservable()
  private _db: AppDB

  private _counter = new BehaviorSubject<DbAdditionalServicesCounter>({
    valid: 0,
    deleted: 0
  })

  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService
  ) {

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
        }
      })
    )

    // Add currencies on synchronize
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
        next: (data) => {
          if (!data) return
          if (data.table != 'additionalServices') return

          data.value = data.value.map(service => new DbAdditionalService(service))
          // Add or update
          data.value.forEach((service: DbAdditionalService) => {
            const index = this._additionalServices.value.findIndex(x => x.additionalServiceId === service.additionalServiceId)
            if (index >= 0) this._additionalServices.value[index] = service
            else this._additionalServices.value.push(service)
          });
          
          this._countAll(this._additionalServices.value)
          this._additionalServices.next(this._additionalServices.value)
          this._dataSynchronizerService.received()
        }
      }))

      this._subscriptions.push(this.currentAdditionalServices.subscribe({
        next: (data) => {
          this._countAll(data)
        }
      }))
  }

  public browse({ allResults = [], filters = [] }): PaginatedDbAdditionalServices {
    let t1 = new Date().getTime();

   
    let searchingResults = allResults.length > 0 ? allResults : this._additionalServices.value
      .sort((a: DbAdditionalService, b: DbAdditionalService) => b.additionalServiceId - a.additionalServiceId)

    if (filters.length > 0) {
      filters.forEach(filter => {
        searchingResults = searchingResults.filter(filter)
      })
    }
  
    const paginationResult: PaginatedDbAdditionalServices = {
      time: new Date().getTime() - t1,
      resultsCount: searchingResults.length,
      allResults: searchingResults
    }

    return paginationResult

  }

  public getCounter(): Observable<DbAdditionalServicesCounter> {
    return this._counter.asObservable()
  }

  getAdditionalServices():DbAdditionalService[] {
    return this._additionalServices.value
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  getPrices({
    days,
    adults,
    children,
    additionalServices
  }: {days: number, adults: number, children: number, additionalServices: any}): {
    isSuccess: boolean,
    total: number,
    additionalServices: any
  } {
    let isSuccess = true

    let total = 0;
    let calculatedAdditionalServices = []

    cloneDeep(additionalServices).forEach(service => {
        const dbService:DbAdditionalService = this.getById(service.additionalServiceId)

        let price = 0

        // Za osobę
        if(dbService.type == 1) {
          price = (parseFloat(dbService.priceAdult.toString()) * adults) + (parseFloat(dbService.priceChildren.toString()) * children)
        }
        // Lub
        // za usluge
        else if(dbService.type == 0) {
          price = parseFloat(dbService.priceAdult.toString())
        }

        // Czy jest za dobę = jak za pobyt nie trzeba mnożyć
        if(dbService.period == 0) {
          price = price * days 
        } 

        service.price = Utils.toDecimal(price)
        calculatedAdditionalServices.push(service)

        total += parseFloat(price.toString())
    });

    return {
      isSuccess: isSuccess,
      total: Utils.toDecimal(total),
      additionalServices: calculatedAdditionalServices
    }
  }

  getAll(): Observable<DbAdditionalService[]> {
    
    this._additionalServices.next([])
    if(!this._db) return of([])

    return from(
      this._db.transaction("r", this._db.additionalServices, () => {
        return this._db.additionalServices.toArray();
      })
        .then((result: DbAdditionalService[]) => {
          // Additional service name can be JSON
          // So it needs to be maped using constructor
          result = result.map(service => new DbAdditionalService(service))
          this._additionalServices.next(result)
          return result;
        })
    );
  }

  getById(additionalServiceId): DbAdditionalService {
    const additionalService = this._additionalServices.value.find(x => x.additionalServiceId == additionalServiceId)
    if (additionalService) return additionalService
    else console.warn(`[AditionalServciesService]: Additional service with id: ${additionalServiceId} not found.`)
  }

  private _countAll(data) {

    let valid = 0
    let deleted = 0

    Object.values(data).forEach((additionalService: DbAdditionalService) => {
      if (additionalService.status == 1) valid++
      else deleted++
    })

    this._counter.next({ valid, deleted })
  }
}

export interface DbAdditionalServicesCounter {
  valid: number,
  deleted: number
}

export interface PaginatedDbAdditionalServices {
  allResults: DbAdditionalService[]
  resultsCount: number
  time: number,
}