import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription, finalize, take } from 'rxjs';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ApplicationData } from '../../data/application-data';
import { UserPermissions } from '../../models/UserPermissions';
import { DATA_INJECTION_TOKEN } from '../../others/data-injection-token';
import { Utils } from '../../others/utils';
import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { HandlingService } from '../../services/handling.service';
import { PanelService } from '../../services/panel.service';
import { PermissionsService } from '../../services/permissions.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SynchronizationService } from '../../services/synchronization.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxColorsModule } from 'ngx-colors';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { MatListModule } from '@angular/material/list';
import { FuseAlertComponent } from '@fuse/components/alert';
import { DataSynchronizerService } from '../../services/data-synchronizer.service';
import { DataChangeTrackingService } from '../../services/data-change-tracking.service';
import { RoomsService } from '../../services/rooms.service';
import { LightStyles } from '../../data/light-styles';
import { cloneDeep } from 'lodash-es';


@Component({
  standalone: true,
  selector: 'app-create-or-update-room-dialog',
  templateUrl: './create-or-update-room-dialog.component.html',
  styleUrls: ['./create-or-update-room-dialog.component.scss'],
  imports: [CommonModule, DragDropModule, MatIconModule, ReactiveFormsModule, ReactiveFormsModule, MatTooltipModule, MatButtonModule, MatSlideToggleModule, MatTabsModule, MatSelectModule, TranslocoModule, MatFormFieldModule, MatInputModule, NgxColorsModule, MatCheckboxModule, QuillModule, QuillEditorComponent, MatListModule, FuseAlertComponent]
})
export class CreateOrUpdateRoomDialogComponent implements OnInit, OnDestroy {

  isProcessing = false;
  subscriptions: Subscription[] = [];
  mode: 'create' | 'update' = 'create';

  // Picker
  colorPickerControls: "default" | "only-alpha" | "no-alpha" = "no-alpha";

  roomEquipment = ApplicationData.RoomEquipment;
  filteredRoomEquipment = ApplicationData.RoomEquipment;

  languages = ApplicationData.Languages;
  roomTypes = ApplicationData.RoomTypes;
  roomStatuses = ApplicationData.CleaningStatuses
  description: any = {};
  createOrUpdateRoomForm = new FormGroup({
    roomId: new FormControl<number>(null),
    showOtherLanguages: new FormControl(false),
    name: new FormControl('', [Validators.required]),
    language: new FormControl('default'),
    descriptionTemp: new FormControl(''),
    info: new FormControl(''),
    roomType: new FormControl(1),
    order: new FormControl('0'),
    singleBed: new FormControl(0),
    doubleBed: new FormControl(0),
    extraBed: new FormControl(0),
    persons: new FormControl(0),
    service: new FormControl(0),
    color: new FormControl('#1f2a3b'),
    colorPckr: new FormControl('#1f2a3b'),
    isLocked: new FormControl(false),
    serviceInfo: new FormControl(''),
    square: new FormControl(''),
    equipment: new FormControl<number[]>([]),
    ownColor: new FormControl(true),
    shareInOffer: new FormControl(true)
  })

  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['clean'],
      ['link']
    ],
  };

  tabIndex = 0;

  imagesLimit = 8;
  // Changing colors
  public color: ThemePalette = 'primary';
  public touchUi = false;

  copyPhotosFromOtherRoom = false;
  roomsCount = 0;

  fieldsToCount = ['extraBed', 'singleBed', 'doubleBed']
  permissions: UserPermissions = new UserPermissions({})

  selectedRoomStatus = null

  constructor(
    @Inject(DATA_INJECTION_TOKEN) public data: any,
    public translate: TranslocoService,
    private panelService: PanelService,
    private handlingService: HandlingService,
    private _apiService: ApiService,
    private dialogService: DialogService,
    private _snackbarService: SnackbarService,
    private permissionsService: PermissionsService,
    private synchronizationService: SynchronizationService,
    private _dataChangeTrackingService: DataChangeTrackingService,
    private _dataSynchronizerService: DataSynchronizerService,
    private _roomsService: RoomsService
  ) {

    this.subscriptions.push(
      this._roomsService.getCounter().subscribe({
        next: (data) => {
          this.roomsCount = data.valid
        }
      }))

    this.subscriptions.push(this.permissionsService.currentPermissions.subscribe({
      next: (data) => {
        this.permissions = data
        this.panelService.updatePermissions(data)
      }
    }))

    this.roomEquipment.forEach(item => {
      item.name = translate.translate(item.name)
    })

    // 
    // 
    // Process mode
    if (typeof data.mode == 'undefined') {
      throw new Error('Mode not set.');
    }

    this.mode = data.mode
    if (this.mode == 'update') {
      this.createOrUpdateRoomForm.controls.roomId.addValidators([Validators.required])
    }

    else if (this.mode == 'create') {
      this.createOrUpdateRoomForm.controls.roomId.removeValidators([Validators.required])
      this.createOrUpdateRoomForm.controls.shareInOffer.setValue(true)
      this.createOrUpdateRoomForm.controls.color.setValue(LightStyles.DefaultRoomRectColor)
      this.createOrUpdateRoomForm.controls.colorPckr.setValue(LightStyles.DefaultRoomRectColor)
      this.createDescriptionObject();
    }

    this.createOrUpdateRoomForm.controls.descriptionTemp.valueChanges.subscribe({
      next: (data) => {
        this.description[this.createOrUpdateRoomForm.controls.language.value] = data
      }
    })

    this.createOrUpdateRoomForm.controls.roomId.updateValueAndValidity()

    // End of mode processing

    if (typeof data.room != 'undefined' && data.room != null) {

      this.createOrUpdateRoomForm.controls.roomId.setValue(data.room.roomId);
      this.createOrUpdateRoomForm.controls.name.setValue(data.room.name);
      this.createOrUpdateRoomForm.controls.info.setValue(data.room.info);

      this.createOrUpdateRoomForm.controls.roomType.setValue(parseInt(data.room.roomType));
      this.createOrUpdateRoomForm.controls.order.setValue(data.room.order);
      this.createOrUpdateRoomForm.controls.singleBed.setValue(data.room.singleBed);
      this.createOrUpdateRoomForm.controls.doubleBed.setValue(data.room.doubleBed);
      this.createOrUpdateRoomForm.controls.extraBed.setValue(data.room.extraBed);
      this.createOrUpdateRoomForm.controls.persons.setValue(data.room.persons);
      this.createOrUpdateRoomForm.controls.service.setValue(data.room.service);
      this.createOrUpdateRoomForm.controls.color.setValue(data.room.color);
      this.createOrUpdateRoomForm.controls.colorPckr.setValue(data.room.color);

      if (data?.room?.color?.length > 0) {
        this.createOrUpdateRoomForm.controls.ownColor.setValue(true)
      }

      this.createOrUpdateRoomForm.controls.isLocked.setValue(Utils.toBooleanFromStringNumber(data.room.isLocked));
      this.createOrUpdateRoomForm.controls.shareInOffer.setValue(Utils.toBooleanFromStringNumber(data.room.shareInOffer));
      this.createOrUpdateRoomForm.controls.serviceInfo.setValue(data.room.serviceInfo);
      this.createOrUpdateRoomForm.controls.square.setValue(data.room.square);

      // Process equipment
      let equipment = data.room.equipment
      // Chech if its a JSON
      if (Utils.isJSON(equipment)) equipment = JSON.parse(data.room.equipment)
      // Check if its an Array
      if (!Array.isArray(equipment)) equipment = [equipment]

      // Check types, just to make sure
      for (let i = 0; i < equipment.length; i++) {
        equipment[i] = parseInt(equipment[i])
      }

      const newEquipment: any = Array.from(new Set(equipment))
      this.selectedEquipment = new Set(equipment)

      this.createOrUpdateRoomForm.controls.equipment.setValue(newEquipment);

      if (Utils.isJSON(data.room.description)) data.room.description = JSON.parse(data.room.description)
      else if (Array.isArray(data.room.description)) data.room.description = {}
      this.description = data.room.description

      const text = this.description?.['default'] || "";
      this.setDescription(text)

      // Automatically select active tab index if passed
      // Default selected tab index
      if (typeof data.activeTabIndex != 'undefined') {
        this.tabIndex = data.activeTabIndex
        // Images tab index - load images
        if (this.tabIndex == 3) this.getImages()
      }
    }

    this.fieldsToCount.forEach(field => {
      this.subscriptions.push(this.createOrUpdateRoomForm.controls[field].valueChanges.subscribe({
        next: (data) => {
          this.countAvailability();
        }
      }))
    })

    this.subscriptions.push(this.createOrUpdateRoomForm.controls.language.valueChanges.subscribe({
      next: (data) => {
        const text = this.description[data] || "";
        this.setDescription(text)
      }
    }))

  }

  ngOnInit(): void {

    // Select default room status by form
    this.selectedRoomStatus = this.roomStatuses.find(x => x.id == this.createOrUpdateRoomForm.controls.service.value)

    this.subscriptions.push(
      this.createOrUpdateRoomForm.controls.service.valueChanges.subscribe({
        next: (value) => {
          this.selectedRoomStatus = this.roomStatuses.find(x => x.id == value)
        }
      })
    )
  }

  changeTab($event) {
    this.tabIndex = $event;
    if (this.tabIndex == 3) {
      this.getImages()
    }
  }

  showGallery(currentImageIndex) {
    this.dialogService.showImageGalleryDialog({
      images: this.images,
      currentImageIndex: currentImageIndex
    })
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex == event.currentIndex) return
    const image = this.images[event.previousIndex]
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    const roomId = this.createOrUpdateRoomForm.controls.roomId.value
    this._apiService.changeImageOrder(roomId, image.photoId, { order: event.currentIndex + 1 })
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response.status == 'error') {
            // Revert changes
            moveItemInArray(this.images, event.currentIndex, event.previousIndex);
          }
          // Fake update rooms (for booking engine refresh of images)
          this._dataSynchronizerService.next("rooms", [], null)
        },
        error: (err) => {
          // Revert changes
          moveItemInArray(this.images, event.currentIndex, event.previousIndex);
        }

      })
  }

  switchCopyPhotos($event) {
    this.copyPhotosFromOtherRoom = !this.copyPhotosFromOtherRoom;
  }

  updateColor(ev) {
    this.createOrUpdateRoomForm.controls.color.setValue(ev)
    this.createOrUpdateRoomForm.controls.colorPckr.setValue(ev)
  }

  setDescription(text) {
    this.createOrUpdateRoomForm.controls.descriptionTemp.setValue(text)
  }

  rotateImage(photoId: number) {
    this._apiService.rotateRoomImage(this.createOrUpdateRoomForm.controls.roomId.value, photoId)
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          if (response.status == 'success') {
            this.getImages()
            this._snackbarService.showSnackBar(this.translate.translate('zdjecie_zostalo_obrocone'), 'success')
            // Fake update rooms (for booking engine refresh of images)
            this._dataSynchronizerService.next("rooms", [], null)
          }
        }
      })
  }
  countAvailability() {
    let start = 0;
    this.fieldsToCount.forEach(field => {

      let val = parseInt(this.createOrUpdateRoomForm.controls[field].value);
      if (field == 'doubleBed') val = val * 2
      start = start + val;
    });
    this.createOrUpdateRoomForm.controls.persons.setValue(start);
  }

  createDescriptionObject() {
    Utils.setNestedPropertyValue(this.description, "default", "");
    this.languages.forEach(lang => {
      Utils.setNestedPropertyValue(this.description, lang.shortName, "");
    });
  }

  decrement(inputName, min) {
    let curr_val = parseInt(this.createOrUpdateRoomForm.get(inputName).value);
    if (curr_val == min) return;
    this.createOrUpdateRoomForm.controls[inputName].setValue(curr_val - 1);
  }

  increment(inputName, max = null) {
    let curr_val = parseInt(this.createOrUpdateRoomForm.get(inputName).value);
    if (max != null && max > 0 && curr_val > max) return;

    let val = 1
    if (inputName == 'doubleBed') val = 2
    this.createOrUpdateRoomForm.controls[inputName].setValue(curr_val + 1);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  imageThumbnails: string[] = [];
  files: any = []
  handleFileInput(event) {
    this.files = event.target.files;
    this.imageThumbnails = []
    if (this.files) {

      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        if (file.type.startsWith('image/')) {

          this._generateThumbnail(file);
        }
      }
    }

    if (this.files.length + this.images.length > 8) {
      this._snackbarService.showSnackBar(this.translate.translate('MAX_FILES_INCLUDED'), 'warning', 8000);
      this.files = [];
      this.imageThumbnails = []
      this.imageInput.nativeElement.value = ''
      return
    }

    this.uploadFiles()
  }

  chooseFiles() {
    if (this.images.length == 8) {
      this._snackbarService.showSnackBar(this.translate.translate('MAX_FILES_INCLUDED'), 'warning', 8000);
      return
    }
    this.simulateClick()
  }

  isUploading = false
  uploadFiles() {

    if (!this.files) return

    const formData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      formData.append(i.toString(), file);
    }
    // Send here
    this._setRoomImages(formData)
  }

  deleteThumbnail(index) {
    this.imageThumbnails.splice(index, 1)
    this.files = this._removeFileByIndex(this.files, index)
  }

  private _setRoomImages(formData) {

    if (formData == null) return
    if (this.createOrUpdateRoomForm.controls.roomId.value == null) return

    this.isUploading = true
    this._apiService.setRoomImages(this.createOrUpdateRoomForm.controls.roomId.value, formData)
      .pipe(take(1))
      .pipe(finalize(() => {
        // Clean
        this.imageThumbnails = [];
        this.files = []
        this.isUploading = false
        this.imageInput.nativeElement.value = ''
      }))
      .subscribe({
        next: (response) => {
          if (response.status == 'success') {
            this._snackbarService.showSnackBar(this.translate.translate('zaktualizowano_zdjecia_pokoju'), 'success')
            this.getImages()
            // Fake update rooms (for booking engine refresh of images)
            this._dataSynchronizerService.next("rooms", [], null)
          }
          else {
            this._snackbarService.showSnackBar(this.translate.translate(response.message), 'success')
          }
        },
        error: (err) => {
          this._snackbarService.showSnackBar(this.translate.translate('cos_poszlo_nie_tak'), 'success')
        }
      })
  }

  private _removeFileByIndex(fileList, indexToRemove) {
    // Convert FileList to an array
    const filesArray = Array.from(fileList);

    // Check if the index is valid
    if (indexToRemove >= 0 && indexToRemove < filesArray.length) {
      // Remove the file from the array
      filesArray.splice(indexToRemove, 1);

      // Create a new FileList-like object
      const newFileList = {
        length: filesArray.length,
        item(index) {
          return filesArray[index];
        }
      };

      return newFileList;
    } else {
      console.error("Invalid index to remove:", indexToRemove);
      return fileList; // Return original FileList if index is invalid
    }
  }

  private _generateThumbnail(file) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageThumbnails.push(event.target.result);
    };
    reader.readAsDataURL(file);
  }
  @ViewChild('imageInput', { static: false }) imageInput: ElementRef;
  simulateClick() {
    if (this.imageInput && this.imageInput.nativeElement) {
      this.imageInput.nativeElement.click()
    }
  }

  close() {
    this.panelService.close()
  }

  downloadImage(photo) {
    const anchor = document.createElement('a')
    anchor.href = photo.path
    anchor.target = '_blank'
    anchor.download = photo.name;
    anchor.click()
  }

  createOrUpdate() {
    if (!this.createOrUpdateRoomForm.valid || this.isProcessing) return;

    if (this.mode == 'create') {
      if (!this.permissions.has('addRoom')) {
        this._snackbarService.showSnackBar(this.translate.translate('brak_uprawnien'), "info", 3000)
        return true
      }
    }

    if (this.mode == 'update') {
      if (!this.permissions.has('editRoom')) {
        this._snackbarService.showSnackBar(this.translate.translate('brak_uprawnien'), "info", 3000)
        return true
      }

    }

    this.isProcessing = true;
    // Prepare
    let requestData: any = {}

    Object.keys(this.createOrUpdateRoomForm.value).forEach(key => {
      if (key != 'descriptionTemp') {
        requestData[key] = this.createOrUpdateRoomForm.value[key]
      }
    })

    Object.keys(this.description).forEach(key => {
      if (key == 'default') return
      const value = this.description[key]
      if (value == null || typeof value == 'undefined') {
        delete this.description[key]
      }
    })

    requestData.description = this.description;

    // Clear color if null passed
    if (this.createOrUpdateRoomForm.controls.ownColor.value == false) {
      requestData.color = ''
      requestData.colorPckr = ''
      delete requestData.ownColor
    }

    if (this.mode == 'create') {
      delete requestData.order;
      // requestData.images = this.previewFiles

      this.subscriptions.push(this._apiService.createRoom(requestData).subscribe({
        next: (data) => {
          this.handlingService.handleResponse(data, "dodano_pomieszczenie")
          if (data.status == "success") this.synchronize()
          this.isProcessing = false;
          this.panelService.close();
        },
        error: (error) => {
          this.isProcessing = false;
        }
      }))
    }

    else if (this.mode == 'update') {
      this.subscriptions.push(this._apiService.updateRoom(requestData.roomId, requestData).subscribe({
        next: (data) => {
          this.handlingService.handleResponse(data, "edytowano_pomieszczenie")
          if (data.status == "success") this.synchronize()
          this.isProcessing = false;
          this.panelService.close();
        },
        error: (error) => {
          this.isProcessing = false;
        }
      }))
    }
  }

  selectedEquipment = new Set()
  public toggleEquipment(value) {

    let equipment: any = cloneDeep(this.createOrUpdateRoomForm.controls.equipment.value)

    if (!equipment || !Array.isArray(equipment)) return
    let index = equipment.findIndex(x => x == value)

    if (index >= 0) equipment.splice(index, 1);
    else equipment.push(value);

    this.selectedEquipment = new Set(equipment)
    equipment = Array.from(this.selectedEquipment)

    this.createOrUpdateRoomForm.controls.equipment.setValue(equipment)
  }

  filterRoomEquipment(value) {
    this.filteredRoomEquipment = cloneDeep(this.roomEquipment.filter(x => x.name.toLowerCase().includes(value.toLowerCase())))
  }

  synchronize() {
    this.synchronizationService.synchronizeWithPendingCheck("Create or update room");
  }


  processing = false
  deleteRoom() {
    if (this.mode != 'update') return;
    if (!this.permissions.has('deleteRoom')) {
      this._snackbarService.showSnackBar('brak_uprawnien', 'warning', 3000)
      return
    }

    this.processing = true;
    this.subscriptions.push(this.dialogService.confirmDialog({
      confirmText: this.translate.translate('usun'),
      inputs: [
        { name: 'confirm', value: false, type: 'checkbox', description: this.translate.translate('potwierdzam_usuniecie_pokoju'), validators: [Validators.requiredTrue] }
      ],
      message: this.translate.translate('czy_napewno_chcesz_usunac_pomieszczenie_oraz_wszystkie_jego_rezerwacje'),
      title: this.translate.translate('uwaga'),
      type: 'danger',
      cancelText: this.translate.translate('anuluj'),
      showIcon: true
    })
      .subscribe(
        {
          next: (data) => {
            if (data == true) {
              this._apiService.deleteRoom(this.createOrUpdateRoomForm.controls.roomId.value).pipe(take(1)).subscribe({
                next: (response) => {
                  if (response != null) this.handlingService.handleResponse(response, 'usunieto_pomieszczenie');
                  if (response.status == 'success') {
                    this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
                    this.synchronizationService.synchronizeWithPendingCheck("Removed room")
                    this.panelService.close();
                  }
                  else {
                    this._snackbarService.showSnackBar(this.translate.translate('wystapil_blad'), 'error');
                    this.processing = false;
                  }
                },
                error: (err) => {
                  this.processing = false;
                }
              })
            }
            else {
              this.processing = false
            }
          }
        }
      ))
  }

  deleteRoomImage(imageId) {
    if (this.mode != 'update') return;
    // if (!this.permissions.has('editRoom')) {
    //   this._snackbarService.showSnackBar('brak_uprawnien', 'warning', 3000)
    //   return
    // }

    this.processing = true;
    this.subscriptions.push(this.dialogService.confirmDialog({
      confirmText: this.translate.translate('usun'),
      message: this.translate.translate('czy_napewno_chcesz_usunac_to_zdjecie'),
      title: this.translate.translate('uwaga'),
      type: 'danger',
      cancelText: this.translate.translate('anuluj'),
      showIcon: true
    })
      .subscribe(
        {
          next: (data) => {
            if (data == true) {
              this._apiService.deleteRoomImage(this.createOrUpdateRoomForm.controls.roomId.value, imageId)
                .pipe(take(1))
                .subscribe({
                  next: (response) => {
                    if (response != null) this.handlingService.handleResponse(response, 'usunieto_zdjecie');
                    if (response.status == 'success') {
                      this.synchronizationService.synchronizeWithPendingCheck("Removed image")
                      this.getImages()
                      // Fake update rooms (for booking engine refresh of images)
                      this._dataSynchronizerService.next("rooms", [], null)
                    }
                    else {
                      this._snackbarService.showSnackBar(this.translate.translate('wystapil_blad'), 'error');
                      this.processing = false;
                    }
                  },
                  error: (err) => {
                    this.processing = false;
                  }
                })
            }
            else {
              this.processing = false
            }
          }
        }
      ))
  }

  images = []
  imageLoadFailed = false
  getImages() {
    const roomId = this.createOrUpdateRoomForm.controls.roomId.value

    if (!roomId) return

    this._apiService.getRoomImages(roomId)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response.status == 'success') {
            if (typeof response.data == 'undefined' || response.data == null) {
              this.images = []
            } else this.images = response.data

            this.imageLoadFailed = false
          } else this.imageLoadFailed = true
        },
        error: (err) => {
          this.imageLoadFailed = true
        }
      })
  }

}
