import { Component, Input } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { DateFormatterComponent } from 'app/core/shared/components/date-formatter/date-formatter.component';
import { SourceAvatarComponent } from 'app/core/shared/components/source-avatar/source-avatar.component';
import { Utils } from 'app/core/shared/others/utils';
import { DateTime } from 'luxon';

@Component({
  selector: 'canceled-reservation-notification',
  standalone: true,
  imports: [CommonModule, JsonPipe, DateFormatterComponent, SourceAvatarComponent, TranslocoModule],
  templateUrl: './canceled-reservation-notification.component.html',
  styleUrl: './canceled-reservation-notification.component.scss'
})
export class CanceledReservationNotificationComponent {
  @Input() notification

  countStay(data) {

    if(!data || !Utils.isDefined(data, 'arrival') || !Utils.isDefined(data,'departure')) {
      return '?'
    }

    const arrival = DateTime.fromISO(Utils.getNestedValue(data,'arrival'))
    const departure = DateTime.fromISO(Utils.getNestedValue(data,'departure'))

    return departure.diff(arrival, 'days').toObject().days
  }
  
}
