import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Utils } from '../../others/utils';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { DateFormatterComponent } from '../date-formatter/date-formatter.component';

@Component({
  standalone: true,
  selector: 'app-show-reservation-booking-data',
  templateUrl: './show-reservation-booking-data.component.html',
  styleUrls: ['./show-reservation-booking-data.component.scss'],
  imports: [TranslocoModule, MatDialogModule, MatIconModule, MatTooltipModule, MatDividerModule]
})
export class ShowReservationBookingDataComponent {
  b;
  sum = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate:TranslocoService
  )
  {
    if (typeof data.reservation != 'undefined') {
      if(Utils.isDefined(data,"reservation.bookingDetails"))
      {
        if(Utils.isJSON(data.reservation.bookingDetails))
        {
          this.b = JSON.parse(data.reservation.bookingDetails);

          if(Utils.isDefined(this.b,"booking.room"))
          {
            this.sum = 0;

            this.b.booking.room.forEach(room=>{
              if(Utils.isDefined(room,"price_details.guest.total"))
              {
                this.sum += parseFloat(room.price_details.guest.total)
              }
            })

            this.sum = parseFloat(this.sum.toFixed(2))
          }
          
          // Fix price standard object to array
          if(Utils.isDefined(this.b,"room_booking.price"))
          {
            if(!Array.isArray(this.b.room_booking.price))
            {
              const price = this.b.room_booking.price;
              this.b.room_booking.price = []
              this.b.room_booking.price.push(price);
            }
          }

          if(Utils.isDefined(this.b,"room_booking.addons"))
          {
            if(!Array.isArray(this.b.room_booking.addons))
            {
              const addon = this.b.room_booking.addons.addon
              this.b.room_booking.addons = []
              this.b.room_booking.addons.push(addon)
            }
            
          }

          if(Utils.isDefined(this.b,"room_booking.cancel_penalties.cancel_penalty"))
          {
            if(!Array.isArray(this.b.room_booking.cancel_penalties.cancel_penalty))
            {
              const penalty = this.b.room_booking.cancel_penalties.cancel_penalty
              this.b.room_booking.cancel_penalties.cancel_penalty = []
              this.b.room_booking.cancel_penalties.cancel_penalty.push(penalty)
            }
            
          }
          
          // END


          // Test seed
          // this.b.room_booking.addons = [
          //   { name: "xd", nights: "2", persons: "2", price_per_unit: 20, totalprice: 40},
          //   { name: "xd", nights: "2", persons: "2", price_per_unit: 20, totalprice: 40},
          //   { name: "xd", nights: "2", persons: "2", price_per_unit: 20, totalprice: 40},
          //   { name: "xd", nights: "2", persons: "2", price_per_unit: 20, totalprice: 40},
          //   { name: "xd", nights: "2", persons: "2", price_per_unit: 20, totalprice: 40},
          //   { name: "xd", nights: "2", persons: "2", price_per_unit: 20, totalprice: 40}
          // ]

          // Testing
          // this.b.booking.booked_at = null;
          // this.b.booking.status = 'cancelled';
          // this.b.booking.total_cancellation_fee = 241.21;

          // Testing credit card
          // this.b.booking.customer.cc_number = '';
          // this.b.booking.customer.cc_name = '';
          // this.b.booking.customer.cc_type = '';
          // this.b.booking.customer.cc_cvc = '';
          // this.b.booking.customer.cc_expiration_date = '';

          // this.b.room_booking.guest_name = '';
          // this.b.room_booking.extra_info = '';
        }
      }
    }
  }

  isNullOrEmpty(value)
  {
    return Utils.isNullOrEmpty(value)
  }

  getRatePlanName(rate_id)
  {
    if(typeof rate_id == 'undefined') { return '';}
    
    let planName = '';
    this.b.room_booking.price.forEach(price=>{
      
    })
  }
}
