import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription, take } from "rxjs";
import { ApplicationData } from "../data/application-data";
import { PricingChannel } from "../models/PricingChannel";
import { PricingService } from "./pricing.service";
import { ApiService } from "./api.service";
import { LocalizeRouterService } from "@penleychan/ngx-transloco-router";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class PricingChannelService implements OnDestroy {

    private _subscriptions: Subscription[] = [];

    private _channel = new BehaviorSubject<PricingChannel>(null)
    public currentChannel = this._channel.asObservable()

    private _pricingChannels = new BehaviorSubject<PricingChannel | any>([])
    public currentPricingChannels = this._pricingChannels.asObservable()

    private _error = new BehaviorSubject<any>(null)
    public currentError = this._error.asObservable()

    constructor(
        private _apiService: ApiService,
        private _localize: LocalizeRouterService,
        private _router: Router
    ) {

    }

    getChannels(): PricingChannel[] {
        return this._pricingChannels.value
    }

    getSelectedChannel() {
        return this._channel.value
    }
    
    selectChannel(pricingId: string, updateUrl = true) {

        if (this._error.value != null) return
        const channel = this._pricingChannels.value.find(x => x.pricingId == pricingId)

        if (!channel || typeof channel == 'undefined') {
            console.error(`[PricingChannelService] - Couldn't set selected channel.pricingId: ${pricingId}`)
            this.selectChannel('mobile-calendar', updateUrl = true)
            return
        }


        this._channel.next(channel)
        if (updateUrl) {
            const localizedPath = this._localize.translateRoute('/app/pricing/manage-prices/' + channel.pricingId)
            this._router.navigateByUrl(localizedPath.toString())
        }
    }

    async fetchChannels() {
        try {
            const response: any = await this._apiService.fetchChannels().pipe(take(1)).toPromise()
            if (response.status === 'success') {
                this._pricingChannels.next(response.data)
                this._error.next(null)
            }
            else console.error("[PricingChannelService] - Couldn't get pricing channels");

        } catch (error) {
            console.error("[PricingChannelService] - Error fetching channels:", error);
            this._error.next(error)
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }
}