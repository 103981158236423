import { Injectable, OnDestroy } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { SnackbarService } from "./snackbar.service";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DisplayErrorsDialogComponent } from "../dialogs/display-errors-dialog/display-errors-dialog.component";
import { Utils } from "../others/utils";
import { SettingsService } from "./settings.service";

@Injectable({
    providedIn: 'root'
})
export class HandlingService implements OnDestroy {

    subscriptions: Subscription[] = [];
    constructor(
        private dialog: MatDialog,
        private translate: TranslocoService,
        private snackbarService: SnackbarService,
        private _settingsService:SettingsService
    ) {

    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }

    async handleErrorsInSnackbar(response) {
        if (response.status != "error") return;

        response.message.forEach(error => {
            this.snackbarService.showSnackBar(
                this.translate.translate(error),
                "error"
            )
        });

    }

    async handleErrorsWithCustomActions(request, error) {
        if(!error) return
        if(!request) return
        if(error.status >= 200 && error.status < 300) return

        // On settings update
        if(request.url.includes('/api/v2/settings')) {
            this._settingsService.refreshSettings()
        }
    }

    async handleResponse(response: ServerResponse | HttpErrorResponse, successMessage: string = null) {

        if(!response) return
        if (response.status == 'success') {
            if (!Utils.isNullOrEmpty(successMessage)) {
                this.snackbarService.showSnackBar(
                    this.translate.translate(successMessage),
                    "success"
                )
            }
            return
        }

        if (response instanceof HttpErrorResponse) {

            if (response.error.status == 'error') {
                let err = []
    
                if (response.error.errors && Object.values(response.error.errors).length > 0) {
                    Object.values(response.error.errors).forEach((error: any) => {
                        err.push(error)
                    })
                }
    
                if (err.length > 0) {
                    this.openDisplayErrorsDialog(err);
                }
    
            }
        }
        

    }

    openDisplayErrorsDialog(errors) {

        this.subscriptions.push(
            this.dialog.open(DisplayErrorsDialogComponent, {
                data: {
                    errors: errors
                },
                maxWidth: '100%'
            })
                .afterClosed()
                .subscribe()
        )
    }
}

export interface ServerResponse {
    status: 'success' | 'error';
    message: [];
    channel_manager?: ChannelManagerResponse[];
}

export interface ChannelManagerResponse {
    channel: string,
    status: 'success' | 'error',
    message: string,
    retry: RetryOptions[]
}

export interface ServerResponseErrors {
    errors: any[],
    channel_manager_errors: ChannelManagerError[]
}

export interface ChannelManagerError {
    channel: string,
    message: string
}

export interface RetryOptions {
    roomId: number,
    arrival: string,
    departure: string
}