import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AlertData } from '../../models/alert-data';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SynchronizationService } from '../../services/synchronization.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FuseCardComponent } from '@fuse/components/card';
import { FuseAlertComponent } from '@fuse/components/alert';

@Component({
  standalone: true,
  selector: 'app-synchronization-dialog',
  templateUrl: './synchronization-dialog.component.html',
  styleUrls: ['./synchronization-dialog.component.scss'],
  imports: [MatProgressBarModule, MatProgressSpinnerModule, MatIconModule, MatButtonModule, CommonModule, TranslocoModule, FuseCardComponent, FuseAlertComponent]
})
export class SynchronizationDialogComponent {

  isSynchronizing = false
  subscriptions: Subscription[] = [];
  sync: any;
  error = null;
  progress = 0;

  savingStatus = null
  savingStatusProgress = 0

  alertData: AlertData = {
    title: "Error",
    message: "We discover connection problems during your synchronization. Please try again",
    canDismiss: false,
    status: 'error'
  }

  constructor(
    private translate: TranslocoService,
    public dialogRef: MatDialogRef<SynchronizationDialogComponent>,
    private synchronizationService: SynchronizationService,
  ) {
    this.subscriptions.push(this.synchronizationService.currentSynchronizationStatus.subscribe(data => {

      if (data != null) {
        this.sync = data;
        this.error = data.error;
        this.isSynchronizing = data.isSynchronizing
        this.savingStatus = data.savingStatus

        // Import progress
        if (data.currentPage > 0 && data.allPages > 0) {
          this.progress = (data.currentPage / data.allPages) * 100;
          if(this.progress > 100) this.progress = 100
        }

        if (this.progress == 100 && data.isSynchronizing == false) {
          setTimeout(() => {
            this.close();
          }, 1000)
        }
        // Saving status progress
        if (this.savingStatus != null) {
          this.savingStatusProgress = (data.savingStatus.success / data.savingStatus.all) * 100;
          if(this.savingStatusProgress > 100) this.savingStatusProgress = 100
        }

        if (this.savingStatusProgress == 100 && data.isSynchronizing == false) {
          setTimeout(() => {
            this.close();
          }, 1000)
        }
      }

    }));
  }

  reSync() {
    this.synchronizationService.synchronizeWithPendingCheck(this.translate.translate('sprobuj_ponownie'))
  }

  close() {
    this.dialogRef.close()
  }
}
