import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { SettingsService } from './core/shared/services/settings.service';
import { UserSettings } from './core/shared/models/UserSettings';
import { Utils } from './core/shared/others/utils';
import { ThemeService } from './core/shared/services/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnDestroy {
    private readonly _subscriptions: Subscription[] = []
    /**
     * Constructor
     */
    constructor(
        private _settingsService: SettingsService,
        private _themeService: ThemeService
    ) {

        this._subscriptions.push(
            this._settingsService.getUserSettings().subscribe({
                next: (userSettings: UserSettings) => {
                    if (Utils.isDefined(userSettings, "settings.generalThemeMode")) {
                        const theme = userSettings.settings.generalThemeMode
                        this._themeService.setThemeModeByValue(theme)
                    }
                }
            })
        )


    }


    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

}
