export class Settings {

     generalThemeMode:number = 0
     generalHomepage:string = 'calendar'
     generalDateFormat:string = 'YYYY-MM-DD'
     generalTimeFormat:string = 'HH:mm'

     calendarCalendarDate =	0
     calendarRefreshTime =	0
     calendarShape =	0
     calendarLabels = []

     calendarFastViewTurnOn:	1
     calendarFastView = []

     calendarDragAndDrop =	1
     calendarDisplaySource =	1
     calendarDisplayCheckInOut =	1
     calendarColorsNoPayment =	"#e53935"
     calendarColorsWaitingForPrepayment =	"#fb8c00"
     calendarColorsPaidPrepayment =	"#43a047"
     calendarColorsPaidAll =	"#00acc1"
     calendarColorsWaitingForPayment =	"#8e24aa"
     calendarColorsTurnOn =	0

     reservationFormView: "TAB" | "NORMAL"	= "TAB"
     reservationField = []
     pricingRestrictions = []
     

     reservationAutomaticPrepaymentAmountPrice =	"0.00"
     reservationAutomaticPrepaymentAmountType =	0
     reservationAutomaticPrepaymentAmountTurnOn =	0
     reservationAutomaticPrepaymentDays =	0
     reservationAutomaticPrepaymentDaysTurnOn =	0

     reservationConfirmation = []

     reservationCheckInCheckOutHoursTurnOn =	0
     reservationDefaultInfo =	""
     roomDirtyRoomCheckOutTurnOn =	0

     invoicesFormat = 'NUMBER/MM/YYYY';
     // 0 - monthly | 1 - yearly
     invoicesNumberingType: 0 | 1 = 0
     invoicesDefaultRate =	""
     invoicesFormDataDefaultSource =	0
     invoicesCompanyName =	""
     invoicesAddress =	""
     invoicesTaxId =	""
     invoicesBankAccountNumber =	""
     invoicesIssuePlace =	""
     invoicesAdditionalInformations =	""
     invoicesAutoDocsFill =	0

     reservationLockAsReservationTurnOn =	0

     syncBookingStatusDefaultStatus =	0
     syncBookingColorDefaultColor =	""
     syncBookingPaymentDefaultPayment =	0
     syncChannelManagerNotificationEmail =	1
     syncChannelManagerNotificationPush =	1
     
     syncIcalendarImport =	0

     // Powiadomienia?
     notificationsShowNewChanges = 1;
     notifySound: null | string = null
     notificationsPushArrivalsDepartures = 1;
     notificationsPushArrivalsDeparturesTime:string | null = '09:00';
     notificationsPushPrepayments = 1;
     notificationsPushPrepaymentsTime:string | null = '09:00';

     constructor(settings?) {

          if(typeof settings != 'undefined') {
               Object.keys(settings).forEach(key=> {
                    if(key == 'notifySound') { console.log("CNST: ",settings[key])}
                    this[key] = settings[key]
               })
          }
     }
}


