<div *ngIf="notification" class="w-full">
    <div *ngIf="room != null">
        {{room?.name}}
    </div>
    <div *ngIf="notification?.body?.data?.new?.length > 0" >
        {{'nowe' | transloco}}: {{notification?.body?.data?.new?.length}}
        <app-reservation-row [reservationId]="reservationId" *ngFor="let reservationId of notification?.body?.data?.new"></app-reservation-row>
    </div>
    <div *ngIf="notification?.body?.data?.edited?.length > 0" >
        {{'edytowane' | transloco}}: {{notification?.body?.data?.edited?.length}}
        <app-reservation-row [reservationId]="reservationId" *ngFor="let reservationId of notification?.body?.data?.edited"></app-reservation-row>
    </div>
    <div *ngIf="notification?.body?.data?.cancelled?.length > 0" >
        {{'usuniete' | transloco}}: {{notification?.body?.data?.cancelled?.length}}
        <app-reservation-row [reservationId]="reservationId" *ngFor="let reservationId of notification?.body?.data?.cancelled"></app-reservation-row>
    </div>
    <div *ngIf="notification?.body?.data?.errors?.length > 0" class="text-red-600 flex items-center">
        <mat-icon class="icon-size-4 mr-0 text-red-600" [svgIcon]="'heroicons_solid:exclamation-triangle'"></mat-icon>
        {{'bledy' | transloco}}: {{notification?.body?.data?.errors?.length}}
        <app-reservation-row [reservationId]="reservationId" *ngFor="let reservationId of notification?.body?.data?.errors"></app-reservation-row>
    </div>
</div>