
export class UserData {
  userId: number = null;
  employeeId: number = null;
  avatar: string = 'user.png';
  email: string = '';
  forename: string = '';
  name: string = '';
  isCompany: number = 1;
  companyName: string = '';
  role: string = '';
  premiumTime: string = '';
  portal: number = 0;
  registerTime: string = '';
  premiumType: number = 0;
  currency: string = '';
  phone: string = '';
  phonePrefix: string = null;
  phoneCountry: string = null;
  street: string = '';
  postalCode: string = '';
  city: string = '';
  countryId: number = null;
  taxId: string = '';
  bankAccountNo: string = '';
  lang: string = '';
  subscriptionState: {
    roomLimit: number,
    isTestMode: boolean,
    expiredInDays: number,
    expiredIn: string
  };
  emailConfirmed: number = 1;
  roomIndex:number

  constructor(object) {
    Object.keys(object).forEach((key: string) => {
      this[key] = object[key];
    });
  }
}
