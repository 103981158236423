import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslocoService } from "@ngneat/transloco";

const componentName = "MatPaginatorIntlService";

@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
  constructor(private translateService: TranslocoService) {
    super();
    this.translateService.langChanges$.subscribe((e) => {
      this.translateLabels();
    });
    this.translateLabels();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return this.translateService.translate('RANGE_PAGE_LABEL_1', { length });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.translateService.translate('RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
    }

  injectTranslateService(translate: TranslocoService): void {
    this.translateService = translate;

    this.translateService.langChanges$.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels(): void {
    this.itemsPerPageLabel = this.translateService.translate('ITEMS_PER_PAGE_LABEL');
    this.nextPageLabel = this.translateService.translate('NEXT_PAGE_LABEL');
    this.previousPageLabel = this.translateService.translate('PREVIOUS_PAGE_LABEL');
    this.firstPageLabel = this.translateService.translate('FIRST_PAGE_LABEL');
    this.lastPageLabel = this.translateService.translate('LAST_PAGE_LABEL');
    this.changes.next();
  }
}