import { Injectable, Injector, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ToastService } from "./toast.service";
import { PanelService } from "./panel.service";
import { ComponentPortal } from "@angular/cdk/portal";
import { Utils } from "../others/utils";
import { DisplayNewChangesComponent } from "../components/display-new-changes/display-new-changes.component";
import { DATA_INJECTION_TOKEN } from "../others/data-injection-token";
import { TranslocoService } from "@ngneat/transloco";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { CONST } from "../data/const";
import { ReservationsService } from "./reservations.service";
import { CollisionService } from "./collision.service";
import { SettingsService } from "./settings.service";
import { UserSettings } from "../models/UserSettings";

@Injectable({
    providedIn: 'root'
})
export class DataChangeTrackingService implements OnDestroy {

    private readonly _subscriptions: Subscription[] = [];
    private _userSettings: UserSettings
    // Comparing changes ids
    private readonly DCTS = "DCTS_"
    // Displaying reservations in show new changes dialog
    private readonly DCTS_CHANGES = "DCTS_CHANGES_"

    clients = {}
    reservations = {}

    changes = []

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }

    constructor(
        private _panelService:PanelService,
        private _translate:TranslocoService,
        private _toastService:ToastService,
        private _dataSynchronizerService:DataSynchronizerService,
        private _reservationsService:ReservationsService,
        private _collisionService:CollisionService,
        private _settingsService: SettingsService
    ){

        this._getLastChanges()
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
              next: (data) => {
      
                // Do check for upcoming reservations
                // Only if it's not a initial synchronization
                if (data.table == 'reservations' && data.syncAt != CONST.DEFAULT_SYNC_TIME) {
                    this.doCheck(data.table, data.value)
                }
            }
        }))

        this._subscriptions.push(
            this._settingsService.currentUserSettings.subscribe({
                next: (userSettings: UserSettings) => {
                    this._userSettings = userSettings
                }
            })
        )
    
    }

    public doCheck(tableName, values) {

        if(tableName != 'reservations') return
        if(values == null || typeof values == 'undefined') return
        if(values.length == 0) return

        // Check if it is internal change
        let data = localStorage.getItem(this.DCTS + tableName)
        let object = {}
        if(data != null && typeof data != 'undefined') {
            object = JSON.parse(data)
        }
        if(object == null) return

        
        let show = false
        values.forEach(obj => {

            const id = Utils.GetTableId(tableName)

            // If is stored in memory
            if(typeof object[obj[id]] == 'undefined') {
                
                const index = this.changes.findIndex(x=>x.reservationId == obj.reservationId)
                const reservation = this._reservationsService.getById(obj.reservationId)
                // Do not show / update locks when not showing changes in reservation
                if(Utils.isNullOrEmpty(reservation.clientId) && this._userSettings.settings.reservationLockAsReservationTurnOn == 0) return
                if(index == -1) { 

                    // Reservation must exist and must have permission
                    if(!reservation || reservation.hasPermission == false) {

                        // Remove change that not meet conditions (has permission)
                        let updated = this.changes.map(x=>x?.reservationId)
                        // Fitler by obj.reservationId because reservation could not exists possibly
                        updated = updated.filter(x=>x == obj.reservationId)
                        localStorage.setItem(this.DCTS_CHANGES + 'reservations', JSON.stringify(updated))

                    } 
                    // 
                    else this.changes.push(reservation)
                }

                // Update already existing in changes
                else  {
                    // Reservation must exist and must have permission
                    if(!reservation || reservation.hasPermission == false) {
                        // Remove change that not meet conditions (has permission)
                        let updated = this.changes.map(x=>x?.reservationId)
                        // Fitler by obj.reservationId because reservation could not exists possibly
                        updated = updated.filter(x=>x == obj.reservationId)
                        localStorage.setItem(this.DCTS_CHANGES + 'reservations', JSON.stringify(updated))
                        delete this.changes[index]
                        this.changes = this.changes.filter(x=>x.reservationId != obj.reservationId)
                    }
                    // Reservation valid = update object
                    else this.changes[index] = obj
                }

                
                show = true
            }
        })

        const changesIds = this.changes.map(x=>x?.reservationId)
        localStorage.setItem(this.DCTS_CHANGES + tableName, JSON.stringify(changesIds))

        if(show && changesIds.length > 0) this.showChangesNotification()

        // Clean changes to 
        localStorage.removeItem(this.DCTS + 'reservations')

    }

    // Adding internal changes removes reservations from collisions (they are automatically checked once more after sync)
    addInternalChange(fieldIds:number[] | number)
    {
        if(!Array.isArray(fieldIds)) fieldIds = [fieldIds]
        if(typeof fieldIds === 'undefined') return
        
        for(let d=0;d< fieldIds.length;d++) {
            const fieldId = fieldIds[d]
            this._collisionService.remove(fieldId)
            this.reservations[fieldId] = fieldId
        }

        localStorage.setItem(this.DCTS + 'reservations', JSON.stringify(this.reservations))
    }

    showChangesNotification()
    {
        const ref = this._toastService
        .showToast({
            icon: "heroicons_solid:bell",
            status: "info",
            type: 'NEW_CHANGES',
            title: this._translate.translate('synchronizacja_danych'),
            message: this._translate.translate('pobrano_nowe_dane_z_innych_zrodel'),
            added: new Date(),
            buttons: [
                {'text' : this._translate.translate('sprawdz_zmiany'), action : 'check'}
            ],
            timeToClose: 10000,
        })
        
        if(ref == null || typeof ref == 'undefined') return
        
        ref.instance.on('check').subscribe({
            next: (data) => {
                if(data == true) {
                    this.showChanges(this.changes)
                    ref.instance.close()
                } 
            }
        })
    }

    clearChanges() {
        this.changes = []
        this.reservations = {}
        localStorage.removeItem(this.DCTS_CHANGES+'reservations')
    }

    showChanges(changes) {
        if (this._panelService.panel.opened) { this._panelService.clearPanelPortal(); }
        this._panelService.open(
            new ComponentPortal<DisplayNewChangesComponent>(
                DisplayNewChangesComponent,
                null,
                Injector.create({
                    providers: [{ provide: DATA_INJECTION_TOKEN, useValue: {changes: changes} }],
                })
                )
        )
    }

    private _getLastChanges() {

        const changesIds = localStorage.getItem(this.DCTS_CHANGES + 'reservations')
        if(!changesIds) return
        this.changes = []

        const changesObj = JSON.parse(changesIds)
        changesObj.forEach(reservationId => {
            const res = this._reservationsService.getById(reservationId)
            if(res) this.changes.push(res)
        });

        this.changes.sort((a,b)=> new Date(b.editDate).getTime() - new Date(a.editDate).getTime())
    }
}

