import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  showSnackBar(message, type: 'success' | 'error' | 'warning' | 'info', duration = 2000) {
    let className;
    switch (type) {
      case 'success': className = 'success-snackbar'; break;
      case 'error': className = 'error-snackbar'; break;
      case 'warning': className = 'warning-snackbar'; break;
      case 'info': className = 'info-snackbar'; break;
      default: className = 'info-snackbar';
    }

    this.snackBar.open(message, '', {
      duration: duration,
      panelClass: [className]
    });
  }
}