<div class="relative flex flex-col max-w-240 min-h-full lg:min-h-auto md:min-w-240 max-h-full lg:max-h-screen overflow-hidden"> <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'historia' | transloco}} #{{reservation?.reservationNo}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden mb-18 sm:mb-0">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-y-auto p-6">
            <history-master [selectedHistoryTypes]="['Reservations','Clients']"
                [reservationNumber]="reservation?.reservationNo" [clientId]="reservation?.clientId">
            </history-master>
        </div>
    </div>
    <!--Footer-->
    <!--Footer-->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t z-10 bg-gray-50 dark:bg-gray-900">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-stroked-button mat-dialog-close class="mc-button ml-auto">{{'zamknij' | transloco}}</button>
            </div>
        </div>
    </div>
</div>