import { BooleanInput } from '@angular/cdk/coercion';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LocalizeRouterModule, LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserAvatarImageComponent } from 'app/core/shared/components/user-avatar-image/user-avatar-image.component';
import { AddNewAccountComponent } from 'app/core/shared/dialogs/add-new-account/add-new-account.component';
import { UserSettings } from 'app/core/shared/models/UserSettings';
import { InternetConnectionService } from 'app/core/shared/services/internet-connection.service';
import { PanelService } from 'app/core/shared/services/panel.service';
import { ReloginService } from 'app/core/shared/services/relogin.service';
import { SettingsService } from 'app/core/shared/services/settings.service';
import { SnackbarService } from 'app/core/shared/services/snackbar.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user',
    standalone     : true,
    imports        : [CommonModule, MatButtonModule, UserAvatarImageComponent, MatMenuModule, MatIconModule,  MatDividerModule, TranslocoModule, RouterModule, LocalizeRouterModule],
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: UserSettings;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        public ics: InternetConnectionService,
        public translate:TranslocoService,
        private _authService:AuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _settingsService: SettingsService,
        private _localize:LocalizeRouterService,
        private _panelService:PanelService,
        private _reloginService:ReloginService,
        private _snackbarService:SnackbarService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    async relogin(user) {
        if(!user.isOfflineModeAvailable && this.ics.offlineMode.value == true) {
            this._snackbarService.showSnackBar(this.translate.translate('nie_mozna_zalogowac_sie_na_to_konto_przywroc_polaczenie_z_internetem'), "error")
            return
        }
        await this._reloginService.relogin(user)
    }

    async addNewAccount(){
        return await new Promise((resolve, reject)=>{
          this._panelService.open(new ComponentPortal<AddNewAccountComponent>(
            AddNewAccountComponent,
              null,
              // Injector.create({
              //   providers: [{ provide: DATA_INJECTION_TOKEN, useValue: {'mode' : mode} }],
              // })
          ))
          resolve(true)
        })
      }
    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._settingsService.getUserSettings()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
            next: (data)=> {
                this.user = data
                this._changeDetectorRef.markForCheck();
            }
        })

        this.ics.offlineMode
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
            next: (mode) => {
                this._changeDetectorRef.markForCheck()
            }
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        const path = this._localize.translateRoute('/sign-out')
        this._router.navigate([path]);
    }
    
    lockScreen(): void
    {
        const path = this._localize.translateRoute('/lock')
        this._router.navigate([path]);
    }
}
