import { Source } from './Source';
import { Settings } from './Settings';
import { Images } from './Images';
import { UserPermissions } from './UserPermissions';
import { HotelData } from './HotelData';
import { UserData } from './UserData';
import { Utils } from '../others/utils';



export class UserSettings {

  userData: UserData = new UserData({})
  hotelData: HotelData = new HotelData({})
  permissions: UserPermissions = new UserPermissions({});
  settings: Settings = new Settings();
  images: Images = new Images();
  connectedAccounts : Array<any> = [];
  roomConnections: any = [];
  status? = 'online';
  
  constructor(
    object:any
  )
  {
    Object.keys(object).forEach((key: string) => {
      if (
          key != 'userData' &&
          key != 'permissions' &&
          key != 'settings' &&
          key != 'sources'
        ) {
        this[key] = object[key]
      }
    })

    this.status = 'online'

    if(Utils.isDefined(object,"userData"))  this.userData = new UserData(object.userData)
    if(Utils.isDefined(object,"permissions"))  this.permissions = new UserPermissions(object.permissions)
    if(Utils.isDefined(object,"settings"))  this.settings = new Settings(object.settings)
    
  }
 

  public static from(obj: any): UserSettings {
    return new UserSettings(obj);
  }
 
}


