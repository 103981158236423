<mat-dialog-content class="mat-typography">
    <div class="flex flex-row">
        <div class="text-xl">
            {{form.controls.sourceId.value == null ? ("nowe_zrodlo_rezerwacji" | transloco) : ("edycja_zrodla_rezerwacji" | transloco)}}
        </div>
        <div class="ms-auto">
            <button mat-icon-button (click)="close()">
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
            </button>
        </div>
    </div>

    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{'nazwa' | transloco}}</mat-label>
        <mat-icon matPrefix [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
        <input matInput type="text" [formControl]="form.controls.name" />
    </mat-form-field>

    <mat-form-field class="w-full mt-3" [subscriptSizing]="'dynamic'" ngx-colors-trigger="colorPicker" [hideTextInput]="false"
    [colorPickerControls]="colorPickerControls" [acceptLabel]="translate.translate('wybierz')"
    [cancelLabel]="translate.translate('anuluj')" (change)="updateColor($event)">
        <mat-label>{{'kolor' | transloco}}</mat-label>
        <input readonly matInput [formControl]="form.controls.color">
        <ngx-colors #colorPicker [formControl]="form.controls.colorPckr" ngx-colors-trigger
            matSuffix></ngx-colors> <!--style="display: inline-block; margin:10px;"-->
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button class="ms-auto" color="primary" (click)="saveOrCreate()">
        {{form.controls.sourceId.value == null ? ("dodaj" | transloco) : ("zapisz" | transloco)}}
    </button>
    <button mat-button (click)="close()">
        {{"zamknij" | transloco}}
    </button>
</mat-dialog-actions>