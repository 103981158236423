import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-file-attachment',
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslocoModule, MatIconModule, MatTooltipModule],
  templateUrl: './file-attachment.component.html',
  styleUrl: './file-attachment.component.scss'
})
export class FileAttachmentComponent {

  @Input('name') name = ""
  @Input('size') size = ""
  @Input('type') type = ""
  @Input('url')  url = ""
  @Input('extension') extension = ""
  
}
