<div class="flex flex-col overflow-hidden m-5">

  <button (click)="close()" mat-icon-button
    class="absolute right-8 top-8 transform -translate-y-1/2 bg-white border border-black rounded-full w-10 h-10 flex items-center justify-center">
    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
  </button>

  <button (click)="prev()" mat-icon-button
    class="absolute left-8 top-1/2 transform -translate-y-1/2 bg-white border border-black rounded-full w-10 h-10 flex items-center justify-center">
    <mat-icon [svgIcon]="'heroicons_solid:arrow-left'"></mat-icon>
  </button>

  <img class="object-scale-down mx-auto rounded-xl w-full" style="max-height: 80vh;" *ngIf="selectedImage != null"
    [src]="selectedImage?.path" [alt]="selectedImage?.alt" alt="Full Size Image" />

  <div class="mx-auto text-center my-4">
    {{'zdjecie' | transloco}} {{currentImageIndex+1}} z {{images?.length}}
  </div>

  <button (click)="next()" mat-icon-button
    class="absolute right-8 top-1/2 transform -translate-y-1/2 bg-white border border-black rounded-full w-10 h-10 flex items-center justify-center">
    <mat-icon [svgIcon]="'heroicons_solid:arrow-right'"></mat-icon>
  </button>

</div>