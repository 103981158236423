
export class HotelData {
  objectId: number = null;
  userId: number = null;
  objectName: string = null;
  checkIn: string = null;
  checkOut: string = null;
  email: string = null;
  webPage: string = null;
  phone: string = null;
  street: string = null;
  postalCode: string = null;
  city: string = null;
  countryId: number = null;
  priceAdult: number = null;
  priceChild: number = null;
  prepaymentDays: number = null;
  prepaymentAmount: string = null;

  constructor(object) {
    Object.keys(object).forEach((key: string) => {
      this[key] = object[key];
    });
  }
}
