<div class="flex flex-col w-full sm:w-100 sm:max-w-100 sm:min-w-100 max-h-screen">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'pokaz_ukryj' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 p-6 overflow-y-scroll">
            
            <ng-container *ngFor="let field of fields">
                <div
                    class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                    matRipple
                    (click)="toggleField(field.id)">
                    <mat-checkbox
                        class="flex items-center h-10 min-h-10 pointer-events-none"
                        [checked]="userFields?.includes(field.id)"
                        [color]="'primary'"
                        [disableRipple]="true">
                    </mat-checkbox>
                    <div class="ml-1">
                        <div class="flex items-center">
                            <div class="text-md">
                                {{field.translation | transloco}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>


        </div>
    </div>
    <!--Footer-->
    <div
        class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <!-- Confirm -->
                <button mat-dialog-close mat-stroked-button>
                    {{'zamknij' | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>
