<h2 mat-dialog-title>
    {{"szczegoly" | transloco}} - {{'Booking.com' | transloco}}
    <button class="mt-2 float-end" mat-dialog-close mat-icon-button matTooltip="{{'zamknij_okno' | transloco}}">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-12 col-md-6">
            Booking ID:<br />
            <b>{{b?.booking?.id}}</b>
        </div>
        <div class="col-12 col-md-6" *ngIf="b?.booking?.status != null && b?.booking?.status != 'cancelled'">
            {{"data_dodania"| transloco}}: <br />

            <b *ngIf="!isNullOrEmpty(b?.booking?.booked_at)">
                <date-formatter [date]="b?.booking?.booked_at" [showTime]="true"></date-formatter>
            </b>
            <b *ngIf="isNullOrEmpty(b?.booking?.booked_at)">
                <date-formatter [date]="b?.booking?.date + ' ' + b?.booking?.time" [showTime]="true"></date-formatter>
            </b>
        </div>
        <div class="col-12 col-md-6" *ngIf="b?.booking?.total_cancellation_fee != null">
            {{"calkowita_oplata_za_anulowanie" | transloco}}:<br />
            <b>{{b?.booking?.total_cancellation_fee}} </b>{{b?.booking?.currencycode}}
        </div>
    </div>
    <div class="row my-1">
        <div class="col-12">
            {{"cena_calkowita_z_podatkami_i_oplatami" | transloco}} <br />
            {{"rezerwacje" | transloco}}: {{b?.booking?.room?.length}}x<br />
            <b>{{sum}} </b>{{b?.booking?.currencycode}}
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="row">
        <div class="col-12">
            <h2>{{"karta_kredytowa" | transloco}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-7">
            {{"numer" | transloco}}:<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_number) ? b?.booking?.customer?.cc_number : '-'}}</b>
        </div>
        <div class="col-12 col-md-5">
            {{"nazwisko" | transloco}}<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_name) ? b?.booking?.customer?.cc_name : '-'}}</b>
        </div>
    </div>
    <div class="row my-2">
        <div class="col-12 col-md-4">
            {{"typ" | transloco}}:<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_type) ? b?.booking?.customer?.cc_type : '-'}}</b>
        </div>
        <div class="col-12 col-md-3">
            CVC<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_cvc) ? b?.booking?.customer?.cc_cvc : '-'}}</b>
        </div>
        <div class="col-12 col-md-5">
            {{"data_wygasniecia" | transloco}}<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_expiration_date) ? b?.booking?.customer?.cc_expiration_date :
                '-'}}</b>
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="row">
        <div class="col-12">
            <h2>{{"szczegoly" | transloco}}</h2>
        </div>
    </div>

    <div class="col-12" *ngIf="!isNullOrEmpty(b?.booking?.status) && b?.booking?.status != 'cancelled' || b?.imported == 1">
        Room Reservation ID:<br />
        <b>{{b?.room_booking?.roomreservation_id}}</b>
    </div>
    <div class="row my-2">
        <div class="col-12 col-md-6">
            {{"pokoj" | transloco}} / ID<br />
            <b>{{isNullOrEmpty(b?.room_booking.name) ? '' : b?.room_booking.name+'/'}}{{b?.room_booking?.id}}</b>
        </div>
        <div class="col-12 col-md-6">
            {{"nazwisko"| transloco}}: <br />
            <b >
                {{!isNullOrEmpty(b?.room_booking?.guest_name) ?  b?.room_booking?.guest_name : b?.booking?.customer?.first_name +' '+b?.booking?.customer?.last_name}}
                <span *ngIf="!isNullOrEmpty(b?.booking?.reservation_extra_info?.flags?.flag['@attributes']?.name)" class="badge badge-info">
                    {{b?.booking?.reservation_extra_info?.flags?.flag['@attributes']?.name}}
                </span>
            </b>
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>


    <div class="row">
        <div class="col-12 col-md-6">
            <b>{{"wyzywienie"| transloco}}</b><br />
            {{!isNullOrEmpty(b?.room_booking?.meal_plan) ? b?.room_booking?.meal_plan : '-'}}
        </div>
        <div class="col-12 col-md-6">
            <b>{{"extra_info"| transloco}}</b><br />  
            <p>{{!isNullOrEmpty(b?.room_booking?.extra_info) ? b?.room_booking?.extra_info : '-'}}</p>   
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="row">
        <div class="col-12 col-md-4">
            {{"palenie" | transloco}}:<br />
            <b>{{isNullOrEmpty(b?.booking?.smoking) ? translate.translate('nie') : translate.translate('tak')}}</b>
        </div>
        <div class="col-12 col-md-3">
            {{"cena_za_pobyt" | transloco}}<br />
            <b>{{b?.room_booking?.totalprice}} </b>{{b?.booking?.currencycode}}
        </div>
        <div class="col-12 col-md-5">
            {{"cena_calkowita_z_podatkami_i_oplatami" | transloco}}<br />
            <b *ngIf="!isNullOrEmpty(b?.room_booking?.price_details?.guest?.total)"> {{b?.room_booking?.price_details?.guest?.total}}</b>
            <b *ngIf="isNullOrEmpty(b?.room_booking?.price_details?.guest?.total)"> {{b?.room_booking?.totalprice}}</b>
            {{b?.booking?.currencycode}}
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="row" *ngIf="b?.room_booking?.addons?.length > 0">
        <div class="col-12">
            <h2>{{"dodatki" | transloco}}</h2>
        </div>
    </div>

    <div class="table-responsibe" *ngIf="b?.room_booking?.addons?.length > 0">
        <table class="table table-striped table-hovered">
            <thead>
                <tr>
                    <th>{{"nazwa" | transloco}}</th>
                    <th>{{"liczba_nocy" | transloco}}</th>
                    <th>{{"liczba_osob" | transloco}}</th>
                    <th>{{"cena_za_dobe" | transloco}}</th>
                    <th>{{"razem" | transloco}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let addon of b?.room_booking?.addons">
                    <td>{{addon?.name}}</td>
                    <td>{{addon?.nights}}</td>
                    <td>{{addon?.persons}}</td>
                    <td><b>{{addon?.price_per_unit?.toFixed(2)}}</b> {{b?.booking?.currencycode}}</td>
                    <td><b>{{addon?.totalprice?.toFixed(2)}}</b> {{b?.booking?.currencycode}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <div class="col-12">
            <h2>{{"plan_cenowy" | transloco}}</h2>
        </div>
    </div>


    <div class="table-responsibe">
        <table class="table table-striped table-hovered">
            <thead>
                <tr>
                    <th>{{"nazwa" | transloco}}</th>
                    <th>{{"data" | transloco}}</th>
                    <th>{{"id_planu" | transloco}}</th>
                    <th>{{"cena" | transloco}}</th>
                    <th>{{"wskaznik_geniuszu" | transloco}}</th>
                    <th>{{"nazwa_promocji" | transloco}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let price of b?.room_booking?.price">
                    <td>Plan cenowy: {{price?.['@attributes'].rate_id}} {{getRatePlanName(price?.['@attributes'].rate_id)}}</td>
                    <td>{{price?.['@attributes']?.date}}</td>
                    <td>{{price?.['@attributes']?.rate_id}}</td>
                    <td><b>{{price?.['@attributes']?.nodeValue}}</b> {{b?.booking?.currencycode}}</td>
                    <td><b>{{price?.['@attributes']?.genius_rate == 'no' ? translate.translate('nie'): translate.translate('tak')}}</b></td>
                    <td><b>{{isNullOrEmpty(price?.['@attributes']?.rewritten_from_name) ? '-' : price['@attributes']?.rewritten_from_name}}</b></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <div class="col-12">
            <h2>Tax and fee</h2>
        </div>
    </div>

    <div class="table-responsibe">
        <table class="table table-striped table-hovered">
            <thead>
                <tr>
                    <th>{{"nazwa" | transloco}}</th>
                    <th>{{"zawiera" | transloco}}</th>
                    <th>{{"za_noc" | transloco}}</th>
                    <th>{{"za_osobe" | transloco}}</th>
                    <th>{{"procent" | transloco}}</th>
                    <th>{{"kwota" | transloco}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tax of b?.room_booking?.price_details?.guest?.extracomponent">
                    <td>{{tax['@attributes']?.text}}</td>
                    <td>{{tax['@attributes']?.included == 'yes' ? translate.translate('tak') : translate.translate('nie')}}</td>
                    <td>{{tax['@attributes']?.per_night == 'yes' ? translate.translate('tak') : translate.translate('nie')}}</td>
                    <td>{{tax['@attributes']?.per_person == 'yes' ? translate.translate('tak') : translate.translate('nie')}}</td>
                    <td>{{tax['@attributes']?.percentage == 'no' ? translate.translate('nie') : tax['@attributes']?.percentage}}</td>
                    <td><b>{{tax['@attributes']?.amount}}</b> {{tax['@attributes']?.currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>


    <div *ngIf="!isNullOrEmpty(b?.room_booking?.cancel_penalties.cancel_penalty)">

        <div class="row">
            <div class="col-12">
                <h2>{{"kary_za_anulowanie" | transloco}}</h2>
            </div>
        </div>

        <div class="table-responsibe">
            <table class="table table-striped table-hovered">
                <thead>
                    <tr>
                        <th>{{"od" | transloco}}</th>
                        <th>{{"do" | transloco}}</th>
                        <th>{{"ostateczny_termin" | transloco}}</th>
                        <th>{{"kwota" | transloco}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let penalty of b?.room_booking?.cancel_penalties.cancel_penalty">
                        <td>
                            <date-formatter [date]="penalty?.['@attributes']?.from"></date-formatter>
                        </td>
                        <td>
                            <date-formatter [date]="penalty?.['@attributes']?.until"></date-formatter>
                        </td>
                        <td>
                            <date-formatter *ngIf="!isNullOrEmpty(penalty?.deadline?.['@attributes']?.time)" [date]="penalty?.deadline?.['@attributes']?.time"></date-formatter>
                            <p *ngIf="isNullOrEmpty(penalty?.deadline?.['@attributes']?.time)">-</p>
                        </td>
                        <td>{{penalty?.amount_percent?.['@attributes']?.amount}} {{penalty?.amount_percent?.['@attributes']?.currency_code}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
   

    <div class="mb-3"></div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="bg-gray-50">
    <button mat-stroked-button mat-dialog-close class="mc-button">{{'zamknij_okno' | transloco}}</button>
</mat-dialog-actions>