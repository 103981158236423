import { CdkPortalOutlet, ComponentPortal, Portal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector, OnDestroy, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { PermissionsService } from './permissions.service';
import { SnackbarService } from './snackbar.service';
import { TranslocoService } from '@ngneat/transloco';
import { CreateOrUpdateClientDialogComponent } from '../dialogs/create-or-update-client-dialog/create-or-update-client-dialog.component';
import { CreateOrUpdateRoomDialogComponent } from '../dialogs/create-or-update-room-dialog/create-or-update-room-dialog.component';
import { DbReservation } from '../models/DbReservation';
import { UserPermissions } from '../models/UserPermissions';
import { DATA_INJECTION_TOKEN } from '../others/data-injection-token';
import { AddReservationTabsDialogComponent } from '../dialogs/add-reservation-tabs-dialog/add-reservation-tabs-dialog.component';
import { AddOrUpdateTaskComponent } from 'app/modules/admin/tasks/add-or-update-task/add-or-update-task.component';
import { AddOrUpdateSectionComponent } from 'app/modules/admin/tasks/add-or-update-section/add-or-update-section.component';
import { ChangesInBulkDialogComponent } from '../dialogs/changes-in-bulk-dialog/changes-in-bulk-dialog.component';
import { AddOrUpdateAdditionalServiceDialogComponent } from '../dialogs/add-or-update-additional-service-dialog/add-or-update-additional-service-dialog.component';
import { ChannelManagerLogDialogComponent } from '../dialogs/channel-manager-log-dialog/channel-manager-log-dialog.component';
import { SettingsService } from './settings.service';
import { UserSettings } from '../models/UserSettings';
import { Utils } from '../others/utils';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { NavigationStart, Router } from '@angular/router';
import { ReservationsListDialogComponent } from '../dialogs/reservations-list-dialog/reservations-list-dialog.component';
import { cloneDeep } from 'lodash-es';
import { AddOrUpdateTaskOrCleaningComponent } from '../dialogs/add-or-update-task-or-cleaning/add-or-update-task-or-cleaning.component';

@Injectable({
  providedIn: 'root'
})

export class PanelService implements OnDestroy {

  @ViewChild('panel') panel: MatDrawer;

  private readonly _subscriptions: Subscription[] = [];
  stackOfPortals = [];
  public activePortal$ = new Subject<Portal<any>>();

  private onCloseEvent$ = new Subject<any>();
  private hasActivePortal$ = new Subject<boolean>();

  readonly hasPortal = this.hasActivePortal$.asObservable();
  readonly onCloseEvent = this.onCloseEvent$.asObservable();

 
  readonly portal$ = this.activePortal$.asObservable();

  permissions: UserPermissions = new UserPermissions({})


  constructor(
    private _permissionsService: PermissionsService,
    private _snackbar: SnackbarService,
    private _translate: TranslocoService,
    private _settingsService: SettingsService,
    private _localize: LocalizeRouterService,
    private _router: Router
  ) {

    this._subscriptions.push(this._permissionsService.currentPermissions.subscribe({
      next: (data) => {
        this.permissions = data
      }
    }))

    
  }

  updatePermissions(permissions) {
    this.permissions = permissions
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  /** Resets the current panel portal. */
  clearPanelPortal(cleanStack = false) {
    this.activePortal$.next(null);
    this.hasActivePortal$.next(false);
    if (cleanStack) this.stackOfPortals = [];
  }

  async setPortal(portal) {

    // this._portalOutlet.detach();

    // this.currentPortal = portal;
    this.activePortal$.next(portal);
    this.hasActivePortal$.next(true);

    // const componentRef = this._portalOutlet.attachComponentPortal(portal);
    // this._currentComponentInstance = componentRef.instance;
    // return componentRef;

  }

  setPanel(panel) {
    this.panel = panel;
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  back() {
    if (this.stackOfPortals.length > 1) {
      const lastPortal = this.stackOfPortals[this.stackOfPortals.length - 2];
      this.open(lastPortal, false);
      this.stackOfPortals.splice(this.stackOfPortals.length - 2, 1)
    }
  }

  /** Opens the panel with optionally a portal to be set. */
  async open(portal?: ComponentPortal<any>, addToStack = true) {

    if (addToStack) this.stackOfPortals.push(portal);

    const p = new Promise(async (resolve) => {
      this.hasActivePortal$.next(false);
      if (this.panel.opened) { resolve(true); return; }

      await this.panel.open()
      this.hasActivePortal$.next(true);

      resolve(true)
    })

    p.then((res) => {
      if (portal) {
        this.setPortal(portal)
      }
    }
    )

  }

  async showCreateOrUpdateRoom(mode: 'create' | 'update', room, activeTabIndex = 0) {
    if (this._settingsService.getPremiumDays() <= 0) {
      console.log(`[PanelService]: No premium days left. Redirecting...`)
      const translatedPath = this._localize.translateRoute('/app/subscription/expired');
      this._router.navigate([translatedPath]);
      return
    }

    if (mode == 'create') {
      if (!this.checkPermission('addRoom')) return
    }

    if (mode == 'update') {
      if (!this.checkPermission('editRoom')) return
    }

    await this.open(new ComponentPortal<CreateOrUpdateRoomDialogComponent>(
      CreateOrUpdateRoomDialogComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {
            activeTabIndex: activeTabIndex,
            mode: mode,
            room: room
          }
        }],
      })
    ), true)
  }

  async showChannelManagerLog(log) {

    // CHECK PERMISSIONS
    // if(mode == 'create') {
    //   if(!this.checkPermission('dodaj_pokoj')) return
    // }

    await this.open(new ComponentPortal<ChannelManagerLogDialogComponent>(
      ChannelManagerLogDialogComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {
            log: log
          }
        }],
      })
    ))
  }

  async showChangeInBulkPanel() {
    await this.open(new ComponentPortal<ChangesInBulkDialogComponent>(
      ChangesInBulkDialogComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {

          }
        }],
      })
    ))
  }

  async showCreateOrUpdateClient(mode, client = undefined) {
    if (this._settingsService.getPremiumDays() <= 0) {
      console.log(`[PanelService]: No premium days left. Redirecting...`)
      const translatedPath = this._localize.translateRoute('/app/subscription/expired');
      this._router.navigate([translatedPath]);
      return
    }

    await this.open(new ComponentPortal<CreateOrUpdateClientDialogComponent>(
      CreateOrUpdateClientDialogComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {
            mode: mode,
            client: client
          }
        }],
      })
    ))
  }

  async openUpdateReservationPanel(reservation: DbReservation) {

    if (!this.checkPermission('showReservation')) return

    await this.open(new ComponentPortal<AddReservationTabsDialogComponent>(
      AddReservationTabsDialogComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {
            mode: 'update',
            reservationId: reservation.reservationId,
            roomId: reservation.roomId,
            startDate: reservation.arrival,
            endDate: reservation.departure,
            reservation: reservation
          }
        }],
      })
    ), true)
  }

  openCreateReservationPanel(
    { roomIdOrIds, startDate, endDate, type = 0, forename, name, email, phone, adults, children }: {
      roomIdOrIds: number | number[], startDate: string, endDate: string, type?: number, forename?: string, name?: string, email?: string, phone?: string, adults?: number, children?: number
    }) {

    if (this._settingsService.getPremiumDays() <= 0) {
      console.log(`[PanelService]: No premium days left. Redirecting...`)
      const translatedPath = this._localize.translateRoute('/app/subscription/expired');
      this._router.navigate([translatedPath]);
      return
    }

    if (typeof roomIdOrIds == 'undefined' || roomIdOrIds == null) {
      roomIdOrIds = null
    }

    if (typeof roomIdOrIds == 'string') {
      roomIdOrIds = parseInt(roomIdOrIds)
    }

    // if (!(startDate instanceof Date)) {
    //   console.error("Invlid start date type.")
    // }
    // if (!(endDate instanceof Date)) {
    //   console.error("Invlid end date type.")
    // }

    if (!this.checkPermission('addReservation')) return
    if ((typeof type == 'undefined' || type == null) && !Array.isArray(roomIdOrIds)) type = 0;
    if ((typeof type == 'undefined' || type == null) && Array.isArray(roomIdOrIds)) type = 1;

    if (type == 0 && Array.isArray(roomIdOrIds)) {
      console.error("Can't send array with type single.", roomIdOrIds)
    }

    this.open(new ComponentPortal<AddReservationTabsDialogComponent>(
      AddReservationTabsDialogComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN, useValue: {
            mode: 'create',
            roomIdOrIds: roomIdOrIds,
            startDate: startDate,
            endDate: endDate,
            type: type,
            forename: forename,
            name: name,
            email: email,
            phone: phone,
            adults: adults,
            children: children
          }
        }],
      })
    ), true)

  }

  async showAddOrUpdateTask(task: any) {
    if (this._settingsService.getPremiumDays() <= 0) {
      console.log(`[PanelService]: No premium days left. Redirecting...`)
      const translatedPath = this._localize.translateRoute('/app/subscription/expired');
      this._router.navigate([translatedPath]);
      return
    }
    // TODO TASK PERMISSION
    // if(!this.checkPermission('edytuj_rezerwacje')) return

    await this.open(new ComponentPortal<AddOrUpdateTaskComponent>(
      AddOrUpdateTaskComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {
            task: task
          }
        }],
      })
    ))
  }

  async showAddOrUpdateTaskOrCleaning(mode: 'TASK' | 'CLEANING') {
 
    await this.open(new ComponentPortal<AddOrUpdateTaskOrCleaningComponent>(
      AddOrUpdateTaskOrCleaningComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {
            mode: mode
          }
        }],
      })
    ))
  }

  async showAddOrUpdateSection(section: any) {
    if (this._settingsService.getPremiumDays() <= 0) {
      console.log(`[PanelService]: No premium days left. Redirecting...`)
      const translatedPath = this._localize.translateRoute('/app/subscription/expired');
      this._router.navigate([translatedPath]);
      return
    }
    // TODO TASK PERMISSION
    // if(!this.checkPermission('edytuj_rezerwacje')) return
    await this.open(new ComponentPortal<AddOrUpdateSectionComponent>(
      AddOrUpdateSectionComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {
            section: section
          }
        }],
      })
    ))
  }

  async showReservationsPanel(reservations, table) {

    this.clearPanelPortal(true);

    await this.open(new ComponentPortal<ReservationsListDialogComponent>(
      ReservationsListDialogComponent,
      null,
      Injector.create({
        providers: [{
          provide: DATA_INJECTION_TOKEN,
          useValue: {
            reservations: reservations,
            table: table
          }
        }],
      })
    ), false)
  }

  /** Toggles the panel. */
  toggle() {
    return this.panel.toggle();
  }

  /** Closes the panel. */
  close() {
    this.onCloseEvent$.next(true)
    this.activePortal$.next(null);
    this.clearPanelPortal();
    this.hasActivePortal$.next(false);
    this.stackOfPortals = [];
    return this.panel.close();

  }

  checkPermission(permission) {
    if (this.permissions.has(permission)) return true
    this._snackbar.showSnackBar(this._translate.translate('brak_uprawnien'), "info", 3000)
    return false
  }

}


