import { Settings } from "./Settings";
import { TranslocoService } from "@ngneat/transloco";
import { SettingsService } from "../services/settings.service";
import { Utils } from "../others/utils";
import { Injectable, inject } from "@angular/core";
import { LightStyles } from "../data/light-styles";
import { ApplicationData } from "../data/application-data";
import { CONST } from "../data/const";
import { DbReservation } from "./DbReservation";
import { UserSettings } from "./UserSettings";
import { Source } from "./Source";
import { PaymentStatuses } from "./payment-statuses.enum";

export class CalendarReservation {

  // private readonly translate = inject(TranslocoService);
  // private readonly settingsService = inject(SettingsService);

  private readonly _settings:UserSettings = new UserSettings({})
  private readonly _sources: Source[] = []

  private readonly _dayInMilliseconds = CONST.DAYINMILLIS;
  private readonly _employees:any;
  private readonly _reservation: DbReservation;
  private readonly translate;
  private readonly _today = new Date();

  private maxChars = 3;
  private charsPerCol = 5.5;
  
  constructor({
    settings,
    sources,
    employees,
    translate,
    reservation
  }: {
    settings:UserSettings, sources: Source[], employees: any, translate:TranslocoService, reservation: DbReservation
  }
    )
  {
    this._settings = settings;
    this.translate = translate
    this._sources = sources;

    if(this._sources.length == 0 || this._sources == null) {
      console.error("Calendar reservation: Sources not loaded. System sources should be loaded here, so your flow is broken.")
    }

    this._today.setHours(0,0,0,0);
    this._employees = employees;
    this._reservation = reservation

    this.arrival = new Date(this._reservation.arrival).getTime();
    this.departure = new Date(this._reservation.departure).getTime();
    
    this.sizeCols = (this.departure - this.arrival) / this._dayInMilliseconds;
    this.width = (ApplicationData.CalendarHeaderWidth * this.sizeCols) - (ApplicationData.ReservationPaddingLeft * 2) - ApplicationData.ReservationPaddingRight
    this.height = ApplicationData.DefaultReservationHeight - (ApplicationData.ReservationPaddingTop * 2)
    
    this.setIsLock();
    this.setText();
    this.setColor();
    this.setSource();

  }

  isLock:boolean;
  text:string;
  hasSource:boolean;
  sourceId = null;
  sourceLogo = ''
  sourceInitial = '';
  sourceColor = 'white';
  sourceTextColor = 'black';
  width;
  height;
  sizeCols:number;
  arrival:number;
  departure:number;
  color:string;
  numberOfDays: number;

  private setIsLock()
  {
    this.isLock = Utils.isLock(this._reservation)
  }
  
  private setColor()
  {
    if (this._reservation.clientId != null) {

      let color = LightStyles.DefaultReservationRectColor;
      switch (this._reservation.color) {
        case null: { color = this.getColor(this._reservation.paymentStatus, this._reservation.prepaymentDeadline); break; }
        case '': { color = this.getColor(this._reservation.paymentStatus, this._reservation.prepaymentDeadline); break; }
        default: { color = this._reservation.color; break; }
      }
      this.color = color;
    }
  }

  private getColor(paymentStatus, prepaymentDeadline) {
    if(typeof paymentStatus  == 'string') console.error("Invalid status 'getColor CalendarReservation'")
    if(!Utils.isDefined(this._settings,"settings.calendarColorsTurnOn")) {return LightStyles.Reservations.typ1d}

    const currentPaymentStatus = Utils.getPaymentStatus(this._today,paymentStatus,prepaymentDeadline);
    if (currentPaymentStatus == PaymentStatuses.oczekiwanie_na_zaliczke) { return this._settings.settings.calendarColorsTurnOn === 1 ? this._settings.settings.calendarColorsWaitingForPrepayment : LightStyles.Reservations.typ1w }
    else if (currentPaymentStatus == PaymentStatuses.uplynal_termin_wplaty_zaliczki || currentPaymentStatus == PaymentStatuses.brak_wplaty) { return this._settings.settings.calendarColorsTurnOn === 1 ? this._settings.settings.calendarColorsNoPayment : LightStyles.Reservations.typ1d }
    else if (currentPaymentStatus == PaymentStatuses.wplacony_zadatek) { return this._settings.settings.calendarColorsTurnOn === 1 ? this._settings.settings.calendarColorsPaidPrepayment : LightStyles.Reservations.typ1 }
    else if (currentPaymentStatus == PaymentStatuses.wplacona_calosc) { return this._settings.settings.calendarColorsTurnOn === 1 ? this._settings.settings.calendarColorsPaidAll : LightStyles.Reservations.typ1i }
    else if (currentPaymentStatus == PaymentStatuses.oczekiwanie_na_platnosc_online) { return this._settings.settings.calendarColorsTurnOn === 1 ? this._settings.settings.calendarColorsWaitingForPayment: LightStyles.Reservations.typ1waiting }
    return LightStyles.Reservations.typ1d
  }

  private setText()
  {
    let text = this.getText();
    if(this.hasSource) {
      this.charsPerCol = 5;
    }

    if(this.sizeCols > 1) { this.maxChars = this.sizeCols * this.charsPerCol}
    
    if(text.length >= this.maxChars) {
      this.text = text.slice(0, this.maxChars) 
      if(this.sizeCols > 2) this.text += "..."
      else this.text += ".."
    }
    else this.text = text
  }
  
  // 👶
  private getText():string
  {
    if(this.isLock) {
      let comment = !Utils.isNullOrEmpty(this._reservation.additionalInfo) ? '('+this._reservation.additionalInfo+')' : '';
      return this.text = this.translate.translate('blokada') + comment;
    }

    let text = '';

    if(Utils.isDefined(this._settings,"settings.calendarLabels"))
    {
      ApplicationData.LabelMappedToObjectProperty.forEach((element) => {
        let isOn = this._settings.settings.calendarLabels.includes(element.key)
      
        if(!isOn) return;
          
        let value = Utils.getNestedValue(this._reservation, element.objKey);
        switch(element.key)
        {
          case 'reservationNo':
            if(!Utils.isNullOrEmpty(this._reservation.groupId) && this._reservation.groupId > 0) text += `(${this.translate.translate('grupa')} ${this._reservation.groupId}) `
            else text += `#${value} `;

          break;

          case 'source':
            const source = this._sources.find(x=>x.sourceId == value);
            if(typeof source != 'undefined') text += `${source.name} `
          break;

          case 'daysStay':
            const days = (new Date(this._reservation.departure).getTime() - new Date(this._reservation.arrival).getTime()) / this._dayInMilliseconds;
            this.numberOfDays = days;
            text += `${days}x ` + this.translate.translate('dni')
          break;

          case 'sentMessages':
            text += `${this._reservation.sentMessages} ✉` + " ";
          break;

          case 'employee':
            const worker = this._employees[this._reservation.employeeId]
            if(typeof worker != 'undefined') text += `${worker.forename} ${worker.name}` + " ";
            else text += `${this._settings.userData.forename} ${this._settings.userData.name}`; 
          break;

          default:
            text += value + " ";
          break;
  
        }

      });
    }
            
    return text;
  }

  private setSource()
  {
    if(!Utils.isDefined(this._settings,"settings.calendarDisplaySource"))  return
    if(!!this._settings.settings.calendarDisplaySource == true)
    {
      const sourceId = this._reservation.sourceId

      if(sourceId >= 0)
      {
        this.hasSource = true;
        this.sourceId = sourceId;
        const sourceObj = this._sources.find(x=>x.sourceId == this.sourceId)
        
        if(typeof sourceObj != 'undefined') 
        {
          const newSourceObj = new Source(sourceObj);
          this.sourceInitial = newSourceObj.initial
          this.sourceLogo = newSourceObj.logo
          this.sourceColor = newSourceObj.color || '#FFFFFF';
          this.sourceTextColor = newSourceObj.textColor
        }

      }
    }
  }
}