import { Injectable, OnDestroy, inject } from "@angular/core";
import { Source } from "../models/Source";
import { AppDB } from "../models/db";
import { DbSource } from "../models/DbSource";
import { BehaviorSubject, Observable, Subscription, from, of } from "rxjs";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
  providedIn: 'root'
})
export class SourcesService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _sources = new BehaviorSubject<Source[]>([])
  public currentSources: Observable<Source[]> = this._sources.asObservable()

  private _db: AppDB

  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService,
    private _translocoService: TranslocoService
  ) {

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
        }
      })
    )

    // Add sources on synchronize
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
        next: (data) => {
          if (!data) return
          if (data.table != 'sources') return

          data.value = data.value.map((source: any) => new Source(source))

          // Add or update
          data.value.forEach((source: Source) => {
            const index = this._sources.value.findIndex(x => x.sourceId === source.sourceId)
            if (index >= 0) this._sources.value[index] = source
            else this._sources.value.push(source)
          });

          // Problem with undefined status of sources
          this._sources.next(this._sources.value.filter(x => x.status === 1))
          // this._sources.next(this._sources.value)
          this._dataSynchronizerService.received()
        }
      }))
  }

  getSources(): Source[] {
    return this._sources.value
  }
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  getAll(): Observable<Source[]> {
    this._sources.next([]);
    if(!this._db) return of([])

    return from(
      this._db.transaction("r", this._db.sources, () => {
        return this._db.sources.where({ status: 1 }).toArray();
      })
        .then((result: DbSource[]) => {

          const sources: Source[] = result.map(source => new Source(source))
          // Translate system sources
          // We use sources in text labels
          // So they have to be translated in .ts not using pipes.
          sources.forEach((source: Source) => {
            if (!source.isCustom) {
              source.name = this._translocoService.translate(source.name)
            }
          })

          this._sources.next(sources)
          return sources;
        })
    );
  }

  getSourceById(sourceId): Source {
    const source = this._sources.value.find(x => x.sourceId == sourceId)
    // console.log("sources: ", this.sources)
    // console.log("sourceId: ", sourceId)
    // console.log("source: ", source)
    if (source) return source
    else console.warn(`Source with id: ${sourceId} not found.`)
  }
}
