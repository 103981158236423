import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SynchronizationDialogComponent } from 'app/core/shared/dialogs/synchronization-dialog/synchronization-dialog.component';
import { InternetConnectionService } from 'app/core/shared/services/internet-connection.service';
import { SynchronizationService } from 'app/core/shared/services/synchronization.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {MatBadgeModule} from '@angular/material/badge';
import { CONST } from 'app/core/shared/data/const';

@Component({
  selector: 'synchronization',
  templateUrl: './synchronization.component.html',
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs       : 'synchronization',
  standalone     : true,
  imports        : [MatButtonModule, MatBadgeModule, MatTooltipModule, NgIf, MatIconModule, NgClass, MatDividerModule, TranslocoModule],
})
export class SynchronizationComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  subscriptions: Subscription[] = []
  isSynchronizing = false
  isSyncDialogShown = false
  constructor(
    private dialog: MatDialog,
    public ics: InternetConnectionService, 
    private synchronizationService:SynchronizationService, 
    public translate:TranslocoService,
    private cdr: ChangeDetectorRef
  ) {
    
  }
  interval
  ngOnInit(): void {

    this.interval = setInterval(() => {
      // this.getNofityCount();
      if (!this.ics.offlineMode.value) this.synchronize();
    }, 60000);

    this.subscriptions.push(this.synchronizationService.currentSynchronizationStatus.subscribe(
      data => {

        if(this.isSynchronizing == true && data.isSynchronizing == false) {
          setTimeout(()=> {
            this.isSynchronizing = data.isSynchronizing;
            this.cdr.markForCheck()
          },750)
        } 
        else {
          this.isSynchronizing = data.isSynchronizing;
          this.cdr.markForCheck()
        }
        
        if (data.isSynchronizing == true) {
          if ((data.isSynchronizing && data.allPages > 1 && !this.isSyncDialogShown) || data.lastSyncAt == CONST.DEFAULT_SYNC_TIME) {
            this.showSynchronizationDialog()
          }
        }
    }));

    this.ics.offlineMode
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
            next: (mode) => {
              this.cdr.markForCheck()
            }
        })
  }

  synchronize()
  {
    this.synchronizationService.newSynchronize('toolbar')
  }

  showSynchronizationDialog() {
    // Prevent showing dialog multiple times
    if(this.isSyncDialogShown == true) return
    this.isSyncDialogShown = true;


    return this.dialog.open(SynchronizationDialogComponent, {
      disableClose: true,
      maxWidth: '100%'
    })
    .afterClosed().subscribe(
      (data) => {
        this.isSyncDialogShown = false;
      }
    );
  }

  ngOnDestroy() {
    if (this.interval) clearInterval(this.interval);

    this.subscriptions.forEach(sub => sub.unsubscribe());
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
