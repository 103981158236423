<mat-progress-bar mode="indeterminate"></mat-progress-bar>
<fuse-card class="flex flex-col items-center max-w-80 w-full p-8 filter-info">
    <div class="relative flex flex-col w-full h-full">
        <!-- Content -->
        <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start">
            <!-- Icon -->
            <div class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
                <mat-icon class="text-current icn-spinner" [svgIcon]="'heroicons_solid:arrow-path'"></mat-icon>
            </div>
            <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
                <!-- Title -->
                <div class="text-xl leading-6 font-medium" [innerHTML]="title"></div>
                <!-- Message -->
                <div class="text-secondary" [innerHTML]="content"></div>
            </div>
        </div>
    </div>
</fuse-card>