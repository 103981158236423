<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            <!-- create -->
            <p class="text-lg font-medium">
                <ng-container *ngIf="mode == 'create'">
                    {{'dodaj_pomieszczenie' | transloco}}
                </ng-container>
                <ng-container *ngIf="mode == 'update'">
                    {{'edycja_pokoju' | transloco}}
                </ng-container>
            </p>
            <div class="ml-auto">
                <button mat-icon-button [matTooltip]="'zamknij_okno' | transloco" (click)="close()">
                    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
    </div>
    <!--tabs-->
    <div class="relative flex flex-col items-center"> <!-- flex-auto -->
        <div class="w-full max-w-3xl">
            <!-- form -->
            <form [formGroup]="createOrUpdateRoomForm">
                <!-- tabs -->
                <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex"
                    (selectedIndexChange)="changeTab($event)" class="vertically-tabs p-0-content"
                    mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight dynamicWidth>
                    <mat-tab>
                        <ng-template matTabLabel>
                            {{'szczegoly' | transloco}}
                        </ng-template>
                    </mat-tab>

                    <mat-tab>
                        <ng-template matTabLabel>
                            {{'informacje' | transloco}}
                        </ng-template>
                    </mat-tab>

                    <mat-tab *ngIf="mode == 'update'">
                        <ng-template matTabLabel>
                            {{'sprzatanie' | transloco}}
                        </ng-template>
                    </mat-tab>

                    <mat-tab *ngIf="mode == 'update'">
                        <ng-template matTabLabel>
                            {{'zdjecia' | transloco}}
                        </ng-template>
                    </mat-tab>

                </mat-tab-group>
            </form>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <ng-container>
                <!-- body -->
                <div class="px-6 py-4">
                    <ng-container *ngIf="tabIndex === 0">
                        <ng-container *ngTemplateOutlet="details"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="tabIndex === 1">
                        <ng-container *ngTemplateOutlet="informations"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="tabIndex === 2">
                        <ng-container *ngTemplateOutlet="cleaning"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="tabIndex === 3">
                        <ng-container *ngTemplateOutlet="photos"></ng-container>
                    </ng-container>
                </div>
                <!-- footer -->
                <div
                    class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
                    <div class="flex items-center">

                        <button [disabled]="processing || !permissions.has('deleteRoom')" (click)="deleteRoom()"
                            color="warn" class="me-2" mat-button
                            [matTooltip]="!permissions.has('deleteRoom') ? translate.translate('brak_uprawnien') : ''"
                            *ngIf="mode == 'update'">
                            {{"usun" | transloco}}
                        </button>

                        <button mat-button (click)="close()" class="mc-button ml-auto">{{'anuluj' | transloco}}</button>


                        <button mat-raised-button [disabled]="isProcessing"
                            [disabled]="mode == 'create' && !permissions.has('addRoom') || mode == 'update' && !permissions.has('editRoom')"
                            [matTooltip]="(mode == 'create' && !permissions.has('addRoom') || mode == 'update' && !permissions.has('editRoom')) ? translate.translate('brak_uprawnien') : ''"
                            color="primary" class="ml-2" (click)="createOrUpdate()">
                            {{mode == 'create' ? ('dodaj_pokoj'| transloco) : ('zapisz_zmiany'| transloco)}}
                        </button>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #details>
    <div *ngIf="mode == 'update'" class="mb-6">
        <span class="h-6 min-h-6 px-2 py-1 rounded-full bg-hover">
            <span class="font-medium text-sm text-secondary">ID: {{createOrUpdateRoomForm.controls.roomId.value}}</span>
        </span>
    </div>
    <div class="grid sm:grid-cols-4 gap-6 w-full">
        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'nazwa_pomieszczenia' | transloco}}</mat-label>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                <input matInput type="text" [formControl]="createOrUpdateRoomForm.controls.name" />
                <mat-error *ngIf="createOrUpdateRoomForm.controls['name'].hasError('required')">
                    {{'to_pole_jest_wymagane' | transloco}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'typ_pomieszczenia' | transloco}}</mat-label>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:home'"></mat-icon>
                <mat-select matInput [formControl]="createOrUpdateRoomForm.controls.roomType">
                    <mat-option [value]="roomType.id" *ngFor="let roomType of roomTypes">
                        {{roomType.text | transloco}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="sm:col-span-1">
            <mat-slide-toggle class="pt-9" [formControl]="createOrUpdateRoomForm.controls.ownColor">
                {{'wlasny_kolor' | transloco}}
            </mat-slide-toggle>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full mt-6" [subscriptSizing]="'dynamic'" ngx-colors-trigger="colorPicker"
                *ngIf="createOrUpdateRoomForm.controls.ownColor.value == true" [hideTextInput]="false"
                [colorPickerControls]="colorPickerControls" [acceptLabel]="translate.translate('wybierz')"
                [cancelLabel]="translate.translate('anuluj')" (change)="updateColor($event)">
                <input readonly matInput [formControl]="createOrUpdateRoomForm.controls.color">
                <ngx-colors #colorPicker [formControl]="createOrUpdateRoomForm.controls.colorPckr" ngx-colors-trigger matSuffix>
                </ngx-colors>
            </mat-form-field>
        </div>

        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'dodatkowe_informacje' | transloco}}</mat-label>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                <textarea matInput [formControl]="createOrUpdateRoomForm.controls.info" cdkAutosizeMinRows="5"
                    cdkAutosizeMaxRows="8"></textarea>
            </mat-form-field>
        </div>
        <div class="sm:col-span-4">
            <mat-slide-toggle [formControl]="createOrUpdateRoomForm.controls.isLocked">{{'pomieszczenie_nieaktywne' |
                transloco}}</mat-slide-toggle>
            <p class="text-secondary">
                <small>{{'ta_opcja_nie_powoduje_zablokowania_dostepnosci' | transloco}}</small>
            </p>
        </div>
    </div>
    <div class="grid sm:grid-cols-3 gap-6 w-full mt-6">
        <div class="sm:col-span-3">
            <p class="text-lg font-medium">{{'liczba_lozek' | transloco}}</p>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'jednoosobowe' | transloco}}</mat-label>
                <button mat-icon-button matPrefix (click)="decrement('singleBed', 0);">
                    <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                </button>
                <input matInput integerOnly [integerOnlyMinValue]="0" [integerOnlyMaxValue]="1" type="text"
                    class="text-center" [formControl]="createOrUpdateRoomForm.controls.singleBed" />
                <button mat-icon-button matSuffix (click)="increment('singleBed');">
                    <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'dwuosobowe' | transloco}}</mat-label>
                <button mat-icon-button matPrefix (click)="decrement('doubleBed', 0);">
                    <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                </button>
                <input matInput integerOnly [integerOnlyMinValue]="0" type="text" class="text-center"
                    [formControl]="createOrUpdateRoomForm.controls.doubleBed" />
                <button mat-icon-button matSuffix (click)="increment('doubleBed');">
                    <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'dostawka' | transloco}}</mat-label>
                <button mat-icon-button matPrefix (click)="decrement('extraBed', 0);">
                    <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                </button>
                <input matInput type="text" class="text-center"
                    [formControl]="createOrUpdateRoomForm.controls.extraBed" />
                <button mat-icon-button matSuffix (click)="increment('extraBed');">
                    <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'liczba_miejsc' | transloco}}</mat-label>
                <button mat-icon-button matPrefix (click)="decrement('persons', 0);">
                    <mat-icon [svgIcon]="'heroicons_solid:minus'"></mat-icon>
                </button>
                <input matInput type="text" class="text-center"
                    [formControl]="createOrUpdateRoomForm.controls.persons" />
                <button mat-icon-button matSuffix (click)="increment('persons');">
                    <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

</ng-template>
<ng-template #informations>
    <div class="grid sm:grid-cols-4 gap-6 w-full">
        <div class="sm:col-span-4">
            <mat-slide-toggle [formControl]="createOrUpdateRoomForm.controls.shareInOffer">{{'udostepnij_w_ofercie' |
                transloco}}</mat-slide-toggle>
            <p class="text-secondary">
                <small>{{'ta_opcja_powoduje_udostepnienie_tego_pokoju_w_systemie_rezerwacji_online' |
                    transloco}}</small>
            </p>
        </div>
        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'metraz' | transloco}}</mat-label>
                <input matInput [formControl]="createOrUpdateRoomForm.controls.square" type="text" />
                <span matTextSuffix>m<sup>2</sup></span>
            </mat-form-field>
        </div>
        <div class="sm:col-span-4">
            <p class="text-lg font-medium">{{'opis' | transloco}}</p>

            <mat-checkbox color="primary" class="-ml-2"
                [formControl]="createOrUpdateRoomForm.controls.showOtherLanguages">{{'pokaz_inne_jezyki' |
                transloco}}</mat-checkbox>

            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                *ngIf="createOrUpdateRoomForm.controls.showOtherLanguages.value === true">
                <mat-label>{{'jezyk' | transloco}}</mat-label>
                <mat-select [formControl]="createOrUpdateRoomForm.controls.language">
                    <mat-option value="default">{{'domyslny' | transloco}}</mat-option>
                    <mat-option [value]="lang.shortName" *ngFor="let lang of languages">{{lang?.name}}
                        <small
                            *ngIf="description?.[lang.shortName]?.length == 0 || description?.[lang.shortName] == null"
                            class="text-secondary">({{'brak' | transloco}})</small>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <quill-editor class="mt-4 w-full" [formControl]="createOrUpdateRoomForm.controls.descriptionTemp"
                [bounds]="'self'" [modules]="quillModules"></quill-editor>
        </div>
        <div class="sm:col-span-4">
            <p class="text-lg font-medium">{{'wyposazenie' | transloco}}
                ({{createOrUpdateRoomForm.controls.equipment.value.length}})</p>
        </div>
        <div class="sm:col-span-4 min-h-80">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'filtruj' | transloco}}</mat-label>
                <input #filterInput matInput type="text" (input)="filterRoomEquipment(filterInput.value)" />
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
            </mat-form-field>
            <mat-selection-list color="primary">
                <!-- Value prevent error of automatically selection -->
                <mat-list-option (click)="toggleEquipment(item?.id)" [selected]="selectedEquipment.has(item.id)" [value]="item.id"  *ngFor="let item of filteredRoomEquipment">
                    {{item?.name}}
                </mat-list-option>
            </mat-selection-list>
            <p *ngIf="filteredRoomEquipment?.length == 0" class="text-secondary">{{'nie_znaleziono_wynikow' | transloco}}</p>
        </div>
    </div>
</ng-template>

<ng-template #cleaning>
    <div class="grid sm:grid-cols-4 gap-6 w-full">
        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'status_pokoju' | transloco}}</mat-label>
                <span matSuffix>
                    <span class="ml-4 inline-block w-2 h-2 rounded-full"
                        [class]="selectedRoomStatus?.class || 'bg-red-500'"></span>
                </span>
                <mat-select matInput [formControl]="createOrUpdateRoomForm.controls.service">
                    <mat-option [value]="roomStatus.id" *ngFor="let roomStatus of roomStatuses">
                        {{ roomStatus.translation| transloco}}
                        <ng-container *ngIf="roomStatus.id == 0">
                            <span class="inline-block w-2 h-2 rounded-full" [ngClass]="roomStatus.class ? roomStatus.class : 'bg-green-500'"></span>
                        </ng-container>
                        <ng-container *ngIf="roomStatus.id == 1">
                            <span class="inline-block w-2 h-2 rounded-full" [ngClass]="roomStatus.class ? roomStatus.class : 'bg-green-500'"></span>
                        </ng-container>
                        <ng-container *ngIf="roomStatus.id == 2">
                            <span class="inline-block w-2 h-2 rounded-full" [ngClass]="roomStatus.class ? roomStatus.class : 'bg-green-500'"></span>
                        </ng-container>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'uwagi' | transloco}}</mat-label>
                <mat-icon matPrefix [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                <textarea matInput [formControl]="createOrUpdateRoomForm.controls.serviceInfo" cdkAutosizeMinRows="10"
                    cdkAutosizeMaxRows="15" rows="10"></textarea>
            </mat-form-field>
        </div>
    </div>

</ng-template>
<ng-template #photos>
    <fuse-alert [type]="'info'" [appearance]="'outline'" *ngIf="imageLoadFailed == false">
        {{'kolejnosc_zdjec_mozna_ustawiac_przesuwajac_je' | transloco}}
    </fuse-alert>

    <div (click)="chooseFiles()" *ngIf="imageLoadFailed == false"
        class="flex justify-center h-40 min-h-40 max-h-40 border-2 border-dashed border-gray-300 rounded-2xl mt-6 cursor-pointer">
        <div class="text-2xl font-bold text-hint mt-16">
            {{'kliknij_lub_przeciagnij_i_zrzuc_tutaj_plik' | transloco}}
        </div>
    </div>
    <input #imageInput [disabled]="isUploading" type="file" style="display:none;" multiple accept="image/*"
        (change)="handleFileInput($event)">
    <div cdkDropList (cdkDropListDropped)="onDrop($event)" class="flex flex-wrap mt-6 flex-center"  *ngIf="imageLoadFailed == false">
        <div   class="w-full" *ngFor="let photo of images; let i = index;">
            <div cdkDrag class="cursor-pointer p-4 bg-white">
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                <div class="flex w-full">
                   
                    <img class="rounded-xl w-1/2 object-cover" [src]="photo.path" alt="Room image">
                    <div class="m-6">
                        <div class="flex justify-between">
                            <div [matTooltip]="'kolejnosc_wyswietlania' | transloco"  class="example-handle" cdkDragHandle>
                                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                  <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                  <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                              </div>
                            <button (click)="showGallery(i)" [matTooltip]="'podglad' | transloco" class="-mt-2 -mr-2" mat-icon-button>
                                <mat-icon class="icon-size-5 text-hint" [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            </button>
                            <button [matTooltip]="'obroc' | transloco" (click)="rotateImage(photo.photoId)" class="-mt-2 -mr-2" mat-icon-button>
                                <mat-icon class="icon-size-5 text-hint"
                                    [svgIcon]="'mat_twotone:rotate_right'"></mat-icon>
                            </button>
                            <button [matTooltip]="'pobierz' | transloco" (click)="downloadImage(photo)" class="-mt-2 -mr-2" mat-icon-button>
                                <mat-icon class="icon-size-5 text-hint"
                                    [svgIcon]="'heroicons_solid:arrow-down-tray'"></mat-icon>
                            </button>
                            <button [matTooltip]="'usun' | transloco" class="-mt-2 -mr-2" mat-icon-button
                                (click)="deleteRoomImage(photo.photoId)">
                                <mat-icon class="icon-size-5 text-hint" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
    </div>

    <div *ngIf="imageLoadFailed == true" class="mt-6">
        {{'nie_udalo_sie_pobrac_danych' | transloco}}
        <br />
        <button mat-raised-button color="primary" (click)="getImages()">{{'sprobuj_ponownie' | transloco}}</button>
    </div>
</ng-template>
