import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { QuillEditorComponent } from 'ngx-quill';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { PanelService } from 'app/core/shared/services/panel.service';

@Component({
  selector: 'app-add-or-update-task',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatMenuModule, MatDividerModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, QuillEditorComponent, MatSelectModule, TranslocoModule],
  templateUrl: './add-or-update-task.component.html',
  styleUrl: './add-or-update-task.component.scss'
})
export class AddOrUpdateTaskComponent {
  
  task = {
    'priority' : 0,
    'dueDate' : '2017-10-18T13:03:37.943Z'
  }

  constructor(
    private _panelService: PanelService
  ) {

  }

  public close() {
    this._panelService.close()
  }

  isOverdue(){
    return true;
  }
  
  showSectionDialog(){}
}

