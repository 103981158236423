import { ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { PaymentStatuses } from '../models/payment-statuses.enum';
import { DbReservation } from '../models/DbReservation';
import { DateTime } from 'luxon';
import { DbRoom } from '../models/DbRoom';
import { ApplicationData } from '../data/application-data';
import { DbClient } from '../models/DbClient';
import { ReservationPricingSummary } from '../models/ReservationPricingSummary';
import { UserSettings } from '../models/UserSettings';
import { DbInvoice } from '../models/DbInvoice';
import * as he from 'he'; 


export class Utils {

    static getFirstAndLastDayOfMonth(month: number, year: number): { from: string, to: string } {
        // Create a DateTime object for the first day of the month
        const firstDay = DateTime.local(year, month + 1, 1);
        // Create a DateTime object for the last day of the month
        const lastDay = firstDay.endOf('month');

        // Format dates to ISO string format
        const firstDayISO = firstDay.toISODate(); // Returns YYYY-MM-DD
        const lastDayISO = lastDay.toISODate(); // Returns YYYY-MM-DD
      
        return {
          from: firstDayISO,
          to: lastDayISO
        };
    }

    static createAddress(street, building, flat, postalCode, city) {
        // Sprawdź, czy parametr "street" zawiera "ul. ", jeżeli tak, usuń go
        // if (street.includes("ul. ")) {
        //   street = street.replace("ul. ", "");
        // }
      
        let address = ''
        // Jeżeli parametr "flat" jest zdefiniowany, dodaj ukośnik przed "building"
        if (flat && flat != '') {
            address = `${street} ${building}/${flat}`;
        } else {
            address = `${street} ${building}`;
        }

        address += `, ${postalCode} ${city}`
        return address
    }

    static removeElementByIndex(arr, index) {

        if(!Array.isArray(arr)) return
        
        if (index >= 0 && index < arr.length) {
            arr.splice(index, 1);
            return true; // Element removed successfully
        } else {
            return false; // Index out of bounds, nothing removed
        }
    }

    static getInvoiceTypTranslation(invoice:DbInvoice) {

        if(!invoice) return 'faktura_vat'
        if(invoice.prepayments.length > 0) return 'faktura_koncowa'

        const type = ApplicationData.InvoiceTypes.find(x=>x.id == invoice.invoiceType)
        if(!type) return  'faktura_vat'

        return type.name
    }

    static replaceRoomTags(translate: TranslocoService, message: string, room: DbRoom, relatedRooms): string {

        const tags = {
            '{room_name}': 'name',
            '{room_type}': 'roomType',
            '{room_beds}': 'persons'
        }

        return this.replaceTags(translate, message, tags, room, relatedRooms)
    }

    static replaceClientTags(translate: TranslocoService, message: string, client: DbClient): string {

        const tags = {
            '{forename}': 'forename',
            '{name}': 'name',
            '{phone}': 'phone',
            '{email}': 'email',
            '{id_no}': 'idCard',
            '{tax_id}': 'taxId',
            '{personal_id}': 'personalId',
            '{address}': 'address',
            '{comments}': 'comments',
            '{guest_status}': 'clientType'
        }

        return this.replaceTags(translate, message, tags, client)
    }

    static replaceReservationTags(translate: TranslocoService, message: string, reservation: DbReservation): string {

        const tags = {
            '{id_res}': 'reservationNo',
            '{id_group}': 'groupId',
            '{arrival}': 'arrival',
            '{departure}': 'departure',
            '{checkin}': 'checkIn',
            '{checkout}': 'checkOut',
            '{adults}': 'adults',
            '{childs}': 'children',
            '{children}': 'children',
            '{ota_reservation_id}': 'bookingId',
            '{meals}': 'meal',
            '{portion_adult}': 'adultsPortion',
            '{portion_child}': 'childrenPortion',
            '{prepayment}': 'prepayment',
            '{prepay_deadline}': 'prepaymentDeadline',
            '{pay_status}': 'paymentStatus',
            '{currency}': 'currency',
            '{room_code}': 'doorCode',
            '{price_per_day}': 'pricePerRoomPerNight',
        }

        return this.replaceTags(translate, message, tags, reservation)
    }

    static replaceCompanyTags(translate: TranslocoService, message: string, userSettings: UserSettings): string {

        const tags = {
            '{object_name}': 'hotelData.objectName',
            '{city}': 'hotelData.city',
            '{street_and_no}': 'hotelData.street',
            '{postal_code}': 'hotelData.postalCode',
            '{object_phone}': 'hotelData.phone',
            '{object_email}': 'hotelData.email',
            '{www_page}': 'hotelData.webPage',
            '{hotel_night_from}': 'hotelData.checkIn',
            '{hotel_night_to}': 'hotelData.checkOut',
            '{bank_acc_nr}': 'userData.bankAccountNo'
        }

        return this.replaceTags(translate, message, tags, userSettings)
    }

    static replacePricingTags(translate: TranslocoService, message: string, reservationPricingSummary: ReservationPricingSummary): string {

        // to pay is with value summary, because it need to be a existing field
        // there is not to pay field right now in pricing summary
        const tags = {
            '{price_per_stay}': 'pricePerRoom',
            '{to_pay}': 'toPay'
        }

        return this.replaceTags(translate, message, tags, reservationPricingSummary)
    }

    static replaceTags(translate: TranslocoService, message, tags, object, object2 = null) {

        Object.keys(tags).forEach(key => {
            if (Utils.isDefined(object, tags[key])) {
                const keyValue = Utils.getNestedValue(object, tags[key])
                if (keyValue != null) {

                    if (key == '{room_name}') {

                        if(object2 != null && Array.isArray(object2) && object2.length > 0) {
                            message = message.replaceAll(key, object2.join(', '))
                        }
                        else message = message.replaceAll(key, keyValue)
                    }

                    if (tags[key] == 'prepaymentDeadline') {
                        message = message.replaceAll(key, keyValue)
                    }

                    else if (tags[key] == 'groupId') {
                        if (keyValue == 0) {
                            message = message.replaceAll(key, translate.translate('brak'))
                        }
                        else {
                            message = message.replaceAll(key, keyValue)
                        }
                    }

                    else if (tags[key] == 'paymentStatus') {
                        const reservationPaymentStatus = ApplicationData.ReservationPaymentStatuses.find(x => x.id == keyValue)
                        if (reservationPaymentStatus) {
                            message = message.replaceAll(key, translate.translate(reservationPaymentStatus.text))
                        } else console.log(`invalid reservationPaymentStatus ${keyValue}`)
                    }

                    else if (tags[key] == 'roomType') {
                        const roomType = ApplicationData.RoomTypes.find(x => x.id == keyValue)
                        if (roomType) {
                            message = message.replaceAll(key, translate.translate(roomType.text))
                        } else console.log(`invalid roomType ${keyValue}`)
                    }

                    else if (tags[key] == 'meal') {
                        const alimentationType = ApplicationData.AlimentationTypes.find(x => x.id == keyValue)
                        if (alimentationType) {
                            message = message.replaceAll(key, translate.translate(alimentationType.translation))
                        } else console.log(`invalid alimentationType ${keyValue}`)
                    }

                    else if (tags[key] == 'clientType') {
                        let clientType = ApplicationData.ClientTypes.find(x => x.id == keyValue)
                        if (clientType) {
                            // Propably you want send an information that your client is not welcome
                            if (clientType.id == 2) clientType = ApplicationData.ClientTypes.find(x => x.id == 0)
                            message = message.replaceAll(key, translate.translate(clientType.translation))
                        } else console.log(`invalid clientType ${keyValue}`)
                    }


                    else {
                        message = message.replaceAll(key, keyValue)
                    }

                } else {
                    message = message.replaceAll(key, '')
                    console.log(`value for key ${key} is ${keyValue}`)
                }
            } else {
                message = message.replaceAll(key, '')
            }
            // else console.log(`${key} is not defined`)
        });
        return message
    }

    static extractNumberFromId(id) {
        // Use regex to match the number at the end of the ID
        const matches = id.match(/\d+$/);

        // If there is a match, return the number as a parsed integer
        if (matches && matches.length > 0) {
            return parseInt(matches[0]);
        } else {
            // If no match is found, return null or handle the case accordingly
            return null;
        }
    }

    static convertDateTextToISO(dateString:string) {
        // Define possible formats
        const formats = [
            'dd.MM.yyyy', // Example format: 23.09.2024
            'MM/dd/yyyy', // Example format: 09/23/2024
            'yyyy-MM-dd', // Example format: 2024-09-23
            // Add more formats as needed
        ];
    
        // Try parsing with each format
        for (const format of formats) {
            const date = DateTime.fromFormat(dateString, format);
            if (date.isValid) {
                return date.toISODate();
            }
        }
    
        // If no format matched, throw an error
        console.warn(`[Utils]: Invalid date format: ${dateString}`)
        return null;
    }

    static convertToISO(dateString) {
        // Attempt to parse the input date string as ISO format
        const parsedDate = DateTime.fromISO(dateString);

        // Check if parsing was successful
        if (parsedDate.isValid) {
            // Convert the parsed date to ISO format
            return parsedDate.toISODate();
        } else {
            // Return null if parsing failed
            return null;
        }
    }
    static isNumeric(value): boolean {
        return !isNaN(value) && !isNaN(parseFloat(value));
    }
    static calculateMatchingScore(roomName: string, targetName: string): number {
        let score = 0;

        for (let i = 0; i < targetName.length; i++) {
            if (roomName.includes(targetName[i])) {
                score++;
            }
        }

        return score;
    }

    static GetTableId(table) {
        let idField = ''
        if (table == 'clients') idField = 'clientId'
        else if (table == 'rooms') idField = 'roomId'
        else if (table == 'reservations') idField = 'reservationId'

        if (idField == '') console.error('[Utils] - GetTableId Invalid table id.')

        return idField
    }

    static countPricePerPerson(pricingModel, priceForDay, person:number) {

        let price = 0;
        let priceModel = null;

        if (Utils.isDefined(pricingModel, "options.persons")) {
            priceModel = pricingModel.options.persons.find(x => x.person == person)
        }

        if (typeof priceForDay == 'undefined' || priceForDay == null) { return 0 }
        if (typeof priceModel == 'undefined' || priceModel == null) { 
            if(Number.isNaN(priceForDay.price)) return 0
            return priceForDay.price || 0
        }

        if(priceForDay.price == 0) return 0
        
        if (Number.isNaN(priceModel.amount)) priceModel.amount = 0;

        // Percentage
        if (priceModel.type == '%') {
            if (priceModel.prefix == '+') price = priceForDay.price * (1 + (priceModel.amount / 100))
            else  price = priceForDay.price * (1 - (priceModel.amount / 100))
        }

        // Currency 
        else {
            if (priceModel.prefix == '+') price = parseFloat(priceForDay.price) + parseFloat(priceModel.amount)
            else price = parseFloat(priceForDay.price) - parseFloat(priceModel.amount)
        }

        if(Number.isNaN(price)) return 0
        return price;
    }

    static getUniqueMonths(arrival, departure) {
        let uniqueMonths = [];


        // Convert arrival and departure to Date objects
        const arrivalDate = new Date(arrival);
        const departureDate = new Date(departure);

        // if(arrivalDate.getTime() > departureDate.getTime()) {
        //     console.error("Found invalid reservation range")
        // }

        // Initial values
        let currentYear = arrivalDate.getFullYear();
        let currentMonth = arrivalDate.getMonth();

        // Iterate over months between arrival and departure
        while (currentYear < departureDate.getFullYear() || (currentYear === departureDate.getFullYear() && currentMonth <= departureDate.getMonth())) {
            const yearMonth = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}`;
            uniqueMonths.push(yearMonth);

            // Move to the next month
            if (currentMonth === 11) {
                currentMonth = 0;
                currentYear++;
            } else {
                currentMonth++;
            }
        }

        return uniqueMonths;
    }

    static getUniqueObjects(partitions, uniqueIndexes) {
        let result = [];
        if (typeof partitions == 'undefined') return []
        uniqueIndexes.forEach(index => {
            if (typeof partitions[index] != 'undefined') {
                // Concatenate the arrays for each unique index
                for (let i = 0; i <= partitions[index].length - 1; i++) {
                    result.push(partitions[index][i])
                }
            }
        });

        // Use a Set to ensure uniqueness and convert back to an array
        return Array.from(new Set(result));
    }

    static roundedRect(x, y, width, height, radius) {
        return new Path2D(`M ${x + radius} ${y} H ${x + width - radius} a ${radius} ${radius} 0 0 1 ${radius} ${radius} V ${y + height - radius} a ${radius} ${radius} 0 0 1 ${-radius} ${radius} H ${x + radius} a ${radius} ${radius} 0 0 1 ${-radius} ${-radius} V ${y + radius} a ${radius} ${radius} 0 0 1 ${radius} ${-radius}`);
    }
    static prepareCanvas(canvas, context, h, w, isNative = false) {
        let ratio = Utils.getPixelRation(context)
        const dpr = window.devicePixelRatio || 1;

        if (!isNative) {
            canvas.nativeElement.width = Math.floor(w * ratio);
            canvas.nativeElement.height = Math.floor(h * ratio);

            canvas.nativeElement.style.width = Math.floor(w) + "px";
            canvas.nativeElement.style.height = Math.floor(h) + "px";
        }
        else {

            canvas.width = Math.floor(w * ratio);
            canvas.height = Math.floor(h * ratio);

            canvas.style.width = Math.floor(w) + "px";
            canvas.style.height = Math.floor(h) + "px";
        }

        context.scale(ratio, ratio)
        return { canvas: canvas, context: context }
    }
    static getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }
    static getPixelRation(ctx) {
        let dpr = window.devicePixelRatio || 1,
            bsr = ctx.webkitBackingStorePixelRatio ||
                ctx.mozBackingStorePixelRatio ||
                ctx.msBackingStorePixelRatio ||
                ctx.oBackingStorePixelRatio ||
                ctx.backingStorePixelRatio || 1;

        return dpr / bsr;
    }
    static buildSubscriptionRoomRangesFromPricing(pricing) {
        const ranges = new Set<{ min: number, max: number }>()

        Object.values(pricing.SUBSCRIPTIONS).forEach((sub: any) => {
            sub.pricing.forEach((price: any) => {
                const toAdd = Array.from(ranges).find(x => x.min == price.min && x.max == price.max)
                if (typeof toAdd == 'undefined') ranges.add({ min: price.min, max: price.max })
            })
        })

        return Array.from(ranges)
    }
    static findRangeObject(arr, value) {
        let i = 0;
        for (const obj of arr) {
          if ((obj.min === null || value >= obj.min) && (obj.max === null || value <= obj.max)) {
            return { range: obj, index: i };
          }
          i++
        }
        return
      }

    static generateDeviceId() {
        if (localStorage.getItem('device_id') == null) {
            localStorage.setItem("device_id", uuidv4());
        }
        return localStorage.getItem("device_id");
    }

    static createStreetAddress(street, building, flat) {
        // Sprawdź, czy parametr "street" zawiera "ul. ", jeżeli tak, usuń go
        if (street.includes("ul. ")) {
            street = street.replace("ul. ", "");
        }
        else if (street.includes("ul.")) {
            street = street.replace("ul.", "");
        }

        // Jeżeli parametr "flat" jest zdefiniowany, dodaj ukośnik przed "building"
        if (flat && flat != '') {
            return `${street} ${building}/${flat}`;
        } else {
            return `${street} ${building}`;
        }
    }

    static checkCSVFileValidity(data, separator) {
        try {
            const lines = data.split('\n');
            const headerParts = this.splitTextBySeparator(lines[0], separator)
            let result: any = [];

            for (let i = lines.length - 1; i >= 0; i--) {

                if (Utils.isNullOrEmpty(lines[i])) continue
                if (this.splitTextBySeparator(lines[i], separator).length < headerParts.length) {
                    lines[i - 1] += lines[i]
                    continue
                }

                result.unshift(lines[i])
            }
            return result

        } catch (err) {
            console.error('Error reading the file:', err);
        }
    }
    static splitTextBySeparator(text, separator) {
        const parts = [];
        let insideQuotes = false;
        let part = '';

        text.split('').forEach(char => {
            if (char === separator) {
                if (insideQuotes) {
                    part += char;
                } else {
                    parts.push(part);
                    part = '';
                }
            } else if (char === '"') {
                insideQuotes = !insideQuotes;
            } else {
                part += char;
            }
        });

        parts.push(part); // Add the last part

        return parts;
    }

    static toDecimal(number) {

        if(typeof number === 'string') {
            number = number.replaceAll(',',".")
        }

        const result = Math.round(number * 100) / 100;

        if(Number.isNaN(result)) return 0
        return result
        
    }

    static findCSVSeparator(csvData) {

        // console.log("CSVDATA: ",csvData)
        const possibleSeparators = [',', ';', '\t', '|']; // List of possible separators
        let firstRow;
        if (Array.isArray(csvData)) {
            firstRow = csvData[0]
        } else {
            firstRow = csvData.split('\n')[0]; // Get the first row of the CSV data
        }

        let maxSeparatorCount = 0;
        let mostCommonSeparator = ',';

        for (const separator of possibleSeparators) {
            const separatorCount = firstRow.split(separator).length;
            if (separatorCount > maxSeparatorCount) {
                maxSeparatorCount = separatorCount;
                mostCommonSeparator = separator;
            }
        }
        return mostCommonSeparator;
    }
    static setTextColorBasedOnBackground(backgroundColor: string): string {
        // Konwertuj color tła na wartości RGB
        // Konwertuj color tła na wartości RGB
        const hexToRgb = (hex: string): number[] => {
            const bigint = parseInt(hex.replace("#", ""), 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            return [r, g, b];
        };


        // Wylicz jasność koloru tła na podstawie wartości RGB
        const brightness = (rgb: number[]): number => {
            return (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
        };

        // Sprawdź poprawność koloru tła
        if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(backgroundColor)) {
            console.warn("Nieprawidłowy format koloru tła: ", backgroundColor, "setting default as dark - white text color" );
            backgroundColor = '#ffffff';
        }


        const rgb = hexToRgb(backgroundColor);
        const bgBrightness = brightness(rgb);

        // Do not change that values
        // They are stricktly connected with pricing-canvas (white and black text in if statement)
        return bgBrightness > 125 ? 'black' : 'white';
    }

    static add0IfLowerThan10(value: number) {
        if (value < 10) { return "0" + value }
        return value;
    }

    static isNullOrEmpty(value) {

        if (value === 0) return false;

        if (
            typeof value == 'undefined' ||
            value == '' ||
            value == null
        ) {
            return true;
        }

        return false;

    }

    static isLock(reservation: DbReservation | DbReservation[]) {
        if (Array.isArray(reservation)) {
            let toReturn = true
            for (let r = 0; r < reservation.length; r++) {
                if (!this.isLock(reservation[r])) {
                    toReturn = false
                }
            }
            return toReturn
        }

        if (reservation == null || typeof reservation == 'undefined') return true;
        if (reservation.clientId > 0) { return false }
        if (reservation.clientId == null) { return true }
        if (typeof reservation.clientId == 'undefined') { return true }

        return false;
    }

    static getFormValidationErrors(form) {
        let controls = [];
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    controls.push(key);
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
        return controls;
    }

    static getFormControlValidationErrors(form, key, checkTouched) {
        let controls = [];
        // Object.keys(form.controls).forEach(key => {
        const controlErrors: ValidationErrors = form.get(key).errors;
        let isTouched = true;
        if (checkTouched) isTouched = form.get(key).touched;

        if (controlErrors != null && isTouched) {
            Object.keys(controlErrors).forEach(keyError => {
                controls.push(key);
                console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
        }
        return controls;
    }

    static isJSON(string) {
        try {
            const t = JSON.parse(string);
            return true;
        }
        catch (err) {
            return false;
        }
    }
    static isDateSet(date) {
        if (
            date == 'null' ||
            date == null ||
            date == '' ||
            date == '0000-00-00' ||
            (moment.isMoment(date) && date.format("YYYY-MM-DD") == '0000-00-00') ||
            (moment.isMoment(date) && date.format("YYYY-MM-DD") == 'Invalid date')
        ) {

            return false;
        }
        return true;
    }

    static getPaymentStatus(today: Date, paymentStatus: number, prepaymentDeadline: string): PaymentStatuses {

        if (paymentStatus == 0 &&
            (prepaymentDeadline == null || prepaymentDeadline == '0000-00-00')
        ) {
            return PaymentStatuses.brak_wplaty
        }

        else if (paymentStatus == 0 &&
            (
                typeof prepaymentDeadline != 'undefined' && prepaymentDeadline != '0000-00-00' &&
                prepaymentDeadline != null && new Date(prepaymentDeadline) > today
            )
        ) {
            return PaymentStatuses.oczekiwanie_na_zaliczke
        }
        else if (
            paymentStatus == 0 &&
            (
                typeof prepaymentDeadline != 'undefined' &&
                prepaymentDeadline != null && prepaymentDeadline != '0000-00-00' &&
                new Date(prepaymentDeadline) < today
            )
        ) {
            return PaymentStatuses.uplynal_termin_wplaty_zaliczki
        }

        else if (paymentStatus == 1) return PaymentStatuses.wplacony_zadatek
        else if (paymentStatus == 2) return PaymentStatuses.wplacona_calosc
        else if (paymentStatus == 3) return PaymentStatuses.oczekiwanie_na_platnosc_online

    }

    static hasValue(value) {
        if (typeof value == 'undefined' || value == null) { return false; }
        if (value == '' || value == 'null') { return false; }
        if (value.length < 1) { return false; }
        return true;
    }

    static HTMLEntityDecode(value) {

        if (value == null) return null;
        if (!value) return '';
        if (value == '') return '';

        return he.decode(value);
        // const parser = new DOMParser();
        // const doc = parser.parseFromString(value, "text/html");
        // return doc.documentElement.textContent;

    }

     /**
     * Decode HTML entities for a single object recursively.
     */
     static HTMLEntityDecodeIfObject(obj: { [key: string]: any }): { [key: string]: any } {
        const decodedObj: { [key: string]: any } = {};
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                // Decode string property
                decodedObj[key] = this.HTMLEntityDecode(obj[key]);
            } else if (Array.isArray(obj[key])) {
                // Recursively decode array property
                decodedObj[key] = this.HTMLEntityDecodeArray(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Recursively decode nested object
                decodedObj[key] = this.HTMLEntityDecodeIfObject(obj[key]);
            } else {
                // Keep other types as is
                decodedObj[key] = obj[key];
            }
        }
        return decodedObj;
    }

    /**
    * Decode HTML entities for an array of objects.
    */
    static HTMLEntityDecodeArray(arr: any[]): any[] {
        const result: any[] = [];
        for (const item of arr) {
            if (typeof item === 'string') {
                // Decode string
                result.push(this.HTMLEntityDecode(item));
            } else if (typeof item === 'number') {
                // Keep numbers as is
                result.push(item);
            } else if (Array.isArray(item)) {
                // Recursively decode array
                result.push(this.HTMLEntityDecodeArray(item));
            } else if (typeof item === 'object' && item !== null) {
                // Decode object
                result.push(this.HTMLEntityDecodeIfObject(item));
            } else {
                // Keep other types as is
                result.push(item);
            }
        }
        return result;
    }

    static getSubscriptionName(id) {
        return id == 0 ? 'STANDARD' : 'PRO'
    }
    
    static isFloat(n) {
        return Number(n) === n && n % 1 !== 0;
    }

    static isInt(n) {
        return Number(n) === n && n % 1 === 0;
    }

    static checkPremiumDays(expiry_time) {
        let now = moment(moment().format('YYYY-MM-DD'));
        let expiry = moment(moment(expiry_time).format('YYYY-MM-DD'));
        let diffInDays = expiry.diff(now, 'days');
        if (diffInDays < 0) {
            diffInDays = 0;
        }
        return diffInDays;
    }

    static isWeekend(dayNumber: number) {
        switch (dayNumber) {
            case 0:
                return true;

            case 6:
                return true;

            default:
                return false;
        }
    }

    static isLuxonWeekend(dayNumber: number) {
        switch (dayNumber) {
            case 6:
                return true;

            case 7:
                return true;

            default:
                return false;
        }
    }

    static getMonth(month: number) {
        switch (month) {
            case -1: return 11;
            case 12: return 0;
            default: return month;
        }
    }

    static isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    static getFastViewPosition(fastViewSizes, scrollContainerPos, scrollTop, scrollLeft, currentHighlighted, headerWidth, dayHeight): {
        data: any,
        top: any,
        bottom: any,
        left: any,
        right: any,
        arrow: { top: any, bottom: any, left: any, right: any, rotate: any }
    } {
        if (typeof currentHighlighted != 'undefined' && currentHighlighted != null) {
            // if (typeof currentHighlighted != 'undefined' && currentHighlighted != null && !this.isDraging && !this.isDrawing) {
            let fastViewResult = {
                data: null,
                top: 100,
                bottom: 'auto',
                left: 400,
                right: 'auto',
                arrow: { top: '-20px', bottom: 'auto', left: '20px', right: '0px', rotate: '90deg' }
            }
            currentHighlighted.reservation;

            if (typeof currentHighlighted != 'undefined') {

                fastViewResult.data = currentHighlighted.reservation

                let xOffset = scrollLeft % headerWidth;
                let yOffset = scrollTop % dayHeight;

                let elemWidth = 350;
                let elemHeight = 400;

                if (typeof fastViewSizes != 'undefined') {
                    elemHeight = fastViewSizes.height;
                    elemWidth = fastViewSizes.width;


                }

                let left: any = (currentHighlighted.col * headerWidth) + scrollContainerPos.left + 30 - xOffset;
                let right: any = 'auto';
                let top: any = ((currentHighlighted.row + 1) * dayHeight) + scrollContainerPos.top - yOffset;
                let bottom: any = 'auto';

                fastViewResult.arrow.left = '20px';
                fastViewResult.arrow.right = 'auto';
                fastViewResult.arrow.top = '-20px';
                fastViewResult.arrow.bottom = 'auto';
                fastViewResult.arrow.rotate = '90deg';

                //Fast view is to outside right part
                if (window.innerWidth < (elemWidth + left)) {
                    // left = window.innerWidth - elemWidth - safeSpace;
                    left = 'auto'
                    right = ((7 - (currentHighlighted.col + (currentHighlighted.sizeCols))) * headerWidth)

                    if (right < 0) {
                        left = window.innerWidth - elemWidth;
                        right = 'auto';
                    }

                    fastViewResult.arrow.left = 'auto';
                    fastViewResult.arrow.right = '20px';
                }

                else if (left < scrollContainerPos.left) {
                    left = scrollContainerPos.left;
                    fastViewResult.arrow.left = '20px';
                    fastViewResult.arrow.right = 'auto';
                }


                if (window.innerHeight < (elemHeight + top)) {
                    top = 'auto'
                    bottom = ((7 - (currentHighlighted.row + 1)) * dayHeight) + yOffset
                    fastViewResult.arrow.rotate = '-90deg';
                    fastViewResult.arrow.bottom = '-20px';
                    fastViewResult.arrow.top = 'auto';
                }

                if (((currentHighlighted.row + 1) * dayHeight) + scrollContainerPos.top < elemHeight) {
                    top = ((currentHighlighted.row + 1) * dayHeight) + scrollContainerPos.top - yOffset;
                    bottom = 'auto';

                    fastViewResult.arrow.rotate = '90deg';
                    fastViewResult.arrow.bottom = 'auto';
                    fastViewResult.arrow.top = '-20px';
                }

                if (typeof top == 'number') { top = top + "px" }
                if (typeof bottom == 'number') { bottom = bottom + "px" }
                if (typeof left == 'number') { left = left + "px" }
                if (typeof right == 'number') { right = right + "px" }

                fastViewResult.top = top
                fastViewResult.bottom = bottom
                fastViewResult.left = left;
                fastViewResult.right = right;

                return fastViewResult;
            }
        }
    }
    static isDefined(obj, path) {
        if (obj == null) { return false; }
        const parts = path.split('.');
        let result = obj;
        for (let i = 0; i < parts.length; i++) {
            result = result[parts[i]];
            if (result == null) {
                return false;
            }
            else if (typeof result == 'undefined') {
                return false;
            }
        }
        return true;
    }
    static getValue(obj, path) {
        const keys = path.split('.');
        let currentObj = obj;

        for (const key of keys) {
            if (currentObj[key] === undefined) {
                console.warn("[Permissions] Invalid permission used: " + path)
                return false; // Property not found
            }
            currentObj = currentObj[key];
        }

        return currentObj === "1";
    }
    static clearUTC(date: any) {

        if (!this.isDateSet(date)) return "";
        var clearUTCDate = moment(date).utcOffset(0, true).format()
        return clearUTCDate

    }
    static setNestedPropertyValue(obj, path, value) {
        const keys = path.split('.');
        // przejdź przez każdy klucz po kolei
        keys.reduce((nestedObj, key, index) => {
            // jeśli to ostatni klucz, ustaw wartość
            if (index === keys.length - 1) {
                nestedObj[key] = value;
            } else {
                // w przeciwnym razie, jeśli dana własność nie istnieje, utwórz ją jako pusty obiekt
                if (!nestedObj.hasOwnProperty(key)) {
                    nestedObj[key] = {};
                }
                // przejdź do następnego zagnieżdżenia
                return nestedObj[key];
            }
        }, obj);

    }
    static toStringNumberFromBoolean(value: boolean) {
        if (value == true) { return '1' }
        else { return '0' }
    }
    static toBooleanFromStringNumber(value: string): boolean {
        if (value == '0') { return false }
        else if (value == '1') { return true }
        return false;
    }
    static translateMultiple(translate: TranslocoService, toTranslate: string) {
        const texts = toTranslate.split(' ');

        let text = '';
        texts.forEach(element => {
            text += translate.translate(element) + " "
        });

        return text;

    }

    static getNestedValue(obj, propertyString) {
        const result = propertyString.split('.').reduce((o, p) => o && o[p], obj || '');
        return result !== undefined ? result : (result === 0 ? 0 : '');
    }

    static getQuickDateText(translate: TranslocoService, date: DateTime): string {
        const today = DateTime.now();
        const yesterday = DateTime.now().minus({days: 1})
        const tomorrow = DateTime.now().plus({days: 1})
        const dayAfterTomorrow = DateTime.now().plus({days: 2})

        if (date.toISODate() === today.toISODate()) {
            return translate.translate('dzisiaj');
        } else if (date.toISODate() === yesterday.toISODate()) {
            return translate.translate('wczoraj');
        } else if (date.toISODate() === tomorrow.toISODate()) {
            return translate.translate('jutro');
        } else if (date.toISODate() === dayAfterTomorrow.toISODate()) {
            return translate.translate('pojutrze');
        } else {
            return '';
        }
    }
    static lastLetterIndexBefore(text: string, letter: string, index: number): number {
        let ostatniaSpacja = -1;
        for (let i = 0; i <= index; i++) {
            if (text[i] === letter) {
                ostatniaSpacja = i;
            }
        }
        return ostatniaSpacja;
    }

    static getObjectsInRange(arr, min, max) {
        if (max < 0) max = 0
        // Sprawdzamy, czy indeksy min i max są poprawne i nie wykraczają poza granice tablicy
        if (min < 0 || min > max) {
            return []; // Zwracamy null, jeśli indeksy są niepoprawne
        }

        if (max > arr.length - 1) { max = arr.length - 1 }

        const result = [];
        // Iterujemy po tablicy i dodajemy obiekty, których indeks znajduje się w zakresie min i max
        for (let i = min; i <= max; i++) {
            result.push(arr[i]);
        }

        return result;
    }

    static multilanguageComparer(a, b) {
        const greckieLitery = {
            α: 'A',
            β: 'B',
            γ: 'G',
            δ: 'D',
            ε: 'E',
            ζ: 'Z',
            η: 'H',
            θ: 'TH',
            ι: 'I',
            κ: 'K',
            λ: 'L',
            μ: 'M',
            ν: 'N',
            ξ: 'KS',
            ο: 'O',
            π: 'P',
            ρ: 'R',
            σ: 'S',
            τ: 'T',
            υ: 'Y',
            φ: 'F',
            χ: 'CH',
            ψ: 'PS',
            ω: 'W'
        };

        const grupaA = a.group.replace(/[α-ω]/g, match => greckieLitery[match]);
        const grupaB = b.group.replace(/[α-ω]/g, match => greckieLitery[match]);

        return grupaA.localeCompare(grupaB);
    }

    static toDate(date: any) {
        if (Utils.isDateSet(date)) {
            if (moment.isMoment(date)) return date.toDate()
            else if (moment.isDate(date)) return date
        }
        console.warn("Invalid date: ", date)
        return new Date();
    }
    static sortByValidNumbers(array, validSet) {
        // Filter the array to keep only the elements that are present in the valid set
        const validArray = array.filter((num) => validSet.has(num));

        // Filter the array to keep only the elements that are NOT present in the valid set
        const invalidArray = array.filter((num) => !validSet.has(num));

        // Sort the validArray in ascending order
        validArray.sort((a, b) => a - b);

        // Concatenate the validArray and invalidArray to get the final sorted array
        const sortedArray = validArray.concat(invalidArray);

        return sortedArray;
    }

    static GetDateWithoutTime(date: Date) {
        if (typeof date === 'undefined') return;
        date.setHours(0, 0, 0, 0)
        var dateOffset = date.getTimezoneOffset() * 60000;
        let cleanDate = new Date(date.getTime() - dateOffset);
        cleanDate.setHours(0, 0, 0, 0)
        return cleanDate
    }

    static removeQuotes(text: String) {
        if (typeof text == 'undefined') return text
        return this.replaceAll(text, '"', '')
    }
    static replaceAll(inputString, search, replacement) {
        const regex = new RegExp(search, 'g');
        return inputString.replace(regex, replacement);
    }

    static formatDateToDDMMYYYY(dateString: string) {
        // Parsowanie daty za pomocą moment.js
        const date = moment(dateString, "DD.MM.YYYY");

        // Formatowanie daty do "DD-MM-YYYY"
        return date.format('YYYY-MM-DD');
    }

    static formatDate(date: Date) {
        const d = moment(date);
        return d.format('YYYY-MM-DD');
    }

    static isFieldParseableToFloat(fieldValue) {
        // Try to parse the field value to a float
        const parsedValue = parseFloat(fieldValue);

        // Check if the parsed value is a number and not NaN
        return !isNaN(parsedValue);
    }
}


