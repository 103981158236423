import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { DbService } from "./db.service";
import { AppDB } from "../models/db";
import Dexie from "dexie";
import { UserSettings } from "../models/UserSettings";

@Injectable({
    providedIn: 'root'
})
export class DbUserSettingsService implements OnDestroy {

    private _subscriptions: Subscription[] = []
    private _db: AppDB

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }


    constructor(private _dbService: DbService) {
        this._subscriptions.push(this._dbService.getDatabase().subscribe({
            next: (data) => {
                this._db = data
            }
        }))
    }

    async saveUserSettings(userSettings:UserSettings) {
        if (!userSettings) return 

        return await this._db.transaction("rw", this._db.userSettings, async () => {
            await Promise.resolve(this._db.userSettings.put(userSettings).catch((ex)=> console.log(`[DbUser Service]: ${ex.message}`)))
        })
        .catch((ex) => {

            console.log(`[DbUser Service]: Can't save user settings for account: ${userSettings.userData.email}`)
            
            if(ex.name == 'OpenFailedError' && ex.message.includes('UpgradeError')) {
                this._hardReset()
            }

            console.log(ex.name, ex.message)
        }).then(()=> {
            console.log("[DbUser Service]: User settings saved.")
        })

        
    }

    async getUserSettings() {
        return await this._db.userSettings.get({ id: 1 }).catch((ex) => console.log(""))
    }

    private async _hardReset() {

        try {
            const dbs = await Dexie.getDatabaseNames()
        
            dbs.forEach(db => {
              const deleteRequest = indexedDB.deleteDatabase(db);
        
              deleteRequest.onsuccess = () => {
                console.log(`[DbUserSettingsService]: Updating application databases: ${db}`);
              };
        
              deleteRequest.onerror = (error) => {
                console.error(`[DbUserSettingsService]: Error deleting database ${db}:`, error);
              };
            })
        } catch( ex) {
            console.log([`[DbUserSettingsService]: ${ex.message}`])
        }
        
        
    
        setTimeout(() => {
          window.location.reload()
        }, 3000)
    
        return;
      }
}