<div class="relative flex flex-col w-full sm:w-120 max-h-full sm:max-h-screen overflow-hidden">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'ustawienia' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 p-6 overflow-y-scroll">
            
            <div class="grid sm:grid-cols-4 gap-6 w-full">
                <div class="sm:col-span-4">
                    <div class="flex-auto cursor-pointer">
                        <div class="text-lg">{{'typ_formularza' | transloco}}</div>
                        <div class="text-md text-secondary">{{'ustaw_jak_ma_wygladac_formularz' | transloco}}</div>
                    </div>
                </div>
                <div class="sm:col-span-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-select [formControl]="reservationFormViewControl">
                            <mat-option [value]="type.id" *ngFor="let type of reservationFormViews" >
                                {{type.translation | transloco}}
                            </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>

            <div class="my-10 border-t dark:border-gray-800"></div>

            <div class="grid sm:grid-cols-4 gap-6 w-full">
                <div class="sm:col-span-4">
                    <div class="flex-auto cursor-pointer">
                        <div class="text-lg">{{'pokaz_ukryj' | transloco}}</div>
                        <div class="text-md text-secondary">{{'wybierz_co_chcesz_wyswietlac_w_formularzu_rezerwacji' | transloco}}</div>
                    </div>
                </div>
                <div class="sm:col-span-4">
                    <ng-container *ngFor="let field of fields">
                        <div
                            class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                            matRipple
                            (click)="toggleField(field.id)">
                            <mat-checkbox
                                class="flex items-center h-10 min-h-10 pointer-events-none"
                                [checked]="userFields?.includes(field.id)"
                                [color]="'primary'"
                                [disableRipple]="true">
                            </mat-checkbox>
                            <div class="ml-1">
                                <div class="flex items-center">
                                    <div class="text-md">
                                        {{field.translation | transloco}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>


        </div>
    </div>
    <!--Footer-->
    <div
        class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <!-- Confirm -->
                <button mat-dialog-close mat-stroked-button>
                    {{'zamknij' | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>
