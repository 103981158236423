import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { fuseAnimations } from '@fuse/animations';
import { RouterLink } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'premium-extend',
  standalone: true,
  imports: [CommonModule, MatButtonModule, RouterLink, LocalizeRouterModule, TranslocoModule],
  animations   : fuseAnimations,
  templateUrl: './premium-extend.component.html',
  styleUrl: './premium-extend.component.scss'
})
export class PremiumExtendComponent {
  @Input() reason = 'PRO_REQUIRED'; // GET param:  ROOM_LIMIT_EXCEED | PRO_REQUIRED

  @Output() readonly onExtendSubscriptionButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  
}
