import { DbReservation } from "./DbReservation";

export class DbMessage {

  messageId: number;
  userId: number;
  reservationId: number;
  sender: any;
  recipient: string[];
  templateName: any;
  topic: any;
  contents: any;
  addDate: any;
  status: number
  attachment: any;

  reservation:DbReservation
  constructor(obj: any) {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key]
    })
  }

}
