<div class="flex items-center m-3 ng-star-inserted">
    <div
        class="flex items-center justify-center w-10 h-10 rounded-md overflow-hidden bg-primary-100 ng-star-inserted">

        <!-- <img
            class="w-10 h-10 rounded-md overflow-hidden"
            *ngIf="type.startsWith('image/')"
            [src]="attachment.path"> -->
        <div
            class="flex items-center justify-center w-10 h-10 rounded-md overflow-hidden bg-primary-100">
            <!--*ngIf="!type.startsWith('image/')"-->
            <div class="flex items-center justify-center uppercase text-sm font-semibold text-primary-500-800">
                <!-- {{type.split('/')[1].trim().toUpperCase()}} -->
                {{extension}} 
            </div>
        </div>

        <!-- <div
            class="flex items-center justify-center text-sm font-semibold text-primary-500-800 uppercase">
            
        </div> -->
    </div>
    <div class="ml-3">
        <div class="text-md font-medium truncate">
            {{name}}
        </div>
        <div class="text-sm font-medium truncate text-secondary uppercase">
            {{size}}
        </div>
    </div>
</div>