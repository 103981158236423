import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { api_v1 } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SMSApiService {

    private constructor(
        private _http: HttpClient
    ) 
    {

    }

    public getConfiguration() {
        return this._http.get(api_v1 + `/sms`);
    }

    public configure(data) {
        return this._http.post(api_v1 + `/sms`, data);
    }

    public deleteConfiguration() {
        return this._http.delete(api_v1 + `/sms`);
    }

    public sendTestMessage(data) {
        return this._http.post(api_v1 + `/sms/send/test`, data);
    }

    public sendMessage(data) {
        return this._http.post(api_v1 + `/confirmation/sms`, data);
    }

}