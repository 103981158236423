<div class="flex flex-col max-w-180 max-h-screen">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'uslugi_dodatkowe' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 p-6 sm:overflow-y-auto">


            <ng-container *ngIf="additionalServices.length > 0; else noServices">
                <ng-container *ngFor="let service of additionalServices">
                    <div (click)="toggle(service.additionalServiceId)" class="flex items-center pl-1 pr-4 py-2 cursor-pointer hover:bg-hover" matRipple>
                        
                        <mat-checkbox
                            class="flex items-center pointer-events-none"
                            [checked]="selected.has(service.additionalServiceId)"
                            [color]="'primary'"
                            [disableRipple]="true">
                        </mat-checkbox>
                        <div class="ml-1">
                            <div class="flex items-center w-full">
                                <div class="text-md">

                                    <div class="font-semibold">
                                        {{service.displayName}} 
                                    </div>

                                   <div class="flex items-center space-x-3">

                                        <div class="truncate">
                                            <div class="lowercase" *ngIf="service.type == 0">{{'za_usluge' | transloco}}</div>
                                            <div class="lowercase" *ngIf="service.type == 1">{{'za_osobe' | transloco}}</div>
                                        </div>

                                        <div class="truncate">

                                            <div *ngIf="service.type == 0">
                                                {{service.priceAdult}} {{default_currency}} <small class="text-secondary lowercase">/
                                                {{service.period == 0 ? ('doba' | transloco) : ('pobyt' | transloco)}}</small>
                                            </div>

                                            <div  class="flex items-center space-x-3" *ngIf="service.type == 1">
                                                
                                                <div>
                                                    {{service.priceAdult}} {{default_currency}}<small class="text-secondary lowercase">/{{service.period == 0 ? ('doba' | transloco) : ('pobyt' | transloco)}}</small>
                                                </div>

                                                <div>
                                                    {{service.priceChildren}} {{default_currency}}<small class="text-secondary lowercase">/{{service.period == 0 ? ('doba' | transloco) : ('pobyt' | transloco)}}</small>
                                                </div>
                                               
                                            </div>

                                        </div>

                                   </div>

                                 

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
           
        </div>
    </div>
    <!--Footer-->
    <div
        class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <button mat-button color="primary" (click)="showAddAdditionalServiceDialog()">{{'dodaj_usluge_dodatkowa' | transloco}}</button>
            <div class="ml-auto">
                <!-- Confirm -->
                <button mat-dialog-close mat-stroked-button class="me-2">
                    {{'anuluj' | transloco}}
                </button>
                <button (click)="saveChanges()" mat-flat-button color="primary">
                    {{'zapisz' | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #noServices>
    <fuse-alert [type]="'primary'" [appearance]="'outline'">
        {{'brak_uslug_dodatkowych' | transloco}} <br/>
        
    </fuse-alert>
</ng-template>
