import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResolverStateService {
  private enabled: boolean = true;

  constructor() {}

  disableResolver(): void {
    this.enabled = false;
  }

  enableResolver(): void {
    this.enabled = true;
  }

  isResolverEnabled(): boolean {
    return this.enabled;
  }
}