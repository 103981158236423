<div class="flex flex-col overflow-hidden m-5" >

  <button (click)="close()" mat-icon-button class="absolute right-8 top-8 transform -translate-y-1/2 bg-white border border-black rounded-full w-10 h-10 flex items-center justify-center">
    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
  </button>
<!-- <button (click)="prev()" >
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
  </svg>
</button> -->

<button (click)="prev()" mat-icon-button class="absolute left-8 top-1/2 transform -translate-y-1/2 bg-white border border-black rounded-full w-10 h-10 flex items-center justify-center">
  <mat-icon [svgIcon]="'heroicons_solid:arrow-left'"></mat-icon>
</button>

<!-- <div class="mx-auto my-auto"  (swiperight)="prev()" (swipeleft)="next()"> -->
    <img class="object-scale-down mx-auto rounded-xl w-full" style="max-height: 80vh;" *ngIf="selectedImage != null" [src]="selectedImage?.path" [alt]="selectedImage?.alt" alt="Full Size Image"   />

    <div class="mx-auto text-center my-4">
        {{'zdjecie' | transloco}} {{currentImageIndex+1}} z {{images?.length}}
    </div>
<!-- </div> -->

<button (click)="next()" mat-icon-button class="absolute right-8 top-1/2 transform -translate-y-1/2 bg-white border border-black rounded-full w-10 h-10 flex items-center justify-center">
  <mat-icon [svgIcon]="'heroicons_solid:arrow-right'"></mat-icon>
</button>
<!-- <button (click)="next()" class="absolute right-8 top-1/2 transform -translate-y-1/2 bg-white border border-black rounded-full w-10 h-10 flex items-center justify-center">
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
  </svg>
</button> -->

 
  <!-- <div class="absolute bottom-0 left-0 flex justify-center w-full p-4">

      <div #scrollContainer class="overflow-x-auto p-2" style="scrollbar-width: thin;">
          <div class="flex space-x-4">
              <img [src]="image.path" [ngClass]="{'current-image' : image.position == currentImageIndex}" (click)="selectImage(image.position)" [alt]="image.alt" class="cursor-pointer h-12 w-auto rounded"  *ngFor="let image of images" />
            </div>
      </div>
   
  </div> -->
</div>
