import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataSynchronizerService {

    private _data = new BehaviorSubject<any>({table: null, value: null})
    currentData = this._data.asObservable()

    next(table, value) {
        this._data.next({
            table: table,
            value: value
        })
    }

    received() {
        this._data.next({table: null, value: null})
    }
}