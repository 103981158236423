import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TaxRatePipe } from 'app/core/shared/pipes/tax-rate.pipe';

@Component({
  selector: 'invoice-item-listing',
  standalone: true,
  imports: [CommonModule, TaxRatePipe, TranslocoModule],
  templateUrl: './invoice-item-listing.component.html',
  styleUrl: './invoice-item-listing.component.scss'
})
export class InvoiceItemListingComponent {

  @Input('invoice') invoice
  @Input('isEditable') isEditable
  @Input('currency') currency

  constructor() {

  }
}
