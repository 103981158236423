<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            <!-- create -->
            <p class="text-lg font-medium">
                {{'kolizje_rezerwacji' | transloco}}
            </p>
            <div class="ml-auto">
                <!-- close -->
                <button mat-icon-button (click)="close()">
                    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
    </div><!-- end p-6 -->

    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">

            <!-- body -->
            <div class="px-6 py-4">

                <fuse-alert [type]="'warn'" [appearance]="'outline'">
                    <span fuseAlertTitle>{{'uwaga' | transloco}}</span>
                    {{'kolizja_text' | transloco}}
                </fuse-alert>

                <div class="mt-4" *ngFor="let roomCollisions of collision.collisions">
                    <p class="font-medium">{{roomCollisions.room.name}}</p>
                    <div *ngFor="let r of roomCollisions.reservations">
                        <div class="collision-box mt-2">
                            <div class="mt-4">
                                <app-reservation-row [showMoreButton]="true"
                                    [reservation]="r.reservation"></app-reservation-row>
                            </div>
                            <div class="mt-4">
                                <app-reservation-row [showMoreButton]="true"
                                    [reservation]="r.collidingReservation"></app-reservation-row>
                            </div>
                            <hr class="my-10">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>