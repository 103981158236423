import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { AvailabilityService } from '../../services/availability.service';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
// import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@Component({
  standalone:true,
  selector: 'app-select-room-dialog',
  templateUrl: './select-room-dialog.component.html',
  styleUrls: ['./select-room-dialog.component.scss'],
  imports: [CommonModule, MatDialogModule, MatIconModule, MatFormFieldModule, TranslocoModule, MatDividerModule, MatButtonModule, MatInputModule, MatCheckboxModule, MatRippleModule]
})
export class SelectRoomDialogComponent {

  rooms = [];
  selected = [];
  title = '';
  confirmText = '';
  disabled = [];
  range: {start:Date, end: Date} = { start: null, end: null };
  @ViewChild('selectedRoomsList') selectedRoomsList
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<SelectRoomDialogComponent>,
    private availabilityService:AvailabilityService,
    public translate:TranslocoService
  ) {
    if (data.selectedIds != null && typeof data.selectedIds != 'undefined') this.selected = data.selectedIds || []
    // Create array from single value if not array
    if(!Array.isArray(data.selectedIds)) this.selected = [data.selectedIds]
    if (data.title != null) this.title = data.title
    if (data.confirmText != null) this.confirmText = data.confirmText
    if (data.disabled != null) this.disabled = data.disabled
    if (data.range != null) {
      this.range = data.range;
    }

    this.search('');
  }

  selectAll() {
    this.selected = this.rooms.map(x => x.roomId)
  }

  deselectAll() {
    this.selected = [];
  }

  toggle(id) {
    if (typeof this.selected == 'undefined') return;
    const indexOf = this.selected.indexOf(id)
    if (indexOf != -1) { this.selected.splice(indexOf, 1) }
    else {

      if (!this.disabled.includes(id)) this.selected.push(id);
    }
  }

  isSelected(id) {
    if (typeof this.selected == 'undefined') return;
    const indexOf = this.selected.indexOf(id);
    if (indexOf == -1) return false;
    return true;
  }

  isDisabled(id) {
    return this.disabled.includes(id);
  }


  search(value) {

    if(this.selected == null) this.selected = [];
    if(typeof this.selected == 'undefined') this.selected = [];
    let availability = this.availabilityService.getAvailability(
      this.range.start,
      this.range.end,
      null,
      null
    )

    value = value.toLowerCase();
    this.rooms = availability.availableRooms
      .filter(
        (x: any) => {
          if (x.status == 0)  return false;

            this.selected.forEach(id => {
              if (id == x.roomId) { x.selected = true }
            })

            if (value.length > 0) {
              if (x.name.toLowerCase().includes(value)) {
                return true;
              }
              else { return false; }
            }
            
            return true;

        })
      .sort((a: any, b: any) => a.order - b.order)
    return this.rooms;
  }


  clear() {
    let value = "";
    this.search(value);
  }

  close(option) {
    // const selected = this.selectedRoomsList.selectedOptions.selected.map(x=>x.value)
    // console.log(selected);
    this.dialog.close({ action: option, selected: this.selected });
  }
}
