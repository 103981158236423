<div class="flex flex-col max-w-180 w-180 max-h-screen">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">Channel manager</div>
        <button mat-icon-button [tabIndex]="-1" (click)="confirmClose()">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>


    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 p-6 sm:overflow-y-auto">

           

            <ng-container *ngFor="let err of errors">
                <div class="text-3xl font-extrabold tracking-tight">
                    {{err.channelName}}
                </div>
                <mat-tab-group animationDuration="0ms" class="vertically-tabs m-0-content p-0-content"
                    mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight dynamicWidth>
                    <mat-tab [label]="'wiadomosc' | transloco">
                        <div class="mt-6">
                            <fuse-alert [type]="'warn'" [appearance]="'outline'">
                                <span fuseAlertTitle>{{'blad' | transloco}}!</span>
                                {{err.message | transloco}}
                            </fuse-alert>
                        </div>
                    </mat-tab>
                    <mat-tab [label]="'szczegoly' | transloco">
                        <div class="mt-6 prose">
                            <textarea fuse-highlight [lang]="'xml'" [value]="err.result">
                            </textarea>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <hr class="mt-6 mb-6">
            </ng-container>

            <!-- <ng-container *ngFor="let su of successes">
                <div class="text-3xl font-extrabold tracking-tight">
                    {{su.channelName}}
                </div>
                <mat-tab-group animationDuration="0ms" class="vertically-tabs m-0-content p-0-content"
                    mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight dynamicWidth>
                    <mat-tab [label]="'wiadomosc' | transloco">
                        <div class="mt-6">
                            <fuse-alert *ngIf="errors.length == 0" [type]="'success'" [appearance]="'outline'">
                                <span fuseAlertTitle>{{'sukces' | transloco}}!</span>
                                {{'wyslano_dostepnosc_do_channel_managera' | transloco}}
                            </fuse-alert>
                        </div>
                    </mat-tab>
                    <mat-tab [label]="'szczegoly' | transloco">
                        <div class="mt-6 prose">
                            <textarea fuse-highlight [lang]="'xml'" [value]="su.result">
                            </textarea>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <hr class="mt-6 mb-6">
            </ng-container> -->
        </div>
    </div>
    <!--Footer-->
    <div
        class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <button *ngIf="errors.length > 0" mat-stroked-button (click)="confirmClose()" class="me-2">
                    {{'anuluj' | transloco}}
                </button>
                <!-- Confirm -->
                <button *ngIf="canTry" [disabled]="isProcessing" mat-flat-button color="primary" (click)="tryAgain()">
                    {{'sprobuj_ponownie' | transloco}}
                </button>

                <button (click)="close()" *ngIf="errors.length == 0" mat-flat-button color="primary">
                    {{'zamknij' | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>