import { PricingChannel } from "../models/PricingChannel";
import { Countries } from "./countries";
import { RoomEquipment } from "./room-equipment";

export class ApplicationData {

    public static ContactEmailAddress = 'info@mobile-calendar.com'
    public static DefaultSourceBackgroundColor = '#e2e8f0';

    public static PricingCalendarHeaderWidth = 50;
    public static CalendarHeaderWidth = 50;
    public static CalendarHeaderHeight = 50;

    public static DefaultReservationHeight = 50;
    public static DefaultReservationWidth = 150;

    public static ReservationPaddingLeft = 2;
    public static ReservationPaddingRight = 2;
    public static ReservationPaddingTop = 4;

    public static InvoicePaymentStatuses = [
        { id: 0, name: 'do_zaplaty'},
        { id: 1, name: 'zaplacono'},
    ]

    public static DaysOfWeek = [
        { id: 0, name: 'poniedzialek'},
        { id: 1, name: 'wtorek'},
        { id: 2, name: 'sroda'},
        { id: 3, name: 'czwartek'},
        { id: 4, name: 'piatek'},
        { id: 5, name: 'sobota'},
        { id: 6, name: 'niedziela'},
    ]

    public static BookingDaysOfWeek = [
        { id: 'Mon', name: 'poniedzialek'},
        { id: 'Tue', name: 'wtorek'},
        { id: 'Wed', name: 'sroda'},
        { id: 'Thu', name: 'czwartek'},
        { id: 'Fri', name: 'piatek'},
        { id: 'Sat', name: 'sobota'},
        { id: 'Sun', name: 'niedziela'},
    ]

    public static Triggers = [
        { id: 0, name: 'Booking.com'},
        { id: 1, name: 'icalendar'},
        { id: 2, name: 'api'},
        { id: 3, name: 'booking_engine'},
        { id: 4, name: 'import_danych'},
    ]

    public static TaxOfficesPL = [
        { id: '0202', name: 'URZĄD SKARBOWY W BOLESŁAWCU'},
    ]

    public static RegistrationTypes = [
        { translation: 'wybierz', value: 0 },
        { translation: 'zameldowany', value: 1 },
        { translation: 'wymeldowany', value: 2 }
    ]

    public static Beds = [
        { translation: 'dostawka', id: 0 },
        { translation: 'pojedyncze', id: 1 },
        { translation: 'podwojne', id: 2 }
    ]

    public static CleaningStatuses = [
        { class: 'bg-green-500', translation: 'czysty', id: 0 },
        { class: 'bg-red-500', translation: 'brudny', id: 1 },
        { class: 'bg-amber-500', translation: 'w_trakcie_sprzatania', id: 2 }
    ]

    public static ClientTypes = [
        { translation: 'brak', id: 0 },
        { translation: 'staly_klient', id: 1 },
        { translation: 'niechciany_klient', id: 2 }
    ]

    public static ReservationTypes = [
        { id : 0, translation: 'rezerwacja', icon: "heroicons_solid:user" }, 
        { id : 1, translation: 'rezerwacja_grupowa', icon: "heroicons_solid:user-group" }, 
        { id : 2, translation: 'blokada', icon: "heroicons_solid:lock-closed" }
    ]

    public static Months = [
        { id: 0, name: "sty", fullName: "styczen" },
        { id: 1, name: "lut", fullName: "luty" },
        { id: 2, name: "mar", fullName: "marzec" },
        { id: 3, name: "kwi", fullName: "kwiecien" },
        { id: 4, name: "maj", fullName: "maj" },
        { id: 5, name: "cze", fullName: "czerwiec" },
        { id: 6, name: "lip", fullName: "lipiec" },
        { id: 7, name: "sie", fullName: "sierpien" },
        { id: 8, name: "wrz", fullName: "wrzesien" },
        { id: 9, name: "paz", fullName: "pazdziernik" },
        { id: 10, name: "lis", fullName: "listopad" },
        { id: 11, name: "gru", fullName: "grudzien" }
    ]

    public static Days = [
        {id : 1, translation : 'pn'},
        {id : 2, translation : 'wt'},
        {id : 3, translation : 'sr'},
        {id : 4, translation : 'cz'},
        {id : 5, translation : 'pt'},
        {id : 6, translation : 'sb'},
        {id : 0, translation : 'nd'}
    ]

    public static InvoiceTypes = [
        { id: 1, name: 'faktura_vat'},
        { id: 2, name: 'faktura_proforma'},
        { id: 3, name: 'faktura_zaliczkowa'},
        { id: 6, name: 'faktura_korygujaca'},
        { id: 4, name: 'faktura_zw'},
        { id: 0, name: 'faktura_rachunek'},
        { id: 5, name: 'faktura_zaliczka_rachunek'},
        { id: 7, name: 'faktura_korygujaca_rachunek'}
    ]
    
    public static Languages = [
        { shortName: 'pl', name: 'Polski', img: '/assets/images/flags/pl.svg' },
        { shortName: 'en', name: 'English', img: '/assets/images/flags/gb-ukm.svg' },
        { shortName: 'es', name: 'Español', img: '/assets/images/flags/es.svg' },
        { shortName: 'fr', name: 'Français', img: '/assets/images/flags/fr.svg' },
        { shortName: 'it', name: 'Italiano', img: '/assets/images/flags/it.svg' },
        { shortName: 'pt', name: 'Português', img: '/assets/images/flags/pt.svg' },
        { shortName: 'uk', name: 'українська', img: '/assets/images/flags/ua.svg' },
        { shortName: 'ru', name: 'Pусский', img: '/assets/images/flags/ru.svg' },
        { shortName: 'id', name: 'bahasa Indonesia', img: '/assets/images/flags/id.svg' },
        { shortName: 'th', name: 'แบบไทย', img: '/assets/images/flags/th.svg' },
        { shortName: 'be', name: 'беларуская', img: '/assets/images/flags/by.svg' },
        { shortName: 'bg', name: 'български', img: '/assets/images/flags/bg.svg' },
        { shortName: 'br', name: 'Brasileiro', img: '/assets/images/flags/br.svg' },
        { shortName: 'cs', name: 'čeština', img: '/assets/images/flags/cz.svg' },
        { shortName: 'et', name: 'eesti keel', img: '/assets/images/flags/ee.svg' },
        { shortName: 'tl', name: 'Filipino', img: '/assets/images/flags/ph.svg' },
        { shortName: 'ga', name: 'Gaeilge', img: '/assets/images/flags/ie.svg' },
        { shortName: 'el', name: 'Ελληνικά', img: '/assets/images/flags/gr.svg' },
        { shortName: 'ko', name: '한국인', img: '/assets/images/flags/kr.svg' },
        { shortName: 'hr', name: 'Hrvatski', img: '/assets/images/flags/hr.svg' },
        { shortName: 'hi', name: 'हिन्दी', img: '/assets/images/flags/in.svg' },
        { shortName: 'hu', name: 'Magyar', img: '/assets/images/flags/hu.svg' },
        { shortName: 'is', name: 'íslenskur', img: '/assets/images/flags/is.svg' },
        { shortName: 'ja', name: '日本', img: '/assets/images/flags/ja.svg' },
        { shortName: 'lv', name: 'latviski', img: '/assets/images/flags/lv.svg' },
        { shortName: 'lt', name: 'Litewskis', img: '/assets/images/flags/lt.svg' },
        { shortName: 'nl', name: 'Nederlands', img: '/assets/images/flags/nl.svg' },
        { shortName: 'no', name: 'Norska', img: '/assets/images/flags/no.svg' },
        { shortName: 'ro', name: 'Română', img: '/assets/images/flags/ro.svg' },
        { shortName: 'sr', name: 'Српски', img: '/assets/images/flags/sr.svg' },
        { shortName: 'sl', name: 'Slovenščina', img: '/assets/images/flags/si.svg' },
        { shortName: 'sk', name: 'Slovák', img: '/assets/images/flags/sk.svg' },
        { shortName: 'fi', name: 'Suomalainen', img: '/assets/images/flags/fi.svg' },
        { shortName: 'sv', name: 'Svenska', img: '/assets/images/flags/se.svg' },
        { shortName: 'vi', name: 'Tiếng Việt', img: '/assets/images/flags/vi.svg' },
        { shortName: 'tr', name: 'Türk', img: '/assets/images/flags/tr.svg' },
        { shortName: 'zh', name: '中國人', img: '/assets/images/flags/cn.svg' }
    ]

    public static AppFlagCodes = {
        'pl': 'pl',
        'en': 'gb-ukm',
        'es': 'es',
        'fr': 'fr',
        'it': 'it',
        'pt': 'pt',
        'uk': 'ua',
        'ru': 'ru',
        'id': 'id',
        'th': 'th',
        'be': 'by',
        'bg': 'bg',
        'br': 'br',
        'cs': 'cz',
        'et': 'ee',
        'tl': 'ph',
        'ga': 'ie',
        'el': 'gr',
        'ko': 'kr',
        'hr': 'hr',
        'hi': 'in',
        'hu': 'hu',
        'is': 'is',
        'ja': 'jp',
        'lv': 'lv',
        'lt': 'lt',
        'nl': 'nl',
        'no': 'no',
        'ro': 'ro',
        'sr': 'rs',
        'sl': 'si',
        'sk': 'sk',
        'fi': 'fi',
        'sv': 'se',
        'vi': 'vn',
        'tr': 'tr',
        'zh': 'cn'
    }

    public static AppLanugagesShort = ApplicationData.Languages.map(x=>x.shortName)

    public static RoomTypes = [
        { id: 1, text: "pokoj" },
        { id: 2, text: "domek" },
        { id: 3, text: "apartament" },
        { id: 4, text: "studio" },
        { id: 5, text: "bungalow" },
        { id: 6, text: "kamping" },
        { id: 7, text: "namiot" },
        { id: 8, text: "sala" },
        { id: 9, text: "lozko" },
        { id: 10, text: "kamper" },
        { id: 11, text: "lodz" },
        { id: 12, text: "jacht" },
        { id: 13, text: "willa" },
        { id: 14, text: "dom" }
    ]

    public static ReservationPaymentStatuses = [
        { id: 0, text: "brak_wplaty", class: "bg-red-400", style: null },
        { id: 'waitingForPrepayment', text: "oczekiwanie_na_zadatek", class: "bg-amber-400", style: null },
        { id: 1, text: "wplacony_zadatek", class: "bg-green-400", style: null },
        { id: 2, text: "wplacona_calosc", class: "bg-blue-400", style: null },
        { id: 3, text: "oczekiwanie_na_platnosc", class: "bg-violet-400", style: null }
    ]

    // Some statuses are dynamic like `Awaiting for prepayment`, 
    // it is easier to have a virtual representation of that statuses
    public static VirtualReservationPaymentStatuses = [
        { id: 1, text: "brak_wplaty"},
        { id: 2, text: "oczekiwanie_na_zaliczke"},
        { id: 3, text: "uplynal_termin_wplaty_zaliczki"},
        { id: 4, text: "wplacony_zadatek"},
        { id: 5, text: "wplacona_calosc"}
    ]

    public static WorkerPositions = [
        { id: 0, text: "administrator"},
        { id: 1, text: "recepcjonista"},
        { id: 2, text: "sprzatajacy"},
        { id: 3, text: "manager"},
        { id: 4, text: "wlasciciel"},
        { id: 5, text: "konserwator_techniczny"},
        { id: 6, text: "manager_gastronomii"},

    ]

    public static PermissionsAdministrator = [
        'addRoom',
        'editRoom',
        'deleteRoom',
        'filterRoom',
        'addReservation',
        'showReservation',
        'editReservation',
        'deleteReservation',
        'editDeletePastReservations',
        'registration',
        'changeReservationPaymentStatus',
        'searchReservation',
        'dashboard',
        'arrivalsDeparturesReport',
        'printCalendar',
        'widget',
        'customerDatabase',
        'statistics',
        'exportReservations',
        'importReservations',
        'searchAvailableRooms',
        'pricing',
        'reservations',
        'sendConfirmation',
        'messagesTemplates',
        'smtpConfiguration',
        'invoicing',
        'editObjectData',
        'restoreReservation',
        'changeEmail',
        'changePassword',
        'editPersonalData',
        'meals',
        'additionalServices',
        'onlineBookingEngine',
        'googleCalendar',
        'booking',
        'reservationSource',
        'notifications',
        'settings',
        'buySubscription',
        'showNewChanges',
        'iCalendar',
        'addTask',
        'changeRoomStatus',
        'history',
        'tasks',
        'employees',
        'restApi'
    ]

    public static PermissionsRecepcionist = [
        'addRoom',
        'editRoom',
        'filterRoom',
        'addReservation',
        'showReservation',
        'editReservation',
        'deleteReservation',
        'registration',
        'changeReservationPaymentStatus',
        'searchReservation',
        'dashboard',
        'arrivalsDeparturesReport',
        'printCalendar',
        'widget',
        'customerDatabase',
        'searchAvailableRooms',
        'pricing',
        'reservations',
        'sendConfirmation',
        'messagesTemplates',
        'invoicing',
        'editObjectData',
        'restoreReservation',
        'changeEmail',
        'changePassword',
        'editPersonalData',
        'meals',
        'additionalServices',
        'onlineBookingEngine',
        'googleCalendar',
        'booking',
        'reservationSource',
        'notifications',
        'settings',
        'buySubscription',
        'showNewChanges',
        'iCalendar',
        'addTask',
        'changeRoomStatus',
        'history',
        'tasks'
    ]

    public static PermissionsCleaner = [
        'filterRoom',
        'showReservation',
        'dashboard',
        'printCalendar',
        'changeEmail',
        'changePassword',
        'addTask',
        'changeRoomStatus',
        'tasks'
    ]

    public static PermissionsManager = [
        'addRoom',
        'editRoom',
        'deleteRoom',
        'filterRoom',
        'addReservation',
        'showReservation',
        'editReservation',
        'deleteReservation',
        'editDeletePastReservations',
        'registration',
        'changeReservationPaymentStatus',
        'searchReservation',
        'dashboard',
        'arrivalsDeparturesReport',
        'printCalendar',
        'widget',
        'customerDatabase',
        'statistics',
        'exportReservations',
        'importReservations',
        'searchAvailableRooms',
        'pricing',
        'reservations',
        'sendConfirmation',
        'messagesTemplates',
        'smtpConfiguration',
        'invoicing',
        'editObjectData',
        'restoreReservation',
        'changeEmail',
        'changePassword',
        'editPersonalData',
        'meals',
        'additionalServices',
        'onlineBookingEngine',
        'googleCalendar',
        'booking',
        'reservationSource',
        'notifications',
        'settings',
        'buySubscription',
        'showNewChanges',
        'iCalendar',
        'addTask',
        'changeRoomStatus',
        'history',
        'tasks',
        'employees',
        'restApi'
    ]

    public static PermissionsOwner = [
        'addRoom',
        'editRoom',
        'deleteRoom',
        'filterRoom',
        'addReservation',
        'showReservation',
        'editReservation',
        'deleteReservation',
        'editDeletePastReservations',
        'registration',
        'changeReservationPaymentStatus',
        'searchReservation',
        'dashboard',
        'arrivalsDeparturesReport',
        'printCalendar',
        'widget',
        'customerDatabase',
        'statistics',
        'exportReservations',
        'importReservations',
        'searchAvailableRooms',
        'pricing',
        'reservations',
        'sendConfirmation',
        'messagesTemplates',
        'smtpConfiguration',
        'invoicing',
        'editObjectData',
        'restoreReservation',
        'changeEmail',
        'changePassword',
        'editPersonalData',
        'meals',
        'additionalServices',
        'onlineBookingEngine',
        'googleCalendar',
        'booking',
        'reservationSource',
        'notifications',
        'settings',
        'buySubscription',
        'showNewChanges',
        'iCalendar',
        'addTask',
        'changeRoomStatus',
        'history',
        'tasks',
        'employees',
        'restApi'
    ]

    public static PermissionsFixer = [
        'addTask',
        'tasks'
    ]

    public static PermissionsGastroManager = [
        'meals',
        'addTask',
        'tasks'
    ]

    public static WorkerPositionPermissions = [
        { id: 0, portal: 0, permissions: this.PermissionsAdministrator },
        { id: 1, portal: 0, permissions: this.PermissionsRecepcionist },
        { id: 2, portal: 1, permissions: this.PermissionsCleaner },
        { id: 3, portal: 0, permissions: this.PermissionsManager },
        { id: 4, portal: 0, permissions: this.PermissionsOwner },
        { id: 5, portal: 1, permissions: this.PermissionsFixer },
        { id: 6, portal: 1, permissions: this.PermissionsGastroManager }
    ]

    public static ReservationPaymentTypes = [
        { id: 0, text: "brak" },
        { id: 1, text: "platnosc_gotowka" },
        { id: 2, text: "platnosc_przelewem" },
        { id: 3, text: "platnosc_karta" },
        { id: 4, text: "karta_wirtualna" }
    ]

    public static InvoicePaymentTypes = [
        { id: 1, text: "gotowka" },
        { id: 2, text: "przelew" },
        { id: 3, text: "karta_platnicza" },
        { id: 4, text: "przedplata" },
        { id: 5, text: "platnosc_online" }
    ]

    public static AlimentationTypes = [

        { id: 0, translation: "OV_bez_wyzywienia", type: [] },
        { id: 1, translation: "BB_sniadanie", type: [1]},
        { id: 2, translation: "HB_sniadanie_obiad", type: [1,2] },
        { id: 3, translation: "FB_sniadanie_obiad_kolacja", type: [1,2,3] },
        { id: 4, translation: "AI_all_inclusive", type: [1,2,3] },
        { id: 5, translation: "obiad", type: [2] },
        { id: 6, translation: "sniadanie_kolacja", type: [1,3] },
        { id: 7, translation: "obiad_kolacja", type: [2,3] },
        { id: 8, translation: "kolacja", type: [3] },
    ]

    public static ConfirmationFields = [

        { id: 'objectName', translation: "nazwa_obiektu"},
        { id: 'street', translation: "ulica_i_numer_domu"},
        { id: 'city', translation: "miejscowosc"},
        { id: 'postalCode', translation: "kod_pocztowy"},
        { id: 'country', translation: "kraj"},
        { id: 'objectPhone', translation: "telefon"},
        { id: 'objectEmail', translation: "email"},
        { id: 'website', translation: "strona_www"},
        { id: 'checkIn', translation: "doba_od"},
        { id: 'checkOut', translation: "doba_do"},

        { id: 'reservationNo', translation: "numer_rezerwacji"},
        { id: 'arrival', translation: "przyjazd"},
        { id: 'departure', translation: "wyjazd"},
        { id: 'daysStay', translation: "liczba_dni_pobytu"},
        { id: 'adults', translation: "dorosli"},
        { id: 'children', translation: "dzieci"},

        { id: 'forename', translation: "imie"},
        { id: 'name', translation: "nazwisko"},
        { id: 'phone', translation: "telefon"},
        { id: 'email', translation: "email"},

        { id: 'payment', translation: "status_platnosci"},
        { id: 'toPay', translation: "do_zaplaty"},
        { id: 'room', translation: "pokoj"},
        { id: 'meals', translation: "wyzywienie"},
        { id: 'additionalServices', translation: "uslugi_dodatkowe"},
    ]


    public static FastView = [
        { id: 'reservationNo', translation: "nr_rezerwacji"},
        { id: 'forename', translation: "imie"},
        { id: 'name', translation: "nazwisko"},
        { id: 'phone', translation: "telefon"},
        { id: 'email', translation: "email"},
        { id: 'arrival', translation: "przyjazd"},
        { id: 'departure', translation: "wyjazd"},
        { id: 'daysStay', translation: "liczba_dni_pobytu"},
        { id: 'adults', translation: "dorosli"},
        { id: 'children', translation: "dzieci"},
        { id: 'payment', translation: "platnosc"},
        { id: 'additionalInfo', translation: "dodatkowe_informacje"},
        { id: 'source', translation: "zrodlo"},
        { id: 'sentMessages', translation: "wyslane_wiadomosci"},
        { id: 'addDate', translation: "data_dodania"},
        { id: 'editDate', translation: "data_edycji"},
        { id: 'employee', translation: "pracownik"}
    ]

    public static ReservationFields = [
        { id: 'hours', translation: 'godziny'},
        { id: 'adults', translation: 'dorosli'},
        { id: 'children', translation: 'dzieci'},
        { id: 'registration', translation: 'meldunek'},
        { id: 'moreClientData', translation: 'wiecej_danych_klienta'},
        { id: 'source', translation: 'zrodlo_rezerwacji'},
        { id: 'meals', translation: 'wyzywienie'},
        { id: 'additionalServices', translation: 'uslugi_dodatkowe'},
        { id: 'doorCode', translation: 'kod_do_drzwi'},
        { id: 'discount', translation: 'rabat'}
    ]
    
    public static PricingRestrictions = [
        { id: 'price1', translation: 'cena_za_1_osobe', availableIn: ['Booking.com']},
        { id: 'minStay', translation: 'minimalny_okres_pobytu', availableIn: ['Mobile-Calendar', 'Booking.com']},
        { id: 'maxStay', translation: 'maksymalny_okres_pobytu', availableIn: ['Mobile-Calendar', 'Booking.com']},
        { id: 'availArrival', translation: 'mozliwy_przyjazd', availableIn: ['Mobile-Calendar', 'Booking.com']},
        { id: 'availDeparture', translation: 'mozliwy_wyjazd', availableIn: ['Mobile-Calendar', 'Booking.com']},
        { id: 'minimumStayArrival', translation: 'min_dni_na_przyjazd', availableIn: ['Booking.com']},
        { id: 'maximumStayArrival', translation: 'max_dni_na_przyjazd', availableIn: ['Booking.com']},
        { id: 'exactStayArrival', translation: 'dokladna_liczba_dni_w_dniu_przyjazdu', availableIn: ['Booking.com']},
        { id: 'minAdvanceRes', translation: 'min_wyprzedzenie_rezerwacji', availableIn: ['Booking.com']},
        { id: 'maxAdvanceRes', translation: 'max_wyprzedzenie_rezerwacji', availableIn: ['Booking.com']},
        { id: 'closed', translation: 'zamkniety', availableIn: ['Booking.com']}
    ]

    public static Labels = [
        { id: "reservationNo", translation: 'numer_rezerwacji'},
        { id: "forename", translation: 'imie'},
        { id: "name", translation: 'nazwisko'},
        { id: "phone", translation: 'telefon'},
        { id: "email", translation: 'email'},
        { id: "arrival", translation: 'przyjazd'},
        { id: "departure", translation: 'wyjazd'},
        { id: "daysStay", translation: 'liczba_dni_pobytu'},
        { id: "adults", translation: 'dorosli'},
        { id: "children", translation: 'dzieci'},
        { id: "additionalInfo", translation: 'dodatkowe_informacje'},
        { id: "source", translation: 'zrodlo'},
        { id: "sentMessages", translation: 'wyslane_wiadomosci'},
        { id: "addDate", translation: 'data_dodania'},
        { id: "editDate", translation: 'data_edycji'},
        { id: "employee", translation: 'pracownik'}
    ]

    public static LabelMappedToObjectProperty = [
        { key: "reservationNo", objKey: "reservationNo" },
        { key: "forename", objKey: "client.forename" },
        { key: "name", objKey: "client.name" },
        { key: "phone", objKey: "client.phone" },
        { key: "email", objKey: "client.email" },
        { key: "arrival", objKey: "arrival" },
        { key: "departure", objKey: "departure" },
        { key: "daysStay", objKey: "days" },
        { key: "adults", objKey: "adults" },
        { key: "children", objKey: "children" },
        { key: "source", objKey: "sourceId" },
        { key: "sentMessages", objKey: "" },
        { key: "addDate", objKey: "addDate" },
        { key: "editDate", objKey: "editDate" },
        { key: "employee", objKey: "userId" }
    ]

    public static Errors = [
        { key: "ROOM_OCCUPIED", translation: "termin_zajety"},
        { key: "GROUP_RESERVATION_NO_ACCCESS", translation: "nie_mozna_przeniesc_rezerwacji_grupowej_do_innego_pokoju"},
        { key: "NOT_FOUND", translation: "blad"},
        { key: "BAD_REQUEST", translation: "blad"},
    ]

    
    public static KidsAges = [
        { value :  1,  text: '0-1'},
        { value :  2,  text: '2'  },
        { value :  3,  text: '3'  },
        { value :  4,  text: '4'  },
        { value :  5,  text: '5'  },
        { value :  6,  text: '6'  },
        { value :  7,  text: '7'  },
        { value :  8,  text: '8'  },
        { value :  9,  text: '9'  },
        { value :  10, text: '10' },
        { value :  11, text: '11' },
        { value :  12, text: '12' },
        { value :  13, text: '13' },
        { value :  14, text: '14' },
        { value :  15, text: '15' },
        { value :  16, text: '16' },
        { value :  17, text: '17' }
    ]

    public static Countries = Countries.List;
    public static RoomEquipment = RoomEquipment.List;

    public static PricingTooltips = {
        "price1": { name: "cena_za_pokoj_do_pojedynczego_wykorzystania_za_noc", description: "cena_za_pokoj_do_pojedynczego_wykorzystania_za_noc_opis"},
        "minStay": { name: "minimalny_okres_pobytu", description: "minimalny_okres_pobytu_opis"},
        "maxStay": { name: "maksymalny_okres_pobytu", description: "maksymalny_okres_pobytu_opis"},
        "availArrival": { name: "mozliwy_przyjazd", description: "mozliwy_przyjazd_opis"},
        "availDeparture": { name: "mozliwy_wyjazd", description: "mozliwy_wyjazd_opis"},
        "minimumStayArrival": { name: "minimalna_liczba_dni_w_dniu_przyjazdu", description: "minimalna_liczba_dni_w_dniu_przyjazdu_opis"},
        "maximumStayArrival": { name: "maksymalna_liczba_dni_w_dniu_przyjazdu", description: "maksymalna_liczba_dni_w_dniu_przyjazdu_opis"},
        "exactStayArrival": { name: "dokladna_liczba_dni_w_dniu_przyjazdu", description: "dokladna_liczba_dni_w_dniu_przyjazdu_opis"},
        "minAdvanceRes": { name: "minimalne_wyprzedzenie_rezerwacji", description: "minimalne_wyprzedzenie_rezerwacji_opis"},
        "maxAdvanceRes": { name: "maksymalne_wyprzedzenie_rezerwacji", description: "maksymalne_wyprzedzenie_rezerwacji_opis"},
        "closed": { name: "zamkniety", description: "zamkniety_opis"},
    }

    public static PricingChannels:PricingChannel[] = [
        new PricingChannel({ 
            id: 'mobile-calendar',
            name: 'Mobile-Calendar',
            restrictions: [
                { id: 'minStay', type: 'text', translation: 'minimalny_okres_pobytu' },
                { id: 'maxStay', type: 'text', translation: 'maksymalny_okres_pobytu' },
                { id: 'availArrival', type: 'checkbox', translation: 'mozliwy_przyjazd' },
                { id: 'availDeparture', type: 'checkbox', translation: 'mozliwy_wyjazd' },
            ],
            fetch: false
        }),
        new PricingChannel({ 
            id: 'booking',
            name: 'Booking.com',
            restrictions: [
                { id: 'minStay', type: 'text', translation: 'minimalny_okres_pobytu' },
                { id: 'maxStay', type: 'text', translation: 'maksymalny_okres_pobytu' },
            ],
            fetch: true
        }),
    ]

    public static NotificationSounds = [
        // { value: null, translation: 'brak', fileName: null},
        { value: 'DEFINITE', translation: 'domyslny', fileName: 'definite-555.mp3'},
    ]

    public static ReservationFormViews = [
        { id: 'TAB', translation: 'podzielony_na_karty'  },
        { id: 'NORMAL', translation: 'wszystko_w_jednym' }
    ]

    public static INVOICES_DATE_FORMAT_MONTHLY = [
        "NUMBER/MM/YYYY", 
        "YYYY/MM/NUMBER", 
        "NUMBER-MM-YYYY", 
        "YYYY-MM-NUMBER"
    ];

    public static INVOICES_DATE_FORMAT_YEARLY = [
        "NUMBER/YYYY", 
        "YYYY/NUMBER", 
        "NUMBER-YYYY", 
        "YYYY-NUMBER"
    ];
}


