import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const localize = inject(LocalizeRouterService);

    // Check the authentication status
    return inject(AuthService).isLoggedIn$.pipe(
        switchMap((authenticated) =>
        {
            // If the user is authenticated...
            if (authenticated)
            {
                console.log("[NoAuthGuard]: Logged users can't have access to that resources. Redirecting...")
                const translatedPath = localize.translateRoute('/');
                router.navigate([translatedPath]);
                return of(false);
            }

            // Allow the access
            return of(true);
        }),
    );
};
