export class DbMeal {
    userId: number
    breakfastFrom: string
    breakfastTo: string
    dinnerFrom	: string
    dinnerTo	: string
    supperFrom: string
    supperTo	: string
    bbAdult	: number
    bbChildren	: number
    dinnerAdult	: number
    dinnerChildren	: number
    halfBoardAdult	: number
    halfBoardChildren	: number
    fullBoardAdult	: number
    fullBoardChildren	: number
    allInclusiveAdult	: number
    allInclusiveChildren	: number
    breakfastSupperAdult	: number
    breakfastSupperChildren: number
    dinnerSupperAdult	: number
    dinnerSupperChildren	: number
    supperAdult	: number
    supperChildren	: number
    status:any	
    description	:any
    editDate	:string
}