import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Collision } from '../../models/Collision';
import { CollisionService } from '../../services/collision.service';
import { PanelService } from '../../services/panel.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MatButtonModule } from '@angular/material/button';
import { ReservationRowComponent } from '../reservation-row/reservation-row.component';


@Component({
  standalone: true,
  selector: 'app-collisions',
  templateUrl: './collisions.component.html',
  styleUrls: ['./collisions.component.scss'],
  imports: [CommonModule, TranslocoModule, MatIconModule, MatTooltipModule, FuseAlertComponent, MatButtonModule, MatIconModule, ReservationRowComponent]
})
export class CollisionsDialogComponent implements OnDestroy {

  collision:Collision = new Collision();
  subscriptions: Subscription[] = [];

  constructor(
    private panelService:PanelService,
    private collisionService:CollisionService
  )
  {
    this.subscriptions.push(this.collisionService.currentCollision.subscribe(
      {
        next: (data)=> {
          this.collision = data;
          if(!this.collision.hasCollision) { this.close();}
        }
      }
    ))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  close()
  {
    this.panelService.close();
    this.panelService.clearPanelPortal();
  }
}
