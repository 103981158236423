import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription, interval, take } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { Utils } from 'app/core/shared/others/utils';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { fuseAnimations } from '@fuse/animations';
import { IAPIResponse } from 'app/core/shared/services/app-init.service';
import { SMTPAccountsApiService } from 'app/core/shared/services/api/smtp-accounts.api.service';

@Component({
  selector: 'app-redirect-smtp-config',
  standalone: true,
  imports: [CommonModule, FuseCardComponent, TranslocoModule, RouterLink, MatIconModule],
  templateUrl: './redirect-smtp-config.component.html',
  styleUrl: './redirect-smtp-config.component.scss',
  animations: fuseAnimations
})
export class RedirectSmtpConfigComponent implements OnInit, OnDestroy {

  status: 'success' | 'error' | 'awaiting' = 'awaiting';
  countdown: number = 10;
  private timerSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute, 
    private _router: Router, 
    private _localize: LocalizeRouterService,
    private _smtpAccountsApiService: SMTPAccountsApiService,
  ) {}

  ngOnInit(): void {
    this._route.queryParamMap.subscribe(params => {
      const code = params.get('code') || ''
      const state = params.get('state') || ''

      if (Utils.isNullOrEmpty(code) || Utils.isNullOrEmpty(state)) {
        this.status = 'error'
        this.startCountdown();
        return;
      }

      this.startCountdown({code: code, state: state});

    });
  }

  startCountdown({code, state} = {code: null, state: null}): void {


    if(this.status === 'awaiting') this.completeRegister({code: code, state: state})

    this.timerSubscription = interval(1000).subscribe(() => {
      this.countdown--;
      if (this.countdown === 0) {
        const path = this._localize.translateRoute('/app/smtp-config')
        this._router.navigate([path]);
      }
    })

  }

  completeRegister({code, state}) {

    this._smtpAccountsApiService.oAuth({code, state})
    .pipe(take(1))
    .subscribe({
      next: (response: IAPIResponse) => {
        if(response.status == 'success') {
          this.status = 'success'
          return
        }

        this.status = 'error'
      }
    })
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
