import { DomPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PushNotificationData } from '../../models/push-notification-data';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { DateFormatterComponent } from '../date-formatter/date-formatter.component';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { fuseAnimations } from '@fuse/animations';

@Component({
  standalone: true,
  selector: 'mc-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [CommonModule, DateFormatterComponent, MatIconModule, MatButtonModule, FuseCardComponent, MatButtonModule, MatIconModule, TranslocoModule],
  animations : fuseAnimations
})
export class ToastComponent implements OnInit {

  @Input('type') type: 'DEFAULT' | 'NEW_CHANGES' = 'DEFAULT'
  @Input('data') data: PushNotificationData;
  
  @Input() portalHost: DomPortalOutlet;
  @Input() portal: ComponentPortal<ToastComponent>;
  state = false;
  actions = {};

  constructor() {
    this.state = true;
  }

  ngOnInit(): void {

  }

  registerAction(action) {
    this.actions[action] = new BehaviorSubject<any>(null)
  }

  invoke(action) {
    this.actions[action].next(true)

  }

  on(action) {
    this.registerAction(action)
    return this.actions[action].asObservable()
  }

  close() {
    if(this.portalHost) this.portalHost.detach();
  }

}
