<h2 mat-dialog-title>
    {{'rezerwacja_grupowa' | transloco}}
    <button class="mt-2 float-end" mat-dialog-close mat-icon-button matTooltip="{{'zamknij_okno' | transloco}}">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <table class="table mb-3">
        <thead>
            <tr>
                <th>{{'pomieszczenie' | transloco}}</th>
                <th>{{'dorosli' | transloco}}</th>
                <th>{{'dzieci' | transloco}}</th>
                <th>{{'cena_za_pokoj' | transloco}}</th>


                <th>{{'wyzywienie' | transloco}}</th>
                <th>{{'dorosli_porcje' | transloco}}</th>
                <th>{{'dzieci_porcje' | transloco}}</th>
                <th>{{'cena_za_wyzywienie' | transloco}}</th>
                
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let room of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]">
                <td>Room {{room}}</td>
                <td>2</td>
                <td>0</td>
                <td>300zł</td>
                <td>BB</td>
                <td>2</td>
                <td>0</td>
                <td>40zł</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td class="text-end"><b>{{'suma' | transloco}}:</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tfoot>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end" class="bg-gray-50">
    <button mat-stroked-button mat-dialog-close class="mc-button">{{'zamknij' | transloco}}</button>
    <button mat-raised-button color="primary" class="mc-button">
        {{'zapisz' | transloco}}
    </button>
</mat-dialog-actions>