import { Injectable, OnDestroy } from "@angular/core";
import { SettingsService } from "./settings.service";
import { TranslocoService } from "@ngneat/transloco";
import { CalendarReservation } from "../models/CalendarReservation";
import { clone } from "lodash";
import { SourcesService } from "./sources.service";
import { Subscription } from "rxjs";
import { Source } from "../models/Source";

@Injectable({
    providedIn: 'root'
})
export class CalendarReservationService implements OnDestroy {

    private _subscriptions: Subscription[] = []
    sources: Source[] = []

    constructor(
        private _settingsService: SettingsService,
        private _sourcesService: SourcesService,
        private _translate: TranslocoService
    ) {
        this._subscriptions.push(this._sourcesService.currentSources.subscribe({ next: (data) => this.sources = data }))
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

    create(employees, reservation) {

        return new CalendarReservation(
            this._settingsService.userSettings.value,
            this.sources,
            employees,
            this._translate,
            reservation
        )
        
    }
}