import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[replaceCommaByDotNoControl]',
})
export class ReplaceCommaByDotNoControlDirective {
    constructor(
      private el: ElementRef 
      )
      { }

    @HostListener('input', ['$event.target.value'])
    onInputChange(value: string) {
      value = value.replace(',', '.');
      value = value.replace(/[^.\d]/g, '');
      if(value.includes('.'))
      {
        let res = '';
        const parts = value.split('.');
          parts.forEach((part,index) => {
            if(index == 0 && part.length > 0)
            {
              res = part +'.'
            }
            else if(index == 1 && part.length > 0)
            {
              res = res + part.slice(0,2);
            }
        });

        value = res;
      }

      this.el.nativeElement.value = value;

      
    }
  }