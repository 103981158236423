import { Component, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectComponent } from '../../components/language-select/language-select.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { PanelService } from '../../services/panel.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SettingsService } from '../../services/settings.service';
import { Subscription, finalize, take } from 'rxjs';
import { UserSettings } from '../../models/UserSettings';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApplicationData } from '../../data/application-data';
import { Utils } from '../../others/utils';
import { ApiService } from '../../services/api.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbAdditionalService } from '../../models/DbAdditionalService';
import { DATA_INJECTION_TOKEN } from '../../others/data-injection-token';
import { cloneDeep } from 'lodash';
import { ReplaceCommaByDotDirective } from '../../directives/replace-comma-by-dot.directive';
import { SynchronizationService } from '../../services/synchronization.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-add-or-update-additional-service-dialog',
  standalone: true,
  imports: [CommonModule, ReplaceCommaByDotDirective, LanguageSelectComponent, MatSelectModule, MatInputModule, MatCheckboxModule, MatFormFieldModule, TranslocoModule, MatIconModule, MatButtonModule, ReactiveFormsModule],
  templateUrl: './add-or-update-additional-service-dialog.component.html',
  styleUrl: './add-or-update-additional-service-dialog.component.scss'
})
export class AddOrUpdateAdditionalServiceDialogComponent implements OnDestroy {

  private _subscriptions:Subscription[] = []
  isProcessing = false
  mode: 'create' | 'update' = 'create'
  languages = ApplicationData.Languages;
  currency = '';

  additionalService:DbAdditionalService = null
  form = new FormGroup({
    showOtherLanguages: new FormControl(false),
    language: new FormControl('default'),
    nameTemp: new FormControl('', [Validators.required]),
    descriptionTemp: new FormControl(''),
    period: new FormControl(0),
    type: new FormControl(0),
    taxRate: new FormControl(null),
    priceAdult: new FormControl<number>(null),
    priceChildren: new FormControl<number>(null)
  })

  name:any = {}
  description:any = {};

  constructor(
    @Inject(DATA_INJECTION_TOKEN) public data: any,
    private _settingsService:SettingsService,
    private _panelService: PanelService,
    private _apiService:ApiService,
    private _synchronizationService:SynchronizationService,
    private _snackbarService:SnackbarService,
    public translate:TranslocoService,
    private _dialogService:DialogService
    )
  {
    if(Utils.isDefined(this.data,"mode")) {
      this.mode = this.data.mode
    }
    if(Utils.isDefined(this.data,"additionalService")) {
      this.additionalService = this.data.additionalService
      this._loadForm(this.data.additionalService)
    }

    this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
      next: (userSettings:UserSettings) => {
        this.currency = userSettings.userData.currency
      }
    }))
    

    if(this.mode == 'create') {
      this.createTranslationObject();
    }

    this._subscriptions.push(this.form.controls.nameTemp.valueChanges.subscribe({
      next: (data) => {
        this.name[this.form.controls.language.value] = data
      }
    }))

    this._subscriptions.push(this.form.controls.descriptionTemp.valueChanges.subscribe({
      next: (data) => {
        this.description[this.form.controls.language.value] = data
      }
    }))

    this._subscriptions.push(this.form.controls.showOtherLanguages.valueChanges.subscribe({
      next: (data) => {
        if(data == false) this.form.controls.language.setValue('default')
      }
    }))
    this._subscriptions.push(this.form.controls.language.valueChanges.subscribe({
      next: (data)=> {
        const n = this.name[data] || "";
        this.form.controls.nameTemp.setValue(n)
        
        const d = this.description[data] || "";
        this.form.controls.descriptionTemp.setValue(d)

        if(data == 'default') this.form.controls.nameTemp.setValidators([Validators.required])
        else this.form.controls.nameTemp.removeValidators([Validators.required])
        
      }
    }))
  }
  
  confirmDelete() {
    this._subscriptions.push(this._dialogService.confirmDialog({
      confirmText: this.translate.translate('usun'),
      message: this.translate.translate('czy_na_pewno_chcesz_usunac_usluge_dodatkowa'),
      title: this.translate.translate('uwaga'),
      type: 'danger',
      cancelText: this.translate.translate('anuluj'),
      showIcon: true
    })
    .subscribe({
        next: (data) => {
          if(data == true) this.deleteAdditionalService()
    }}))
  }
  deleteAdditionalService() {
    this.isProcessing = true

    if(this.mode != 'update') return 
      this._apiService.deleteAdditionalService(this.additionalService.additionalServiceId)
      .pipe(take(1))
      .pipe(finalize(()=> {
        this.isProcessing = false
      }))
      .subscribe({
        next: (response) => {
          if(response.status == 'success') {
            this._synchronizationService.newSynchronize("Removed additional service")
            this._snackbarService.showSnackBar(this.translate.translate('usunieto_usluge_dodatkowa'),'success',8000)
            this.close()
          } else {
            this._snackbarService.showSnackBar(this.translate.translate(response.message),'success',8000)
          }
        }
      })
    
  }

  createOrUpdate() {

    if(this.form.invalid) return
    if(this.name?.default?.length == 0 || this.name?.default == null) {
      this.form.controls.language.setValue('default')
      
      this.form.controls.nameTemp.setValue(null)
      this.form.controls.nameTemp.markAsDirty({ onlySelf: true})
      this.form.controls.nameTemp.markAsTouched({ onlySelf: true})
      this.form.markAllAsTouched()
      return
    }

    let formValue:any = cloneDeep(this.form.value)
    delete formValue.nameTemp
    delete formValue.descriptionTemp
    delete formValue.showOtherLanguages

    formValue.name = this.name
    formValue.description = this.description

    // Remove empty values
    Object.keys(formValue.name).forEach(key=> {
      if(formValue.name[key] == '') {
        delete formValue.name[key]
      } 
    })

    // Remove empty values
    Object.keys(formValue.name).forEach(key=> {
      if(formValue.name[key] == '') {
        delete formValue.description[key]
      } 
    })


    this.isProcessing = true
    if(this.mode == 'create') {
      this._apiService.createAdditionalService(formValue)
      .pipe(take(1))
      .pipe(finalize(()=> {
        this.isProcessing = false
      }))
      .subscribe({
        next: (response) => {
          if(response.status == 'success') {
            this._synchronizationService.newSynchronize("Added additional service")
            this._snackbarService.showSnackBar(this.translate.translate('zapisano_usluge_dodatkowa'),'success',8000)
            this.close()
          } else {
            this._snackbarService.showSnackBar(this.translate.translate(response.message),'success',8000)
          }
        }
      })
    }

    else if(this.mode == 'update') {
      this._apiService.updateAdditionalService(this.additionalService.additionalServiceId, formValue)
      .pipe(take(1))
      .pipe(finalize(()=> {
        this.isProcessing = false
      }))
      .subscribe({
        next: (response) => {
          if(response.status == 'success') {
            this._synchronizationService.newSynchronize("Updated additional service")
            this._snackbarService.showSnackBar(this.translate.translate('edytowano_usluge_dodatkowa'),'success',8000)
            this.close()
          } else {
            this._snackbarService.showSnackBar(this.translate.translate(response.message),'success',8000)
          }
        }
      })
    }
  }


  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  public close() {
    this._panelService.close();
    this._panelService.clearPanelPortal();
  }
  
  langChanged($event){

  }

  createTranslationObject()
  {
    Utils.setNestedPropertyValue(this.description, "default","");
    this.languages.forEach(lang => {
      Utils.setNestedPropertyValue(this.description, lang.shortName,"");
    });

    Utils.setNestedPropertyValue(this.name, "default","");
    this.languages.forEach(lang => {
      Utils.setNestedPropertyValue(this.name, lang.shortName,"");
    });
  }

  // Private methods
  private _loadForm(service:DbAdditionalService) {
    this.name = service.name
    this.description = service.description
    this.form.controls.nameTemp.setValue(this.name.default)
    this.form.controls.descriptionTemp.setValue(this.description.default)
    this.form.controls.period.setValue(service.period)
    this.form.controls.type.setValue(service.type)
    this.form.controls.taxRate.setValue(service.taxRate)
    this.form.controls.priceAdult.setValue(service.priceAdult)
    this.form.controls.priceChildren.setValue(service.priceChildren)
  }
}
