<div class="flex flex-col w-full min-h-[calc(100%-64px)] max-h-[calc(100%-64px)]">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">

            <ng-container>

                <p class="text-lg font-medium">
                    <span *ngIf="form.controls.type.value == 'CLEANING'">
                        {{'sprzatanie' | transloco}}
                    </span>
                    <span *ngIf="form.controls.type.value == 'TASK'">
                        {{'zadanie' | transloco}}
                    </span>
                </p>
                <div class="ml-auto">
                    <!-- close -->
                    <button mat-icon-button [matTooltip]="'zamknij' | transloco" (click)="close()">
                        <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                    </button>
                </div>
            </ng-container>

        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden mb-2">
        <div class="flex flex-col flex-auto overflow-y-auto p-2 px-4">

            <mat-radio-group class="invisible absolute w-0 h-0 pointer-events-none" #typeRadioGroup="matRadioGroup"
                [formControl]="form.controls.type">
                <ng-container *ngFor="let type of types;">
                    <mat-radio-button [value]="type.id"></mat-radio-button>
                </ng-container>
            </mat-radio-group>

            <div class="grid sm:grid-cols-2 gap-6 w-full pb-6 flex-auto max-h-30 mb-4">
                <ng-container *ngFor="let type of types;">
                    <div (click)="form.controls.type.setValue(type.id)"
                        class="relative sm:col-span-1 shadow cursor-pointer bg-card flex flex-col items-center justify-center rounded-2xl"
                        [ngClass]="{'ring ring-inset ring-primary': form.controls.type.value === type.id}">
                        <div class="py-8 px-1">
                            <ng-container *ngIf="form.controls.type.value === type.id">
                                <mat-icon class="absolute top-0 right-0 mt-3 mr-3 icon-size-7 text-primary"
                                    [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                            </ng-container>
                            <div class="text-center">
                                <mat-icon class="icon-size-7 text-primary" [svgIcon]="type.icon"></mat-icon>
                                <div class="mt-1 whitespace-normal text-secondary">{{type.translation | transloco}}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="mb-4">
                <mat-label class="font-medium">{{'pomieszczenia' |
                    transloco}} ({{selectedRooms?.length}}) *</mat-label>
                <div class="rooms-container my-3">
                    <div class="flex flex-wrap items-center -m-1.5">
                        <ng-container *ngFor="let room of selectedRooms; let i = index;">
                            <div class="flex items-center justify-center pl-4 px-2 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 cursor-pointer"
                                matRipple (click)="removeRoom(i)">
                                <span class="text-md font-medium whitespace-nowrap">
                                    {{ (room.name.length > 35) ? (room.name | slice:0:35)+'...':(room.name) }}
                                </span>
                                <mat-icon class="icon-size-5 ml-2" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                            </div>
                        </ng-container>
                        <mat-error *ngIf="selectedRooms.length == 0">
                            {{'to_pole_jest_wymagane' | transloco}}
                        </mat-error>
                    </div>
                </div>
                <div class="group inline-flex items-center -ml-4 px-4 rounded cursor-pointer"
                    (click)="openSelectRoomDialogComponent()">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    <span class="ml-2 font-medium text-secondary group-hover:underline">{{'wybierz' | transloco}}</span>
                </div>
            </div>

            <div class="mb-4" *ngIf="form.controls.type.value === 'CLEANING'">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{'status_pokoju' | transloco}}</mat-label>
                        <span matSuffix>
                            <span class="ml-4 inline-block w-2 h-2 rounded-full"
                                [class]="selectedRoomStatus?.class || 'bg-red-500'"></span>
                        </span>
                        <mat-select matInput [formControl]="form.controls.service">
                            <mat-option [value]="roomStatus.id" *ngFor="let roomStatus of roomStatuses">
                                {{ roomStatus.translation| transloco}}
                                <ng-container *ngIf="roomStatus.id == 0">
                                    <span class="inline-block w-2 h-2 rounded-full" [ngClass]="roomStatus.class ? roomStatus.class : 'bg-green-500'"></span>
                                </ng-container>
                                <ng-container *ngIf="roomStatus.id == 1">
                                    <span class="inline-block w-2 h-2 rounded-full" [ngClass]="roomStatus.class ? roomStatus.class : 'bg-green-500'"></span>
                                </ng-container>
                                <ng-container *ngIf="roomStatus.id == 2">
                                    <span class="inline-block w-2 h-2 rounded-full" [ngClass]="roomStatus.class ? roomStatus.class : 'bg-green-500'"></span>
                                </ng-container>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
            </div>
            <div class="mb-4" *ngIf="form.controls.type.value === 'TASK'">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'uwagi' | transloco}}</mat-label>
                    <mat-icon matPrefix [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                    <textarea matInput [formControl]="form.controls.serviceInfo" cdkAutosizeMinRows="10"
                        cdkAutosizeMaxRows="15" rows="10"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<!-- footer -->
<div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t z-10 bg-gray-50 dark:bg-gray-900">

    <div class="flex">

        <button mat-button (click)="close()" class="mc-button">{{'anuluj' | transloco}}</button>



        <div class="ml-auto flex">
            <button mat-raised-button color="primary" [disabled]="form.invalid || isProcessing" (click)="create()">
                {{'dodaj' | transloco}}
            </button>

        </div>
    </div>
</div>