import { Component, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DbAdditionalService } from '../../models/DbAdditionalService';
import { TranslocoModule } from '@ngneat/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AdditionalServicesService } from '../../services/additional-services.service';
import { Subscription } from 'rxjs';
import { AddAdditionalServiceDialogComponent } from '../add-additional-service-dialog/add-additional-service-dialog.component';
import { AddOrUpdateAdditionalServiceDialogComponent } from '../add-or-update-additional-service-dialog/add-or-update-additional-service-dialog.component';

@Component({
  selector: 'app-select-additional-services-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatCheckboxModule, MatButtonModule, MatDialogModule, TranslocoModule],
  templateUrl: './select-additional-services-dialog.component.html',
  styleUrl: './select-additional-services-dialog.component.scss'
})
export class SelectAdditionalServicesDialogComponent implements OnDestroy{

  private _subscriptions: Subscription[] = [];

  additionalServices: DbAdditionalService[] = [];
  selected = new Set()

  constructor(
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<SelectAdditionalServicesDialogComponent>, 
    private _additionalServicesService: AdditionalServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){

      this._subscriptions.push(
        this._additionalServicesService.currentAdditionalServices.subscribe({
          next: (data) =>  this.additionalServices = data.filter(x=>x.status == 1)
      }))
      
      if(!data.selected) return
      this.selected = new Set(data.selected)
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  toggle(additionalServiceId) {
    if(this.selected.has(additionalServiceId)) {
      this.selected.delete(additionalServiceId)
    } else this.selected.add(additionalServiceId)
  }

  showAddAdditionalServiceDialog() {
    this._dialog.open(AddOrUpdateAdditionalServiceDialogComponent, {
      data: {
        mode: 'create'
      }
    })
  }
  
  saveChanges() {
    this._dialogRef.close({
      status: true,
      results: Array.from(this.selected)
    })
  }

}
