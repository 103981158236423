import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { api_v1, environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, catchError, Observable, of, Subject, switchMap, take, tap, throwError } from 'rxjs';
import { RefreshPageComponent } from '../shared/dialogs/refresh-page/refresh-page.component';
import { DbService } from '../shared/services/db.service';
import { SnackbarService } from '../shared/services/snackbar.service';
import { AppInitService } from '../shared/services/app-init.service';
import Dexie from 'dexie';
import { SettingsService } from '../shared/services/settings.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

    accessToken = localStorage.getItem(environment.ACCESS_TOKEN) ?? ''
    refreshToken = localStorage.getItem(environment.REFRESH_TOKEN) ?? ''
    
    public _isLoggedIn$ = new BehaviorSubject<boolean>(false);
    isLoggedIn$ = this._isLoggedIn$.asObservable();

    deviceInfo = this._deviceService.getDeviceInfo()
    deviceId = localStorage.getItem('device_id') == null ? AuthUtils.generateDeviceId() : localStorage.device_id;
    device = `${this.deviceInfo.browser} v${this.deviceInfo.browser_version}`;
    deviceName = `${this.deviceInfo.os} ${this._deviceService.getDeviceInfo().os_version} `;
    platform = this._deviceService.deviceType;

    constructor(
        private _httpClient: HttpClient,
        private _deviceService: DeviceDetectorService,
        private _localize: LocalizeRouterService,
        private _router: Router,
        private _translocoService: TranslocoService,
        private _snackbarService:SnackbarService
    ) {
        this._isLoggedIn$.next(!!this.token);
    }

    get token(): string {
        return localStorage.getItem(environment.ACCESS_TOKEN) ?? '';
    }

    relogin(userId: number, deviceId: string, device: string, deviceName: string, platform: string): any {
        return this._httpClient.post(api_v1 + 'relogin', { userId, deviceId, device, deviceName, platform });
    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    updateTokens() {
        this.accessToken = localStorage.getItem(environment.ACCESS_TOKEN) ?? ''
        this.refreshToken = localStorage.getItem(environment.REFRESH_TOKEN) ?? ''
    }
    isLoggedIn() {
        return this._isLoggedIn$.value;
    }
    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(data: any): Observable<any> {
        return this._httpClient.post(api_v1 + 'forgot-password', data);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(data: any): Observable<any> {
        return this._httpClient.post(api_v1 + 'reset-password', data);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string, password: string }) {
        // Throw error, if the user is already logged in
        if (this.isLoggedIn()) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(api_v1 + 'login', {
            email: credentials.email,
            password: credentials.password,
            deviceId: this.deviceId,
            device: this.device,
            deviceName: this.deviceName,
            platform: this.platform
        })
            .pipe(
                tap((response: any) => {
                    if (response.status != 'success')  return

                    this._isLoggedIn$.next(true);
                    localStorage.setItem(environment.ACCESS_TOKEN, response.data.accessToken);
                    localStorage.setItem(environment.REFRESH_TOKEN, response.data.refreshToken);

                    this.accessToken = response.data.accessToken
                    this.refreshToken = response.data.refreshToken

                    
                    
                })
            );
    }

    signUp(credentials: { email: string, password: string, language: string }) {
        // Throw error, if the user is already logged in
        if (this.isLoggedIn()) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(api_v1 + 'register', {
            email: credentials.email,
            password: credentials.password,
            lang: credentials.language,
            acceptRegulations: true,
            deviceId: this.deviceId,
            device: this.device,
            deviceName: this.deviceName,
            platform: this.platform
        })
    }

    getRefreshToken() {
        return this._httpClient.post(api_v1 + 'token/refresh', { 
            refreshToken: this.refreshToken 
        }).pipe(
          tap(
            (response: any) => {

                if(response.status != 'success') return

                localStorage.setItem(environment.ACCESS_TOKEN, response.data.accessToken);
                localStorage.setItem(environment.REFRESH_TOKEN, response.data.refreshToken);

                this.accessToken = response.data.accessToken
                this.refreshToken = response.data.refreshToken

            }
          )
        );
      }

    /**
    * Sign in using the access token
    */
    // signInUsingToken(): Observable<any> {
    //     // Sign in using the token
    //     return this._httpClient.post('api/auth/sign-in-with-token', {
    //         accessToken: this.accessToken,
    //     }).pipe(
    //         catchError(() =>
    //             // Return false
    //             of(false),
    //         ),
    //         switchMap((response: any) => {
    //             // Replace the access token with the new one if it's available on
    //             // the response object.
    //             //
    //             // This is an added optional step for better security. Once you sign
    //             // in using the token, you should generate a new one on the server
    //             // side and attach it to the response object. Then the following
    //             // piece of code can replace the token with the refreshed one.
    //             if (response.accessToken) {
    //                 this.accessToken = response.accessToken;
    //             }
    //             // Set the authenticated flag to true
    //             this._isAuthenticated.next(true)
    //             // Store the user on the user service
    //             this._userService.user = response.user;
    //             // Return true
    //             return of(true);
    //         }),
    //     );
    // }

    /**
    * Sign out
    */
    signOut(): void {
        this._httpClient.post(api_v1 + 'logout', {})
            .pipe(take(1))
            .subscribe({
                next: (response: any) => {
                    this._processLogout()
                },
                error: (error) => {
                    this._processLogout()
                }
            })
    }

    private _processLogout() {
 
        // Remove the access token from the local storage
        localStorage.clear()

        console.log(`[AuthService] Set is loggedIn to false`)
        // Set the authenticated flag to false
        this._isLoggedIn$.next(false);
        const trasnlatedPath = this._localize.translateRoute('/sign-out')
        this._router.navigate([trasnlatedPath]);
        this._snackbarService.showSnackBar(this._translocoService.translate('twoja_sesja_wygasla'), 'error', 5000);

    }
    /**
     * Sign up
     *
     * @param user
     */
    // signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    //     return this._httpClient.post('api/auth/sign-up', user);
    // }

    /**
     * Unlock session
     *
     * @param credentials
     */
    // unlockSession(credentials: { email: string; password: string }): Observable<any> {
    //     return this._httpClient.post('api/auth/unlock-session', credentials);
    // }


}
