import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ServerResponseErrors } from '../../services/handling.service';
import { Utils } from '../../others/utils';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'app-display-errors-dialog',
  templateUrl: './display-errors-dialog.component.html',
  styleUrls: ['./display-errors-dialog.component.scss'],
  imports: [CommonModule, MatButtonModule, FuseAlertComponent, MatIconModule, MatDialogModule, TranslocoModule]
})
export class DisplayErrorsDialogComponent {

  errors:any = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {errors},
    private dialog: MatDialogRef<DisplayErrorsDialogComponent>
  )
  {
    if(Utils.isDefined(data,"errors"))
    {
      this.errors = data.errors
    }
  }

  close()
  {
    this.dialog.close();
  }

}


