import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { AvailableLangs, TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { AuthService } from 'app/core/auth/auth.service';
import { ApplicationData } from 'app/core/shared/data/application-data';
import { ApiService } from 'app/core/shared/services/api.service';
import { IAPIResponse } from 'app/core/shared/services/app-init.service';
import { ResolverStateService } from 'app/core/shared/services/resolver-state.service';
import { Subscription, take, takeUntil } from 'rxjs';
import { Location } from '@angular/common';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';

@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    exportAs       : 'languages',
    standalone     : true,
    imports        : [MatButtonModule, MatMenuModule, TranslocoPipe, MatTooltipModule, NgTemplateOutlet, NgFor],
})
export class LanguagesComponent implements OnInit, OnDestroy
{
    private _subscriptions: Subscription[] = []
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
    */
    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _localizeRouterService:LocalizeRouterService,
        private _dateAdapter: DateAdapter<LuxonDateAdapter>, 
        private _apiService:ApiService,
        private _spalshScreenService: FuseSplashScreenService,
        private _authService:AuthService,
        private _location: Location
    )
    { 
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the available languages from transloco
        this.availableLangs = ApplicationData.Languages.map(x => { return {id: x.shortName, label: x.name}});


        // Subscribe to language changes
        this._subscriptions.push(
            this._translocoService.langChanges$
            .subscribe({
                next: (activeLang) =>
                {
                    // Get the active lang
                    this.activeLang = activeLang;

                    const locale = this._translocoService.translate('locale')

                    // Set html lang attribute
                    document.documentElement.lang = activeLang;
                    
                    // Update app locale
                    this._dateAdapter.setLocale(locale);
                    // Update the navigation
                    this._updateNavigation(activeLang);
            
    
            }})
        )

        // Set the country iso codes for languages for flags
        this.flagCodes = ApplicationData.AppFlagCodes
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

    changeLanguageInUrl(lang: string): void {
        const currentPath = this._location.path(); // Get the current URL after the domain
        const segments = currentPath.split('/'); // Split the URL into segments
    
        if (segments[1] !== lang) {
          // Replace the first segment with the new language
          segments[1] = lang;
    
          // Reconstruct the URL
          const newPath = segments.join('/');
    
          // Update the browser's address bar without firing router events
          this._location.replaceState(newPath);
        }
      }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void
    {
        
        localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE', lang)
        this.changeLanguageInUrl(lang)

        this._spalshScreenService.show()
        if(!this._authService.isLoggedIn()) {
            window.location.reload()
            return
        }
       
        // Save user default lanugage
        this._saveUserLanugage(lang)
      
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _saveUserLanugage(lang) {

        if(!this._authService.isLoggedIn()) return

        this._apiService.changeLanguage(lang)
        .pipe(take(1))
        .subscribe({
          next: (response: IAPIResponse) => {
            if(response.status !== 'success') return
            window.location.reload()
          }
        })
      }
    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void
    {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if ( !navComponent )
        {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;
        // Refresh the navigation component
        navComponent.refresh();
        // // Get the Project dashboard item and update its title
        // const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        // if ( projectDashboardItem )
        // {
        //     this._translocoService.selectTranslate('Project').pipe(take(1))
        //         .subscribe((translation) =>
        //         {
        //             // Set the title
        //             projectDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }

        // // Get the Analytics dashboard item and update its title
        // const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        // if ( analyticsDashboardItem )
        // {
        //     this._translocoService.selectTranslate('Analytics').pipe(take(1))
        //         .subscribe((translation) =>
        //         {
        //             // Set the title
        //             analyticsDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }
    }
}
