import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslocoModule} from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SourcesService } from '../../services/sources.service';

@Component({
  standalone: true,
  selector: 'app-source-avatar',
  templateUrl: './source-avatar.component.html',
  styleUrls: ['./source-avatar.component.scss'],
  imports: [CommonModule, MatIconModule, MatTooltipModule, TranslocoModule],
})
export class SourceAvatarComponent implements OnChanges {

  subscriptions: Subscription[] = [];

  @Input() sourceId = null;
  @Input() showDefault = false;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  source = null;

  constructor(
    private _sourcesService: SourcesService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.sourceId == null) return
    this.source = this._sourcesService.getSourceById(this.sourceId)
  }

}
