<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            <!-- create -->
            <ng-container>
                <p class="text-lg font-medium">
                    <span *ngIf="mode == 'create'">{{'dodaj_klienta' | transloco}}
                    </span>
                    <span *ngIf="mode == 'update'">{{ client.status == 1 ? ('edytuj_klienta' | transloco) :
                        ('dane_klienta' | transloco)}}
                        <span *ngIf="clientForm.controls.clientId.value != null"
                            class="h-6 min-h-6 ml-2 px-2 py-1 rounded-full bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50">
                            <span class="font-medium text-sm">ID: {{clientForm.controls.clientId.value}}</span>
                        </span>
                    </span>
                </p>
                <div class="ml-auto">
                    <!-- close -->
                    <button mat-icon-button [matTooltip]="'Close'" (click)="close()">
                        <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                    </button>
                </div>
            </ng-container>

        </div>
    </div>

    <div class="relative flex flex-col items-center">
        <!-- flex-auto -->
        <div class="w-full max-w-3xl">
            <!-- form -->
            <!-- tabs -->
            <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex" (selectedIndexChange)="changeTab($event)"
                class="vertically-tabs p-0-content" mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight
                dynamicWidth>

                <mat-tab>
                    <ng-template matTabLabel>
                        {{'klient' | transloco}}
                    </ng-template>
                </mat-tab>

                <mat-tab *ngIf="mode == 'update'" [label]="">
                    <ng-template matTabLabel>
                        {{'rezerwacje' | transloco}}
                    </ng-template>
                </mat-tab>

            </mat-tab-group>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <ng-container>
                <div class="px-6 py-4">
                    <div *ngIf="tabIndex == 0" class="grid sm:grid-cols-4 gap-6 w-full">

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-icon matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
                                <mat-label>{{'imie' | transloco}}</mat-label>
                                <input matInput type="text" [formControl]="clientForm.controls.forename" />
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>{{'nazwisko' | transloco}}</mat-label>
                                <input matInput type="text" [formControl]="clientForm.controls.name" />
                                <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                <mat-error *ngIf="clientForm.controls['name'].hasError('required')">
                                    {{'to_pole_jest_wymagane' | transloco}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-icon matPrefix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
                                <mat-label>{{'telefon' | transloco}}</mat-label>
                                <input matInput type="text" [formControl]="clientForm.controls.phone" />
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-icon matPrefix [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
                                <mat-label>{{'email' | transloco}}</mat-label>
                                <input matInput type="text" [formControl]="clientForm.controls.email" />
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-4" *ngIf="formFields.includes('moreClientData')">
                            <mat-slide-toggle (change)="show.more_client_data = !show.more_client_data">
                                {{'wiecej_danych_klienta' | transloco}}</mat-slide-toggle>
                        </div>
                        <div *ngIf="formFields.includes('moreClientData')" class="sm:col-span-4"
                            [style.display]="show.more_client_data == true ? 'block' : 'none'">
                            <!--*ngIf="show.more_client_data == true"-->
                            <div class="grid sm:grid-cols-4 gap-6 w-full">
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:identification'"></mat-icon>
                                        <mat-label>{{'nr_dowodu' | transloco}}</mat-label>
                                        <input matInput type="text" [formControl]="clientForm.controls.idCard" />
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:finger-print'"></mat-icon>
                                        <mat-label>{{'pesel' | transloco}}</mat-label>
                                        <input matInput type="text" [formControl]="clientForm.controls.personalId" />
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:building-office'"></mat-icon>
                                        <mat-label>{{'nazwa_firmy' | transloco}}</mat-label>
                                        <input matInput type="text" [formControl]="clientForm.controls.companyName" />
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:hashtag'"></mat-icon>
                                        <mat-label>{{'nip' | transloco}}</mat-label>
                                        <input matInput type="text" [formControl]="clientForm.controls.taxId" />
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-search-select [label]="translate.translate('narodowosc')"
                                        [displayProperty]="'name'" [value]="clientForm.controls.countryId.value"
                                        (valueChanges)="setCountry($event)" [options]="countries"
                                        [matPrefixIcon]="'heroicons_solid:map-pin'"></mat-search-select>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:truck'"></mat-icon>
                                        <mat-label>{{'nr_rejestracyjny_pojazdu' | transloco}}</mat-label>
                                        <input matInput type="text"
                                            [formControl]="clientForm.controls.vehicleRegistrationNumber" />
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:map-pin'"></mat-icon>
                                        <mat-label>{{'adres' | transloco}}</mat-label>
                                        <textarea matInput type="text" [formControl]="clientForm.controls.address"
                                            cdkAutosizeMinRows="2"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                        <mat-icon matPrefix
                                            [svgIcon]="'heroicons_solid:chat-bubble-bottom-center-text'"></mat-icon>
                                        <mat-label>{{'uwagi' | transloco}}</mat-label>
                                        <textarea matInput type="text" [formControl]="clientForm.controls.comments"
                                            cdkAutosizeMinRows="2"></textarea>
                                    </mat-form-field>
                                </div>


                                <div class="sm:col-span-2">
                                    <mat-search-select [label]="translate.translate('jezyk')" [displayProperty]="'name'"
                                        [valueProperty]="'shortName'" [value]="clientForm.controls.lang.value"
                                        (valueChanges)="setLanguage($event)" [options]="languages"
                                        [matPrefixIcon]="'heroicons_solid:language'">
                                    </mat-search-select>
                                </div>

                                <div class="sm:col-span-4">
                                    <mat-label class="font-medium">{{'status_klienta' | transloco}}</mat-label>
                                    <br />
                                    <mat-radio-group color="primary" [formControl]="clientForm.controls.clientType">
                                        <mat-radio-button [value]="clientType.id"
                                            *ngFor="let clientType of clientTypes">{{clientType.translation |
                                            transloco}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="tabIndex == 1" class="grid sm:grid-cols-4 gap-6 w-full">

                        <div class="col-span-12" *ngFor="let reservation of reservations">
                           <app-reservation-row [reservation]="reservation" [showMoreButton]="true"></app-reservation-row>
                        </div>
                       
                    </div>
                </div>


                <div
                    class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
                    <div class="flex items-center">
                        <button color="warn" class="me-2" mat-button (click)="deleteClient()"
                            *ngIf="mode == 'update' && client?.status != 0">
                            {{"usun" | transloco}}
                        </button>


                        <button class="ms-auto" [disabled]="processing" *ngIf="mode == 'create'" mat-raised-button
                            color="primary" (click)="createClient()">
                            {{'dodaj_klienta' | transloco}}
                        </button>

                        <button class="ml-auto" [disabled]="processing" *ngIf="mode == 'update' && client.status == 1"
                            mat-raised-button color="primary" (click)="updateClient()">
                            {{'zapisz_zmiany' | transloco}}
                        </button>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>