import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  standalone: true,
  selector: 'app-add-note-dialog',
  templateUrl: './add-note-dialog.component.html',
  styleUrls: ['./add-note-dialog.component.scss'],
  imports: [MatDialogModule, ReactiveFormsModule, MatIconModule, TranslocoModule]
})
export class AddNoteDialogComponent {

note = null;
form = this.formBuilder.group({
  title: new FormControl(''),
  description: new FormControl(''),
})

constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialogRef<AddNoteDialogComponent>,
  public translate:TranslocoService,
  private formBuilder:FormBuilder
)
{
  if (typeof data.note !== 'undefined' && data.note != null) { 
    this.note = data.note
    if(typeof data.note.title !== 'undefined') this.form.controls.title.setValue(data.note.title)
    if(typeof data.note.description !== 'undefined') this.form.controls.description.setValue(data.note.description)
  }

}

saveOrCreate()
{

}



close(){
  this.dialog.close()
}

}
