import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { Utils } from '../../others/utils';
import { DateFormatterComponent } from '../date-formatter/date-formatter.component';
import { PanelService } from '../../services/panel.service';

@Component({
  selector: 'log-action-name',
  standalone: true,
  imports: [CommonModule, JsonPipe, DateFormatterComponent, TranslocoModule],
  templateUrl: './log-action-name.component.html',
  styleUrl: './log-action-name.component.scss'
})
export class LogActionNameComponent implements OnChanges {

  @Input('log') log

  isExpanded = false
  // Counting from 0, 2 = 3 records
  expandableLimit = 2
  constructor(private _panelService:PanelService) {
  
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  isLock(reservationOrReservations) {
    return Utils.isLock(reservationOrReservations)
  }

   
  showReservation(reservation) {
    this._panelService.openUpdateReservationPanel(reservation)
  }

  showClient(client) {
    this._panelService.showCreateOrUpdateClient('update', client)
  }

  showRoom(room) {
    this._panelService.showCreateOrUpdateRoom('update', room)
  }
}
