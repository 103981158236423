import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[advanceRes]'
})
export class AdvanceResDirective {

  private regex: RegExp = new RegExp(/^[0-9]*[HhDd]?$/);

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Check if the value matches the regex
    if (!this.regex.test(value)) {
      value = value.slice(0, -1); // Remove the last character if it doesn't match
    }

    // Capitalize the last letter if it's 'h' or 'd'
    if (value.length > 0) {
      const lastChar = value.charAt(value.length - 1);
      if (lastChar === 'h' || lastChar === 'd') {
        value = value.slice(0, -1) + lastChar.toUpperCase();
      }
    }

    input.value = value;
  }
}