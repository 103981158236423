import { Component, HostListener, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DATA_INJECTION_TOKEN } from '../../others/data-injection-token';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-image-gallery-dialog',
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatIconModule, MatButtonModule],
  templateUrl: './image-gallery-dialog.component.html',
  styleUrl: './image-gallery-dialog.component.scss'
})
export class ImageGalleryDialogComponent {

  currentImageIndex = 0
  images = []
  selectedImage
  isLoading = true
  loadedImagesCount = 0

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<ImageGalleryDialogComponent>,
  ) {

    if(typeof data.images != 'undefined') {
      this.images = data.images;
    }
    
    if(typeof data.currentImageIndex != 'undefined') {
      this.currentImageIndex = data.currentImageIndex
      this.selectImage(this.currentImageIndex)
    }
    
  }

  close() {
    this.dialog.close()
  }

  imageLoaded() {
    // Increment the count of loaded images
    this.loadedImagesCount++;

    // Check if all images have been loaded
    if (this.loadedImagesCount === this.images.length) {
      // If all images are loaded, set isLoading to false
      this.isLoading = false;
    }
  }

  next() {
    this.currentImageIndex++
    if(this.currentImageIndex >= this.images.length) this.currentImageIndex = 0
    this.selectImage(this.currentImageIndex)
  }

  selectImage(index:number) {
    this.selectedImage = this.images[index]
    this.currentImageIndex = index
  }

  prev() {
    this.currentImageIndex--
    if(this.currentImageIndex == -1) this.currentImageIndex = this.images.length -1
    this.selectImage(this.currentImageIndex)
  }
  
  
  @HostListener('window:keydown.arrowleft', ['$event'])
  handleLeftArrowKey(event: KeyboardEvent) {
    event.preventDefault(); // Prevent default browser behavior for arrow keys
    this.prev(); // Call method to handle previous item logic
  }

  // Method to handle right arrow key press
  @HostListener('window:keydown.arrowright', ['$event'])
  handleRightArrowKey(event: KeyboardEvent) {
    event.preventDefault(); // Prevent default browser behavior for arrow keys
    this.next(); // Call method to handle next item logic
  }
}
