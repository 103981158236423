import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'taxRate'
})
export class TaxRatePipe implements PipeTransform {
    transform(value: any): any {
      if (!isNaN(value)) {
        return parseFloat(value) + '%';
      } else {
        return value;
      }
    }
  }
