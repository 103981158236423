import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit,  OnDestroy, Inject, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DATA_INJECTION_TOKEN } from '../../others/data-injection-token';
import { PanelService } from '../../services/panel.service';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { cloneDeep } from 'lodash-es';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReservationRowComponent } from '../../components/reservation-row/reservation-row.component'
import { Utils } from '../../others/utils';
import { PaymentStatuses } from '../../models/payment-statuses.enum';
import { DbReservation } from '../../models/DbReservation';
import { ReservationsService } from '../../services/reservations.service';
import { DataSynchronizerService } from '../../services/data-synchronizer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reservations-list-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule, MatTooltipModule, TranslocoModule, MatButtonToggleModule, MatButtonModule, forwardRef(() => ReservationRowComponent)],
  templateUrl: './reservations-list-dialog.component.html',
  styleUrl: './reservations-list-dialog.component.scss'
})
export class ReservationsListDialogComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = []

  reservationRegistrationType = 'arrivals'
  title = 'rezerwacje'

  table = 'arrivals'
  reservations = []
  filteredReservations = []

  constructor(
    @Inject(DATA_INJECTION_TOKEN) public data: any,
    private _panelService: PanelService,
    private _reservationsService: ReservationsService,
    private _dataSynchronizerService: DataSynchronizerService,
    private _cdr: ChangeDetectorRef
  ) {

    if (typeof data.title != 'undefined') {
      this.title = data.title
    }

    if (typeof data.reservations != 'undefined') {
      this.reservations = data.reservations
      for(let r of this.reservations) {
        this.filteredReservations.push(r)
      }
    }

    if (typeof data.table != 'undefined') {
      this.table = data.table
    }

    this.reservationRegistrationType = 'all'

  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
      next: (data) => {

        if(data.table != 'reservations') return

          for(let i=0; i<this.reservations.length;i++) {
            this.reservations[i] = this._reservationsService.getById(this.reservations[i].reservationId)
          }
        
          this.filteredReservations = this.reservations
          this._filterReservations()
       
        }
    }))
  }

  private _filterReservations() {

    if (this.reservationRegistrationType == 'all') {
      this.filteredReservations = this.reservations
    }

    else if (this.reservationRegistrationType == 'no-data') {
      this.filteredReservations = this.reservations.filter(x => x.registration == 0)
    }

    else if (this.reservationRegistrationType == 'checked-in') {
      this.filteredReservations = this.reservations.filter(x => x.registration == 1)
    }

    else if (this.reservationRegistrationType == 'checked-out') {
      this.filteredReservations = this.reservations.filter(x => x.registration == 2)
    }

    else if (this.reservationRegistrationType == 'no-prepayment') {
      const today = new Date()
      this.filteredReservations = this.reservations.filter((x:DbReservation) => Utils.getPaymentStatus(today, x.paymentStatus, x.prepaymentDeadline) == PaymentStatuses.uplynal_termin_wplaty_zaliczki)
    }

    else if (this.reservationRegistrationType == 'awaiting-for-prepayment') {
      const today = new Date()
      this.filteredReservations = this.reservations.filter((x:DbReservation) => Utils.getPaymentStatus(today, x.paymentStatus, x.prepaymentDeadline) == PaymentStatuses.oczekiwanie_na_zaliczke)
    }
 
  }
  
  public onReservationRegistrationTypesChange(ev) {

    const { value } = ev
    this.reservationRegistrationType = value

    this._filterReservations()

  }

  public close() {
    this._panelService.close();
    this._panelService.clearPanelPortal();
  }

  ngOnDestroy(): void {
    this.reservations = null
    this.filteredReservations = null
    this._subscriptions.forEach(sub=>sub.unsubscribe())
    this._cdr.markForCheck()
  }

}
