import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[replaceCommaByDot]',
})
export class ReplaceCommaByDotDirective {
    constructor(
      private el: ElementRef,
      private control?: NgControl, 
      )
      {

      }

    @HostListener('input', ['$event.target.value'])
    onInputChange(value: any) {

      value = value.replace(',', '.');
      value = value.replace(/[^.\d]/g, '');

      if(value.includes('.')) {
        let res = '';
        const parts = value.split('.');
          parts.forEach((part,index) => {
            if(index == 0 && part.length > 0)
            {
              res = part +'.'
            }
            else if(index == 1 && part.length > 0)
            {
              res = res + part.slice(0,2);
            }
        });

        value = res;
      }


      // Fix for 0123 numbers passed
      if(value.length > 1 && value[0] == '0')
      {
        value = Number(value)
      }
      
      // Parse to float
      if(typeof value == 'string' && !value.includes('.')) {
        value = parseFloat(value)
      }

      if(Number.isNaN(value)) value = ''
      this.el.nativeElement.value = value;
      if(typeof this.control != 'undefined')
      {
        this.control.control.setValue(value);
        this.control.control.updateValueAndValidity();
      }

    }

    @HostListener('blur')
    onBlur() {
      let value = this.el.nativeElement.value;
  
      if (value === '') {
        value = 0;
        this.el.nativeElement.value = value;
  
        if (this.control) {
          this.control.control.setValue(value);
          this.control.control.updateValueAndValidity();
        }
      }
    }
  }