import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxColorsModule } from 'ngx-colors';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-create-or-update-source-dialog',
  standalone: true,
  templateUrl: './create-or-update-source-dialog.component.html',
  styleUrl: './create-or-update-source-dialog.component.scss',
  imports: [CommonModule, MatDialogModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, TranslocoModule, MatIconModule, NgxColorsModule, MatButtonModule]
})
export class CreateOrUpdateSourceDialogComponent {

  colorPickerControls: "default" | "only-alpha" | "no-alpha" = "no-alpha";

  form = new UntypedFormGroup({
    sourceId: new UntypedFormControl(),
    name: new UntypedFormControl(),
    color: new UntypedFormControl(),
    colorPckr: new UntypedFormControl(),
  })

  constructor(
    private apiService:ApiService,
    public translate:TranslocoService,
    private dialog: MatDialogRef<CreateOrUpdateSourceDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(!data.source) return
    if(typeof data.source == 'undefined') return

    this.form.controls.sourceId.setValue(data.source.sourceId)
    this.form.controls.name.setValue(data.source.name)
    this.form.controls.color.setValue(data.source.color)
    this.form.controls.colorPckr.setValue(data.source.color)
    
  }

  saveOrCreate() {

  }

  updateColor(ev) {
    this.form.controls.color.setValue(ev)
    this.form.controls.colorPckr.setValue(ev)
  }

  close() {
    this.dialog.close()
  }
}
