export class DbClient {
    clientId: number;
    userId?: string;
    status?: number;
    forename?: string;
    name?: string;
    phone?: string;
    email?: string;
    taxId?: string;
    companyName?: string;
    personalId?: string;
    idCard?: string;
    clientType?: number;
    lang?: string;
    address?: string;
    comments?: string;
    vehicleRegistrationNumber?: string;
    countryId?: number;
    addDate?: string;
    editDate?: string;
    calendarId?: null;
    bookingId?: null;
    liczba_rezerwacji?: number = 0
    reservations?: [] = []
    canceledReservations?: [] = []

    forenameToLowerCase?: string
    nameToLowerCase?: string
    constructor(object) {
        Object.keys(object).forEach(key => {
            this[key] = object[key]
        })
        this.forenameToLowerCase = this.forename.toLowerCase()
        this.nameToLowerCase = this.name.toLowerCase()
        
    }
}