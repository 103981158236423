import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { PanelService } from '../../services/panel.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatRippleModule } from '@angular/material/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApplicationData } from '../../data/application-data';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription, finalize, take } from 'rxjs';
import { PricingChannelService } from '../../services/pricing-channel.service';
import { RoomsService } from '../../services/rooms.service';
import { Utils } from '../../others/utils';
import { cloneDeep } from 'lodash';
import { ApiService } from '../../services/api.service';
import { SynchronizationService } from '../../services/synchronization.service';
import { DateTime } from 'luxon';
import { SnackbarService } from '../../services/snackbar.service';
import { ReplaceCommaByDotDirective } from '../../directives/replace-comma-by-dot.directive';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntegerOnlyDirective } from '../../directives/integer-only.directive';
import { SettingsService } from '../../services/settings.service';
import { UserSettings } from '../../models/UserSettings';
import { AdvanceResDirective } from '../../directives/advance-res.directive';

@Component({
  selector: 'app-changes-in-bulk-dialog',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, AdvanceResDirective, MatInputModule, IntegerOnlyDirective, TranslocoModule, MatSelectModule, MatSlideToggleModule, ReplaceCommaByDotDirective, MatDatepickerModule, MatButtonModule, MatIconModule, MatCheckboxModule, ScrollingModule, MatTooltipModule, MatRippleModule, ReactiveFormsModule],
  templateUrl: './changes-in-bulk-dialog.component.html',
  styleUrl: './changes-in-bulk-dialog.component.scss'
})
export class ChangesInBulkDialogComponent implements OnInit, OnDestroy{

  private _subscriptions:Subscription[] = []
  objects = []

  rates = []
  restrictions = []
  availableRestrictions = []


  startControl = new FormControl(null, Validators.required)
  endControl = new FormControl(null, Validators.required)
  
  rateControl = new FormControl(null, Validators.required)
  priceControl = new FormControl('')
  searchControl = new FormControl()

  restrictionSubscriptions:Subscription[] = []
  restrictionControls = [
    { 
      control: new FormControl(null, Validators.required),
      valueControl: new FormControl('')
    }
  ]

  availableWeekDays = ApplicationData.Days
  weekDaysControls:any = []
  selectedObjectsControl = new FormControl([])

  selectedIds = new Set();
  channel

  filteredObjects = []

  isProcessing = false
  isDialog = false
  constructor(
    private _pricingChannelService:PricingChannelService,
    private _panelService: PanelService,
    private _roomsService:RoomsService,
    private _apiService:ApiService,
    private _synchronizationService:SynchronizationService,
    private _snackbarService:SnackbarService,
    public translate:TranslocoService,
    private _dialogRef: MatDialogRef<ChangesInBulkDialogComponent>,
    private _settingsService:SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  {
    if (typeof data.isDialog != 'undefined') {
      this.isDialog = data.isDialog
    }
    
    this._subscriptions.push(this._pricingChannelService.currentChannel.subscribe({
      next: (data) => {
        this.channel = data

        this.restrictions = this.channel.restrictions
        this._setAvailableRestrictions()

        if (this.channel.id != 'mobile-calendar') {
          this.channel.rooms.forEach(room => {
            const roomNames = []
            room.roomNamesString = ''
            if (room?.roomIds?.length > 0) {
              room.roomIds.forEach((id,index) => {
                const foundRoom = this._roomsService.getById(id)
                if (foundRoom) {
                  roomNames.push(foundRoom.name)
                  if(room) room.roomNamesString += foundRoom.name
                  if(index != room.roomIds.length - 1) room.roomNamesString += ', '
                }
              });

            }
            room.roomNames = roomNames
          });
        }

        this.selectedIds = new Set()
        this.restrictionSubscriptions.forEach(sub=>sub.unsubscribe())

        this.restrictionControls = []
        this.addRestriction()
        
        
        this.filteredObjects = this.channel.rooms
        this._getRates()

    }}))

    this._subscriptions.push(
      this.searchControl.valueChanges.subscribe({
        next: (data) => {
          this.filterObjects()
        }
      })
    )

    this._subscriptions.push(
      this.rateControl.valueChanges.subscribe({
        next: (data) => {
          this._setAvailableRestrictions()
          this.filterObjects()
        }
      })
    )

    this._recreateWeekDaysControls()
  }

  private _recreateWeekDaysControls() {
    this.weekDaysControls = []
    this.availableWeekDays.forEach(day=> {
      this.weekDaysControls.push( 
        {
          id: day.id,
          translation: day.translation,
          control: new FormControl(true)
        }
      )
    })
  }

  private _reorderDays(firstWeekDay: number) {
    this.availableWeekDays = cloneDeep(ApplicationData.Days)
    if (firstWeekDay === 0) {
      // Move 'Sun' to the first position
      const sunIndex = this.availableWeekDays.findIndex(day => day.id === 0);
      if (sunIndex !== -1) {
        const sunDay = this.availableWeekDays.splice(sunIndex, 1)[0];
        this.availableWeekDays.unshift(sunDay);
      }

      this._recreateWeekDaysControls()
    }
  }

  // Filter restrictions per rate
  private _setAvailableRestrictions() {
    this.availableRestrictions = cloneDeep(this.channel.restrictions)

    const rateId = this.rateControl.value
    if(!rateId) return 

    const selectedRate = this.rates.find(x=>x.rateId == rateId)
    if(!selectedRate) return
    
    if(!Utils.isDefined(selectedRate,"pricing.price1")) return
    const price1 = Utils.getNestedValue(selectedRate, "pricing.price1")
    if(price1 == true) return

    this.availableRestrictions = this.availableRestrictions.filter(x=>x.id != 'price1')
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._settingsService.getUserSettings().subscribe({
        next: (userSettings:UserSettings) => {

          if(Utils.isDefined(userSettings, "settings.generalFirstDayOfWeek")) {
            if(userSettings.settings.generalFirstDayOfWeek == 'SUN') {
              this._reorderDays(0)
            }
          }

          if(Utils.isDefined(userSettings, "userData.currency")) {
            this.defaultCurrency = userSettings.userData.currency
          }
        }
      })
    )
  }

  filterObjects() {
    const rateId = this.rateControl.value
    let searchRoom = this.searchControl.value
    const filters = []

    if(!Utils.isNullOrEmpty(rateId)) {
      filters.push(x => x.rates.some(x=>x.rateId == rateId))
    }

    if(!Utils.isNullOrEmpty(searchRoom)) {
      searchRoom = searchRoom.toLowerCase()
      filters.push(
        x => {
          return x.name.toString().toLowerCase().includes(searchRoom) ||
          ((typeof x?.roomNamesString != 'undefined') && x?.roomNamesString?.toLowerCase()?.includes(searchRoom))
        }
        
      )
    }

    // if(!Utils.isNullOrEmpty(searchRoom) && this.channel.pricingId == 'booking') {
    //   searchRoom = searchRoom.toLowerCase()
    //   filters.push(x => x.roomNames.some(searchRoom))
    // }

    this.filteredObjects = cloneDeep(this.channel.rooms)
    filters.forEach( f => {
      this.filteredObjects = this.filteredObjects.filter(f)
    })

    this.selectedIds.forEach(id=> {
      if(!this.filteredObjects.some(x=>x.id == id)) this.selectedIds.delete(id)
    })
  }
  public areDaysSelected() {
    let selectedDays = 0
    for(let d=0;d<this.weekDaysControls.length;d++) {
      const isSelected = this.weekDaysControls[d].control.value
      if(isSelected) selectedDays ++;
    }

    if(selectedDays == 0) return false
    return true
  }
  public isFormValid() {

    const areDaysSelected = this.areDaysSelected()

    if(!areDaysSelected) return false
    if(Utils.isNullOrEmpty(this.startControl.value)) return false
    if(Utils.isNullOrEmpty(this.endControl.value)) return false
    if(Utils.isNullOrEmpty(this.rateControl.value)) return false
    if(this.setRestrictions == false && this.setPrices == false) return false

    if(this.setPrices) {
      // if(Utils.isNullOrEmpty(this.priceControl.value)) return false
    }

    if(this.setRestrictions) {
      for(let i=0;i<this.restrictionControls.length;i++) {
        const restrictionControl = this.restrictionControls[i]
        if(Utils.isNullOrEmpty(restrictionControl.control.value)) return false
        // if(Utils.isNullOrEmpty(restrictionControl.valueControl.value)) return false
      }
    }

    if(this.selectedIds.size == 0) return false
    return true
  }

  public clear() {
    this.searchControl.setValue('')
  }

  public isRestrictionAvailable(id:string) {
    for(let r=0;r<this.restrictionControls.length; r++) {
      const restrictionControl = this.restrictionControls[r]

      if(restrictionControl.control.value == id) return false
    }
    return true
  }

  public getRestrictionType(id:string) {
    const restriction = this.restrictions.find(x=>x.id == id)
    if(restriction) return restriction.type
    return 'text'
  }

  public addRestriction() {

    const restriction = {
        control: new FormControl(),
        valueControl: new FormControl('')
    }
    
    this.restrictionControls.push(restriction)

    // When control is changing value should be reset
    // Control value changes
    this.restrictionSubscriptions.push(
      restriction.control.valueChanges.subscribe({
        next: (value) => {
          restriction.valueControl.setValue(null)
        }
      })
    )
    // Value control changes
    // this.restrictionSubscriptions.push(
    //   restriction.valueControl.valueChanges.subscribe({
    //     next: (value) => {

    //     }
    //   })
    // )

  }

  public deleteRestriction(index:number) {
    this.restrictionControls.splice(index,1)
  }

  public selectAll() {
    this.filteredObjects.forEach(room=> {
      this.selectedIds.add(room.id)
    })
  }

  public deSelectAll() {
    this.selectedIds = new Set()
  }
  public toggle(id) {
    if (this.selectedIds.has(id)) this.selectedIds.delete(id)
    else this.selectedIds.add(id)
  }

  ngOnDestroy(): void {
    this.restrictionSubscriptions.forEach(sub=>sub.unsubscribe())
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  close() {

    if(this.isDialog) {
      this._dialogRef.close()
      return
    }

    this._panelService.close();
    this._panelService.clearPanelPortal();
  }

  setPrices = false;
  setRestrictions = false;
  defaultCurrency = 'PLN';

  private _getRates() {
    this.rates = []
    this.channel.rooms.forEach(room => {
      room.rates.forEach(rate => {
        const index = this.rates.findIndex(x => x.rateId == rate.rateId)
        if (index == -1) {
          rate.roomId = room.id
          this.rates.push(rate)
        } 
      })
    })

    this._getRatesRooms()
  }
  
  private _getRatesRooms() {
    this.rates.forEach(rate => {
      rate.roomNames = ''
      const roomIds = []

      this.channel.rooms.forEach(room => {

        if(!Utils.isDefined(room, "roomIds")) return
        
        if(room.rates.some(x=>x.rateId == rate.rateId)) {
          roomIds.push(...room.roomIds)
        }
      })

      for(let roomId of roomIds) {
        const room = this._roomsService.getById(roomId)
        if(room) {
          rate.roomNames += room.name
          if(roomIds.indexOf(roomId) != roomIds.length - 1) rate.roomNames += ', '
        }
      }
    })
  }

  update() {
    if(!this.isFormValid()) return
    
    const rooms = Array.from(this.selectedIds)
    const request:any = {}

    if(this.setPrices) {
      request.price = this.priceControl.value
    }

    if(this.setRestrictions) {
      this.restrictionControls.forEach(restrictionControl => {
        request[restrictionControl.control.value] = restrictionControl.valueControl.value
      })
    }

    const days = []
    this.weekDaysControls.forEach(weekDay=> {
      if(weekDay.control.value == true) days.push(weekDay.id)
    })

    const startDate = DateTime.fromISO(this.startControl.value).toISODate()
    const endDate = DateTime.fromISO(this.endControl.value).toISODate()
    this.isProcessing = true

    this._apiService.changePriceOrRestriction( {
      channelId: this.channel.pricingId,
      startDate: startDate,
      endDate: endDate,
      rooms: rooms,
      days: days,
      rateId: this.rateControl.value,
      data: request
    })
    .pipe(finalize(()=> {
      this.isProcessing = false
    }))
    .pipe(take(1))
    .subscribe({
      next: (response:any) => {
        if(response.status == 'success') {
          this._synchronizationService.newSynchronize('Changes in bulk update')
          this._snackbarService.showSnackBar(this.translate.translate('cennik_zostal_zaktualizowany'), 'success')
        }
      }
    })
  }
}
