import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  selector: 'app-configure-group-prices',
  templateUrl: './configure-group-prices.component.html',
  styleUrls: ['./configure-group-prices.component.scss'],
  imports: [MatIconModule, TranslocoModule, MatDialogModule, MatTooltipModule]
})
export class ConfigureGroupPricesComponent {
  constructor()
  {
    
  }
}
