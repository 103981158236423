import { CalendarReservation } from "./CalendarReservation";
import { Ceny } from "./Ceny";
import { DbClient } from "./DbClient";
import { DbEmployee } from "./DbEmployee";
import { DbRoom } from "./DbRoom";
import { IAdditionalService, ReservationPricingSummary } from "./ReservationPricingSummary";

export class DbReservation {
    userId?:         number;
    reservationId:    number;
    reservationNo?:    number;
    status:           number;
    roomId?:        number;
    room?: DbRoom;
    clientId?:       number;
    additionalInfo?:        string;
    arrival?:         string;
    departure?:           string;
    checkIn?:          string;
    checkOut?:         string;
    registration?:         number;
    prepayment?:         number;
    prepaymentDeadline?:      string;
    paymentStatus?:      number;
    price?:    number;
    adults?:      number;
    children?:    number;
    currency?:           string;
    groupId?:         number;
    addDate?:     string;
    editDate?:        string;
    meal?:       number;
    adultsPortion?:   number;
    childrenPortion?:    number;
    // additionalServices?: string;
    sourceId?:           number;
    color?:            string;
    paymentType?: number;
    employeeId?:     number;
    doorCode?:     string;
    icalUid?:         string;
    idIcal?:          null;
    lastSync?:        null;
    calendarId?:      null;
    bookingDetails?:  null;
    bookingId?:       null;
    client?:           DbClient;
    calendarReservation?:       CalendarReservation;
    sentMessages?:           number;
    user?: DbEmployee;
    arrivalTimestamp?: number
    departureTimestamp?: number
    
    hasPermission: boolean = true

    // Price calculations
    pricePerRoomPerNight: number
    pricePerMeal: number
    pricePerRoom: number
    pricePerNight: number
    pricePerAdditionalServices: number
    discount: number
    discountType: number
    additionalServices: IAdditionalService[]
    kidsAge: number[]

    // Always save reservations through constructor
    // avoiding invalid types

    // Virtual fields
    pricingSummary:ReservationPricingSummary
    mealName:string
    additionalServicesText:string
    constructor(obj) {
        Object.keys(obj).forEach(key => {
           
            this[key] = obj[key]

            if(key == 'arrival') {
                this.arrivalTimestamp = new Date(obj[key]).getTime()
            }
            if(key == 'departure') {
                this.departureTimestamp = new Date(obj[key]).getTime()
            }
        })  
    }
}

export class ReservationPriceDetails {
    pricePerRoomPerNight: number
    pricePerMeal: number
    pricePerRoom: number
    pricePerNight: number
    pricePerAdditionalServices: number
    discount: number
    discountType: number
    additionalServices 
    kidsAge: number[]
    constructor(obj) {
        
        if(obj == null) return
        if(typeof obj == 'undefined') return

        Object.keys(obj).forEach(key => {
            if(key == 'discountType') {
                this[key] = parseInt(obj[key])
            }
            if(
                key == 'kidsAge' ||
                key == 'additionalServices') {
                this[key] = obj[key]
            }
            else {
                this[key] = parseFloat(obj[key])
            }
        })  
    }
}