import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Utils } from '../../others/utils';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FuseAlertComponent } from '@fuse/components/alert';
import { fuseAnimations } from '@fuse/animations';
import { BookingReservationResponse, BookingRoomReservation } from '../../models/BookingReservationResponse';

@Component({
  selector: 'app-show-reservation-booking-data-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule, MatTooltipModule, TranslocoModule, MatDividerModule, MatButtonModule, MatTabsModule, MatFormFieldModule, MatInputModule, FuseAlertComponent],
  templateUrl: './show-reservation-booking-data-dialog.component.html',
  styleUrl: './show-reservation-booking-data-dialog.component.scss',
  animations: fuseAnimations
})
export class ShowReservationBookingDataDialogComponent {

  cards = {
    "Visa": 'assets/images/credit-cards/visa.png',
    "MasterCard": 'assets/images/credit-cards/master-card.png',
    "American Express": 'assets/images/credit-cards/american-express.png',
    "DinersClub": 'assets/images/credit-cards/diners-club.svg',
    "Discover": 'assets/images/credit-cards/discover.jpg',
    "JCB": 'assets/images/credit-cards/jcb.png',
  }

  b: BookingReservationResponse;
  bookingRoomReservation: BookingRoomReservation = null

  cancelPenalties:any = []
  taxesAndFees:any = []
  ratesDays:any = []
  sum = 0;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslocoService
  ) {

    if (typeof data.reservation == 'undefined') return
    if (!Utils.isDefined(data, "reservation.bookingDetails")) return

    this.b = new BookingReservationResponse(data.reservation.bookingDetails)
    if (Utils.isDefined(this.b, "booking.room")) {

      this.sum = 0;

      this.b.booking.room.forEach(room => {

        const id = `${this.b.booking.id}-${room.roomreservation_id}`

        if (id == data.reservation.bookingId) {
          this.bookingRoomReservation = room
        }

        if (Utils.isDefined(room, "price_details.guest.total")) {
          this.sum += parseFloat(room.price_details.guest.total)
        }

      })

      this.sum = parseFloat(this.sum.toFixed(2))
      // Get cancel penalties
      if(Utils.isDefined(this.bookingRoomReservation,"cancel_penalties.cancel_penalty"))
      {
        const cancelPentalty = this.bookingRoomReservation.cancel_penalties.cancel_penalty
        if(Array.isArray(cancelPentalty)) {
          cancelPentalty.forEach(c=> {
            const date = Utils.getNestedValue(c,"@attributes.from")
            const value = Utils.getNestedValue(c,"amount_percent.@attributes.amount")
            const currency = Utils.getNestedValue(c,"amount_percent.@attributes.currency_code")
            this._addCancelPenalty(date,value,currency)
          })
        } else {
          const date = Utils.getNestedValue(this.bookingRoomReservation,"cancel_penalties.cancel_penalty.@attributes.from")
          const value = Utils.getNestedValue(this.bookingRoomReservation,"cancel_penalties.cancel_penalty.amount_percent.@attributes.amount")
          const currency = Utils.getNestedValue(this.bookingRoomReservation,"cancel_penalties.cancel_penalty.amount_percent.@attributes.currency_code")
          
          this._addCancelPenalty(date,value,currency)
        }
      }

      if(Utils.isDefined(this.bookingRoomReservation,"price"))
      {
        const price = this.bookingRoomReservation.price
        if(Array.isArray(price)) {
          this.bookingRoomReservation.price.forEach(c=> {
            const rateDay = c['@attributes']
            this.ratesDays.push(rateDay);
          })
        }
        else {
          const rateDay = price['@attributes']
          this.ratesDays.push(rateDay);
        }
      }

      if(Utils.isDefined(this.bookingRoomReservation,"price_details.guest.extracomponent"))
      {
        this.bookingRoomReservation.price_details.guest.extracomponent.forEach(c=> {
          const fee = c['@attributes']
          this.taxesAndFees.push(fee);
        })
      }
    }

  }

  private _addCancelPenalty(date, value, currency) {
    this.cancelPenalties.push(
      {
        date: date,
        value: value,
        currency: currency
      }
    )
  }

  isNullOrEmpty(value) {
    return Utils.isNullOrEmpty(value)
  }

  getRatePlanName(rate_id) {
    if (typeof rate_id == 'undefined') { return ''; }

    let planName = '';
    this.b.room_booking.price.forEach(price => {

    })
  }

  tabIndex = 0;

  prevTab() {
    if (this.tabIndex > 0)
      this.tabIndex--;
  }

  nextTab() {
    this.tabIndex++;
  }

  changeTab($event) {
    this.tabIndex = $event;
  }
  displayVCC = false;
  showVCC() {
    this.displayVCC = true;
  }
}
