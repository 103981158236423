import { ApplicationData } from "../data/application-data";
import { Utils } from "../others/utils";

export class Source {
    sourceId: number
    userId: number
    
    isCustom: boolean
    name: string
    initial: string
    logo: string | null
    color: string = ApplicationData.DefaultSourceBackgroundColor;
    textColor: string
    status: number
    addDate: string
    editDate: string
    isEmoji: boolean = false
    displayName? :string = '' 
    constructor({
        sourceId = null,
        userId,
        name,
        logo,
        color,
        addDate,
        editDate,
        status
    }:{
        sourceId?,
        userId?,
        name?,
        logo?,
        color?,
        addDate?,
        editDate?,
        status?
    }) 
    {
        this.sourceId = sourceId
        this.userId = userId
        this.name = name
        this.displayName = name
        this.addDate = addDate
        this.editDate = editDate
        this.logo = logo

        
        if(!Utils.isNullOrEmpty(name)) {
            this.initial = name.slice(0, 1) || ''
        } else {
            this.initial = ''
        }


        if(this._isEmoji(this.name)) {
            this.isEmoji = true

            if(this.name.length >= 2) {
                this.initial = name.slice(0, 2) || ''
            }
        }

        this.setBackground(color || ApplicationData.DefaultSourceBackgroundColor);

        this.isCustom = this.userId == null ? false : true
        this.status = status
    }

    setBackground(bgColor: string) {
        this.color = bgColor;
        this.textColor = Utils.setTextColorBasedOnBackground(this.color || '#ffffff');
    }

    private _isEmoji(str: string): boolean {
        const emojiRegex = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;
        return emojiRegex.test(str);
    }
}