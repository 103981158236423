import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalendarImagesService {
  public images: { [key: string]: HTMLImageElement } = {};

  logos = ['mobile-calendar', 'booking.com']
  sources = [
    'email',
    'phone',
    'booking',
    'airbnb',
    'hrs',
    'expedia',
    'mobile-calendar',
    'nocowanie',
    'noclegowo',
    '9flats',
    'abritel',
    'agoda',
    'aloha_camp',
    'fewo_direct',
    'flip_key',
    'holiday_lettings',
    'home_to_go',
    'hostel_world',
    'hotelbeds',
    'hotels_com',
    'housetrip',
    'kayak',
    'orbitz',
    'slowhop',
    'traveloka',
    'trip_advisor',
    'trip_com',
    'trip_hobo',
    'vrbo',
    'wimdu',
    'zoover',
  ]

  filenamesAndPaths = [
    // Sources
    { key: 'source_1', path: '/assets/images/sources/phone.png' },
    { key: 'source_2', path: '/assets/images/sources/mail.png' },
    { key: 'source_empty_source', path: '/assets/images/sources/empty_source.png' },
    { key: 'source_ical', path: '/assets/images/sources/ical.png' },
    // Branding
    { key: 'room_badge_booking', path: '/assets/images/room-badges/booking.png' },
    { key: 'room_badge_ical', path: '/assets/images/room-badges/ical.png' },
    { key: 'room_badge_user', path: '/assets/images/room-badges/user-white.svg' },
    // Others
    { key: 'others_lock', path: '/assets/images/others/lock.png' },
    { key: 'others_arrow_right', path: '/assets/images/others/arrow_right.svg' },
    { key: 'others_arrow_bottom', path: '/assets/images/others/arrow_bottom.svg' },
    { key: 'others_arrow_right_white', path: '/assets/images/others/arrow_right_white.svg' },
    { key: 'others_arrow_bottom_white', path: '/assets/images/others/arrow_bottom_white.svg' },
    { key: 'others_check', path: '/assets/images/others/check.svg' },
    { key: 'others_close', path: '/assets/images/others/close.svg' },
    { key: 'others_check_green', path: '/assets/images/others/check_green.svg' },
    { key: 'others_close_red', path: '/assets/images/others/close_red.svg' },
    { key: 'others_user_black', path: '/assets/images/others/user_black.svg' },
    { key: 'others_user_white', path: '/assets/images/others/user_white.svg' },
    { key: 'others_info_black', path: '/assets/images/others/info_black.svg' },
    { key: 'others_info_white', path: '/assets/images/others/info_white.svg' },
    // LOGOS
    { key: 'mobile-calendar', path: '/assets/images/logo/mobile-calendar.svg' },
    { key: 'booking.com', path: '/assets/images/logo/booking.com.svg' },
  ];

  constructor() {
    this.sources.forEach(source => {
      this.filenamesAndPaths.push({
        key: 'source_' + source,
        path: `/assets/images/sources/${source}.png`
      })
    });

    this.logos.forEach(logo => {
      this.filenamesAndPaths.push({
        key: 'logo_' + logo,
        path: `/assets/images/logo/${logo}.svg`
      })
    });

  }

  async loadAll() {
    for (const { key, path } of this.filenamesAndPaths) {
      try {
        await this.loadImage(key, path);
      } catch (error) {
        console.error(`Error loading image (${key}): ${error}`);
      }
    }
    console.log('[CalendarImagesService]: Finished images loader.')
  }

  loadImage(key: string, path: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      if (this.images[key]) {
        resolve(this.images[key]);
      } else {
        const img = new Image();
        img.onload = () => {
          this.images[key] = img;
          resolve(img);
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = path;
      }
    });
  }

  getImage(key: string): HTMLImageElement | null {
    if (typeof this.images[key] == 'undefined') {
      console.warn('[CalendarImagesService]: Image not found: ' + key)
    }
    return this.images[key] || null;
  }

}

