import { Injectable, Provider, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InternetConnectionService } from '../services/internet-connection.service';

@Injectable()
export class OfflineModeInterceptor implements HttpInterceptor {

private readonly _ics: InternetConnectionService = inject(InternetConnectionService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(!request) return next.handle(null)
    if(request instanceof(HttpErrorResponse)) return next.handle(null)
    const clonedRequest = request.clone();

    // Turn off automatically offline mode, when valid request sent.
    return next.handle(clonedRequest).pipe(
      tap(event => {

        if (event instanceof HttpResponse) {
          if (event.status === 200 && request.url.includes('mobile-calendar.com')) {
            this.turnOfflineModeOff();
          }
        }
      })
    );
  }

  private turnOfflineModeOff(): void {
    // Turn offline mode off only if is on
    if(this._ics.offlineMode.value == true) this._ics.offlineModeOff()
  }
}


export const OfflineModeInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: OfflineModeInterceptor,
    multi: true
}